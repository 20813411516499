import { testNavData } from './testNavData';
import { hasClass, btnClickToggleTarget, btnRemoveClass } from '../../lb';
import SVGs from '../../../SVGs/js/svgs';
import { genBreadcrumbs } from '../../layouts/breadcrumbs';
import { SearchGlobal } from "../../../../funnelbackSearch/js/global";

const mobileWidth = 900;
let dataWindowWidth = 0;

function btnRemoveNav() {
    const buttons = document.querySelectorAll('[btn-remove-nav]');
    Object.values(buttons).map((v) => {
        if (v) {
            v.addEventListener('click', function () {
                const headerNavs = document.querySelectorAll('[btn-header-nav]');
                for (const headerNav of headerNavs) {
                    const elemParent = headerNav.parentNode;
                    headerNav.classList.remove('active');
                    elemParent.classList.remove('active');
                    document.querySelector('body').classList.remove('header-nav-active');
                }
            });
        }
        return true;
    });
}
function btnHeaderNav() {
    const buttons = document.querySelectorAll('[btn-header-nav]');
    Object.values(buttons).map((v) => {
        if (v) {
            v.addEventListener('click', function (e) {
                const windowWidth = window.innerWidth;
                const _this = e.target;
                const elemParent = _this.parentNode;
                const body = document.querySelector('body');
                const alreadyActive = hasClass(_this, 'active');
                if (windowWidth > mobileWidth) {
                    Object.values(buttons).map((n) => {
                        n.classList.remove('active');
                        n.classList.remove('transitionIn');
                        n.parentNode.classList.remove('active');
                    });
                }
                _this.classList.add('transitionIn');
                elemParent.classList.add('transitionIn');
                body.classList.remove('header-nav-active');
                setTimeout(function(){
                    _this.classList.remove('transitionIn');
                    elemParent.classList.remove('transitionIn');
                    if (hasClass(_this, 'active') === false && alreadyActive === false) {
                        _this.classList.add('active');
                        elemParent.classList.add('active');
                        body.classList.add('header-nav-active');
                    } else {
                        if (windowWidth <= mobileWidth) {
                            _this.classList.remove('active');
                            elemParent.classList.remove('active');
                            body.classList.remove('header-nav-active');
                        }
                    }
                }, 100);
                e.stopImmediatePropagation();
            });
        }
        return true;
    });
}
function HeaderSticky() {
    window.addEventListener("scroll", function() {
        const top = this.scrollY;
        const header = document.querySelector('header');
        const headerHeight = header && header.offsetHeight?header.offsetHeight:0;
        if (top > headerHeight) {
            header.classList.add('sticky');
        } else {
            header.classList.remove('sticky');
        }
    }, false);
}
function searchForm(dataSettings) {
    const jsonFB = dataSettings?JSON.stringify(dataSettings).replace(/"/g, '&quot;'):'';
    const genHTML = `<form action="${dataSettings && dataSettings.url?dataSettings.url:''}" method="GET" class="header-search--form" role="search" novalidate="novalidate" data-funnelback="${jsonFB}">
        <label for="search-query"></label>
        <input type="text" placeholder="${dataSettings && dataSettings.placeholder?dataSettings.placeholder:''}" name="query" value="" title="query" />
        <input type="hidden" name="collection" value="${dataSettings&&dataSettings.collection?dataSettings.collection:''}" />
        <input type="hidden" name="searchtype" value="${dataSettings&&dataSettings.searchtype?dataSettings.searchtype:''}" />
        <svgs data-svg="icon-x-circle" fb-clear></svgs>
        <button type="submit" class="search__submit search__submit--icon">
            <div class="btn-group ds-btn-primary">
            <span class="txt">${dataSettings && dataSettings.btnText?dataSettings.btnText:''}</span>
            <svgs data-svg="icon-search"></svgs>
            </div>
        </button>
        <div class="funnelback-suggest--wrapper" data-suggest></div>
    </form>`;
return genHTML;
}

function genHeader(siteData, innit = false) {
    const assetRoot = siteData.assetRoot;
    const dataPages = siteData.pages;
    const navHeaderContainer = document.querySelector('[header-nav]');
    const dataSettings = navHeaderContainer && navHeaderContainer.getAttribute('data-json') !== null && navHeaderContainer.getAttribute('data-json') !== ''? JSON.parse(navHeaderContainer.getAttribute('data-json'))[0] : testNavData.settings;
    const version = dataSettings.headerNavVersion;
    let cntDataPage = 0;
    const navMains = [];
    const body = document.querySelector('body');
    body.setAttribute('header-version', version);
    const mainContainer = body.querySelector('main');
    if (mainContainer) {
        mainContainer.setAttribute('header-version', version);
    }
    let genHTML = `
    <header class="header-nav" version="${version}">`;
    genHTML += `<div class="bg-header" btn-remove-nav></div>`;
    genHTML += `<a href="./">`;
    genHTML += `<div class="logo"><svgs data-svg="icon-logo-white"></svg></div>`;
    genHTML += `</a>`;
    genHTML += `<div class="header-search">`;
    genHTML += searchForm(dataSettings.search);
    genHTML += `
        <div class="btn btn-close" btn-close>
            <svgs data-svg="icon-x"></svgs>
        </div>
    </div>`;
    genHTML += `<div class="nav-list">`;
        const navGroupData = [];
        for (const dataPage of dataPages) {
            if (dataPage.parent === dataSettings.type) {
                navMains.push({"id": dataPage.id, "name": dataPage.name, "fileType": dataPage.file})
            }
        }

        genHTML += genNavLists(navMains, dataPages, cntDataPage, dataSettings, 'nav-list', 'desktop');
        genHTML += `</div>`;
        genHTML += `<div class="nav-list nav-list-main mobile">`; // Mobile Nav
        genHTML += `<div class="nav-list-main-item">`;
//         genHTML += `<div class="item-icon icon-search mobile-search" btn-search><svgs data-svg="icon-magnify"></svgs></div>`;
        genHTML += `<div class="item-icon icon-hamburger" btn-mobile-nav>`;
        genHTML += `<div class="icon"></div>`;
        genHTML += `<div class="icon"></div>`;
        genHTML += `<div class="icon"></div>`;
        genHTML += `</div>`;
        genHTML += `</div>`;
        genHTML += `<div class="mobile-navlist-wrapper">`;
        genHTML += genNavLists(navMains, dataPages, cntDataPage, dataSettings, 'mobile-nav-list', 'mobile');
        genHTML += `</div>`;
    genHTML += `</div>
    </div>
    `;
    if (navHeaderContainer) {
        navHeaderContainer.innerHTML = genHTML;
    }
    setSubNavePos(navHeaderContainer, 10, version);
    window.addEventListener('resize', function() {
        setSubNavePos(navHeaderContainer, -5, version);
    }, true);
    if (navHeaderContainer && navHeaderContainer.getAttribute('data-json') && innit === true) {
        navHeaderContainer.removeAttribute('data-json');
    }
    genBreadcrumbs(version);
    btnRemoveNav();
    btnHeaderNav(navHeaderContainer);
    new SVGs();
    HeaderSticky();
    btnMobileNav();
    btnClickToggleTarget('click', navHeaderContainer, '[btn-search]', 'header', 'search--show', 100);
    btnRemoveClass('click', '[btn-close]', 'search--show', 'header', 100);
    SearchGlobal();
}
function setSubNavePos(navHeaderContainer, setHeight, version) {
    const windowWidth = window.innerWidth;
    const header = navHeaderContainer.querySelector('header');
    const body = document.querySelector('body');
    const headerHeight = header && header.offsetHeight?header.offsetHeight:0;
    if (windowWidth > mobileWidth) {
        const navListSubWrappers = navHeaderContainer.querySelectorAll('.nav-list-sub-wrapper');
        for (const navListSubWrapper of navListSubWrappers) {
            navListSubWrapper.style = `top: ${headerHeight + setHeight}px`;
        }
        body.classList.remove('body-lock-scroll');
        body.classList.remove('header-nav-active');
        if (header) {
            header.classList.remove('mobile-nav');
            header.classList.remove('mobile-nav-transitionIn');
            header.classList.remove('mobile-nav-show');
        }

        if (dataWindowWidth == 0) {
            if (version !== 'Transparent') {
                document.querySelector('main').style = `margin-top: ${headerHeight - 1}px`;
            } else {
                document.querySelector('main').style = `margin-top: 0px`;
            }
        } else {
            if (version === 'Transparent') {
                document.querySelector('main').style = `margin-top: 0px`;
            }
        }
    } else {
        const mobileNavListContainer = navHeaderContainer.querySelector('.mobile-nav-list-container');
            mobileNavListContainer.style = `top: ${headerHeight}px`;
            header.classList.add('mobile-nav');
            document.querySelector('main').style = `margin-top: ${headerHeight - 12}px`;
        dataWindowWidth = 0;
    }
    window.addEventListener('resize', function() {
        if (windowWidth <= mobileWidth) {
        const header = document.querySelector('header');
        const activeHeaders = header.querySelectorAll('.active');
        for (const activeHeader of activeHeaders) {
            activeHeader.classList.remove('active');
        }
        }
    }, true);
    dataWindowWidth = windowWidth;
}
function genNavLists(navMains, dataPages, cntDataPage, dataSettings, ClassName, device) {
    let genHTML = '';
    const searchTextTitle = dataSettings.searchTitle;
    const btn_ATAR_text = dataSettings.btn_ATAR_text;
    const btn_ATAR_link = dataSettings.btn_ATAR_link;
    const btn_view_all_courses_link = dataSettings.course.btn_view_all_courses_link;
    const btn_view_all_courses_text = dataSettings.course.btn_view_all_courses_text;
    if (device.indexOf('mobile') > -1) {
        genHTML += `<div class="${ClassName}-container">`;
    }
    for (const navMain of navMains) {
        genHTML += `<div class="${ClassName} ${ClassName}-main">`;
        for (const dataPage of dataPages) {
            if (dataPage.parentData.assetid == navMain.id) {
                if (dataPage.file === 'folder') {
                    genHTML += `<div class="${ClassName}-main-item item item-links">`;
                    if (device.indexOf('mobile') < 0) {
                        genHTML += `<div icon-arrow-up></div>`;
                    }
                    if (device.indexOf('mobile') > -1) {
                        genHTML += `<div class="btn-mobile-list" for="${dataPage.name}" btn-header-nav>`;
                        genHTML += `<span class="title txt" >${dataPage.name}</span>`;
                        genHTML += `<svgs class="icon-title-wrapper" data-svg="icon-chevron-right"></svgs></div>`;
                    } else {
                        genHTML += `<span class="title txt" btn-header-nav>${dataPage.name}</span>`;
                    }
                    genHTML += `<div class="${ClassName}-sub-wrapper">`;
                    genHTML += `<div class="${ClassName}-sub-wrapper-container">`;
                    if (dataPage.name.toLowerCase() === 'courses') {
                                genHTML += `<div class="${ClassName}-sub-item sub-item sub-item-courses"><span class="txt">${searchTextTitle}</span>`;
                                genHTML += `<div class="bx-item bx-search">`;
                                genHTML += searchForm(dataSettings.course);
                                genHTML += `</div>`;
                                genHTML += `<div class="bx-item"><a class="title-sub-link" href="${btn_ATAR_link}" target="_self"><h3>${btn_ATAR_text}</h3><svgs data-svg="icon-chevron-right"></svgs></a></div>`;
                                genHTML += `<div class="bx-item"><a href="${btn_view_all_courses_link}" class="btn btn-white-red"><span class="txt">${btn_view_all_courses_text}</span></a></div>`;
                                genHTML += `</div>`;
                    }
                    for (const dataPageSub of dataPages) {
                        if (dataPageSub.parent == dataPage.id) {
                            if (dataPageSub.file === 'folder') {
                                genHTML += `<div class="${ClassName}-sub-item sub-item"><span class="txt">${dataPageSub.name}</span>`;
                                for (const dataPageSubSub of dataPages) {
                                    if (dataPageSubSub.parent == dataPageSub.id) {
                                        const description = dataPageSubSub && dataPageSubSub.description ? dataPageSubSub.description : '';
                                        genHTML += `<div class="${ClassName}-sub-item-sub sub-item-sub">${dataPageSubSub.link}<svgs data-svg="icon-chevron-right"></svgs></div>`;
                                        genHTML += `<div class="${ClassName}-sub-item-sub-sub sub-item-sub-sub">${description}</div>`;
                                    }
                                }
                                genHTML += `</div>`;
                            } else {
                                genHTML += `<div class="${ClassName}-sub-item sub-item">${dataPageSub.link}</div>`;
                            }
                        }
                    }
                    genHTML += `</div></div></div>`;
                } else {
                    genHTML += `<div class="${ClassName}-main-item item item-links"><span class="title">${dataPage.link}</span></div>`;
                }
            }
            cntDataPage++;
        } // End for dataPage
        if (navMain.name.indexOf('Nav last') > -1) {
//             genHTML += `<div class="${ClassName}-main-item item item-icon icon-search" btn-search>`;
//             genHTML += `<svgs data-svg="icon-magnify"></svgs>`;
            genHTML += `</div>`;
        }
        genHTML += `</div>`;
    }
    if (device.indexOf('mobile') > -1) {
        genHTML += `</div>`;
    }
    return genHTML;
}
function btnMobileNav() {
    const buttons = document.querySelectorAll('[btn-mobile-nav]');
    Object.values(buttons).map((v) => {
        if (v) {
            v.addEventListener('click', function (e) {
                const _this = e.target;
                const elemParent = _this.parentNode;
                const body = document.querySelector('body');
                const header = document.querySelector('header');
                elemParent.classList.add('transitionIn');
                header.classList.add('mobile-nav-transitionIn');
                setTimeout(function(){
                    if (hasClass(_this, 'active') === false) {
                        _this.classList.add('active');
                        elemParent.classList.add('active');
                        header.classList.add('mobile-nav-show');
                        body.classList.add('body-lock-scroll');
                    } else {
                        _this.classList.remove('active');
                        elemParent.classList.remove('active');
                        header.classList.remove('mobile-nav-show');
                        body.classList.remove('body-lock-scroll');
                    }
                    header.classList.remove('mobile-nav-transitionIn');
                    elemParent.classList.remove('transitionIn');
                }, 200);
                e.stopImmediatePropagation();
            });
        }
        return true;
    });
}
function HeaderNav() {
    const dataSiteStorage = localStorageGet('headers');
    if (dataSiteStorage && dataSiteStorage.pages && dataSiteStorage.pages.length > 0) {
        genHeader(dataSiteStorage);
    }
    if (window.location.href.indexOf('.latrobe.edu.au') > -1) {
        const navHeaderContainer = document.querySelector('[header-nav]');
        const dataSettings = navHeaderContainer && navHeaderContainer.getAttribute('data-json') !== null && navHeaderContainer.getAttribute('data-json') !== ''? JSON.parse(navHeaderContainer.getAttribute('data-json'))[0] : testNavData.settings;
        if (dataSettings && dataSettings.url) {
            getJson(dataSettings.url);
        }
    } else {
        setTimeout(function(){
            genHeader(testNavData.data.sites, true);
        }, 500);
        localStorageStore('headers', testNavData.data.sites)
    }
}
async function getJson(url) {
    const genUrl = url.indexOf('_nocache') > -1?url:`${url}/_nocache`;
    const res = await fetch(genUrl)
    .then(response =>response.json())
    .then(data => {
        genHeader(data.sites, true);
        localStorageStore('headers', data.sites)
    })
    .catch(error => {
        console.log(error)
    });
    return res;
}
function localStorageStore(key, dataInp) {
    if (typeof(Storage) !== "undefined") {
        const data = JSON.stringify(dataInp);
        window.localStorage.setItem(key, data);
    }
}
function localStorageGet(key) {
    let data = {};
    if (typeof(Storage) !== "undefined") {
        data = window.localStorage && window.localStorage.getItem(key)? JSON.parse(window.localStorage.getItem(key)) : '';
    }
    return data;
}
export default class {
    constructor(context) {
        this.context = context;
        this.Header();
    }
    Header() {
        HeaderNav();
    }
}
