import { testData } from './testData';
import SVGs from '../../_global/SVGs/js/svgs';

function WYSIWYGcontentCCT() {
    let genHTML = ''; let cnt = 0; let chkNotIfEmpty = false;
    const dataElems = document.querySelectorAll('[data-WYSIWYGcontent]');
    for (const dataElem of dataElems) {
        const dataJsons = dataElem.getAttribute('data-json') !== null && dataElem.getAttribute('data-json') !== ''? JSON.parse(dataElem.getAttribute('data-json')):testData.data;
        genHTML = `<div class="WYSIWYGcontent--wrapper">`;
            for (const dataJson of dataJsons) {
                if (dataJson.display === "visible") {
                    genHTML += `<div class="WYSIWYGcontent--wrapper--container">`;
                    if ((dataJson.image !== null && dataJson.image !== undefined && dataJson.image !== '') && dataJson.imagePosition === "top") {
                        genHTML += `<div class="bx-item bx-item--image bx-image">
                            <img src="${dataJson.image}" />
                        </div>`;
                    }
                    genHTML += `<div class="bx-item bx-item--content bx-content">`;
                    if (dataJson.headline !== null && dataJson.headline !== undefined && dataJson.headline !== '') {
                        genHTML += `<h2 class="title">${dataJson.headline}</h2>`;
                    }
                    if (dataJson.subheadline !== null && dataJson.subheadline !== undefined && dataJson.subheadline !== '') {
                        genHTML += `<h3 class="sub-title">${dataJson.subheadline}</h3>`;
                    }
                    if (dataJson.content !== null && dataJson.content !== undefined && dataJson.content !== '') {
                        genHTML += `<div class="bx-item bx-item--content">${dataJson.content}</div>`;
                    }
                    `</div>`;
                    if ((dataJson.image !== null && dataJson.image !== undefined && dataJson.image !== '') && dataJson.imagePosition !== "top") {
                        genHTML += `<div class="bx-item bx-item--image bx-image">
                            <img src="${dataJson.image}" />
                        </div>`;
                    }
                    genHTML += `</div>`;
                    chkNotIfEmpty = true;
                }
            }
        genHTML += `</div>`;
        if (chkNotIfEmpty === true) {
            dataElem.innerHTML = genHTML;
        }
        dataElem.removeAttribute('data-WYSIWYGcontent');
        if (dataElem && dataElem.getAttribute('data-json')) {
            dataElem.removeAttribute('data-json');
        }
        cnt++;
    }
}
(function(){
    'use strict';
    WYSIWYGcontentCCT();
    new SVGs();
}());
