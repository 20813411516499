export const testData = {
    data: [
        {
            display: "visible",
            imageStyle: "small",
            imagePos: "right",
            image: "../externals/img/banners/cardPromo.jpg",
            contentPos: "right",
            headline: "Designed to provide a one-stop-shop solution",
            content: "We provide a strong internal network and forge relationships with people to deliver our client’s daily wants and needs.",
            btnStyle: "play",
            btnLink: "/watch",
//             btnText: "Watch story"
        }
    ]
};
