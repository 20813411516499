export const testData = {
    settings: {
        display: "visible",
        version: "3 columns",
        background: "white",
        title: "for further information CONTACT",
//         button_text: "Request more info",
//         button_url: "/info"
    },
    data: [
        {
            icon: "../externals/icons/ContactCTAsectionCCT/phone.svg",
            headline: "Talk to us",
            content: "Contact us to understand what options are available in your interested area.",
            button_text: "Call Us",
            button_url: "/call",
            button_ex_url: "tel:0433125959"
        },
        {
            icon: "../externals/icons/ContactCTAsectionCCT/users.svg",
            headline: "Free consultation",
            content: "Please book a consultation with us to get advice from our professional team. ",
            button_text: "Book a consultation",
            button_url: "/consultation",
            button_ex_url: "mail:info@preciousgemsproperty.com.au"
        },
//         {
//             icon: "../externals/icons/ContactCTAsectionCCT/conversation.svg",
//             headline: "Live chat",
//             content: "Chat is available from 9am to 5pm AEST Monday to Friday during business days.",
//             button_text: "Chat now",
//             button_url: "/chat",
//             button_ex_url: ""
//         },
        {
            icon: "../externals/icons/ContactCTAsectionCCT/mail.svg",
            headline: "Ask a question",
            content: "Submit a question online. We’ll get back to you within the next business day.",
            button_text: "Send a question",
            button_url: "/question",
            button_ex_url: "mail:info@preciousgemsproperty.com.au"
        }
    ]
};
