function hasClass(element, className) {
    const elemClass = element && element.getAttribute('class')  ? (element.getAttribute('class')).indexOf(String(className)) > -1 : false;
    return elemClass;
}
function btnCollapse() {
    const buttons = document.querySelectorAll( '.btn-collapse' );
    const eventLists = ['click', 'keyup'];
    Object.values(buttons).map((v) => {
        if (v) {
            for (const eventList of eventLists) {
                v.addEventListener(eventList, function (e) {
                    const _this = e.target;
                    const elemParent = _this.parentNode;
                    if (eventList === 'click' || (eventList === 'keyup' && e.key == 'Enter') || (eventList === 'keyup' && (e.key == ' ' || e.code == "Space"))) {
                        if (hasClass(_this, 'active') === false) {
                            _this.classList.add('active');
                            elemParent.querySelector('.data-collapse').classList.add('collapsed');
                        } else {
                            _this.classList.remove('active');
                            elemParent.querySelector('.data-collapse').classList.remove('collapsed');
                        }
                        window.onkeydown = function(e) {
                            if (e.key == ' ' || e.code == 'Space') {
                                return false;
                            }
                        };
                        e.stopImmediatePropagation();
                    }
                });
            }
        }
        return true;
    });

}
function btnHover(elemAttribute) {
    const buttons = document.querySelectorAll(elemAttribute);
    Object.values(buttons).map((v) => {
        if (v) {
            v.addEventListener('mouseover', function (e) {
                const _this = e.target;
                const elemParent = _this.parentNode;
                _this.classList.add('hover');
                elemParent.classList.add('hover');
                e.stopImmediatePropagation();
            });
            v.addEventListener('mouseout', function (e) {
                const _this = e.target;
                const elemParent = _this.parentNode;
                _this.classList.remove('hover');
                elemParent.classList.remove('hover');
                e.stopImmediatePropagation();
            });
        }
        return true;
    });
}
function btnClick() {
    const buttons = document.querySelectorAll('[btn-click]');
    Object.values(buttons).map((v) => {
        if (v) {
            v.addEventListener('click', function (e) {
                const _this = e.target;
                const elemParent = _this.parentNode;
                if (hasClass(_this, 'active') === false) {
                    _this.classList.add('active');
                    elemParent.classList.add('active');
                } else {
                    _this.classList.remove('active');
                    elemParent.classList.remove('active');
                }
                e.stopImmediatePropagation();
            });
        }
        return true;
    });
}
function btnBgClick(action, dataElem, elemAttribute, elemClass, delay) {
    const buttons = dataElem.querySelectorAll(elemAttribute);
    Object.values(buttons).map((v) => {
        if (v) {
            v.addEventListener(action, function (e) {
                const _this = e.target;
                const elemParent = _this.parentNode;
                elemParent.classList.remove('transitionIn');
                elemParent.classList.add('transitionOut');
                setTimeout(function(){
                    elemParent.classList.remove('transitionOut');
                    _this.classList.remove(elemClass);
                    const allChildren = elemParent.querySelectorAll(`.${elemClass}`);
                    for (const child of allChildren) {
                        child.classList.remove(elemClass);
                    }
                    elemParent.classList.remove(elemClass);
                }, delay);
                e.stopImmediatePropagation();
            });
        }
        return true;
    });
}
function btnClickToggle(action, dataElem, elemAttribute, elemClass, delay) {
    const buttons = dataElem.querySelectorAll(elemAttribute);
    Object.values(buttons).map((v) => {
        if (v) {
            v.addEventListener(action, function (e) {
                const _this = e.target;
                const elemParent = _this.parentNode;
                elemParent.classList.add('transitionIn');
                setTimeout(function(){
                    elemParent.classList.remove('transitionIn');
                    if (hasClass(_this, elemClass) === false) {
                        _this.classList.add(elemClass);
                        elemParent.classList.add(elemClass);
                    } else {
                        _this.classList.remove(elemClass);
                        elemParent.classList.remove(elemClass);
                    }
                }, delay);
                e.stopImmediatePropagation();
            });
        }
        return true;
    });
}

function btnClickToggleTarget(action, dataElem, elemAttr, elemTargetAttr, elemClass, delay) {
    const buttons = dataElem.querySelectorAll(elemAttr);
    Object.values(buttons).map((v) => {
        if (v) {
            v.addEventListener(action, function (e) {
                const _this = e.target;
                const elemParent = _this.parentNode;
                const elemTargets = document.querySelectorAll(elemTargetAttr);
                elemParent.classList.add('transitionIn');
                setTimeout(function(){
                    elemParent.classList.remove('transitionIn');
                    if (hasClass(_this, elemClass) === false) {
                        _this.classList.add(elemClass);
                        elemParent.classList.add(elemClass);
                    } else {
                        _this.classList.remove(elemClass);
                        elemParent.classList.remove(elemClass);
                    }
                }, delay);
                for (const elemTarget of elemTargets) {
                    elemTarget.classList.add('transitionIn');
                    setTimeout(function(){
                        elemTarget.classList.remove('transitionIn');
                        if (hasClass(elemTarget, elemClass) === false) {
                            elemTarget.classList.add(elemClass);
                        } else {
                            elemTarget.classList.remove(elemClass);
                        }
                    }, delay);
                }
                e.stopImmediatePropagation();
            });
        }
        return true;
    });
}
function btnRadio() {
    const buttons = document.querySelectorAll('[btn-radio]');
    Object.values(buttons).map((v) => {
        if (v) {
            v.addEventListener('click', function (e) {
                const _this = e.target;
                const elemParent = _this.parentNode;
                const alreadyActive = hasClass(_this, 'active');
                Object.values(buttons).map((n) => {
                    n.classList.remove('active');
                    n.parentNode.classList.remove('active');
                });
                _this.classList.add('transitionIn');
                elemParent.classList.add('transitionIn');
                setTimeout(function(){
                    if (hasClass(_this, 'active') === false && alreadyActive === false) {
                        _this.classList.remove('transitionIn');
                        _this.classList.add('active');
                        elemParent.classList.remove('transitionIn');
                        elemParent.classList.add('active');
                    }
                }, 100);
                e.stopImmediatePropagation();
            });
        }
        return true;
    });
}
function btnRemoveClass(action, elemAttr, elemClass, elemTargetAttr, delay) {
    const transitionClass = 'transitionOut';
    const buttons = document.querySelectorAll(elemAttr);
    Object.values(buttons).map((v) => {
        if (v) {
            v.addEventListener(action, function (e) {
                const _this = e.target;
                const elemParent = _this.parentNode;
                _this.classList.add(transitionClass);
                elemParent.classList.add(transitionClass);
                setTimeout(function(){
                    _this.classList.remove(elemClass);
                    elemParent.classList.remove(elemClass);
                    _this.classList.remove(transitionClass);
                    elemParent.classList.remove(transitionClass);
                }, delay);
                const elemTargets = document.querySelectorAll(elemTargetAttr);
                for (const elemTarget of elemTargets) {
                    elemTarget.classList.add(transitionClass);
                    setTimeout(function(){
                        elemTarget.classList.remove(transitionClass);
                        elemTarget.classList.remove(elemClass);
                    }, delay);
                }
                e.stopImmediatePropagation();
            });
        }
        return true;
    });
}
function btnClearText(action, elemAttr, elemClass, elemTargetAttrClass, elemTargetAttr, elemTargetAttrInnerHTML, delay) {
    const transitionClass = 'transitionOut';
    const buttons = document.querySelectorAll(elemAttr);
    Object.values(buttons).map((v) => {
        if (v) {
            v.addEventListener(action, function (e) {
                const _this = e.target;
                const elemParent = _this.parentNode;
                _this.classList.add(transitionClass);
                elemParent.classList.add(transitionClass);
                setTimeout(function(){
                    _this.classList.remove(elemClass);
                    elemParent.classList.remove(elemClass);
                    _this.classList.remove(transitionClass);
                    elemParent.classList.remove(transitionClass);
                }, delay);
                const elemTargetClasses = document.querySelectorAll(elemTargetAttrClass);
                for (const elemTarget of elemTargetClasses) {
                    elemTarget.classList.add(transitionClass);
                    setTimeout(function(){
                        elemTarget.classList.remove(transitionClass);
                        elemTarget.classList.remove(elemClass);
                    }, delay);
                }
                const elemTargets = document.querySelectorAll(elemTargetAttr);
                for (const elemTarget of elemTargets) {
                    elemTarget.value = '';
                }
                const elemTargets2 = document.querySelectorAll(elemTargetAttrInnerHTML);
                for (const item of elemTargets2) {
                    item.innerHTML = '';
                }
                e.stopImmediatePropagation();
            });
        }
        return true;
    });
}
function btnRemoveClassElem(action, elemAttr, elemClass, elemTargetAttr, delay) {
    const transitionClass = 'transitionOut';
    const buttons = document.querySelectorAll(elemAttr);
    Object.values(buttons).map((v) => {
        if (v) {
            v.addEventListener(action, function (e) {
                const _this = e.target;
                const elemParent = _this.parentNode;
                _this.classList.add(transitionClass);
                elemParent.classList.add(transitionClass);
                setTimeout(function(){
                    _this.classList.remove(elemClass);
                    elemParent.classList.remove(elemClass);
                    _this.classList.remove(transitionClass);
                    elemParent.classList.remove(transitionClass);
                }, delay);
                const elemTargets = document.querySelectorAll(elemTargetAttr);
                for (const elemTarget of elemTargets) {
                    elemTarget.classList.add(transitionClass);
                    setTimeout(function(){
                        elemTarget.classList.remove(transitionClass);
                        elemTarget.classList.remove(elemClass);
                        elemTarget.remove();
                    }, delay);
                }
                e.stopImmediatePropagation();
            });
        }
        return true;
    });
}
function iconLoader() {
    const body = document.querySelector('body');
    const loader = document.createElement('div');
    loader.classList = 'icon-loader--wrapper';
    loader.setAttribute('loader', true)
    loader.innerHTML = `<svgs data-svg="icon-loader"></svgs>`;
    body.append(loader);
    const elemLoaders = document.querySelectorAll('[loader]');
    for (const elemLoader of elemLoaders) {
        setTimeout(function(){
            elemLoader.classList.add('loaded');
        }, 1000);
        setTimeout(function(){
            elemLoader.classList.add('unloaded');
        }, 1500);
        setTimeout(function(){
            elemLoader.remove();
        }, 1500);
    }
}
function btnLoader(action, elemAttr) {
    const buttons = document.querySelectorAll(elemAttr);
    Object.values(buttons).map((v) => {
        if (v) {
            v.addEventListener(action, function (e) {
                iconLoader();
                e.stopImmediatePropagation();
            });
        }
        return true;
    });
}
export {
  hasClass,
  btnCollapse,
  btnClick,
  btnRadio,
  btnHover,
  btnRemoveClass,
  btnRemoveClassElem,
  btnClickToggle,
  btnClickToggleTarget,
  btnBgClick,
  btnClearText,
  iconLoader,
  btnLoader
};
