export const testData = {
    settings: {
        version: "default",
        version: "b",
        version: "float",
//         version: "border",
//         version: "noBorder",
        perPage: 4,
        display: "visible",
//         display: "hidden",
        button_text: "View course",
        btnMoreURL: "/find-out-more",
        btnMoreText: "Find out more"
    },
    tags: {
        StudyFlex: "<p> StudyFlex --- Whether your course is considered on campus, online or a mix of both, a StudyFlex course provides: </p> <ul> <li> at least one third of its subjects on campus or online; and/or </li> <li> at least two StudyFlex majors – that is two eight-subject sequences that offer at least half of their subjects on campus or online. </li> </ul> <p> Every course has a different mix of StudyFlex options – they might be available throughout your course, or they might be concentrated in a specific period. They might make up to a third of your course, or they might make up all of it. It all depends on the specific needs of your subjects and what you’re studying. </p>",
        CSP: "<p> CSP -- Whether your course is considered on campus, online or a mix of both, a StudyFlex course provides: </p> <ul> <li> at least one third of its subjects on campus or online; and/or </li> <li> at least two StudyFlex majors – that is two eight-subject sequences that offer at least half of their subjects on campus or online. </li> </ul> <p> Every course has a different mix of StudyFlex options – they might be available throughout your course, or they might be concentrated in a specific period. They might make up to a third of your course, or they might make up all of it. It all depends on the specific needs of your subjects and what you’re studying. </p>"
    },
    data: [
        {
            image: "../externals/img/CourseCardListing.jpg",
            headline: "Bachelor of Information Technology",
            content: "Build your IT knowledge with practical, real-world experience. Learn from industry leaders in areas like programming, networking and project management. 1",
            tags: "StudyFlex,CSP",
            url: "/technology",
            popular: true
        },
        {
            image: "../externals/img/business-1.jpg",
            headline: "Bachelor of Information Technology",
            content: "Build your IT knowledge with practical, real-world experience. Learn from industry leaders in areas like programming, networking and project management. 3",
            url: "/information"
        },
        {
            image: "../externals/img/business-2.jpg",
            headline: "Bachelor of Information Technology",
            content: "Build your IT knowledge with practical, real-world experience. Learn from industry leaders in areas like programming, networking and project management.",
            url: "/information",
            popular: true
        },
        {
            image: "../externals/img/business-3.jpg",
            headline: "Bachelor of Information Technology",
            content: "Build your IT knowledge with practical, real-world experience. Learn from industry leaders in areas like programming, networking and project management.",
            tags: "StudyFlex,CSP",
            url: "/technology",
            popular: true
        },
        {
            image: "../externals/img/CourseCardListing.jpg",
            headline: "Bachelor of Information Technology",
            content: "Build your IT knowledge with practical, real-world experience. Learn from industry leaders in areas like programming, networking and project management.",
            tags: "StudyFlex,CSP",
            url: "/information",
            popular: false
        },
        {
            image: "../externals/img/business-1.jpg",
            headline: "Bachelor of Information Technology",
            content: "Build your IT knowledge with practical, real-world experience. Learn from industry leaders in areas like programming, networking and project management. 3",
            url: "/information"
        },
        {
            image: "../externals/img/business-2.jpg",
            headline: "Bachelor of Information Technology",
            content: "Build your IT knowledge with practical, real-world experience. Learn from industry leaders in areas like programming, networking and project management.",
            url: "/information",
            popular: true
        },
        {
            image: "../externals/img/business-3.jpg",
            headline: "Bachelor of Information Technology",
            content: "Build your IT knowledge with practical, real-world experience. Learn from industry leaders in areas like programming, networking and project management.",
            tags: "StudyFlex,CSP",
            url: "/technology",
            popular: true
        }
    ]
};
