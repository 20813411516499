import { testData } from './testData';
import SVGs from '../../_global/SVGs/js/svgs';
const mobileWidth = 800;
function BannerCenteredCCT() {
    let genHTML = ''; let cnt = 0;
    const dataElems = document.querySelectorAll('[data-BannerCentered]');
    for (const dataElem of dataElems) {
        const dataJsons = dataElem.getAttribute('data-json') !== null && dataElem.getAttribute('data-json') !== ''? JSON.parse(dataElem.getAttribute('data-json')):testData.data;
        genHTML = `<div class="BannerCentered--wrapper" data-id="${cnt}">`;
            for (const dataJson of dataJsons) {
                const bannerImage = dataJson && dataJson.image? dataJson.image:'';
                if (bannerImage !== '') {
                    genHTML += `
                    <div class="item bx-item bx-item--image" mobile-image-background="${bannerImage}" BannerCentered-content>
                        <img src="${dataJson.image}" />
                    </div>`;
                }
                genHTML += `
                <div class="item bx-item bx-item--content" ${bannerImage!==''?'banner-image':'banner-content'}>`;
                if (dataJson && dataJson.headline !== '' && dataJson.headline !== null && dataJson.headline !== undefined) {
                    genHTML += `<h2>${dataJson.headline}</h2>`;
                }
                genHTML += `
                <div class="content">${dataJson && dataJson.content? dataJson.content:''}</div>
                </div>`;
            }
        genHTML += `</div>`;
        dataElem.innerHTML = genHTML;
        dataElem.removeAttribute('data-BannerCentered');
        if (dataElem && dataElem.getAttribute('data-json')) {
            dataElem.removeAttribute('data-json');
        }
        genMobileBackgroundImg();
        cnt++;
    }
    window.addEventListener('resize', function() {
        genMobileBackgroundImg();
    }, true);
}
function genMobileBackgroundImg() {
    const windowWidth = window.innerWidth;
    const dataElems = document.querySelectorAll('[mobile-image-background]');
    for (const dataElem of dataElems) {
        const imgSrc = dataElem.getAttribute('mobile-image-background');
        if ((windowWidth <= mobileWidth) && (imgSrc !== null && imgSrc !== '' && imgSrc !== undefined)) {
            dataElem.style = `background-image: url(${imgSrc});`;
        } else {
            dataElem.style = '';
        }
    }
}
(function(){
    'use strict';
    BannerCenteredCCT();
    new SVGs();
}());
