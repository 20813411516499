import { btnCollapse, btnHover } from './lb';
import SVGs from '../SVGs/js/svgs';
import Nav from './nav';
import HeaderNav from './layouts/header/header-nav';
import Footer from './layouts/footer/footer';

(function(){
    new SVGs();
    new Nav();
    new HeaderNav();
    new Footer();
    btnCollapse();
    btnHover('[btn-hover]');
}());
