import { testData } from './testData';
import SVGs from '../../_global/SVGs/js/svgs';

function CardPromoCCT() {
    let genHTML = ''; let cnt = 0;
    const dataElems = document.querySelectorAll('[data-CardPromo]');
    for (const dataElem of dataElems) {
        const dataJsons = dataElem.getAttribute('data-json') !== null && dataElem.getAttribute('data-json') !== ''? JSON.parse(dataElem.getAttribute('data-json')):testData.data;
        genHTML = `<div class="CardPromo--wrapper">`;
            for (const dataJson of dataJsons) {
                if (dataJson.display === "visible") {
                    genHTML += `<div class="CardPromo--wrapper--container" txt-pos="${dataJson.contentPos}" img-style="${dataJson.imageStyle}" img-pos="${dataJson.imagePos}" CardPromo>
                    <div class="bx-item bx-item--image bx-image">
                        <img src="${dataJson.image}" img-primary />
                        <img src="${dataJson.image}" bg-img />
                    </div>
                    <div class="bx-item bx-item--content bx-content">
                        <h2 class="title">${dataJson.headline}</h2>
                        <div class="content">${dataJson.content}</div>`;
                    if (dataJson && (dataJson.btnLink && dataJson.btnText)) {
                        genHTML += `
                        <div class="bx-item--btn btn-wrapper" btn-style="${dataJson.btnStyle}">
                            <a href="${dataJson.btnLink}" class="ds-btn ds-btn-secondary">
                                <span class="txt">${dataJson.btnText}</span>
                                <svgs data-svg="icon-chevron-right"></svgs>
                                <svgs data-svg="icon-play"></svgs>
                            </a>
                        </div>`;
                    }
                    genHTML += `
                    </div>
                    </div>`;
                }
                genHTML += `</div>`;
                dataElem.innerHTML = genHTML;
            } // End For
        genHTML += `</div>`;

        dataElem.removeAttribute('data-CardPromo');
        if (dataElem && dataElem.getAttribute('data-json')) {
            dataElem.removeAttribute('data-json');
        }
        cnt++;
    }
}
(function(){
    'use strict';
    CardPromoCCT();
    new SVGs();
}());
