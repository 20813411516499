import { testData } from './testData';
import { aboutData } from './data/aboutData';
import { teamData } from './data/teams';
import SVGs from '../../_global/SVGs/js/svgs';

const loadingDelay = 500;

function BannerHero() {
    let genHTML = ''; let cnt = 0;
    const dataElems = document.querySelectorAll('[ds-hero-promo]');
    for (const dataElem of dataElems) {
        dataElem.setAttribute('elem-loading', true);
        const page = dataElem.getAttribute('page') !== null && dataElem.getAttribute('page') !== ''? dataElem.getAttribute('page'):'';
        console.log(' page >>' , page)
        let dataJson = dataElem.getAttribute('data-json') !== null && dataElem.getAttribute('data-json') !== ''? JSON.parse(dataElem.getAttribute('data-json'))[cnt]
        : page === 'about'
        ? aboutData.data[cnt]
        : testData.data[cnt];
        if (page.indexOf('team')>-1) {
            dataJson = teamData['data'][page];
            console.log(dataJson)
        }
        const button_url_1 = dataJson && (dataJson.button_url_1 !== '' && dataJson.button_url_1 !== null) ? dataJson.button_url_1
        : dataJson && dataJson.button_ext_url_1
        ? dataJson.button_ext_url_1
        : '';
        const button_url_2 = dataJson && (dataJson.button_url_2 !== '' && dataJson.button_url_2 !== null) ? dataJson.button_url_2
        : dataJson && dataJson.button_ext_url_2
        ? dataJson.button_ext_url_2
        : '';
        const button_text_1 = dataJson && (dataJson.button_text_1 !== '' && dataJson.button_text_1 !== null && dataJson.button_text_1 !== undefined) ? dataJson.button_text_1: 'Submit';
        const button_text_2 = dataJson && (dataJson.button_text_2 !== '' && dataJson.button_text_2 !== null && dataJson.button_text_2 !== undefined) ? dataJson.button_text_2: 'Submit';
        const VideoSrc = dataJson && (dataJson.video !== '' && dataJson.video !== null && dataJson.video !== undefined) ? dataJson.video: '';
        const imgMobile = dataJson && (dataJson.imageMobile !== '' && dataJson.imageMobile !== null && dataJson.imageMobile !== undefined) ? dataJson.imageMobile: dataJson.image;
        const imgSubfix = (window.location.host.indexOf('0.0.0.0') > -1)?'':'';
        const Breadcrumbs = getBreadcrumbs();
        genHTML = `
        <div class="ds-hero-promo ds-hero-promo--transparent-gradient" version="${dataJson.version}" data-hero-promo>
            <div class="ds-item ds-hero-promo__image" item-media-banner>
                <img class="main-image desktop" src="${dataJson.image}${imgSubfix}" alt="" />
                <img class="main-image mobile" src="${imgMobile}${imgSubfix}" alt="" />`;
        if (VideoSrc !== '') {
            genHTML += `<video autoplay="" muted="" loop="" playsinline="">
                <source src="${dataJson.video}" type="video/mp4" disablepictureinpicture="" disableremoteplayback="" x-webkit-airplay="deny">
                </video>`;
        }
        genHTML += `</div>
            <div class="ds-item ds-hero-promo__content">
            <div class="mobile-breadcrumbs breadcrumb-wrapper">${Breadcrumbs}</div>
                <h2 class="ds-hero-promo__text__headline">${dataJson.headline}</h2>`;
        if (dataJson.subheadline !== '' && dataJson.subheadline !== null && dataJson.subheadline !== undefined) {
                genHTML += `<div class="ds-hero-promo__text__subheadline">${dataJson.subheadline}</div>`;
        }
        if (dataJson.content !== '' && dataJson.content !== null && dataJson.content !== undefined) {
                genHTML += `
                <div class="ds-hero-promo__text__content" data-text-content>${dataJson.content}</div>`;
        }
                genHTML += `
                <div class="ds-hero-promo__button-group">`;
        if (button_url_1 !== '' && button_url_1 !== null && button_url_1 !== undefined) {
            genHTML += `
                        <a href="${button_url_1}" class="ds-btn ds-btn-primary ds-icon-chevron-right">${button_text_1}</a>`;
        }
        if (button_url_2 !== '' && button_url_2 !== null && button_url_2 !== undefined) {
            genHTML += `
                        <a href="${button_url_2}" class="ds-btn ds-btn-primary ds-btn--light ds-icon-chevron-right">${button_text_2}</a>`;
        }
        genHTML += `</div>
            </div>
            <div class="ds-item ds-hero-promo__gradient-overlay" gradient-overlay></div>
        </div>`;
        if (dataJson.version === 'full-image-banner') {
            genHTML += `<div class="breadcrumb-wrapper">${Breadcrumbs}</div>`;
            const breadcrumbs = document.querySelector('[data-breadcrumbs]');
            if (breadcrumbs) {
                document.querySelector('[data-breadcrumbs]').remove();
            }
        }
        dataElem.innerHTML = genHTML;
        setTimeout(function(){
            const elemTextContent = dataElem.querySelector('[data-text-content]');
            if (elemTextContent && elemTextContent.offsetHeight) {
                if (elemTextContent.offsetHeight > 200) {
                    elemTextContent.classList.add('scroll-y');
                }
            }
            if (VideoSrc !== '') {
                checkVideo(dataElem);
            } else {
                setHeroBannerHeight(dataElem, dataJson.version);
            }
        }, 100);

        window.addEventListener('resize', function() {
            if (VideoSrc === '') {
                setHeroBannerHeight(dataElem, dataJson.version);
            }
        }, true);
        dataElem.removeAttribute('ds-hero-promo');
        if (dataElem && dataElem.getAttribute('data-json')) {
            dataElem.removeAttribute('data-json');
        }
        setTimeout(function(){
        dataElem.removeAttribute('elem-loading');
        }, loadingDelay);
        cnt++;
    }

    new SVGs();
}
function getBreadcrumbs() {
    const Breadcrumb = document.querySelector('[data-breadcrumbs]');
    if (Breadcrumb) {
        return Breadcrumb.innerHTML;
    } else {
        return '';
    }
}
function checkVideo(dataElem) {
    setTimeout(function(){
        const video = dataElem.querySelector('video');
        if (video) {
            if (video.readyState === 4) {
                dataElem.setAttribute('video-container', true);
                dataElem.querySelector('[data-hero-promo]').style = `height: auto`;
                const bgVideo = document.createElement('div');
                bgVideo.style = `height: ${video.videoHeight}px; width: ${video.videoWidth}px;`;
                video.parentNode.append(bgVideo);
            } else {
                video.remove();
                setHeroBannerHeight(dataElem);
            }
        }
    }, loadingDelay);
}
function setHeroBannerHeight(dataElem, version) {
    const windowWidth = window.innerWidth;
    const elemImageBanner = dataElem.querySelector('.main-image');
    if (windowWidth > 800) {
        if (version !== 'dark-header-and-small-image') {
            dataElem.querySelector('[data-hero-promo]').style = `height: ${elemImageBanner.height}px`;
        } else {
            dataElem.querySelector('[data-hero-promo]').style = `height: auto`;
        }
    } else {
        dataElem.querySelector('[data-hero-promo]').style = `height: auto`;
    }
}
(function(){
    'use strict';
    BannerHero();
}());
