import { testData } from './testData';
import { hasClass, btnClickToggle, btnBgClick, btnClearText, iconLoader } from './../../_global/js/lb';
import { getParamsVal, getQueryVal, removeArrayDuplicates, removeArray, boldQuery, removeUrlParams } from './fb-library';
import SVGs from '../../_global/SVGs/js/svgs';

function funnelbackSearchCCT(dataSettings, windowHref, Querys, dataElem, dataJsons, dataInit) {
    let genHTML = ''; let cnt = 0;
    const collections = dataSettings.collections;
    const dataRes = dataJsons.response.resultPacket.results;
    const dataResSummary = dataJsons.response.resultPacket.resultsSummary;
    const dataSumTotal = dataResSummary.totalMatching;
    let dataNextStart = dataResSummary.nextStart;
    const thisCollection = dataJsons.response.resultPacket.collection;
    const chkCollection = activeCollection(windowHref, collections);
    const chkCollectionParam = windowHref.indexOf('collection=');
    let QueryNoCollection = removeArray(Querys, 'collection');
    const dataFacets = dataJsons.response.facets;
    const categories = dataJsons.response.resultPacket.contextualNavigation.categories;
    let fetchUrl = '';
    const uniqQuerys = removeArrayDuplicates(Querys);
    fetchUrl = `${removeUrlParams(uniqQuerys.join('&'), 'collection')}&collection=${thisCollection}`;
    fetchUrl = `${dataSettings.FbUrl}?${fetchUrl}&start_rank=${dataNextStart}`;

    QueryNoCollection = removeArrayDuplicates(QueryNoCollection);
    QueryNoCollection = removeArray(QueryNoCollection, 'sort');
    const QueryString = QueryNoCollection.length>0 && QueryNoCollection[0] !== ''?'&'+QueryNoCollection.join('&'):'';
    dataElem.setAttribute('nextStart', dataNextStart)
    if (dataInit === true) {
        genHTML = `<div class="funnelbackSearch--wrapper">
        <h1 class="title">${dataSettings.title}</h1>
        <div class="btn-tabs-wrapper">`;
        for (const item of collections) {
            const activeClass = (cnt==0 && chkCollectionParam<0)||(chkCollection !== '' && item.collection === chkCollection && chkCollectionParam>-1)?' active':'';
            genHTML += `<a class="btn btn-tab ds-btn-primary${activeClass}" href="?collection=${item.collection}"><span class="txt">${item.name}</span></a>`;
            cnt++;
        }
        const plurals = dataSumTotal > 1?'s':'';
        genHTML += `<div class="funnelbackSearch--wrapper--group">`;
        genHTML += `<div class="bx-item funnelbackSearch--wrapper--summary">`;
        genHTML += `<span class="txt">About ${dataSumTotal} result${plurals}</span>`;
        genHTML += `</div>`;
        genHTML += `<div class="bx-item funnelbackSearch--wrapper--summary--sort" fb-sort>
        <div class="bg" click-bg></div>
        <span class="bx-title" btn-select><span class="txt">Sort By</span> <svgs data-svg="icon-updown"></svgs></span>
        <div class="bg" click-bg></div>
        <div class="sort-select--wrapper">
            <a href="${windowHref.split('?')[0]}?collection=${thisCollection}${QueryString}"><div class="sort-select--item" select-item>Relevance</div></a>
            <a href="${windowHref.split('?')[0]}?collection=${thisCollection}${QueryString}&sort=date"><div class="sort-select--item" select-item>Newest</div></a>
            <a href="${windowHref.split('?')[0]}?collection=${thisCollection}${QueryString}&sort=adate"><div class="sort-select--item" select-item>Oldest</div></a>
        </div>
        </div>`;
        genHTML += `</div>`;
        genHTML += `</div>`;
        genHTML += `<div class="search-results--wrapper">`;
        genHTML += `<div class="search-results--main" fb-search-results>`;
        genHTML += `<div class="search-results">`;
        genHTML += buildResults(dataRes, dataJsons.question.query);
        genHTML += `</div>`;
        genHTML += `</div>`;
        genHTML += `<div class="search-side-wrapper">`;
        genHTML += buildFacets(dataFacets, 2, 5);
        genHTML += buildRelated(categories, 2, 5);
        genHTML += `</div>`;
        genHTML += `</div>`
        genHTML += `</div>`;
        if (dataNextStart) {
            genHTML += `<div class="bx-loadmore--wrapper" cta-loadmore-container><div class="btn btn-loadmore ds-btn-secondary" cta-loadmore>Load more</div></div>`;
        }
        dataElem.innerHTML = genHTML;
        dataElem.removeAttribute('data-funnelbackSearch');
        if (dataElem && dataElem.getAttribute('data-json')) {
            dataElem.removeAttribute('data-json');
        }
    } else {
        const parentNode = dataElem.querySelector('[fb-search-results]');
        dataNextStart = dataElem.getAttribute('nextStart');
        const nextContent = document.createElement('div');
        nextContent.classList = 'search-results next-page';
        nextContent.innerHTML = buildResults(dataRes, dataJsons.question.query);
        parentNode.append(nextContent);
        if (dataNextStart === null) {
            dataElem.querySelector('[cta-loadmore]').remove();
        }
    }
    btnLoadMore(dataElem, genHTML, dataSettings, 'click', '[cta-loadmore]', thisCollection, false);
    btnClickToggle('click', dataElem, '[btn-select]', 'active', 100);
    btnBgClick('click', dataElem, '[click-bg]', 'active', 100);
    new SVGs();
}
function buildFacets(dataFacets, titleLimit, limit) {
    let genHTML = ''; let cnt = 0; let cntTitle = 0;
    if (dataFacets.length > 0) {
        genHTML += `<div class="search-filter">`;
        genHTML += `<h3 class="title">Refine your results</h3>`;
        for (const item of dataFacets) {
            genHTML += `<h4 class="sub-title">${item.name}</h4>`;
            for (const val of item.allValues) {
                genHTML += `<a href="${val.toggleUrl}"><div class="txt">${val.label}</div></a>`;
                cnt++;
                if (cnt >= limit) { break; }
            }
            cntTitle++;
            if (cntTitle >= titleLimit) { break; }
        }
        genHTML += `</div>`;
    }
    return genHTML;
}
function buildRelated(categories, titleLimit, limit) {
    let genHTML = ''; let cnt = 0; let cntTitle = 0;
    if (categories.length > 0) {
        genHTML += `<div class="search-filter feature facet">`;
        genHTML += `<h3 class="title">Have you tried…</h3>`;
            for (const category of categories) {
                genHTML += `<h4 class="sub-title">${category.name}</h4>`;
                const clusters = category.clusters;
                for (const cluster of clusters) {
                    genHTML += `<a href="${cluster.href}"><div class="txt">${cluster.label}</div></a>`;
                    cnt++;
                    if (cnt >= limit) { break; }
                }
                cntTitle++;
                if (cntTitle >= titleLimit) { break; }
            }
        genHTML += `</div>`;
    }
    return genHTML;
}
function buildResults(dataRes, Query) {
    let genHTML = '';
    if (dataRes.length > 0) {
        for (const item of dataRes) {
            const title = boldQuery(item.title, Query)
            genHTML += `<div class="search-result">`
            genHTML += `<a href="${item.indexUrl}" data-clicktracking="${item.clickTrackingUrl}" ><h4 class="title">${boldQuery(item.title, Query)}</h4></a>`;
            genHTML += `<p class="summary">${boldQuery(item.summary, Query)}</p>`;
            genHTML += `</div>`;
        }
    }
    return genHTML;
}
function activeCollection(windowHref, collections) {
    const paramCollection = getParamsVal(windowHref, 'collection');
    let activeCollection = '';
    for (const item of collections) {
        if (item.collection === paramCollection) {
            activeCollection = item.collection;
            break;
        }
    }
    return activeCollection;
}
function SearchResult() {
    const windowHref = window.location.href;
    const dataElems = document.querySelectorAll('[data-funnelbackSearch]');
    const dataJsons = [];
    const Querys = window.location.search.replace('?','').split('&');
    const uniqQuerys = removeArrayDuplicates(Querys);
    for (const dataElem of dataElems) {
        const dataSettings = testData.settings;
        const FbUrl = testData.settings.FbUrl;
        const collection = testData.settings.collection;
        let Params = removeUrlParams(window.location.search, 'collection');
        let fetchUrl = `${FbUrl}?collection=${collection}&${Params}`;
        getJson(dataSettings, windowHref, uniqQuerys, dataElem, fetchUrl, true);
    }
}
async function getJson(dataSettings, windowHref, Querys, dataElem, url, dataInit) {
    const res = await fetch(url)
    .then(response =>response.json())
    .then(data => {
        funnelbackSearchCCT(dataSettings, windowHref, Querys, dataElem, data, dataInit);
        return data;
    })
    .catch(error => {
        return error;
    });
    return res;
}
function btnLoadMore(dataElem, genHTML, dataSettings, action, elemAttr, thisCollection, dataInit) {
    const transitionClass = 'transitionIn';
    const buttons = document.querySelectorAll(elemAttr);
    Object.values(buttons).map((v) => {
        if (v) {
            v.addEventListener(action, function (e) {
                const _this = e.target;
                const _parent = _this.parentNode;
                const windowHref = window.location.href;
                const Querys = window.location.search.replace('?','').split('&');
                const uniqQuerys = removeArrayDuplicates(Querys);
                const elemNextStart = dataElem.querySelector('[nextStart]');
                const dataNextStart = dataElem.getAttribute('nextStart');
                let fetchUrl = `${removeUrlParams(uniqQuerys.join('&'), 'collection')}&collection=${thisCollection}`;
                iconLoader();
                fetchUrl = `${dataSettings.FbUrl}?${fetchUrl}&start_rank=${dataNextStart}`;
                const res = getJson(dataSettings, windowHref, uniqQuerys, dataElem, fetchUrl, dataInit)
                .then (data => {
                    const nextStart = data.response.resultPacket.resultsSummary.nextStart;
                    const datanextStart = document.querySelector('[nextStart]');
                    if (nextStart !== null) {
                        datanextStart.setAttribute('nextStart', nextStart);
                    } else {
                        datanextStart.querySelector('[cta-loadmore-container]').remove();
                    }
                });
                e.stopImmediatePropagation();
            });
        }
        return true;
    });
}
async function autoSugestion(dataElem, url, dataFB, collection, searchtype, Querys) {
    let genHTML = '';
    const fetchUrl = `${dataFB.suggest}?collection=${collection}&searchtype=${searchtype}&partial_query=${Querys}`;
    const res = await fetch(fetchUrl)
    .then(response =>response.json())
    .then(data => {
        let cnt = 0;
        const suggestNum = dataFB && dataFB.suggestNum?dataFB.suggestNum:10;
        if (data.length > 0) {
            genHTML = '<div class="funnelback-suggest--wrapper-contents">';
            for (const item of data) {
                const resultUrl = `${url}?collection=${collection}&searchtype=${searchtype}&query=${item}`;
                genHTML += `<a href="${resultUrl}" target="_self"><div class="item item-text"><span class="txt">${item}</span></div></a>`;
                if (cnt >= suggestNum) { break; }
                cnt++;
            }
            genHTML += `<div>`;
        }
        dataElem.querySelector('[data-suggest]').innerHTML = genHTML;
        return data;
    })
    .catch(error => {
        return error;
    });
    return res;
}
function SearchGlobal() {
    const funnelbacks = document.querySelectorAll('[data-funnelback]');
    for (const funnelback of funnelbacks) {
        const inpAutoSugestion = funnelback.querySelector('[name="query"]');
            inpAutoSugestion.addEventListener('keyup', function (e) {
                const _this = e.target;
                const _parent = _this.parentNode;
                const collection = _parent.querySelector('[name="collection"]').value;
                const searchtype = _parent.querySelector('[name="searchtype"]').value;
                const Querys = _this.value;
                const dataFB = funnelback && funnelback.getAttribute('data-funnelback')? JSON.parse(funnelback.getAttribute('data-funnelback')):[];
                const url = funnelback.getAttribute('action');
                autoSugestion(funnelback, url, dataFB, collection, searchtype, Querys);
                if (Querys !== '') {
                    funnelback.classList.add('active');
                } else {
                    funnelback.classList.remove('active');
                }
                btnClearText('click', '[fb-clear]', 'active', '[data-funnelback]', '[name="query"]', '[data-suggest]', 100);
                e.stopImmediatePropagation();
            });
    }
}
export {
  SearchGlobal
};
(function(){
    'use strict';
    SearchResult();
}());

