import { testData } from './testData';
import SVGs from '../../_global/SVGs/js/svgs';

function LinkListCCT() {
    let genHTML = ''; let cnt = 0;
    const dataElems = document.querySelectorAll('[data-LinkList]');
    for (const dataElem of dataElems) {
        const dataJsonOrigin = dataElem.getAttribute('data-json') !== null && dataElem.getAttribute('data-json') !== ''? JSON.parse(dataElem.getAttribute('data-json')):testData;
        const dataSettings = dataJsonOrigin.settings?dataJsonOrigin.settings:testData.settings;
        let dataJsons = [];
        const dataItems = dataElem.querySelectorAll('[item]');
        for (const dataItem of dataItems) {
            const url = dataItem.querySelector('url') && dataItem.querySelector('url').innerHTML !== ''? JSON.parse(dataItem.querySelector('url').innerHTML)[0]:'';
            dataJsons.push({
                text: dataItem.querySelector('text')? dataItem.querySelector('text').innerHTML:'',
                url: url
            });
        }
        if (dataJsons.length < 1) {
            dataJsons = testData.data;
        }
        if (dataSettings.display === 'visible') {
            const title = dataSettings && (dataSettings.title !== '' && dataSettings.title !== null && dataSettings.title !== undefined)?`<h2 class="title">${dataSettings.title}</h2>`: '';
            genHTML = `<div class="LinkList--wrapper item--wraper" version="${dataSettings.version}" LinkList>
                        ${title}
                        <div class="LinkList--wrapper--container">`;
            if (dataSettings.image !== '' && dataSettings.image !== null && dataSettings.image !== undefined) {
                const subfix = window.location.href.indexOf(':8080') < 0?'/_nocache':'';
                genHTML += `<div class="LinkList--wrapper--container--image">
                    <img src="${dataSettings.image}" />
                </div>`;
            }
            genHTML += `<ul class="ds-link-list ds-link-list--2-cols">`;
            for (const dataJson of dataJsons) {
                genHTML += `<li class="ds-link-list__item">
                <a class="ds-link-list__link" href="${dataJson.url}" target="_self">${dataJson.text}</a>
                </li>`;
            }
            if ((dataSettings.helpUrl !== '' && dataSettings.helpUrl !== null && dataSettings.helpUrl !== undefined) && dataSettings.helpText !== '' && dataSettings.helpText !== null && dataSettings.helpText !== undefined) {
                genHTML += `<li class="ds-link-list__item">
                <a class="ds-link-list__info" href="${dataSettings.helpUrl}" data-component="ds-course-search">${dataSettings.helpText}<svgs data-svg="icon-info"></svgs></a>
                </li>`;
            }
            genHTML += `</ul>`;
            genHTML += `</div>`;
            genHTML += `</div>`;
            dataElem.innerHTML = genHTML;
        }
        dataElem.removeAttribute('data-LinkList');
        if (dataElem && dataElem.getAttribute('data-json')) {
            dataElem.removeAttribute('data-json');
        }
        cnt++;
    }
}
(function(){
    'use strict';
    LinkListCCT();
    new SVGs();
}());
