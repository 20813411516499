export const testData = {
    data: [
        {
            display: "visible",
            image: "../externals/img/banners/about-bedroom.jpg",
            imagePosition: "bottom",
            headline: "Precious Gems Property",
            subheadline: "We are here for you",
            content: "<p>Wepride ourselves on being a progressive and proactive real estate agency specialising in real estate, including residential Sales and  Leasing Property Management in Sydney.</p> <p>Designed to provide a one-stop-shop solution, we provide a strong internal network and forge relationships with people to deliver our client’s daily wants and needs.</p> <p>Precious Gems Property has always been a “Professional  HOME matchmaker.” we will work hard to ensure that you are alleviated from the stress of buying or owning a property.</p> <p>Our team is a group of passionate, dedicated, and hardworking individuals who are property investors. We do not come for selling but mostly to share our investment experiences.</p> <p>We have loved the real estate. But we love helping people even more. We are proud to say that from day one until now; our team has provided the perfect settlement and the value of customer dream homes—more than 120 cases.</p>"
        }
    ]
};
