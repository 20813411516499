export const testData = {
    data: [
        {
            version: "standard",
//             version: "white-header-and-full-image-banner",
//             version:"dark-header-and-small-image",
//             version: "full-image-banner",
            image: "../externals/img/banners/home-1.jpg",
            imageMobile: "../externals/img/banners/home-1-sm.jpg",
//             video: "https://www.latrobe.edu.au/__data/assets/video_file/0009/1372572/impact-is-real2.mp4",
//             headline: "LA TROBE ONLINE – STUDY THAT FITS AROUND YOUR LIFE",
            headline: "Precious Gems Property",
            subheadline: "We take pride in our consistency of quality in everything we do.",
            content: "We pride ourselves on being a progressive and proactive real estate agency specialising in real estate, including residential Sales and  Leasing Property Management in Sydney.",
//             button_ext_url_1: "",
//             button_text_1: "Apply now",
//             button_url_2: "",
//             button_ext_url_2: "https://www.youtube.com/watch?v=tCxBaFE3C0Q",
//             button_text_2: "Register for Open Day"
        }
    ]
};
