import { testData } from './testData';
import SVGs from '../../_global/SVGs/js/svgs';
import Splide from '@splidejs/splide';
import { hasClass, btnRemoveClassElem } from './../../_global/js/lb';

function CourseCardListingCCT() {
    let genHTML = '';
    const windowWidth = window.innerWidth;
    const dataElems = document.querySelectorAll('[data-CourseCardListing]');
    for (const dataElem of dataElems) {
        const dataAttrJsons = dataElem && dataElem.getAttribute('data-json')? JSON.parse(dataElem.getAttribute('data-json')):testData;
        const dataSettings = dataAttrJsons && dataAttrJsons.settings?dataAttrJsons.settings:testData.settings;
        const display = dataSettings && dataSettings.display?dataSettings.display:'visible';
        const dataItems = dataElem.querySelectorAll('[item]');
        if (display === 'visible') {
            const numSlides = dataSettings && dataSettings.perPage?dataSettings.perPage:4;
            const version = dataSettings && dataSettings.version?dataSettings.version:'default';
            const dataTags = dataJsons && dataJsons.tags?dataJsons.tags:testData.tags;
            let dataJsons = [];
             dataElem.setAttribute('version', version);
            for (const dataItem of dataItems) {
                const imgSrc = dataItem.querySelector('dataImage') && dataItem.querySelector('dataImage').innerHTML !== ''? JSON.parse(dataItem.querySelector('dataImage').innerHTML)[0]:'';
                const btnUrl = dataItem.querySelector('button') && dataItem.querySelector('button').innerHTML !== ''? JSON.parse(dataItem.querySelector('button').innerHTML)[0]:'';
                dataJsons.push({
                    image: imgSrc,
                    headline: dataItem.querySelector('headline')? dataItem.querySelector('headline').innerHTML:'',
                    content: dataItem.querySelector('content')? dataItem.querySelector('content').innerHTML:'',
                    tags: dataItem.querySelector('tags')? dataItem.querySelector('tags').innerHTML:'',
                    url: btnUrl,
                    popular: dataItem.querySelector('popular')? dataItem.querySelector('popular').innerHTML:'',
                });
            }
            if (dataJsons.length < 1) {
                dataJsons = testData.data;
            }
            const runSplide = (dataJsons.length > numSlides && windowWidth >= 900) || (dataJsons.length > 1 && windowWidth < 900)?true:false;
            const elemSplide = runSplide === true? 'splide':'listing';
            genHTML = `
            <div class="courscard-listing-wrapper" module-courscard-listing>
                <${elemSplide}>`;
                if (runSplide === true) {
                    genHTML += `
                <div class="${elemSplide}__track">
                    <div class="${elemSplide}__list">`;
                }
                for (const dataJson of dataJsons) {
                    const btnLink = dataJson && dataJson.url?dataJson.url:'';
                    const tags = dataJson && dataJson.tags?dataJson.tags.split(','):[];
                    genHTML += `
                    <div class="${elemSplide}__slide slide bx-slide ">`;
                    genHTML += `
                        <div class="bx-container">
                        <div class="bx-container-contents">`;
                        if (dataJson.image !== null && dataJson.image !== '') {
                            genHTML += `
                            <div class="bx-item bx-image">
                                <img src="${dataJson.image}?_nocache" />`;
                            if (dataJson.popular === 'true' || dataJson.popular === true) {
                                genHTML += `<div class="bx-popular"><svgs data-svg="icon-star"></svgs><span class="txt">Popular course</span></div>`;
                            }
                            genHTML += `</div>`;
                        }
                        genHTML += `<div class="bx-text-wrapper">
                        <h1 class="bx-item title">${dataJson.headline}</h1>`;
                    if (tags.length > 0) {
                        genHTML += `<div class="bx-item bx-tags">`;
                        for (const tag of tags) {
                            if (tag !== null && tag !== '' && dataTags[tag] !== null && dataTags[tag] !== '') {
                                genHTML += `<a btn-tags data-tag="${tag}" data-tag-content="${dataTags[tag]}"><span class="bx-tag" data-tag="${tag}">${tag}</span></a>`;
                            }
                        }
                        genHTML += `</div>`;
                    }
                    genHTML += `<div class="bx-item content">${dataJson.content}</div>`;
                    if (btnLink !== '') {
                        genHTML += `<a href="${btnLink}"><div class="bx-item ds-btn-link btn-link">${dataSettings.button_text} <svgs data-svg="icon-chevron-right"></svgs></div></a>`;
                    }
                    genHTML += `</div>`;
                    genHTML += `</div>`;
                    genHTML += `</div>
                    </div>`;
                }
                if (dataJsons.length > numSlides) {
                    genHTML += `
                            </div>
                        </div>`;
                }
            genHTML += `
                </${elemSplide}>
            </div>`;
            genHTML += (dataSettings.btnMoreURL !== '' && dataSettings.btnMoreURL !== null && dataSettings.btnMoreURL !== undefined) && (dataSettings.btnMoreText !== '' && dataSettings.btnMoreText !== null && dataSettings.btnMoreText !== undefined)?
            `<div class="btn-find-out-more">
                <a class="ds-btn-secondary ds-icon-chevron-right" href="${dataSettings.btnMoreURL}" target="_self">${dataSettings.btnMoreText}</a>
            </div>`
            :'';
            dataElem.innerHTML = genHTML;
            dataElem.removeAttribute('data-CourseCardListing');
            if (dataElem && dataElem.getAttribute('data-json')) {
                dataElem.removeAttribute('data-json');
            }
            if (runSplide === true) {
                const mobileSlides = version !== 'float'?1:1.25;
                genSplide(dataElem, numSlides, mobileSlides);
            }
        } else {
            for (const dataItem of dataItems) {
                dataItem.remove();
            }
            dataElem.removeAttribute('data-CourseCardListing');
            if (dataElem && dataElem.getAttribute('data-json')) {
                dataElem.removeAttribute('data-json');
            }
        }
    }
    btnTags('[btn-tags]');
}
function btnTags(elemAttribute) {
    let genHTML = '';
    const buttons = document.querySelectorAll(elemAttribute);
    Object.values(buttons).map((v) => {
        if (v) {
            v.addEventListener('click', function (e) {
                const body = document.querySelector('body');
                const _this = e.target;
                const elemParent = _this.parentNode;
                const tag = _this.getAttribute('data-tag');
                const tagContent = _this.getAttribute('data-tag-content');
                if (hasClass(_this, 'active') === false) {
                    _this.classList.add('active');
                    elemParent.classList.add('active');
                } else {
                    _this.classList.remove('active');
                    elemParent.classList.remove('active');
                }
                const elemPopup = document.createElement('div');
                elemPopup.classList = 'window-popup-wrapper transitionIn';
                genHTML = `<div class="popup-wrapper"><div class="bg" btn-bg></div>`;
                genHTML += `<div class="content-container">`;
                genHTML += `<div class="btn-close" btn-close-window-popup><svgs data-svg="icon-close"></svgs></div>`;
                genHTML += `<div class="title">${tag} courses</div>`;
                genHTML += `<div class="content">${tagContent}</div>`;
                genHTML += `</div>`;
                genHTML += `</div>`;
                elemPopup.innerHTML = genHTML;
                body.append(elemPopup);
                setTimeout(function(){
                    elemPopup.classList.remove('transitionIn');
                    elemPopup.classList.add('active');
                }, 500);
                btnRemoveClassElem('click', '[btn-bg]', 'active', '.window-popup-wrapper', 500);
                btnRemoveClassElem('click', '[btn-close-window-popup]', 'active', '.window-popup-wrapper', 500);
                new SVGs();
                e.stopImmediatePropagation();
            });
        }
        return true;
    });
}
function genSplide(dataElem, perPage, perPageMobile) {
    const elemSplides = dataElem.querySelectorAll('splide');
    for (const elemSplide of elemSplides) {
        if (hasClass(elemSplide, 'splide') === false) {
            elemSplide.classList.add('splide');
        }
        if (elemSplide) {
            new Splide( '.splide', {
            type   : 'loop',
            start: 0,
            perPage: perPage,
            rewindSpeed: 1000,
            trimSpace: false,
            easing: 'cubic-bezier(0.25, 1, 0.5, 1)',
            paginationKeyboard: true,
            arrows: false,
            breakpoints: {
                1100: {
                  perPage: (perPage - 1) >= 1? (perPage - 1) : 1,
                },
                800: {
                  perPage: (perPage - 2) >= 1? (perPage - 2) : 1,
                },
                640: {
                  perPage: perPageMobile,
                },
            },
            } ).mount();
        }
    }
}
(function(){
    'use strict';
    CourseCardListingCCT();
    new SVGs();
}());
