const Icon = () => {
return `<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
<g id="icon-search">
<path id="Vector" d="M12.5 20.841C17.1944 20.841 21 17.0642 21 12.4053C21 7.74647 17.1944 3.96971 12.5 3.96971C7.80558 3.96971 4 7.74647 4 12.4053C4 17.0642 7.80558 20.841 12.5 20.841Z" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
<path id="Vector_2" d="M26 25.8031L19 18.8561" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
</g>
</svg>
`
}

export default Icon
