const Icon = () => {
return `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<path d="M5.5491 8.2532H0.877563V23.5615H5.5491V8.2532Z" fill="white"/>
<path d="M3.25164 0.67981C1.76308 0.67981 0.55957 1.90425 0.55957 3.4187C0.55957 4.93314 1.76308 6.15759 3.25164 6.15759C4.7402 6.15759 5.94371 4.93314 5.94371 3.4187C5.94371 1.90425 4.7402 0.67981 3.25164 0.67981Z" fill="white"/>
<path d="M17.5057 7.93111C15.2096 7.93111 13.7052 9.22023 13.0717 10.4288H12.9926V8.33396H8.47937V23.6423H13.1509V16.0687C13.1509 14.0544 13.4676 12.1207 15.9222 12.1207C18.3767 12.1207 18.3767 14.3767 18.3767 16.1492V23.5617H23.0482L23.1274 15.1824C23.1274 11.0733 22.2565 7.93111 17.5057 7.93111Z" fill="white"/>
</svg>
`
}

export default Icon
