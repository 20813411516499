import { testData } from './testData';
import SVGs from '../../_global/SVGs/js/svgs';
import Splide from '@splidejs/splide';
import { hasClass } from './../../_global/js/lb';

function NavigationStoriesMobileCCT() {
    let genHTML = '';
    const dataElems = document.querySelectorAll('[data-NavigationStoriesMobile]');
    for (const dataElem of dataElems) {
        const slides = dataElem.querySelectorAll('slide');
        const dataJsons = [];
        if (slides.length > 0) {
            for (const slide of slides) {
                dataJsons.push({
                    image: slide && slide.innerHTML !== null && slide.innerHTML !== ''? JSON.parse(slide.innerHTML)[0] : '',
                    text: slide.getAttribute('data-text')
                });
            }
        } else {
            for (const data of testData.data) {
                dataJsons.push({
                    image: data.image,
                    text: data.text
                });
            }
        }
        genHTML = `
        <splide>
            <div class="splide__track">
                <div class="splide__list">`;
        for (const dataJson of dataJsons) {
            genHTML += `
            <div class="splide__slide">
                <div class="splide__slide--item">
                <div class="bx-image">
                    <image src="${dataJson.image}/_nocache" />
                </div>
                <div class="txt">${dataJson.text}</div>
                </div>
            </div>
            `;
        }
        genHTML += `
                </div>
            </div>
        </splide>
        `;
        dataElem.innerHTML = genHTML;
        genSplide(dataElem);
        dataElem.removeAttribute('data-NavigationStoriesMobile');
        if (dataElem && dataElem.getAttribute('data-json')) {
            dataElem.removeAttribute('data-json');
        }
    }
}
function genSplide(dataElem) {
    const elemSplides = dataElem.querySelectorAll('splide');
    for (const elemSplide of elemSplides) {
        if (hasClass(elemSplide, 'splide') === false) {
            elemSplide.classList.add('splide');
        }
        if (elemSplide) {
            new Splide( '.splide', {
              type   : 'loop',
              start: 0,
              perPage: 3.5,
              rewind     : true,
              rewindSpeed: 1000,
              trimSpace: false,
              easing: 'cubic-bezier(0.25, 1, 0.5, 1)',
              focus: 'left',
              slideFocus: true,
              paginationKeyboard: true,
              arrows: false,
            } ).mount();
        }
    }
}
(function(){
    'use strict';
    NavigationStoriesMobileCCT();
    new SVGs();
}());
