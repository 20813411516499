export const testData = {
    settings: {
    collections: [
            {
                collection: "Latrobe-search",
                name: "All results"
            },
            {
                collection: "course-tabbed",
                name: "Course"
            },
            {
                collection: "events-tabbed",
                name: "Events"
            }
        ],
        searchtype: "global",
        FbUrl: "https://search.latrobe.edu.au/s/search.json",
        FbUrl: "https://squiz-search.clients.funnelback.com/s/search.json",
        url: "/search.html",
        title: `Search Results for <span style="color:red;">Health</span>`,
        collection: "pnp~sp-local-government-meta"
    },
    data: {
"question": {
"executionContext": "Public",
"query": "nsw",
"originalQuery": "nsw",
"collection": {
"id": "Latrobe-search",
"profiles": {
"_default": {
"id": "_default",
"facetedNavConfConfig": {
"facetDefinitions": [
{
"name": "Audience",
"categoryDefinitions": [
{
"facetName": "Audience",
"data": "B",
"label": null,
"subCategories": [],
"queryStringCategoryExtraPart": "B",
"metadataClass": "B",
"allQueryStringParamNames": [
"f.Audience|B"
],
"queryStringParamName": "f.Audience|B"
}
],
"selectionType": "SINGLE",
"constraintJoin": "AND",
"facetValues": "FROM_SCOPED_QUERY_HIDE_UNSELECTED_PARENT_VALUES",
"order": [
"SELECTED_FIRST",
"COUNT_DESCENDING"
],
"allQueryStringParamNames": [
"f.Audience|B"
]
}
],
"metadataFieldsUsed": [
"B"
]
},
"padreOpts": null
}
},
"facetedNavigationConfConfig": null,
"facetedNavigationLiveConfig": null,
"metaComponents": [
"Latrobe",
"Staff-Symplectic"
],
"type": "meta"
},
"profile": "_default",
"clive": null,
"form": "simple",
"cnClickedCluster": null,
"facetsQueryConstraints": [],
"facetCollectionConstraints": null,
"facetsGScopeConstraints": null,
"impersonated": false,
"requestId": "10.128.48.247",
"logQuery": null,
"questionType": "SEARCH",
"locale": "en_GB",
"location": {
"areaCode": 0,
"city": null,
"countryCode": null,
"countryName": null,
"dmaCode": 0,
"latitude": 0,
"longitude": 0,
"metroCode": 0,
"postalCode": null,
"region": null
},
"hostname": null,
"funnelbackVersion": {
"major": 15,
"minor": 14,
"revision": 0
},
"cnPreviousClusters": [],
"metaParameters": [],
"systemMetaParameters": [],
"additionalParameters": {
"HTTP_HOST": [
"search.latrobe.edu.au"
],
"searchtype": [
"global"
],
"origin": [
"0.0,0.0"
],
"REMOTE_ADDR": [
"10.128.48.247"
],
"REQUEST_URI": [
"/s/search.json"
],
"REQUEST_URL": [
"https://search.latrobe.edu.au/s/search.json?collection=Latrobe-search&searchtype=global&query=nsw"
]
},
"environmentVariables": {
"REMOTE_ADDR": "10.128.48.247",
"REQUEST_URI": "/s/search.json",
"HTTP_HOST": "search.latrobe.edu.au",
"REQUEST_URL": "https://search.latrobe.edu.au/s/search.json?collection=Latrobe-search&searchtype=global&query=nsw"
},
"dynamicQueryProcessorOptions": [
"-rmcf=[B]",
"-qlog_file=/data/search/funnelback/data/Latrobe-search/live/log/queries-funnelbackprd-bun.log"
],
"userKeys": [],
"selectedFacets": [],
"selectedCategoryValues": {},
"rawInputParameters": {
"HTTP_HOST": [
"search.latrobe.edu.au"
],
"searchtype": [
"global"
],
"query": [
"nsw"
],
"REMOTE_ADDR": [
"10.128.48.247"
],
"REQUEST_URI": [
"/s/search.json"
],
"collection": [
"Latrobe-search"
],
"REQUEST_URL": [
"https://search.latrobe.edu.au/s/search.json?collection=Latrobe-search&searchtype=global&query=nsw"
]
},
"inputParameterMap": {
"HTTP_HOST": "search.latrobe.edu.au",
"query": "nsw",
"REMOTE_ADDR": "10.128.48.247",
"REQUEST_URL": "https://search.latrobe.edu.au/s/search.json?collection=Latrobe-search&searchtype=global&query=nsw",
"collection": "Latrobe-search",
"searchtype": "global",
"REQUEST_URI": "/s/search.json"
},
"customData": {},
"queryStringMapCopy": {
"searchtype": [
"global"
],
"query": [
"nsw"
],
"collection": [
"Latrobe-search"
]
},
"extraSearch": false
},
"response": {
"resultPacket": {
"details": {
"padreVersion": "FUNNELBACK_PADRE_15.14.0.19 MDPLFS (Web/Ent) [64 bit]",
"collectionSize": "/data/search/funnelback/data/Latrobe-search/live/idx/index: 1530.3 MB, 21239 docs",
"collectionUpdated": 1696953603000
},
"query": "nsw",
"queryAsProcessed": "nsw",
"queryRaw": "nsw",
"querySystemRaw": null,
"queryCleaned": "nsw",
"collection": "Latrobe-search",
"resultsSummary": {
"fullyMatching": 325,
"collapsed": 0,
"partiallyMatching": 0,
"totalMatching": 325,
"estimatedCounts": false,
"carriedOverFtd": null,
"totalDistinctMatchingUrls": null,
"numRanks": 20,
"currStart": 1,
"currEnd": 20,
"prevStart": null,
"nextStart": 21,
"totalSecurityObscuredUrls": null,
"anyUrlsPromoted": false,
"resultDiversificationApplied": true
},
"spell": null,
"bestBets": [],
"results": [
{
"rank": 1,
"score": 1000,
"title": "How to be creative: inside an artist residency at Mungo, NSW – Nest",
"collection": "Latrobe",
"component": 0,
"collapsed": null,
"liveUrl": "https://www.latrobe.edu.au/nest/creative-inside-artist-residency-mungo-nsw/",
"summary": "How to be creative: inside an artist residency at Mungo, NSW. ... Mungo National Park is an Aboriginal cultural site and World Heritage Area in outback NSW.",
"cacheUrl": "/s/cache?collection=Latrobe&url=https%3A%2F%2Fwww.latrobe.edu.au%2Fnest%2Fcreative-inside-artist-residency-mungo-nsw%2F&profile=_default",
"date": 1515502800000,
"fileSize": 76344,
"fileType": "html",
"tier": 1,
"docNum": 13065,
"exploreLink": null,
"kmFromOrigin": null,
"quickLinks": null,
"displayUrl": "https://www.latrobe.edu.au/nest/creative-inside-artist-residency-mungo-nsw/",
"clickTrackingUrl": "http://search.latrobe.edu.au/s/redirect?collection=Latrobe-search&url=https%3A%2F%2Fwww.latrobe.edu.au%2Fnest%2Fcreative-inside-artist-residency-mungo-nsw%2F&auth=J3xamCHLOcfc%2FvE36G7%2BpQ&profile=_default&rank=1&query=nsw",
"explain": null,
"indexUrl": "https://www.latrobe.edu.au/nest/creative-inside-artist-residency-mungo-nsw/",
"gscopesSet": [],
"documentVisibleToUser": true,
"promoted": false,
"diversified": false,
"metaData": {
"A": ""
},
"tags": [],
"customData": {}
},
{
"rank": 2,
"score": 969,
"title": "Teach NSW Career Compass Webinar Series",
"collection": "Latrobe",
"component": 0,
"collapsed": null,
"liveUrl": "https://latrobe.careerhub.com.au/students/news/detail/513/teach-nsw-career-compass-webin",
"summary": "Are you planning to apply for a scholarship or sponsored retraining program with the NSW Department of Education? ... This Career Compass webinar will highlight:. requirements to teach in a NSW public school.",
"cacheUrl": "/s/cache?collection=Latrobe&url=https%3A%2F%2Flatrobe.careerhub.com.au%2Fstudents%2Fnews%2Fdetail%2F513%2Fteach-nsw-career-compass-webin&profile=_default",
"date": null,
"fileSize": 9594,
"fileType": "html",
"tier": 1,
"docNum": 7512,
"exploreLink": null,
"kmFromOrigin": null,
"quickLinks": null,
"displayUrl": "https://latrobe.careerhub.com.au/students/news/detail/513/teach-nsw-career-compass-webin",
"clickTrackingUrl": "http://search.latrobe.edu.au/s/redirect?collection=Latrobe-search&url=https%3A%2F%2Flatrobe.careerhub.com.au%2Fstudents%2Fnews%2Fdetail%2F513%2Fteach-nsw-career-compass-webin&auth=XIZUNktx00uGQ%2BqGa4umBQ&profile=_default&rank=2&query=nsw",
"explain": null,
"indexUrl": "https://latrobe.careerhub.com.au/students/news/detail/513/teach-nsw-career-compass-webin",
"gscopesSet": [],
"documentVisibleToUser": true,
"promoted": false,
"diversified": false,
"metaData": {
"A": ""
},
"tags": [],
"customData": {}
},
{
"rank": 3,
"score": 867,
"title": "Living in Sydney, Sydney Campus",
"collection": "Latrobe",
"component": 0,
"collapsed": null,
"liveUrl": "https://www.latrobe.edu.au/sydney/living",
"summary": "NSW public holidays are New Year&#39;s Day, Australia Day, Good Friday, Easter Monday, Anzac Day, Christmas Day and Boxing Day, the Queen’s birthday and Labour Day. ... at the Art Gallery of NSW before strolling to the Museum of Sydney to learn about our colonial history.",
"cacheUrl": "/s/cache?collection=Latrobe&url=https%3A%2F%2Fwww.latrobe.edu.au%2Fsydney%2Fliving&profile=_default",
"date": 1666184400000,
"fileSize": 94680,
"fileType": "html",
"tier": 1,
"docNum": 8453,
"exploreLink": null,
"kmFromOrigin": null,
"quickLinks": null,
"displayUrl": "https://www.latrobe.edu.au/sydney/living",
"clickTrackingUrl": "http://search.latrobe.edu.au/s/redirect?collection=Latrobe-search&url=https%3A%2F%2Fwww.latrobe.edu.au%2Fsydney%2Fliving&auth=iiY7xWd%2B9Wl8nVB%2FQIypQA&profile=_default&rank=3&query=nsw",
"explain": null,
"indexUrl": "https://www.latrobe.edu.au/sydney/living",
"gscopesSet": [],
"documentVisibleToUser": true,
"promoted": false,
"diversified": false,
"metaData": {
"A": "",
"B": "staff, students, community",
"c": "What students needs to know about living in one of the best cities in the world - Sydney."
},
"tags": [],
"customData": {}
},
{
"rank": 4,
"score": 832,
"title": "CareerHub News",
"collection": "Latrobe",
"component": 0,
"collapsed": null,
"liveUrl": "https://latrobe.careerhub.com.au/rss/news",
"summary": "With a vast Tue, 18 Jul 2023 05:58:00 Z https://latrobe.careerhub.com.au/students/news/Detail/513 The NSW Department of Education are running 3 separate webinars for",
"cacheUrl": "/s/cache?collection=Latrobe&url=https%3A%2F%2Flatrobe.careerhub.com.au%2Frss%2Fnews&profile=_default",
"date": null,
"fileSize": 8901,
"fileType": "xml",
"tier": 1,
"docNum": 7485,
"exploreLink": null,
"kmFromOrigin": null,
"quickLinks": null,
"displayUrl": "https://latrobe.careerhub.com.au/rss/news",
"clickTrackingUrl": "http://search.latrobe.edu.au/s/redirect?collection=Latrobe-search&url=https%3A%2F%2Flatrobe.careerhub.com.au%2Frss%2Fnews&auth=F2cs7JUOyeGZ3dIHTDsKKA&profile=_default&rank=4&query=nsw",
"explain": null,
"indexUrl": "https://latrobe.careerhub.com.au/rss/news",
"gscopesSet": [],
"documentVisibleToUser": true,
"promoted": false,
"diversified": false,
"metaData": {
"A": ""
},
"tags": [],
"customData": {}
},
{
"rank": 5,
"score": 808,
"title": "Industry Engagement and Innovation, Research, Research centres and institutes, Centre for Technology Infusion",
"collection": "Latrobe",
"component": 0,
"collapsed": null,
"liveUrl": "https://www.latrobe.edu.au/research/centres/cti/engagement-and-innovation",
"summary": "With the help of the Physical Disability Council of NSW (PDCN) and the Australian Federation of Disability Organisations (AFDO), we held a series of online information sessions and focus groups to ... Partners. Transport for NSW. iMOVE. AFDO. PDCNSW. Lead researcher – Mr Erik van Vulpen.",
"cacheUrl": "/s/cache?collection=Latrobe&url=https%3A%2F%2Fwww.latrobe.edu.au%2Fresearch%2Fcentres%2Fcti%2Fengagement-and-innovation&profile=_default",
"date": 1671627600000,
"fileSize": 94132,
"fileType": "html",
"tier": 1,
"docNum": 9762,
"exploreLink": null,
"kmFromOrigin": null,
"quickLinks": null,
"displayUrl": "https://www.latrobe.edu.au/research/centres/cti/engagement-and-innovation",
"clickTrackingUrl": "http://search.latrobe.edu.au/s/redirect?collection=Latrobe-search&url=https%3A%2F%2Fwww.latrobe.edu.au%2Fresearch%2Fcentres%2Fcti%2Fengagement-and-innovation&auth=xEEhfnRttSSpThcW6QidvQ&profile=_default&rank=5&query=nsw",
"explain": null,
"indexUrl": "https://www.latrobe.edu.au/research/centres/cti/engagement-and-innovation",
"gscopesSet": [],
"documentVisibleToUser": true,
"promoted": false,
"diversified": false,
"metaData": {
"A": "",
"B": "staff, students, community",
"c": "Developing and testing next generation technology solutions for modern industry."
},
"tags": [],
"customData": {}
},
{
"rank": 6,
"score": 805,
"title": "For primary school students, Olga Tennison Autism Research Centre, Resources about Autism",
"collection": "Latrobe",
"component": 0,
"collapsed": null,
"liveUrl": "https://www.latrobe.edu.au/otarc/resources-about-autism/for-primary-school-students",
"summary": "For primary school students web page",
"cacheUrl": "/s/cache?collection=Latrobe&url=https%3A%2F%2Fwww.latrobe.edu.au%2Fotarc%2Fresources-about-autism%2Ffor-primary-school-students&profile=_default",
"date": 1687788000000,
"fileSize": 88332,
"fileType": "html",
"tier": 1,
"docNum": 9984,
"exploreLink": null,
"kmFromOrigin": null,
"quickLinks": null,
"displayUrl": "https://www.latrobe.edu.au/otarc/resources-about-autism/for-primary-school-students",
"clickTrackingUrl": "http://search.latrobe.edu.au/s/redirect?collection=Latrobe-search&url=https%3A%2F%2Fwww.latrobe.edu.au%2Fotarc%2Fresources-about-autism%2Ffor-primary-school-students&auth=2mCRrpucGq4SikdXEqqihQ&profile=_default&rank=6&query=nsw",
"explain": null,
"indexUrl": "https://www.latrobe.edu.au/otarc/resources-about-autism/for-primary-school-students",
"gscopesSet": [],
"documentVisibleToUser": true,
"promoted": false,
"diversified": false,
"metaData": {
"A": "",
"B": "staff, students, community",
"c": "For primary school students web page"
},
"tags": [],
"customData": {}
},
{
"rank": 7,
"score": 796,
"title": "Visit ASK La Trobe, ASK La Trobe",
"collection": "Latrobe",
"component": 0,
"collapsed": null,
"liveUrl": "https://www.latrobe.edu.au/students/ask-us/visit",
"summary": "255 Elizabeth Street. Sydney NSW 2000. Australia. T: (61 2) 9397 7600.",
"cacheUrl": "/s/cache?collection=Latrobe&url=https%3A%2F%2Fwww.latrobe.edu.au%2Fstudents%2Fask-us%2Fvisit&profile=_default",
"date": 1692626400000,
"fileSize": 87666,
"fileType": "html",
"tier": 1,
"docNum": 365,
"exploreLink": null,
"kmFromOrigin": null,
"quickLinks": null,
"displayUrl": "https://www.latrobe.edu.au/students/ask-us/visit",
"clickTrackingUrl": "http://search.latrobe.edu.au/s/redirect?collection=Latrobe-search&url=https%3A%2F%2Fwww.latrobe.edu.au%2Fstudents%2Fask-us%2Fvisit&auth=JSCT%2BcJbpIlSSHTcD05e0w&profile=_default&rank=7&query=nsw",
"explain": null,
"indexUrl": "https://www.latrobe.edu.au/students/ask-us/visit",
"gscopesSet": [],
"documentVisibleToUser": true,
"promoted": false,
"diversified": false,
"metaData": {
"A": "",
"B": "staff, students, community",
"c": "Where to find ASK La Trobe Help Zones"
},
"tags": [],
"customData": {}
},
{
"rank": 8,
"score": 780,
"title": "ATAR Calculator | La Trobe University",
"collection": "Latrobe",
"component": 0,
"collapsed": null,
"liveUrl": "https://www.latrobe.edu.au/study/undergrad/atar-calculator",
"summary": "Estimate your ATAR and discover your course options.",
"cacheUrl": "/s/cache?collection=Latrobe&url=https%3A%2F%2Fwww.latrobe.edu.au%2Fstudy%2Fundergrad%2Fatar-calculator&profile=_default",
"date": 1693922400000,
"fileSize": 175927,
"fileType": "html",
"tier": 1,
"docNum": 113,
"exploreLink": null,
"kmFromOrigin": null,
"quickLinks": null,
"displayUrl": "https://www.latrobe.edu.au/study/undergrad/atar-calculator",
"clickTrackingUrl": "http://search.latrobe.edu.au/s/redirect?collection=Latrobe-search&url=https%3A%2F%2Fwww.latrobe.edu.au%2Fstudy%2Fundergrad%2Fatar-calculator&auth=w%2BaivN%2BghuytCqoLKSl2NA&profile=_default&rank=8&query=nsw",
"explain": null,
"indexUrl": "https://www.latrobe.edu.au/study/undergrad/atar-calculator",
"gscopesSet": [],
"documentVisibleToUser": true,
"promoted": false,
"diversified": false,
"metaData": {
"A": "",
"B": "staff, students, community",
"c": "Estimate your ATAR and discover your course options."
},
"tags": [],
"customData": {}
},
{
"rank": 9,
"score": 760,
"title": "Getting here and parking, Albury-Wodonga Campus, About the Albury-Wodonga Campus",
"collection": "Latrobe",
"component": 0,
"collapsed": null,
"liveUrl": "https://www.latrobe.edu.au/albury-wodonga/about/location",
"summary": "Albury and Wodonga are twin cities on the NSW-Victorian borders. Driving distance from:. ... travelling from New South Wales origins, use Transport NSW.",
"cacheUrl": "/s/cache?collection=Latrobe&url=https%3A%2F%2Fwww.latrobe.edu.au%2Falbury-wodonga%2Fabout%2Flocation&profile=_default",
"date": 1594216800000,
"fileSize": 79957,
"fileType": "html",
"tier": 1,
"docNum": 1342,
"exploreLink": null,
"kmFromOrigin": null,
"quickLinks": null,
"displayUrl": "https://www.latrobe.edu.au/albury-wodonga/about/location",
"clickTrackingUrl": "http://search.latrobe.edu.au/s/redirect?collection=Latrobe-search&url=https%3A%2F%2Fwww.latrobe.edu.au%2Falbury-wodonga%2Fabout%2Flocation&auth=m3YXAT6jee9iwdQOVsw6fw&profile=_default&rank=9&query=nsw",
"explain": null,
"indexUrl": "https://www.latrobe.edu.au/albury-wodonga/about/location",
"gscopesSet": [],
"documentVisibleToUser": true,
"promoted": false,
"diversified": false,
"metaData": {
"A": "",
"B": "staff, students, community",
"c": "Find everything you need to know about getting to the La Trobe University, Albury Wodonga Campus."
},
"tags": [],
"customData": {}
},
{
"rank": 10,
"score": 758,
"title": "Long Term Intervention Monitoring, Centre for Freshwater Ecosystems ",
"collection": "Latrobe",
"component": 0,
"collapsed": null,
"liveUrl": "https://www.latrobe.edu.au/freshwater-ecosystems/research/projects/ltim",
"summary": "NSW Office of Water. NSW National Parks and Wildlife Services. Western Local Land Services. ... NSW DPI Fishing and Aquaculture. University of New South Wales. Monitoring and Evaluation Provider.",
"cacheUrl": "/s/cache?collection=Latrobe&url=https%3A%2F%2Fwww.latrobe.edu.au%2Ffreshwater-ecosystems%2Fresearch%2Fprojects%2Fltim&profile=_default",
"date": 1529416800000,
"fileSize": 98752,
"fileType": "html",
"tier": 1,
"docNum": 13174,
"exploreLink": null,
"kmFromOrigin": null,
"quickLinks": null,
"displayUrl": "https://www.latrobe.edu.au/freshwater-ecosystems/research/projects/ltim",
"clickTrackingUrl": "http://search.latrobe.edu.au/s/redirect?collection=Latrobe-search&url=https%3A%2F%2Fwww.latrobe.edu.au%2Ffreshwater-ecosystems%2Fresearch%2Fprojects%2Fltim&auth=CsHmbb99VUTkduJp%2BT%2F%2FpQ&profile=_default&rank=10&query=nsw",
"explain": null,
"indexUrl": "https://www.latrobe.edu.au/freshwater-ecosystems/research/projects/ltim",
"gscopesSet": [],
"documentVisibleToUser": true,
"promoted": false,
"diversified": false,
"metaData": {
"A": "",
"B": "staff, students, community",
"c": "Long Term Intervention Monitoring (LTIM) is a key element of the Commonwealth Environmental Water Office (CEWO) response to the requirements of the Water Act 2007 and the MDBA’s Basin Plan."
},
"tags": [],
"customData": {}
},
{
"rank": 11,
"score": 754,
"title": "Key dates: all students, Key dates",
"collection": "Latrobe",
"component": 0,
"collapsed": null,
"liveUrl": "https://www.latrobe.edu.au/calendar/all-students",
"summary": "Semesters, enrolment, orientation, census dates and vacations.",
"cacheUrl": "/s/cache?collection=Latrobe&url=https%3A%2F%2Fwww.latrobe.edu.au%2Fcalendar%2Fall-students&profile=_default",
"date": 1602680400000,
"fileSize": 78132,
"fileType": "html",
"tier": 1,
"docNum": 1903,
"exploreLink": null,
"kmFromOrigin": null,
"quickLinks": null,
"displayUrl": "https://www.latrobe.edu.au/calendar/all-students",
"clickTrackingUrl": "http://search.latrobe.edu.au/s/redirect?collection=Latrobe-search&url=https%3A%2F%2Fwww.latrobe.edu.au%2Fcalendar%2Fall-students&auth=8FGiALiO78lNMzB3sdIiQw&profile=_default&rank=11&query=nsw",
"explain": null,
"indexUrl": "https://www.latrobe.edu.au/calendar/all-students",
"gscopesSet": [],
"documentVisibleToUser": true,
"promoted": false,
"diversified": false,
"metaData": {
"A": "",
"B": "staff, students, community",
"c": "Semesters, enrolment, orientation, census dates and vacations."
},
"tags": [],
"customData": {}
},
{
"rank": 12,
"score": 754,
"title": "Locations and Maps, About La Trobe, La Trobe at a glance",
"collection": "Latrobe",
"component": 0,
"collapsed": null,
"liveUrl": "https://www.latrobe.edu.au/about/at-a-glance/maps",
"summary": "Sydney. 255 Elizabeth Street, Sydney, NSW 2000. Regional campuses.",
"cacheUrl": "/s/cache?collection=Latrobe&url=https%3A%2F%2Fwww.latrobe.edu.au%2Fabout%2Fat-a-glance%2Fmaps&profile=_default",
"date": 1520341200000,
"fileSize": 82377,
"fileType": "html",
"tier": 1,
"docNum": 102,
"exploreLink": null,
"kmFromOrigin": null,
"quickLinks": null,
"displayUrl": "https://www.latrobe.edu.au/about/at-a-glance/maps",
"clickTrackingUrl": "http://search.latrobe.edu.au/s/redirect?collection=Latrobe-search&url=https%3A%2F%2Fwww.latrobe.edu.au%2Fabout%2Fat-a-glance%2Fmaps&auth=Xdi0eHsDdEnyskEqyOrBlg&profile=_default&rank=12&query=nsw",
"explain": null,
"indexUrl": "https://www.latrobe.edu.au/about/at-a-glance/maps",
"gscopesSet": [],
"documentVisibleToUser": true,
"promoted": false,
"diversified": false,
"metaData": {
"A": "",
"B": "staff, students, community",
"c": "Find out where our campuses are located and how to get there, including public transport and parking."
},
"tags": [],
"customData": {}
},
{
"rank": 13,
"score": 752,
"title": "Albury-Wodonga Campus",
"collection": "Latrobe",
"component": 0,
"collapsed": null,
"liveUrl": "https://www.latrobe.edu.au/albury-wodonga",
"summary": "I moved from Griffith (NSW) to attend uni here. I wish people knew how welcoming and lovely the community is here at La Trobe.",
"cacheUrl": "/s/cache?collection=Latrobe&url=https%3A%2F%2Fwww.latrobe.edu.au%2Falbury-wodonga&profile=_default",
"date": 1659967200000,
"fileSize": 99065,
"fileType": "html",
"tier": 1,
"docNum": 628,
"exploreLink": null,
"kmFromOrigin": null,
"quickLinks": null,
"displayUrl": "https://www.latrobe.edu.au/albury-wodonga",
"clickTrackingUrl": "http://search.latrobe.edu.au/s/redirect?collection=Latrobe-search&url=https%3A%2F%2Fwww.latrobe.edu.au%2Falbury-wodonga&auth=lyxrjfP0VRBNNG0ri6sM5A&profile=_default&rank=13&query=nsw",
"explain": null,
"indexUrl": "https://www.latrobe.edu.au/albury-wodonga",
"gscopesSet": [],
"documentVisibleToUser": true,
"promoted": false,
"diversified": false,
"metaData": {
"A": "",
"B": "staff, students, community",
"c": "Discover how you can study at the La Trobe University Albury Wodonga Campus."
},
"tags": [],
"customData": {}
},
{
"rank": 14,
"score": 750,
"title": "Library room bookings - La Trobe University",
"collection": "Latrobe",
"component": 0,
"collapsed": null,
"liveUrl": "https://www.latrobe.edu.au/library/facilities/book",
"summary": "Enter your current La Trobe University student username and password. Username. Example: JBloggs. Password. Copyright 2012 La Trobe University. All rights reserved. CRICOS Provider Code: Version: 4.0.11.0  Created: 12 December, 2012.",
"cacheUrl": "/s/cache?collection=Latrobe&url=https%3A%2F%2Fwww.latrobe.edu.au%2Flibrary%2Ffacilities%2Fbook&profile=_default",
"date": null,
"fileSize": 5972,
"fileType": "html",
"tier": 1,
"docNum": 8555,
"exploreLink": null,
"kmFromOrigin": null,
"quickLinks": null,
"displayUrl": "https://www.latrobe.edu.au/library/facilities/book",
"clickTrackingUrl": "http://search.latrobe.edu.au/s/redirect?collection=Latrobe-search&url=https%3A%2F%2Fwww.latrobe.edu.au%2Flibrary%2Ffacilities%2Fbook&auth=Z8Hnr1tzUeFZtg%2FjBeLWRw&profile=_default&rank=14&query=nsw",
"explain": null,
"indexUrl": "https://www.latrobe.edu.au/library/facilities/book",
"gscopesSet": [],
"documentVisibleToUser": true,
"promoted": false,
"diversified": false,
"metaData": {
"A": ""
},
"tags": [],
"customData": {}
},
{
"rank": 15,
"score": 728,
"title": "Sir John Quick Lecture 2023, Events",
"collection": "Latrobe",
"component": 0,
"collapsed": null,
"liveUrl": "https://www.latrobe.edu.au/events/all/sir-john-quick-lecture-2023",
"summary": "She has also previously worked for the High Court of Australia, the Commonwealth Parliamentary Research Service, the Senate Legal and Constitutional Committee and The Cabinet Office of NSW.",
"cacheUrl": "/s/cache?collection=Latrobe&url=https%3A%2F%2Fwww.latrobe.edu.au%2Fevents%2Fall%2Fsir-john-quick-lecture-2023&profile=_default",
"date": 1692108000000,
"fileSize": 101557,
"fileType": "html",
"tier": 1,
"docNum": 10408,
"exploreLink": null,
"kmFromOrigin": null,
"quickLinks": null,
"displayUrl": "https://www.latrobe.edu.au/events/all/sir-john-quick-lecture-2023",
"clickTrackingUrl": "http://search.latrobe.edu.au/s/redirect?collection=Latrobe-search&url=https%3A%2F%2Fwww.latrobe.edu.au%2Fevents%2Fall%2Fsir-john-quick-lecture-2023&auth=f7IOezkko%2F1EYtmcwARMfg&profile=_default&rank=15&query=nsw",
"explain": null,
"indexUrl": "https://www.latrobe.edu.au/events/all/sir-john-quick-lecture-2023",
"gscopesSet": [],
"documentVisibleToUser": true,
"promoted": false,
"diversified": false,
"metaData": {
"A": "",
"B": "staff, students, community",
"c": "A highly topical lecture providing historic context for The Voice referendum"
},
"tags": [],
"customData": {}
},
{
"rank": 16,
"score": 719,
"title": "Partners, Living with Disability Research Centre",
"collection": "Latrobe",
"component": 0,
"collapsed": null,
"liveUrl": "https://www.latrobe.edu.au/lids/partners",
"summary": "NSW Trustee and Guardian. Oak Possibility. Office of Public Advocate Victoria.",
"cacheUrl": "/s/cache?collection=Latrobe&url=https%3A%2F%2Fwww.latrobe.edu.au%2Flids%2Fpartners&profile=_default",
"date": 1446642000000,
"fileSize": 86266,
"fileType": "html",
"tier": 1,
"docNum": 9963,
"exploreLink": null,
"kmFromOrigin": null,
"quickLinks": null,
"displayUrl": "https://www.latrobe.edu.au/lids/partners",
"clickTrackingUrl": "http://search.latrobe.edu.au/s/redirect?collection=Latrobe-search&url=https%3A%2F%2Fwww.latrobe.edu.au%2Flids%2Fpartners&auth=iX2TiITGqSBN66YcSgCENA&profile=_default&rank=16&query=nsw",
"explain": null,
"indexUrl": "https://www.latrobe.edu.au/lids/partners",
"gscopesSet": [],
"documentVisibleToUser": true,
"promoted": false,
"diversified": false,
"metaData": {
"A": "",
"B": "staff, students, community",
"c": "The Living with Disability Centre is highly collaborative – with established links across La Trobe University, with other universities disability services and mainstream service."
},
"tags": [],
"customData": {}
},
{
"rank": 17,
"score": 717,
"title": "MyPrint Recharge - La Trobe University",
"collection": "Latrobe",
"component": 0,
"collapsed": null,
"liveUrl": "https://www.latrobe.edu.au/campusgraphics/print/reload_online.php",
"summary": "MyPrint Recharge",
"cacheUrl": "/s/cache?collection=Latrobe&url=https%3A%2F%2Fwww.latrobe.edu.au%2Fcampusgraphics%2Fprint%2Freload_online.php&profile=_default",
"date": null,
"fileSize": 11263,
"fileType": "html",
"tier": 1,
"docNum": 8089,
"exploreLink": null,
"kmFromOrigin": null,
"quickLinks": null,
"displayUrl": "https://www.latrobe.edu.au/campusgraphics/print/reload_online.php",
"clickTrackingUrl": "http://search.latrobe.edu.au/s/redirect?collection=Latrobe-search&url=https%3A%2F%2Fwww.latrobe.edu.au%2Fcampusgraphics%2Fprint%2Freload_online.php&auth=LPZkdJ4eGLKiQkcKQQqvWw&profile=_default&rank=17&query=nsw",
"explain": null,
"indexUrl": "https://www.latrobe.edu.au/campusgraphics/print/reload_online.php",
"gscopesSet": [],
"documentVisibleToUser": true,
"promoted": false,
"diversified": false,
"metaData": {
"A": "",
"B": "community",
"c": "MyPrint Recharge"
},
"tags": [],
"customData": {}
},
{
"rank": 18,
"score": 716,
"title": "Writing Themselves In 4, Australian Research Centre in Sex, Health and Society",
"collection": "Latrobe",
"component": 0,
"collapsed": null,
"liveUrl": "https://www.latrobe.edu.au/arcshs/work/writing-themselves-in-4",
"summary": "Summary reports. These reports summarise key findings from Writing Themselves In 4 that are specific to the participants who were residents in the Australian Capital Territory (ACT), New South Wales (NSW),",
"cacheUrl": "/s/cache?collection=Latrobe&url=https%3A%2F%2Fwww.latrobe.edu.au%2Farcshs%2Fwork%2Fwriting-themselves-in-4&profile=_default",
"date": 1610888400000,
"fileSize": 95486,
"fileType": "html",
"tier": 1,
"docNum": 9852,
"exploreLink": null,
"kmFromOrigin": null,
"quickLinks": null,
"displayUrl": "https://www.latrobe.edu.au/arcshs/work/writing-themselves-in-4",
"clickTrackingUrl": "http://search.latrobe.edu.au/s/redirect?collection=Latrobe-search&url=https%3A%2F%2Fwww.latrobe.edu.au%2Farcshs%2Fwork%2Fwriting-themselves-in-4&auth=wqBQwFWkXxP7ernY%2Fc497Q&profile=_default&rank=18&query=nsw",
"explain": null,
"indexUrl": "https://www.latrobe.edu.au/arcshs/work/writing-themselves-in-4",
"gscopesSet": [],
"documentVisibleToUser": true,
"promoted": false,
"diversified": false,
"metaData": {
"A": "",
"B": "staff, students, community",
"c": "Read and download all the Writing Themselves In 4 reports. Including the national, SA, ACT, NSW and Victoria reports."
},
"tags": [],
"customData": {}
},
{
"rank": 19,
"score": 714,
"title": "Industry connected, Courses and degrees, The La Trobe MBA",
"collection": "Latrobe",
"component": 0,
"collapsed": null,
"liveUrl": "https://www.latrobe.edu.au/courses/mba/industry-connected",
"summary": "Janine Young. Ombudsman,. Energy and water NSW, La Trobe graduate. The Master of Business Administration enabled me to grasp concepts paramount to correct business development, management and operations of an organisation. ... NSW Department of Parliamentary Services, Chief Executive. Port of Melbourne Corporation, Executive General Manager, Business &amp;Planning.",
"cacheUrl": "/s/cache?collection=Latrobe&url=https%3A%2F%2Fwww.latrobe.edu.au%2Fcourses%2Fmba%2Findustry-connected&profile=_default",
"date": 1599746400000,
"fileSize": 83662,
"fileType": "html",
"tier": 1,
"docNum": 9701,
"exploreLink": null,
"kmFromOrigin": null,
"quickLinks": null,
"displayUrl": "https://www.latrobe.edu.au/courses/mba/industry-connected",
"clickTrackingUrl": "http://search.latrobe.edu.au/s/redirect?collection=Latrobe-search&url=https%3A%2F%2Fwww.latrobe.edu.au%2Fcourses%2Fmba%2Findustry-connected&auth=vfuTOxJLnIv%2FN%2FJyy8BMzw&profile=_default&rank=19&query=nsw",
"explain": null,
"indexUrl": "https://www.latrobe.edu.au/courses/mba/industry-connected",
"gscopesSet": [],
"documentVisibleToUser": true,
"promoted": false,
"diversified": false,
"metaData": {
"A": "",
"B": "staff, students, community",
"c": "Our MBA draws on a range of industry partners, placing a strong emphasis on enterprise learning. You’ll broaden your business knowledge through our uniquely co-designed program."
},
"tags": [],
"customData": {}
},
{
"rank": 20,
"score": 712,
"title": "Educational Partnerships Program, Outreach",
"collection": "Latrobe",
"component": 0,
"collapsed": null,
"liveUrl": "https://www.latrobe.edu.au/outreach/educational-partnerships-program",
"summary": "James Fallon High School (NSW). Murray High School (NSW). Wangaratta High School. ... Chaffey College. Coomealla High School (NSW). Irymple Secondary College. Merbein P‐10 College.",
"cacheUrl": "/s/cache?collection=Latrobe&url=https%3A%2F%2Fwww.latrobe.edu.au%2Foutreach%2Feducational-partnerships-program&profile=_default",
"date": 1621519200000,
"fileSize": 97783,
"fileType": "html",
"tier": 1,
"docNum": 8817,
"exploreLink": null,
"kmFromOrigin": null,
"quickLinks": null,
"displayUrl": "https://www.latrobe.edu.au/outreach/educational-partnerships-program",
"clickTrackingUrl": "http://search.latrobe.edu.au/s/redirect?collection=Latrobe-search&url=https%3A%2F%2Fwww.latrobe.edu.au%2Foutreach%2Feducational-partnerships-program&auth=YxkGJZhr15YtpI6gD3%2FkzA&profile=_default&rank=20&query=nsw",
"explain": null,
"indexUrl": "https://www.latrobe.edu.au/outreach/educational-partnerships-program",
"gscopesSet": [],
"documentVisibleToUser": true,
"promoted": false,
"diversified": false,
"metaData": {
"A": "",
"B": "staff, students, community",
"c": "Our Educational Partnerships program works works collaboratively with secondary schools."
},
"tags": [],
"customData": {}
}
],
"tierBars": [
{
"matched": 1,
"outOf": 1,
"firstRank": 0,
"lastRank": 20,
"eventDate": null
}
],
"error": null,
"padreElapsedTime": 63,
"phlusterElapsedTime": 0.04,
"queryProcessorCodes": "2x",
"contextualNavigation": {
"searchTerm": "nsw",
"clusterNav": {
"level": 0,
"url": "collection=Latrobe-search&searchtype=global&query=nsw",
"label": "nsw"
},
"categories": [
{
"name": "type",
"more": 0,
"moreLink": null,
"fewerLink": null,
"clusters": [
{
"href": "?clicked_fluster=regional+nsw&cluster0=nsw&searchtype=global&query=%60regional+NSW%60&collection=Latrobe-search",
"count": 4,
"label": "Regional...",
"query": "regional NSW"
},
{
"href": "?clicked_fluster=sydney+nsw&cluster0=nsw&searchtype=global&query=%60Sydney+NSW%60&collection=Latrobe-search",
"count": 4,
"label": "Sydney...",
"query": "Sydney NSW"
},
{
"href": "?clicked_fluster=transport+nsw&cluster0=nsw&searchtype=global&query=%60Transport+NSW%60&collection=Latrobe-search",
"count": 3,
"label": "Transport...",
"query": "Transport NSW"
},
{
"href": "?clicked_fluster=phd+nsw&cluster0=nsw&searchtype=global&query=%60PhD+NSW%60&collection=Latrobe-search",
"count": 3,
"label": "PhD...",
"query": "PhD NSW"
}
]
},
{
"name": "topic",
"more": 0,
"moreLink": null,
"fewerLink": null,
"clusters": [
{
"href": "?clicked_fluster=victoria+and+nsw&cluster0=nsw&searchtype=global&query=%60Victoria+and+NSW%60&collection=Latrobe-search",
"count": 9,
"label": "Victoria and...",
"query": "Victoria and NSW"
},
{
"href": "?clicked_fluster=nsw+and+victoria&cluster0=nsw&searchtype=global&query=%60NSW+and+Victoria%60&collection=Latrobe-search",
"count": 7,
"label": "...and Victoria",
"query": "NSW and Victoria"
},
{
"href": "?clicked_fluster=nsw+department&cluster0=nsw&searchtype=global&query=%60NSW+Department%60&collection=Latrobe-search",
"count": 7,
"label": "...Department",
"query": "NSW Department"
},
{
"href": "?clicked_fluster=nsw+2000&cluster0=nsw&searchtype=global&query=%60NSW+2000%60&collection=Latrobe-search",
"count": 6,
"label": "...2000",
"query": "NSW 2000"
},
{
"href": "?clicked_fluster=society+of+nsw&cluster0=nsw&searchtype=global&query=%60Society+of+NSW%60&collection=Latrobe-search",
"count": 3,
"label": "Society of...",
"query": "Society of NSW"
}
]
},
{
"name": "site",
"more": 0,
"moreLink": null,
"fewerLink": null,
"clusters": [
{
"href": "?cluster0=nsw&searchtype=global&query=nsw+%7Cu%3Awww.latrobe.edu.au&collection=Latrobe-search",
"count": 143,
"label": "www.latrobe.edu.au",
"query": null
},
{
"href": "?cluster0=nsw&searchtype=global&query=nsw+%7Cu%3Alatrobe.careerhub.com.au&collection=Latrobe-search",
"count": 7,
"label": "latrobe.careerhub.com.au",
"query": null
}
]
}
],
"customData": {}
},
"rmcs": {
"B:students": 128,
"-B:": 195,
"B:Mature Age": 1,
"B:Undergraduate": 1,
"B:staff": 128,
"B:TAFE students": 1,
"B:UGNSL": 1,
"B:community": 129,
"B:postgraduate": 1
},
"rmcItemResults": {},
"metadataRanges": {},
"boundingBoxes": {},
"urlCounts": {},
"dateCounts": {},
"queryHighlightRegex": "(?i)\\bnsw\\b",
"origin": [],
"entityList": {},
"includeScopes": [],
"excludeScopes": [],
"coolerWeights": {},
"explainTypes": {},
"stopWords": [],
"stemmedEquivs": {
"empty": true
},
"coolerNames": {},
"svgs": {},
"uniqueCountsByGroups": [],
"sumByGroups": [],
"indexedTermCounts": [],
"documentsPerCollection": {},
"metadataSums": {},
"gscopeCounts": {},
"resultsWithTierBars": [
{
"matched": 1,
"outOf": 1,
"firstRank": 0,
"lastRank": 20,
"eventDate": null
},
{
"rank": 1,
"score": 1000,
"title": "How to be creative: inside an artist residency at Mungo, NSW – Nest",
"collection": "Latrobe",
"component": 0,
"collapsed": null,
"liveUrl": "https://www.latrobe.edu.au/nest/creative-inside-artist-residency-mungo-nsw/",
"summary": "How to be creative: inside an artist residency at Mungo, NSW. ... Mungo National Park is an Aboriginal cultural site and World Heritage Area in outback NSW.",
"cacheUrl": "/s/cache?collection=Latrobe&url=https%3A%2F%2Fwww.latrobe.edu.au%2Fnest%2Fcreative-inside-artist-residency-mungo-nsw%2F&profile=_default",
"date": 1515502800000,
"fileSize": 76344,
"fileType": "html",
"tier": 1,
"docNum": 13065,
"exploreLink": null,
"kmFromOrigin": null,
"quickLinks": null,
"displayUrl": "https://www.latrobe.edu.au/nest/creative-inside-artist-residency-mungo-nsw/",
"clickTrackingUrl": "http://search.latrobe.edu.au/s/redirect?collection=Latrobe-search&url=https%3A%2F%2Fwww.latrobe.edu.au%2Fnest%2Fcreative-inside-artist-residency-mungo-nsw%2F&auth=J3xamCHLOcfc%2FvE36G7%2BpQ&profile=_default&rank=1&query=nsw",
"explain": null,
"indexUrl": "https://www.latrobe.edu.au/nest/creative-inside-artist-residency-mungo-nsw/",
"gscopesSet": [],
"documentVisibleToUser": true,
"promoted": false,
"diversified": false,
"metaData": {
"A": ""
},
"tags": [],
"customData": {}
},
{
"rank": 2,
"score": 969,
"title": "Teach NSW Career Compass Webinar Series",
"collection": "Latrobe",
"component": 0,
"collapsed": null,
"liveUrl": "https://latrobe.careerhub.com.au/students/news/detail/513/teach-nsw-career-compass-webin",
"summary": "Are you planning to apply for a scholarship or sponsored retraining program with the NSW Department of Education? ... This Career Compass webinar will highlight:. requirements to teach in a NSW public school.",
"cacheUrl": "/s/cache?collection=Latrobe&url=https%3A%2F%2Flatrobe.careerhub.com.au%2Fstudents%2Fnews%2Fdetail%2F513%2Fteach-nsw-career-compass-webin&profile=_default",
"date": null,
"fileSize": 9594,
"fileType": "html",
"tier": 1,
"docNum": 7512,
"exploreLink": null,
"kmFromOrigin": null,
"quickLinks": null,
"displayUrl": "https://latrobe.careerhub.com.au/students/news/detail/513/teach-nsw-career-compass-webin",
"clickTrackingUrl": "http://search.latrobe.edu.au/s/redirect?collection=Latrobe-search&url=https%3A%2F%2Flatrobe.careerhub.com.au%2Fstudents%2Fnews%2Fdetail%2F513%2Fteach-nsw-career-compass-webin&auth=XIZUNktx00uGQ%2BqGa4umBQ&profile=_default&rank=2&query=nsw",
"explain": null,
"indexUrl": "https://latrobe.careerhub.com.au/students/news/detail/513/teach-nsw-career-compass-webin",
"gscopesSet": [],
"documentVisibleToUser": true,
"promoted": false,
"diversified": false,
"metaData": {
"A": ""
},
"tags": [],
"customData": {}
},
{
"rank": 3,
"score": 867,
"title": "Living in Sydney, Sydney Campus",
"collection": "Latrobe",
"component": 0,
"collapsed": null,
"liveUrl": "https://www.latrobe.edu.au/sydney/living",
"summary": "NSW public holidays are New Year&#39;s Day, Australia Day, Good Friday, Easter Monday, Anzac Day, Christmas Day and Boxing Day, the Queen’s birthday and Labour Day. ... at the Art Gallery of NSW before strolling to the Museum of Sydney to learn about our colonial history.",
"cacheUrl": "/s/cache?collection=Latrobe&url=https%3A%2F%2Fwww.latrobe.edu.au%2Fsydney%2Fliving&profile=_default",
"date": 1666184400000,
"fileSize": 94680,
"fileType": "html",
"tier": 1,
"docNum": 8453,
"exploreLink": null,
"kmFromOrigin": null,
"quickLinks": null,
"displayUrl": "https://www.latrobe.edu.au/sydney/living",
"clickTrackingUrl": "http://search.latrobe.edu.au/s/redirect?collection=Latrobe-search&url=https%3A%2F%2Fwww.latrobe.edu.au%2Fsydney%2Fliving&auth=iiY7xWd%2B9Wl8nVB%2FQIypQA&profile=_default&rank=3&query=nsw",
"explain": null,
"indexUrl": "https://www.latrobe.edu.au/sydney/living",
"gscopesSet": [],
"documentVisibleToUser": true,
"promoted": false,
"diversified": false,
"metaData": {
"A": "",
"B": "staff, students, community",
"c": "What students needs to know about living in one of the best cities in the world - Sydney."
},
"tags": [],
"customData": {}
},
{
"rank": 4,
"score": 832,
"title": "CareerHub News",
"collection": "Latrobe",
"component": 0,
"collapsed": null,
"liveUrl": "https://latrobe.careerhub.com.au/rss/news",
"summary": "With a vast Tue, 18 Jul 2023 05:58:00 Z https://latrobe.careerhub.com.au/students/news/Detail/513 The NSW Department of Education are running 3 separate webinars for",
"cacheUrl": "/s/cache?collection=Latrobe&url=https%3A%2F%2Flatrobe.careerhub.com.au%2Frss%2Fnews&profile=_default",
"date": null,
"fileSize": 8901,
"fileType": "xml",
"tier": 1,
"docNum": 7485,
"exploreLink": null,
"kmFromOrigin": null,
"quickLinks": null,
"displayUrl": "https://latrobe.careerhub.com.au/rss/news",
"clickTrackingUrl": "http://search.latrobe.edu.au/s/redirect?collection=Latrobe-search&url=https%3A%2F%2Flatrobe.careerhub.com.au%2Frss%2Fnews&auth=F2cs7JUOyeGZ3dIHTDsKKA&profile=_default&rank=4&query=nsw",
"explain": null,
"indexUrl": "https://latrobe.careerhub.com.au/rss/news",
"gscopesSet": [],
"documentVisibleToUser": true,
"promoted": false,
"diversified": false,
"metaData": {
"A": ""
},
"tags": [],
"customData": {}
},
{
"rank": 5,
"score": 808,
"title": "Industry Engagement and Innovation, Research, Research centres and institutes, Centre for Technology Infusion",
"collection": "Latrobe",
"component": 0,
"collapsed": null,
"liveUrl": "https://www.latrobe.edu.au/research/centres/cti/engagement-and-innovation",
"summary": "With the help of the Physical Disability Council of NSW (PDCN) and the Australian Federation of Disability Organisations (AFDO), we held a series of online information sessions and focus groups to ... Partners. Transport for NSW. iMOVE. AFDO. PDCNSW. Lead researcher – Mr Erik van Vulpen.",
"cacheUrl": "/s/cache?collection=Latrobe&url=https%3A%2F%2Fwww.latrobe.edu.au%2Fresearch%2Fcentres%2Fcti%2Fengagement-and-innovation&profile=_default",
"date": 1671627600000,
"fileSize": 94132,
"fileType": "html",
"tier": 1,
"docNum": 9762,
"exploreLink": null,
"kmFromOrigin": null,
"quickLinks": null,
"displayUrl": "https://www.latrobe.edu.au/research/centres/cti/engagement-and-innovation",
"clickTrackingUrl": "http://search.latrobe.edu.au/s/redirect?collection=Latrobe-search&url=https%3A%2F%2Fwww.latrobe.edu.au%2Fresearch%2Fcentres%2Fcti%2Fengagement-and-innovation&auth=xEEhfnRttSSpThcW6QidvQ&profile=_default&rank=5&query=nsw",
"explain": null,
"indexUrl": "https://www.latrobe.edu.au/research/centres/cti/engagement-and-innovation",
"gscopesSet": [],
"documentVisibleToUser": true,
"promoted": false,
"diversified": false,
"metaData": {
"A": "",
"B": "staff, students, community",
"c": "Developing and testing next generation technology solutions for modern industry."
},
"tags": [],
"customData": {}
},
{
"rank": 6,
"score": 805,
"title": "For primary school students, Olga Tennison Autism Research Centre, Resources about Autism",
"collection": "Latrobe",
"component": 0,
"collapsed": null,
"liveUrl": "https://www.latrobe.edu.au/otarc/resources-about-autism/for-primary-school-students",
"summary": "For primary school students web page",
"cacheUrl": "/s/cache?collection=Latrobe&url=https%3A%2F%2Fwww.latrobe.edu.au%2Fotarc%2Fresources-about-autism%2Ffor-primary-school-students&profile=_default",
"date": 1687788000000,
"fileSize": 88332,
"fileType": "html",
"tier": 1,
"docNum": 9984,
"exploreLink": null,
"kmFromOrigin": null,
"quickLinks": null,
"displayUrl": "https://www.latrobe.edu.au/otarc/resources-about-autism/for-primary-school-students",
"clickTrackingUrl": "http://search.latrobe.edu.au/s/redirect?collection=Latrobe-search&url=https%3A%2F%2Fwww.latrobe.edu.au%2Fotarc%2Fresources-about-autism%2Ffor-primary-school-students&auth=2mCRrpucGq4SikdXEqqihQ&profile=_default&rank=6&query=nsw",
"explain": null,
"indexUrl": "https://www.latrobe.edu.au/otarc/resources-about-autism/for-primary-school-students",
"gscopesSet": [],
"documentVisibleToUser": true,
"promoted": false,
"diversified": false,
"metaData": {
"A": "",
"B": "staff, students, community",
"c": "For primary school students web page"
},
"tags": [],
"customData": {}
},
{
"rank": 7,
"score": 796,
"title": "Visit ASK La Trobe, ASK La Trobe",
"collection": "Latrobe",
"component": 0,
"collapsed": null,
"liveUrl": "https://www.latrobe.edu.au/students/ask-us/visit",
"summary": "255 Elizabeth Street. Sydney NSW 2000. Australia. T: (61 2) 9397 7600.",
"cacheUrl": "/s/cache?collection=Latrobe&url=https%3A%2F%2Fwww.latrobe.edu.au%2Fstudents%2Fask-us%2Fvisit&profile=_default",
"date": 1692626400000,
"fileSize": 87666,
"fileType": "html",
"tier": 1,
"docNum": 365,
"exploreLink": null,
"kmFromOrigin": null,
"quickLinks": null,
"displayUrl": "https://www.latrobe.edu.au/students/ask-us/visit",
"clickTrackingUrl": "http://search.latrobe.edu.au/s/redirect?collection=Latrobe-search&url=https%3A%2F%2Fwww.latrobe.edu.au%2Fstudents%2Fask-us%2Fvisit&auth=JSCT%2BcJbpIlSSHTcD05e0w&profile=_default&rank=7&query=nsw",
"explain": null,
"indexUrl": "https://www.latrobe.edu.au/students/ask-us/visit",
"gscopesSet": [],
"documentVisibleToUser": true,
"promoted": false,
"diversified": false,
"metaData": {
"A": "",
"B": "staff, students, community",
"c": "Where to find ASK La Trobe Help Zones"
},
"tags": [],
"customData": {}
},
{
"rank": 8,
"score": 780,
"title": "ATAR Calculator | La Trobe University",
"collection": "Latrobe",
"component": 0,
"collapsed": null,
"liveUrl": "https://www.latrobe.edu.au/study/undergrad/atar-calculator",
"summary": "Estimate your ATAR and discover your course options.",
"cacheUrl": "/s/cache?collection=Latrobe&url=https%3A%2F%2Fwww.latrobe.edu.au%2Fstudy%2Fundergrad%2Fatar-calculator&profile=_default",
"date": 1693922400000,
"fileSize": 175927,
"fileType": "html",
"tier": 1,
"docNum": 113,
"exploreLink": null,
"kmFromOrigin": null,
"quickLinks": null,
"displayUrl": "https://www.latrobe.edu.au/study/undergrad/atar-calculator",
"clickTrackingUrl": "http://search.latrobe.edu.au/s/redirect?collection=Latrobe-search&url=https%3A%2F%2Fwww.latrobe.edu.au%2Fstudy%2Fundergrad%2Fatar-calculator&auth=w%2BaivN%2BghuytCqoLKSl2NA&profile=_default&rank=8&query=nsw",
"explain": null,
"indexUrl": "https://www.latrobe.edu.au/study/undergrad/atar-calculator",
"gscopesSet": [],
"documentVisibleToUser": true,
"promoted": false,
"diversified": false,
"metaData": {
"A": "",
"B": "staff, students, community",
"c": "Estimate your ATAR and discover your course options."
},
"tags": [],
"customData": {}
},
{
"rank": 9,
"score": 760,
"title": "Getting here and parking, Albury-Wodonga Campus, About the Albury-Wodonga Campus",
"collection": "Latrobe",
"component": 0,
"collapsed": null,
"liveUrl": "https://www.latrobe.edu.au/albury-wodonga/about/location",
"summary": "Albury and Wodonga are twin cities on the NSW-Victorian borders. Driving distance from:. ... travelling from New South Wales origins, use Transport NSW.",
"cacheUrl": "/s/cache?collection=Latrobe&url=https%3A%2F%2Fwww.latrobe.edu.au%2Falbury-wodonga%2Fabout%2Flocation&profile=_default",
"date": 1594216800000,
"fileSize": 79957,
"fileType": "html",
"tier": 1,
"docNum": 1342,
"exploreLink": null,
"kmFromOrigin": null,
"quickLinks": null,
"displayUrl": "https://www.latrobe.edu.au/albury-wodonga/about/location",
"clickTrackingUrl": "http://search.latrobe.edu.au/s/redirect?collection=Latrobe-search&url=https%3A%2F%2Fwww.latrobe.edu.au%2Falbury-wodonga%2Fabout%2Flocation&auth=m3YXAT6jee9iwdQOVsw6fw&profile=_default&rank=9&query=nsw",
"explain": null,
"indexUrl": "https://www.latrobe.edu.au/albury-wodonga/about/location",
"gscopesSet": [],
"documentVisibleToUser": true,
"promoted": false,
"diversified": false,
"metaData": {
"A": "",
"B": "staff, students, community",
"c": "Find everything you need to know about getting to the La Trobe University, Albury Wodonga Campus."
},
"tags": [],
"customData": {}
},
{
"rank": 10,
"score": 758,
"title": "Long Term Intervention Monitoring, Centre for Freshwater Ecosystems ",
"collection": "Latrobe",
"component": 0,
"collapsed": null,
"liveUrl": "https://www.latrobe.edu.au/freshwater-ecosystems/research/projects/ltim",
"summary": "NSW Office of Water. NSW National Parks and Wildlife Services. Western Local Land Services. ... NSW DPI Fishing and Aquaculture. University of New South Wales. Monitoring and Evaluation Provider.",
"cacheUrl": "/s/cache?collection=Latrobe&url=https%3A%2F%2Fwww.latrobe.edu.au%2Ffreshwater-ecosystems%2Fresearch%2Fprojects%2Fltim&profile=_default",
"date": 1529416800000,
"fileSize": 98752,
"fileType": "html",
"tier": 1,
"docNum": 13174,
"exploreLink": null,
"kmFromOrigin": null,
"quickLinks": null,
"displayUrl": "https://www.latrobe.edu.au/freshwater-ecosystems/research/projects/ltim",
"clickTrackingUrl": "http://search.latrobe.edu.au/s/redirect?collection=Latrobe-search&url=https%3A%2F%2Fwww.latrobe.edu.au%2Ffreshwater-ecosystems%2Fresearch%2Fprojects%2Fltim&auth=CsHmbb99VUTkduJp%2BT%2F%2FpQ&profile=_default&rank=10&query=nsw",
"explain": null,
"indexUrl": "https://www.latrobe.edu.au/freshwater-ecosystems/research/projects/ltim",
"gscopesSet": [],
"documentVisibleToUser": true,
"promoted": false,
"diversified": false,
"metaData": {
"A": "",
"B": "staff, students, community",
"c": "Long Term Intervention Monitoring (LTIM) is a key element of the Commonwealth Environmental Water Office (CEWO) response to the requirements of the Water Act 2007 and the MDBA’s Basin Plan."
},
"tags": [],
"customData": {}
},
{
"rank": 11,
"score": 754,
"title": "Key dates: all students, Key dates",
"collection": "Latrobe",
"component": 0,
"collapsed": null,
"liveUrl": "https://www.latrobe.edu.au/calendar/all-students",
"summary": "Semesters, enrolment, orientation, census dates and vacations.",
"cacheUrl": "/s/cache?collection=Latrobe&url=https%3A%2F%2Fwww.latrobe.edu.au%2Fcalendar%2Fall-students&profile=_default",
"date": 1602680400000,
"fileSize": 78132,
"fileType": "html",
"tier": 1,
"docNum": 1903,
"exploreLink": null,
"kmFromOrigin": null,
"quickLinks": null,
"displayUrl": "https://www.latrobe.edu.au/calendar/all-students",
"clickTrackingUrl": "http://search.latrobe.edu.au/s/redirect?collection=Latrobe-search&url=https%3A%2F%2Fwww.latrobe.edu.au%2Fcalendar%2Fall-students&auth=8FGiALiO78lNMzB3sdIiQw&profile=_default&rank=11&query=nsw",
"explain": null,
"indexUrl": "https://www.latrobe.edu.au/calendar/all-students",
"gscopesSet": [],
"documentVisibleToUser": true,
"promoted": false,
"diversified": false,
"metaData": {
"A": "",
"B": "staff, students, community",
"c": "Semesters, enrolment, orientation, census dates and vacations."
},
"tags": [],
"customData": {}
},
{
"rank": 12,
"score": 754,
"title": "Locations and Maps, About La Trobe, La Trobe at a glance",
"collection": "Latrobe",
"component": 0,
"collapsed": null,
"liveUrl": "https://www.latrobe.edu.au/about/at-a-glance/maps",
"summary": "Sydney. 255 Elizabeth Street, Sydney, NSW 2000. Regional campuses.",
"cacheUrl": "/s/cache?collection=Latrobe&url=https%3A%2F%2Fwww.latrobe.edu.au%2Fabout%2Fat-a-glance%2Fmaps&profile=_default",
"date": 1520341200000,
"fileSize": 82377,
"fileType": "html",
"tier": 1,
"docNum": 102,
"exploreLink": null,
"kmFromOrigin": null,
"quickLinks": null,
"displayUrl": "https://www.latrobe.edu.au/about/at-a-glance/maps",
"clickTrackingUrl": "http://search.latrobe.edu.au/s/redirect?collection=Latrobe-search&url=https%3A%2F%2Fwww.latrobe.edu.au%2Fabout%2Fat-a-glance%2Fmaps&auth=Xdi0eHsDdEnyskEqyOrBlg&profile=_default&rank=12&query=nsw",
"explain": null,
"indexUrl": "https://www.latrobe.edu.au/about/at-a-glance/maps",
"gscopesSet": [],
"documentVisibleToUser": true,
"promoted": false,
"diversified": false,
"metaData": {
"A": "",
"B": "staff, students, community",
"c": "Find out where our campuses are located and how to get there, including public transport and parking."
},
"tags": [],
"customData": {}
},
{
"rank": 13,
"score": 752,
"title": "Albury-Wodonga Campus",
"collection": "Latrobe",
"component": 0,
"collapsed": null,
"liveUrl": "https://www.latrobe.edu.au/albury-wodonga",
"summary": "I moved from Griffith (NSW) to attend uni here. I wish people knew how welcoming and lovely the community is here at La Trobe.",
"cacheUrl": "/s/cache?collection=Latrobe&url=https%3A%2F%2Fwww.latrobe.edu.au%2Falbury-wodonga&profile=_default",
"date": 1659967200000,
"fileSize": 99065,
"fileType": "html",
"tier": 1,
"docNum": 628,
"exploreLink": null,
"kmFromOrigin": null,
"quickLinks": null,
"displayUrl": "https://www.latrobe.edu.au/albury-wodonga",
"clickTrackingUrl": "http://search.latrobe.edu.au/s/redirect?collection=Latrobe-search&url=https%3A%2F%2Fwww.latrobe.edu.au%2Falbury-wodonga&auth=lyxrjfP0VRBNNG0ri6sM5A&profile=_default&rank=13&query=nsw",
"explain": null,
"indexUrl": "https://www.latrobe.edu.au/albury-wodonga",
"gscopesSet": [],
"documentVisibleToUser": true,
"promoted": false,
"diversified": false,
"metaData": {
"A": "",
"B": "staff, students, community",
"c": "Discover how you can study at the La Trobe University Albury Wodonga Campus."
},
"tags": [],
"customData": {}
},
{
"rank": 14,
"score": 750,
"title": "Library room bookings - La Trobe University",
"collection": "Latrobe",
"component": 0,
"collapsed": null,
"liveUrl": "https://www.latrobe.edu.au/library/facilities/book",
"summary": "Enter your current La Trobe University student username and password. Username. Example: JBloggs. Password. Copyright 2012 La Trobe University. All rights reserved. CRICOS Provider Code: Version: 4.0.11.0  Created: 12 December, 2012.",
"cacheUrl": "/s/cache?collection=Latrobe&url=https%3A%2F%2Fwww.latrobe.edu.au%2Flibrary%2Ffacilities%2Fbook&profile=_default",
"date": null,
"fileSize": 5972,
"fileType": "html",
"tier": 1,
"docNum": 8555,
"exploreLink": null,
"kmFromOrigin": null,
"quickLinks": null,
"displayUrl": "https://www.latrobe.edu.au/library/facilities/book",
"clickTrackingUrl": "http://search.latrobe.edu.au/s/redirect?collection=Latrobe-search&url=https%3A%2F%2Fwww.latrobe.edu.au%2Flibrary%2Ffacilities%2Fbook&auth=Z8Hnr1tzUeFZtg%2FjBeLWRw&profile=_default&rank=14&query=nsw",
"explain": null,
"indexUrl": "https://www.latrobe.edu.au/library/facilities/book",
"gscopesSet": [],
"documentVisibleToUser": true,
"promoted": false,
"diversified": false,
"metaData": {
"A": ""
},
"tags": [],
"customData": {}
},
{
"rank": 15,
"score": 728,
"title": "Sir John Quick Lecture 2023, Events",
"collection": "Latrobe",
"component": 0,
"collapsed": null,
"liveUrl": "https://www.latrobe.edu.au/events/all/sir-john-quick-lecture-2023",
"summary": "She has also previously worked for the High Court of Australia, the Commonwealth Parliamentary Research Service, the Senate Legal and Constitutional Committee and The Cabinet Office of NSW.",
"cacheUrl": "/s/cache?collection=Latrobe&url=https%3A%2F%2Fwww.latrobe.edu.au%2Fevents%2Fall%2Fsir-john-quick-lecture-2023&profile=_default",
"date": 1692108000000,
"fileSize": 101557,
"fileType": "html",
"tier": 1,
"docNum": 10408,
"exploreLink": null,
"kmFromOrigin": null,
"quickLinks": null,
"displayUrl": "https://www.latrobe.edu.au/events/all/sir-john-quick-lecture-2023",
"clickTrackingUrl": "http://search.latrobe.edu.au/s/redirect?collection=Latrobe-search&url=https%3A%2F%2Fwww.latrobe.edu.au%2Fevents%2Fall%2Fsir-john-quick-lecture-2023&auth=f7IOezkko%2F1EYtmcwARMfg&profile=_default&rank=15&query=nsw",
"explain": null,
"indexUrl": "https://www.latrobe.edu.au/events/all/sir-john-quick-lecture-2023",
"gscopesSet": [],
"documentVisibleToUser": true,
"promoted": false,
"diversified": false,
"metaData": {
"A": "",
"B": "staff, students, community",
"c": "A highly topical lecture providing historic context for The Voice referendum"
},
"tags": [],
"customData": {}
},
{
"rank": 16,
"score": 719,
"title": "Partners, Living with Disability Research Centre",
"collection": "Latrobe",
"component": 0,
"collapsed": null,
"liveUrl": "https://www.latrobe.edu.au/lids/partners",
"summary": "NSW Trustee and Guardian. Oak Possibility. Office of Public Advocate Victoria.",
"cacheUrl": "/s/cache?collection=Latrobe&url=https%3A%2F%2Fwww.latrobe.edu.au%2Flids%2Fpartners&profile=_default",
"date": 1446642000000,
"fileSize": 86266,
"fileType": "html",
"tier": 1,
"docNum": 9963,
"exploreLink": null,
"kmFromOrigin": null,
"quickLinks": null,
"displayUrl": "https://www.latrobe.edu.au/lids/partners",
"clickTrackingUrl": "http://search.latrobe.edu.au/s/redirect?collection=Latrobe-search&url=https%3A%2F%2Fwww.latrobe.edu.au%2Flids%2Fpartners&auth=iX2TiITGqSBN66YcSgCENA&profile=_default&rank=16&query=nsw",
"explain": null,
"indexUrl": "https://www.latrobe.edu.au/lids/partners",
"gscopesSet": [],
"documentVisibleToUser": true,
"promoted": false,
"diversified": false,
"metaData": {
"A": "",
"B": "staff, students, community",
"c": "The Living with Disability Centre is highly collaborative – with established links across La Trobe University, with other universities disability services and mainstream service."
},
"tags": [],
"customData": {}
},
{
"rank": 17,
"score": 717,
"title": "MyPrint Recharge - La Trobe University",
"collection": "Latrobe",
"component": 0,
"collapsed": null,
"liveUrl": "https://www.latrobe.edu.au/campusgraphics/print/reload_online.php",
"summary": "MyPrint Recharge",
"cacheUrl": "/s/cache?collection=Latrobe&url=https%3A%2F%2Fwww.latrobe.edu.au%2Fcampusgraphics%2Fprint%2Freload_online.php&profile=_default",
"date": null,
"fileSize": 11263,
"fileType": "html",
"tier": 1,
"docNum": 8089,
"exploreLink": null,
"kmFromOrigin": null,
"quickLinks": null,
"displayUrl": "https://www.latrobe.edu.au/campusgraphics/print/reload_online.php",
"clickTrackingUrl": "http://search.latrobe.edu.au/s/redirect?collection=Latrobe-search&url=https%3A%2F%2Fwww.latrobe.edu.au%2Fcampusgraphics%2Fprint%2Freload_online.php&auth=LPZkdJ4eGLKiQkcKQQqvWw&profile=_default&rank=17&query=nsw",
"explain": null,
"indexUrl": "https://www.latrobe.edu.au/campusgraphics/print/reload_online.php",
"gscopesSet": [],
"documentVisibleToUser": true,
"promoted": false,
"diversified": false,
"metaData": {
"A": "",
"B": "community",
"c": "MyPrint Recharge"
},
"tags": [],
"customData": {}
},
{
"rank": 18,
"score": 716,
"title": "Writing Themselves In 4, Australian Research Centre in Sex, Health and Society",
"collection": "Latrobe",
"component": 0,
"collapsed": null,
"liveUrl": "https://www.latrobe.edu.au/arcshs/work/writing-themselves-in-4",
"summary": "Summary reports. These reports summarise key findings from Writing Themselves In 4 that are specific to the participants who were residents in the Australian Capital Territory (ACT), New South Wales (NSW),",
"cacheUrl": "/s/cache?collection=Latrobe&url=https%3A%2F%2Fwww.latrobe.edu.au%2Farcshs%2Fwork%2Fwriting-themselves-in-4&profile=_default",
"date": 1610888400000,
"fileSize": 95486,
"fileType": "html",
"tier": 1,
"docNum": 9852,
"exploreLink": null,
"kmFromOrigin": null,
"quickLinks": null,
"displayUrl": "https://www.latrobe.edu.au/arcshs/work/writing-themselves-in-4",
"clickTrackingUrl": "http://search.latrobe.edu.au/s/redirect?collection=Latrobe-search&url=https%3A%2F%2Fwww.latrobe.edu.au%2Farcshs%2Fwork%2Fwriting-themselves-in-4&auth=wqBQwFWkXxP7ernY%2Fc497Q&profile=_default&rank=18&query=nsw",
"explain": null,
"indexUrl": "https://www.latrobe.edu.au/arcshs/work/writing-themselves-in-4",
"gscopesSet": [],
"documentVisibleToUser": true,
"promoted": false,
"diversified": false,
"metaData": {
"A": "",
"B": "staff, students, community",
"c": "Read and download all the Writing Themselves In 4 reports. Including the national, SA, ACT, NSW and Victoria reports."
},
"tags": [],
"customData": {}
},
{
"rank": 19,
"score": 714,
"title": "Industry connected, Courses and degrees, The La Trobe MBA",
"collection": "Latrobe",
"component": 0,
"collapsed": null,
"liveUrl": "https://www.latrobe.edu.au/courses/mba/industry-connected",
"summary": "Janine Young. Ombudsman,. Energy and water NSW, La Trobe graduate. The Master of Business Administration enabled me to grasp concepts paramount to correct business development, management and operations of an organisation. ... NSW Department of Parliamentary Services, Chief Executive. Port of Melbourne Corporation, Executive General Manager, Business &amp;Planning.",
"cacheUrl": "/s/cache?collection=Latrobe&url=https%3A%2F%2Fwww.latrobe.edu.au%2Fcourses%2Fmba%2Findustry-connected&profile=_default",
"date": 1599746400000,
"fileSize": 83662,
"fileType": "html",
"tier": 1,
"docNum": 9701,
"exploreLink": null,
"kmFromOrigin": null,
"quickLinks": null,
"displayUrl": "https://www.latrobe.edu.au/courses/mba/industry-connected",
"clickTrackingUrl": "http://search.latrobe.edu.au/s/redirect?collection=Latrobe-search&url=https%3A%2F%2Fwww.latrobe.edu.au%2Fcourses%2Fmba%2Findustry-connected&auth=vfuTOxJLnIv%2FN%2FJyy8BMzw&profile=_default&rank=19&query=nsw",
"explain": null,
"indexUrl": "https://www.latrobe.edu.au/courses/mba/industry-connected",
"gscopesSet": [],
"documentVisibleToUser": true,
"promoted": false,
"diversified": false,
"metaData": {
"A": "",
"B": "staff, students, community",
"c": "Our MBA draws on a range of industry partners, placing a strong emphasis on enterprise learning. You’ll broaden your business knowledge through our uniquely co-designed program."
},
"tags": [],
"customData": {}
},
{
"rank": 20,
"score": 712,
"title": "Educational Partnerships Program, Outreach",
"collection": "Latrobe",
"component": 0,
"collapsed": null,
"liveUrl": "https://www.latrobe.edu.au/outreach/educational-partnerships-program",
"summary": "James Fallon High School (NSW). Murray High School (NSW). Wangaratta High School. ... Chaffey College. Coomealla High School (NSW). Irymple Secondary College. Merbein P‐10 College.",
"cacheUrl": "/s/cache?collection=Latrobe&url=https%3A%2F%2Fwww.latrobe.edu.au%2Foutreach%2Feducational-partnerships-program&profile=_default",
"date": 1621519200000,
"fileSize": 97783,
"fileType": "html",
"tier": 1,
"docNum": 8817,
"exploreLink": null,
"kmFromOrigin": null,
"quickLinks": null,
"displayUrl": "https://www.latrobe.edu.au/outreach/educational-partnerships-program",
"clickTrackingUrl": "http://search.latrobe.edu.au/s/redirect?collection=Latrobe-search&url=https%3A%2F%2Fwww.latrobe.edu.au%2Foutreach%2Feducational-partnerships-program&auth=YxkGJZhr15YtpI6gD3%2FkzA&profile=_default&rank=20&query=nsw",
"explain": null,
"indexUrl": "https://www.latrobe.edu.au/outreach/educational-partnerships-program",
"gscopesSet": [],
"documentVisibleToUser": true,
"promoted": false,
"diversified": false,
"metaData": {
"A": "",
"B": "staff, students, community",
"c": "Our Educational Partnerships program works works collaboratively with secondary schools."
},
"tags": [],
"customData": {}
}
],
"qsups": []
},
"returnCode": 0,
"untruncatedPadreOutputSize": 24592,
"facets": [
{
"name": "Audience",
"unselectAllUrl": "?searchtype=global&query=nsw&collection=Latrobe-search",
"selectionType": "SINGLE",
"constraintJoin": "AND",
"order": [
"SELECTED_FIRST",
"COUNT_DESCENDING"
],
"facetValues": "FROM_SCOPED_QUERY_HIDE_UNSELECTED_PARENT_VALUES",
"guessedDisplayType": "SINGLE_DRILL_DOWN",
"categories": [
{
"label": null,
"queryStringParamName": "f.Audience|B",
"values": [
{
"data": "community",
"label": "Community",
"count": 129,
"queryStringParam": "f.Audience%7CB=community",
"constraint": "B",
"selected": false,
"queryStringParamName": "f.Audience|B",
"queryStringParamValue": "community",
"toggleUrl": "?f.Audience%7CB=community&searchtype=global&query=nsw&collection=Latrobe-search"
},
{
"data": "students",
"label": "Students",
"count": 128,
"queryStringParam": "f.Audience%7CB=students",
"constraint": "B",
"selected": false,
"queryStringParamName": "f.Audience|B",
"queryStringParamValue": "students",
"toggleUrl": "?f.Audience%7CB=students&searchtype=global&query=nsw&collection=Latrobe-search"
},
{
"data": "staff",
"label": "Staff",
"count": 128,
"queryStringParam": "f.Audience%7CB=staff",
"constraint": "B",
"selected": false,
"queryStringParamName": "f.Audience|B",
"queryStringParamValue": "staff",
"toggleUrl": "?f.Audience%7CB=staff&searchtype=global&query=nsw&collection=Latrobe-search"
},
{
"data": "Mature Age",
"label": "Mature Age",
"count": 1,
"queryStringParam": "f.Audience%7CB=Mature+Age",
"constraint": "B",
"selected": false,
"queryStringParamName": "f.Audience|B",
"queryStringParamValue": "Mature Age",
"toggleUrl": "?f.Audience%7CB=Mature+Age&searchtype=global&query=nsw&collection=Latrobe-search"
},
{
"data": "Undergraduate",
"label": "Undergraduate",
"count": 1,
"queryStringParam": "f.Audience%7CB=Undergraduate",
"constraint": "B",
"selected": false,
"queryStringParamName": "f.Audience|B",
"queryStringParamValue": "Undergraduate",
"toggleUrl": "?f.Audience%7CB=Undergraduate&searchtype=global&query=nsw&collection=Latrobe-search"
},
{
"data": "TAFE students",
"label": "TAFE students",
"count": 1,
"queryStringParam": "f.Audience%7CB=TAFE+students",
"constraint": "B",
"selected": false,
"queryStringParamName": "f.Audience|B",
"queryStringParamValue": "TAFE students",
"toggleUrl": "?f.Audience%7CB=TAFE+students&searchtype=global&query=nsw&collection=Latrobe-search"
},
{
"data": "UGNSL",
"label": "UGNSL",
"count": 1,
"queryStringParam": "f.Audience%7CB=UGNSL",
"constraint": "B",
"selected": false,
"queryStringParamName": "f.Audience|B",
"queryStringParamValue": "UGNSL",
"toggleUrl": "?f.Audience%7CB=UGNSL&searchtype=global&query=nsw&collection=Latrobe-search"
},
{
"data": "postgraduate",
"label": "Postgraduate",
"count": 1,
"queryStringParam": "f.Audience%7CB=postgraduate",
"constraint": "B",
"selected": false,
"queryStringParamName": "f.Audience|B",
"queryStringParamValue": "postgraduate",
"toggleUrl": "?f.Audience%7CB=postgraduate&searchtype=global&query=nsw&collection=Latrobe-search"
}
],
"categories": []
}
],
"customData": {},
"selectedValues": [],
"allValues": [
{
"data": "community",
"label": "Community",
"count": 129,
"queryStringParam": "f.Audience%7CB=community",
"constraint": "B",
"selected": false,
"queryStringParamName": "f.Audience|B",
"queryStringParamValue": "community",
"toggleUrl": "?f.Audience%7CB=community&searchtype=global&query=nsw&collection=Latrobe-search"
},
{
"data": "students",
"label": "Students",
"count": 128,
"queryStringParam": "f.Audience%7CB=students",
"constraint": "B",
"selected": false,
"queryStringParamName": "f.Audience|B",
"queryStringParamValue": "students",
"toggleUrl": "?f.Audience%7CB=students&searchtype=global&query=nsw&collection=Latrobe-search"
},
{
"data": "staff",
"label": "Staff",
"count": 128,
"queryStringParam": "f.Audience%7CB=staff",
"constraint": "B",
"selected": false,
"queryStringParamName": "f.Audience|B",
"queryStringParamValue": "staff",
"toggleUrl": "?f.Audience%7CB=staff&searchtype=global&query=nsw&collection=Latrobe-search"
},
{
"data": "Mature Age",
"label": "Mature Age",
"count": 1,
"queryStringParam": "f.Audience%7CB=Mature+Age",
"constraint": "B",
"selected": false,
"queryStringParamName": "f.Audience|B",
"queryStringParamValue": "Mature Age",
"toggleUrl": "?f.Audience%7CB=Mature+Age&searchtype=global&query=nsw&collection=Latrobe-search"
},
{
"data": "Undergraduate",
"label": "Undergraduate",
"count": 1,
"queryStringParam": "f.Audience%7CB=Undergraduate",
"constraint": "B",
"selected": false,
"queryStringParamName": "f.Audience|B",
"queryStringParamValue": "Undergraduate",
"toggleUrl": "?f.Audience%7CB=Undergraduate&searchtype=global&query=nsw&collection=Latrobe-search"
},
{
"data": "TAFE students",
"label": "TAFE students",
"count": 1,
"queryStringParam": "f.Audience%7CB=TAFE+students",
"constraint": "B",
"selected": false,
"queryStringParamName": "f.Audience|B",
"queryStringParamValue": "TAFE students",
"toggleUrl": "?f.Audience%7CB=TAFE+students&searchtype=global&query=nsw&collection=Latrobe-search"
},
{
"data": "UGNSL",
"label": "UGNSL",
"count": 1,
"queryStringParam": "f.Audience%7CB=UGNSL",
"constraint": "B",
"selected": false,
"queryStringParamName": "f.Audience|B",
"queryStringParamValue": "UGNSL",
"toggleUrl": "?f.Audience%7CB=UGNSL&searchtype=global&query=nsw&collection=Latrobe-search"
},
{
"data": "postgraduate",
"label": "Postgraduate",
"count": 1,
"queryStringParam": "f.Audience%7CB=postgraduate",
"constraint": "B",
"selected": false,
"queryStringParamName": "f.Audience|B",
"queryStringParamValue": "postgraduate",
"toggleUrl": "?f.Audience%7CB=postgraduate&searchtype=global&query=nsw&collection=Latrobe-search"
}
],
"selected": false
}
],
"facetExtras": {
"unselectAllFacetsUrl": "?searchtype=global&query=nsw&collection=Latrobe-search",
"hasSelectedNonTabFacets": false,
"hasNonTabFacets": true
},
"customData": {},
"optimiserModel": null,
"curator": {
"exhibits": []
},
"performanceMetrics": {
"id": "",
"running": true,
"lastTaskInfo": {
"taskName": "output:GenericHookScriptRunner post_process",
"timeMillis": 0,
"timeSeconds": 0
},
"taskCount": 62,
"totalTimeMillis": 101,
"lastTaskTimeMillis": 0,
"lastTaskName": "output:GenericHookScriptRunner post_process",
"totalTimeSeconds": 0.101,
"taskInfo": [
{
"taskName": "input:PassThroughParameters",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "input:PassThroughEnvironmentVariables",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "input:RestrictToCurrentProfileConfig",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "input:DisableLoggingFromCgiParams",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "input:IncreasePadreTimeOutForServiceUsers",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "input:LocaleOverride",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "input:ContentAuditor",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "input:Geolocation",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "input:GenericHookScriptRunner pre_process",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "input:AccessibilityAuditorOptionsForSpeed",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "input:SetQuery",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "input:SetQueryForCounts",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "input:SetQueryProcessorOptions",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "input:ConfigureFacets",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "input:Session",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "input:UserKeys",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "input:FacetScope",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "input:LegacyFacetedNavigation",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "input:FacetedNavigation",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "input:FacetedNavigationSetQueryProcessorOptions",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "input:MultiFacetedNavigation",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "input:MetaParameters",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "input:MetadataAliases",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "input:MetaDates",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "input:Curator",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "input:CliveMapping",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "input:QuickLinks",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "input:ExploreQuery",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "input:Hostname",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "input:UserIdInQueryLogs",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "input:ReMapRMCFOptionInputProcessor",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "input:SetQueryProcessorOptionsForCounts",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "input:ExtraSearches",
"timeMillis": 5,
"timeSeconds": 0.005
},
{
"taskName": "input:GenericHookScriptRunner extra_searches",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "input:ExtraSearchesExecutor",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "datafetch:GenericHookScriptRunner pre_datafetch",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "datafetch:SetPriorityQueryProcessorOptions",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "datafetch:DefaultPadreForking",
"timeMillis": 91,
"timeSeconds": 0.091
},
{
"taskName": "datafetch:GenericHookScriptRunner post_datafetch",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "output:PadreReturnCode",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "output:ReMapRMCFOutputProcessor",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "output:FixMetaCollectionUpdateDate",
"timeMillis": 1,
"timeSeconds": 0.001
},
{
"taskName": "output:FixPseudoLiveLinks",
"timeMillis": 3,
"timeSeconds": 0.003
},
{
"taskName": "output:Curator",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "output:FixCacheAndClickLinks",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "output:FixDisplayUrls",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "output:HTMLEncodeSummaries",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "output:ExtraSearchesExecutor",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "output:FacetedNavigation",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "output:FacetedNavigationWhiteBlackList",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "output:PopulateFacetCategoryLabels",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "output:SortFacetValues",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "output:DateFacetSort",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "output:ContextualNavigation",
"timeMillis": 1,
"timeSeconds": 0.001
},
{
"taskName": "output:QuickLinks",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "output:QueryCleanOutputProcessor",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "output:ContentOptimiser",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "output:GetTranslations",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "output:SearchHistory",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "output:RemoveFacetedNavigationExtraSearches",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "output:Metrics",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "output:GenericHookScriptRunner post_process",
"timeMillis": 0,
"timeSeconds": 0
}
]
}
},
"session": null,
"QueryString": "collection=Latrobe-search&searchtype=global&query=nsw",
"anyExtraSearchesIncomplete": false,
"extraSearches": {
"course": {
"question": {
"executionContext": "Public",
"query": "nsw",
"originalQuery": "nsw",
"collection": {
"id": "course-tabbed",
"profiles": {
"_default": {
"id": "_default",
"facetedNavConfConfig": {
"facetDefinitions": [
{
"name": "Study level",
"categoryDefinitions": [
{
"facetName": "Study level",
"data": "B",
"label": null,
"subCategories": [],
"queryStringCategoryExtraPart": "B",
"metadataClass": "B",
"allQueryStringParamNames": [
"f.Study level|B"
],
"queryStringParamName": "f.Study level|B"
}
],
"selectionType": "SINGLE",
"constraintJoin": "LEGACY",
"facetValues": "FROM_SCOPED_QUERY",
"order": [
"SELECTED_FIRST",
"COUNT_DESCENDING"
],
"allQueryStringParamNames": [
"f.Study level|B"
]
},
{
"name": "Campus",
"categoryDefinitions": [
{
"facetName": "Campus",
"data": "A",
"label": null,
"subCategories": [],
"queryStringCategoryExtraPart": "A",
"metadataClass": "A",
"allQueryStringParamNames": [
"f.Campus|A"
],
"queryStringParamName": "f.Campus|A"
}
],
"selectionType": "SINGLE",
"constraintJoin": "LEGACY",
"facetValues": "FROM_SCOPED_QUERY",
"order": [
"SELECTED_FIRST",
"COUNT_DESCENDING"
],
"allQueryStringParamNames": [
"f.Campus|A"
]
}
],
"metadataFieldsUsed": [
"B",
"A"
]
},
"padreOpts": null
}
},
"facetedNavigationConfConfig": null,
"facetedNavigationLiveConfig": null,
"metaComponents": [],
"type": "web"
},
"profile": "_default",
"clive": null,
"form": "simple",
"cnClickedCluster": null,
"facetsQueryConstraints": [],
"facetCollectionConstraints": null,
"facetsGScopeConstraints": null,
"impersonated": false,
"requestId": "10.128.48.247",
"logQuery": null,
"questionType": "EXTRA_SEARCH",
"locale": "en_GB",
"location": {
"areaCode": 0,
"city": null,
"countryCode": null,
"countryName": null,
"dmaCode": 0,
"latitude": 0,
"longitude": 0,
"metroCode": 0,
"postalCode": null,
"region": null
},
"hostname": null,
"funnelbackVersion": {
"major": 15,
"minor": 14,
"revision": 0
},
"cnPreviousClusters": [],
"metaParameters": [],
"systemMetaParameters": [],
"additionalParameters": {
"HTTP_HOST": [
"search.latrobe.edu.au"
],
"searchtype": [
"global"
],
"origin": [
"0.0,0.0"
],
"REMOTE_ADDR": [
"10.128.48.247"
],
"REQUEST_URI": [
"/s/search.json"
],
"REQUEST_URL": [
"https://search.latrobe.edu.au/s/search.json?collection=Latrobe-search&searchtype=global&query=nsw"
]
},
"environmentVariables": {
"REMOTE_ADDR": "10.128.48.247",
"REQUEST_URI": "/s/search.json",
"HTTP_HOST": "search.latrobe.edu.au",
"REQUEST_URL": "https://search.latrobe.edu.au/s/search.json?collection=Latrobe-search&searchtype=global&query=nsw"
},
"dynamicQueryProcessorOptions": [
"-rmcf=[A,B]",
"-qlog_file=/data/search/funnelback/data/course-tabbed/live/log/queries-funnelbackprd-bun.log"
],
"userKeys": [],
"selectedFacets": [],
"selectedCategoryValues": {},
"rawInputParameters": {
"HTTP_HOST": [
"search.latrobe.edu.au"
],
"searchtype": [
"global"
],
"query": [
"nsw"
],
"REMOTE_ADDR": [
"10.128.48.247"
],
"REQUEST_URI": [
"/s/search.json"
],
"collection": [
"Latrobe-search"
],
"REQUEST_URL": [
"https://search.latrobe.edu.au/s/search.json?collection=Latrobe-search&searchtype=global&query=nsw"
]
},
"inputParameterMap": {
"HTTP_HOST": "search.latrobe.edu.au",
"query": "nsw",
"REMOTE_ADDR": "10.128.48.247",
"REQUEST_URL": "https://search.latrobe.edu.au/s/search.json?collection=Latrobe-search&searchtype=global&query=nsw",
"collection": "Latrobe-search",
"searchtype": "global",
"REQUEST_URI": "/s/search.json"
},
"customData": {},
"queryStringMapCopy": {
"query": [
"nsw"
],
"searchtype": [
"global"
],
"collection": [
"Latrobe-search"
]
},
"extraSearch": true
},
"response": {
"resultPacket": {
"details": {
"padreVersion": "FUNNELBACK_PADRE_15.14.0.19 MDPLFS (Web/Ent) [64 bit]",
"collectionSize": "/data/search/funnelback/data/course-tabbed/live/idx/index: 54.9 MB, 463 docs",
"collectionUpdated": 1696906803000
},
"query": "nsw",
"queryAsProcessed": "nsw",
"queryRaw": "nsw",
"querySystemRaw": null,
"queryCleaned": "nsw",
"collection": "course-tabbed",
"resultsSummary": {
"fullyMatching": 3,
"collapsed": 0,
"partiallyMatching": 0,
"totalMatching": 3,
"estimatedCounts": false,
"carriedOverFtd": null,
"totalDistinctMatchingUrls": null,
"numRanks": 20,
"currStart": 1,
"currEnd": 3,
"prevStart": null,
"nextStart": null,
"totalSecurityObscuredUrls": null,
"anyUrlsPromoted": false,
"resultDiversificationApplied": true
},
"spell": null,
"bestBets": [],
"results": [
{
"rank": 1,
"score": 1000,
"title": "Industry connected, Courses and degrees, The La Trobe MBA",
"collection": "course-tabbed",
"component": 0,
"collapsed": null,
"liveUrl": "https://www.latrobe.edu.au/courses/mba/industry-connected",
"summary": "Janine Young. Ombudsman,. Energy and water NSW, La Trobe graduate. The Master of Business Administration enabled me to grasp concepts paramount to correct business development, management and operations of an organisation. ... NSW Department of",
"cacheUrl": "/s/cache?collection=course-tabbed&url=https%3A%2F%2Fwww.latrobe.edu.au%2Fcourses%2Fmba%2Findustry-connected&profile=_default",
"date": null,
"fileSize": 83662,
"fileType": "html",
"tier": 1,
"docNum": 376,
"exploreLink": null,
"kmFromOrigin": null,
"quickLinks": null,
"displayUrl": "https://www.latrobe.edu.au/courses/mba/industry-connected",
"clickTrackingUrl": "http://search.latrobe.edu.au/s/redirect?collection=course-tabbed&url=https%3A%2F%2Fwww.latrobe.edu.au%2Fcourses%2Fmba%2Findustry-connected&auth=vfuTOxJLnIv%2FN%2FJyy8BMzw&profile=_default&rank=1&query=nsw",
"explain": null,
"indexUrl": "https://www.latrobe.edu.au/courses/mba/industry-connected",
"gscopesSet": [],
"documentVisibleToUser": true,
"promoted": false,
"diversified": false,
"metaData": {
"c": "Our MBA draws on a range of industry partners, placing a strong emphasis on enterprise learning. You’ll broaden your business knowledge through our uniquely co-designed program."
},
"tags": [],
"customData": {}
},
{
"rank": 2,
"score": 629,
"title": "Developing Strength and Power, Courses and degrees, Short courses",
"collection": "course-tabbed",
"component": 0,
"collapsed": null,
"liveUrl": "https://www.latrobe.edu.au/courses/short-courses/developing-strength-and-power",
"summary": "Alex Natera Manager Sport Science, NSW Institute of Sport, Australia. Dr Warren Young Adjunct A/Prof, Federation University, Australia.",
"cacheUrl": "/s/cache?collection=course-tabbed&url=https%3A%2F%2Fwww.latrobe.edu.au%2Fcourses%2Fshort-courses%2Fdeveloping-strength-and-power&profile=_default",
"date": null,
"fileSize": 82108,
"fileType": "html",
"tier": 1,
"docNum": 327,
"exploreLink": null,
"kmFromOrigin": null,
"quickLinks": null,
"displayUrl": "https://www.latrobe.edu.au/courses/short-courses/developing-strength-and-power",
"clickTrackingUrl": "http://search.latrobe.edu.au/s/redirect?collection=course-tabbed&url=https%3A%2F%2Fwww.latrobe.edu.au%2Fcourses%2Fshort-courses%2Fdeveloping-strength-and-power&auth=app%2B22c74jISDRUkN7xnxA&profile=_default&rank=2&query=nsw",
"explain": null,
"indexUrl": "https://www.latrobe.edu.au/courses/short-courses/developing-strength-and-power",
"gscopesSet": [],
"documentVisibleToUser": true,
"promoted": false,
"diversified": true,
"metaData": {
"c": "Learn how to visualise data with Tableau to drive effective business outcomes."
},
"tags": [],
"customData": {}
},
{
"rank": 3,
"score": 434,
"title": "Re-entry pathway scholarships for nurses, Courses and degrees, Short courses, Scholarships",
"collection": "course-tabbed",
"component": 0,
"collapsed": null,
"liveUrl": "https://www.latrobe.edu.au/courses/short-courses/scholarships/re-entry-pathway-scholarships-for-nurses",
"summary": "Snowy Valleys Council. Wentworth Shire. Note: The Silver City Highway between the Broken Hill and Wentworth Local Government Areas is part of the NSW-Victoria border bubble.",
"cacheUrl": "/s/cache?collection=course-tabbed&url=https%3A%2F%2Fwww.latrobe.edu.au%2Fcourses%2Fshort-courses%2Fscholarships%2Fre-entry-pathway-scholarships-for-nurses&profile=_default",
"date": null,
"fileSize": 85211,
"fileType": "html",
"tier": 1,
"docNum": 461,
"exploreLink": null,
"kmFromOrigin": null,
"quickLinks": null,
"displayUrl": "https://www.latrobe.edu.au/courses/short-courses/scholarships/re-entry-pathway-scholarships-for-nurses",
"clickTrackingUrl": "http://search.latrobe.edu.au/s/redirect?collection=course-tabbed&url=https%3A%2F%2Fwww.latrobe.edu.au%2Fcourses%2Fshort-courses%2Fscholarships%2Fre-entry-pathway-scholarships-for-nurses&auth=cOfyS322sCfcXW80gFANTA&profile=_default&rank=3&query=nsw",
"explain": null,
"indexUrl": "https://www.latrobe.edu.au/courses/short-courses/scholarships/re-entry-pathway-scholarships-for-nurses",
"gscopesSet": [],
"documentVisibleToUser": true,
"promoted": false,
"diversified": true,
"metaData": {
"c": "Re-entry pathway scholarships for nurses"
},
"tags": [],
"customData": {}
}
],
"tierBars": [
{
"matched": 1,
"outOf": 1,
"firstRank": 0,
"lastRank": 3,
"eventDate": null
}
],
"error": null,
"padreElapsedTime": 7,
"phlusterElapsedTime": 0.001,
"queryProcessorCodes": "2x",
"contextualNavigation": {
"searchTerm": "nsw",
"clusterNav": {
"level": 0,
"url": "collection=Latrobe-search&query=nsw&searchtype=global",
"label": "nsw"
},
"categories": [],
"customData": {}
},
"rmcs": {
"-B:": 3,
"-A:": 3
},
"rmcItemResults": {},
"metadataRanges": {},
"boundingBoxes": {},
"urlCounts": {},
"dateCounts": {},
"queryHighlightRegex": "(?i)\\bnsw\\b",
"origin": [],
"entityList": {},
"includeScopes": [],
"excludeScopes": [],
"coolerWeights": {},
"explainTypes": {},
"stopWords": [],
"stemmedEquivs": {
"empty": true
},
"coolerNames": {},
"svgs": {},
"uniqueCountsByGroups": [],
"sumByGroups": [],
"indexedTermCounts": [],
"documentsPerCollection": {},
"metadataSums": {},
"gscopeCounts": {},
"resultsWithTierBars": [
{
"matched": 1,
"outOf": 1,
"firstRank": 0,
"lastRank": 3,
"eventDate": null
},
{
"rank": 1,
"score": 1000,
"title": "Industry connected, Courses and degrees, The La Trobe MBA",
"collection": "course-tabbed",
"component": 0,
"collapsed": null,
"liveUrl": "https://www.latrobe.edu.au/courses/mba/industry-connected",
"summary": "Janine Young. Ombudsman,. Energy and water NSW, La Trobe graduate. The Master of Business Administration enabled me to grasp concepts paramount to correct business development, management and operations of an organisation. ... NSW Department of",
"cacheUrl": "/s/cache?collection=course-tabbed&url=https%3A%2F%2Fwww.latrobe.edu.au%2Fcourses%2Fmba%2Findustry-connected&profile=_default",
"date": null,
"fileSize": 83662,
"fileType": "html",
"tier": 1,
"docNum": 376,
"exploreLink": null,
"kmFromOrigin": null,
"quickLinks": null,
"displayUrl": "https://www.latrobe.edu.au/courses/mba/industry-connected",
"clickTrackingUrl": "http://search.latrobe.edu.au/s/redirect?collection=course-tabbed&url=https%3A%2F%2Fwww.latrobe.edu.au%2Fcourses%2Fmba%2Findustry-connected&auth=vfuTOxJLnIv%2FN%2FJyy8BMzw&profile=_default&rank=1&query=nsw",
"explain": null,
"indexUrl": "https://www.latrobe.edu.au/courses/mba/industry-connected",
"gscopesSet": [],
"documentVisibleToUser": true,
"promoted": false,
"diversified": false,
"metaData": {
"c": "Our MBA draws on a range of industry partners, placing a strong emphasis on enterprise learning. You’ll broaden your business knowledge through our uniquely co-designed program."
},
"tags": [],
"customData": {}
},
{
"rank": 2,
"score": 629,
"title": "Developing Strength and Power, Courses and degrees, Short courses",
"collection": "course-tabbed",
"component": 0,
"collapsed": null,
"liveUrl": "https://www.latrobe.edu.au/courses/short-courses/developing-strength-and-power",
"summary": "Alex Natera Manager Sport Science, NSW Institute of Sport, Australia. Dr Warren Young Adjunct A/Prof, Federation University, Australia.",
"cacheUrl": "/s/cache?collection=course-tabbed&url=https%3A%2F%2Fwww.latrobe.edu.au%2Fcourses%2Fshort-courses%2Fdeveloping-strength-and-power&profile=_default",
"date": null,
"fileSize": 82108,
"fileType": "html",
"tier": 1,
"docNum": 327,
"exploreLink": null,
"kmFromOrigin": null,
"quickLinks": null,
"displayUrl": "https://www.latrobe.edu.au/courses/short-courses/developing-strength-and-power",
"clickTrackingUrl": "http://search.latrobe.edu.au/s/redirect?collection=course-tabbed&url=https%3A%2F%2Fwww.latrobe.edu.au%2Fcourses%2Fshort-courses%2Fdeveloping-strength-and-power&auth=app%2B22c74jISDRUkN7xnxA&profile=_default&rank=2&query=nsw",
"explain": null,
"indexUrl": "https://www.latrobe.edu.au/courses/short-courses/developing-strength-and-power",
"gscopesSet": [],
"documentVisibleToUser": true,
"promoted": false,
"diversified": true,
"metaData": {
"c": "Learn how to visualise data with Tableau to drive effective business outcomes."
},
"tags": [],
"customData": {}
},
{
"rank": 3,
"score": 434,
"title": "Re-entry pathway scholarships for nurses, Courses and degrees, Short courses, Scholarships",
"collection": "course-tabbed",
"component": 0,
"collapsed": null,
"liveUrl": "https://www.latrobe.edu.au/courses/short-courses/scholarships/re-entry-pathway-scholarships-for-nurses",
"summary": "Snowy Valleys Council. Wentworth Shire. Note: The Silver City Highway between the Broken Hill and Wentworth Local Government Areas is part of the NSW-Victoria border bubble.",
"cacheUrl": "/s/cache?collection=course-tabbed&url=https%3A%2F%2Fwww.latrobe.edu.au%2Fcourses%2Fshort-courses%2Fscholarships%2Fre-entry-pathway-scholarships-for-nurses&profile=_default",
"date": null,
"fileSize": 85211,
"fileType": "html",
"tier": 1,
"docNum": 461,
"exploreLink": null,
"kmFromOrigin": null,
"quickLinks": null,
"displayUrl": "https://www.latrobe.edu.au/courses/short-courses/scholarships/re-entry-pathway-scholarships-for-nurses",
"clickTrackingUrl": "http://search.latrobe.edu.au/s/redirect?collection=course-tabbed&url=https%3A%2F%2Fwww.latrobe.edu.au%2Fcourses%2Fshort-courses%2Fscholarships%2Fre-entry-pathway-scholarships-for-nurses&auth=cOfyS322sCfcXW80gFANTA&profile=_default&rank=3&query=nsw",
"explain": null,
"indexUrl": "https://www.latrobe.edu.au/courses/short-courses/scholarships/re-entry-pathway-scholarships-for-nurses",
"gscopesSet": [],
"documentVisibleToUser": true,
"promoted": false,
"diversified": true,
"metaData": {
"c": "Re-entry pathway scholarships for nurses"
},
"tags": [],
"customData": {}
}
],
"qsups": []
},
"returnCode": 0,
"untruncatedPadreOutputSize": 6836,
"facets": [
{
"name": "Study level",
"unselectAllUrl": "?query=nsw&searchtype=global&collection=Latrobe-search",
"selectionType": "SINGLE",
"constraintJoin": "LEGACY",
"order": [
"SELECTED_FIRST",
"COUNT_DESCENDING"
],
"facetValues": "FROM_SCOPED_QUERY",
"guessedDisplayType": "SINGLE_DRILL_DOWN",
"categories": [],
"customData": {},
"selectedValues": [],
"allValues": [],
"selected": false
},
{
"name": "Campus",
"unselectAllUrl": "?query=nsw&searchtype=global&collection=Latrobe-search",
"selectionType": "SINGLE",
"constraintJoin": "LEGACY",
"order": [
"SELECTED_FIRST",
"COUNT_DESCENDING"
],
"facetValues": "FROM_SCOPED_QUERY",
"guessedDisplayType": "SINGLE_DRILL_DOWN",
"categories": [],
"customData": {},
"selectedValues": [],
"allValues": [],
"selected": false
}
],
"facetExtras": {
"unselectAllFacetsUrl": "?query=nsw&searchtype=global&collection=Latrobe-search",
"hasSelectedNonTabFacets": false,
"hasNonTabFacets": true
},
"customData": {},
"optimiserModel": null,
"curator": {
"exhibits": []
},
"performanceMetrics": {
"id": "",
"running": true,
"lastTaskInfo": {
"taskName": "output:GenericHookScriptRunner post_process",
"timeMillis": 0,
"timeSeconds": 0
},
"taskCount": 62,
"totalTimeMillis": 38,
"lastTaskTimeMillis": 0,
"lastTaskName": "output:GenericHookScriptRunner post_process",
"totalTimeSeconds": 0.038,
"taskInfo": [
{
"taskName": "input:PassThroughParameters",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "input:PassThroughEnvironmentVariables",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "input:RestrictToCurrentProfileConfig",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "input:DisableLoggingFromCgiParams",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "input:IncreasePadreTimeOutForServiceUsers",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "input:LocaleOverride",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "input:ContentAuditor",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "input:Geolocation",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "input:GenericHookScriptRunner pre_process",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "input:AccessibilityAuditorOptionsForSpeed",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "input:SetQuery",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "input:SetQueryForCounts",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "input:SetQueryProcessorOptions",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "input:ConfigureFacets",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "input:Session",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "input:UserKeys",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "input:FacetScope",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "input:LegacyFacetedNavigation",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "input:FacetedNavigation",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "input:FacetedNavigationSetQueryProcessorOptions",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "input:MultiFacetedNavigation",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "input:MetaParameters",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "input:MetadataAliases",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "input:MetaDates",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "input:Curator",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "input:CliveMapping",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "input:QuickLinks",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "input:ExploreQuery",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "input:Hostname",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "input:UserIdInQueryLogs",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "input:ReMapRMCFOptionInputProcessor",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "input:SetQueryProcessorOptionsForCounts",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "input:ExtraSearches",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "input:GenericHookScriptRunner extra_searches",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "input:ExtraSearchesExecutor",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "datafetch:GenericHookScriptRunner pre_datafetch",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "datafetch:SetPriorityQueryProcessorOptions",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "datafetch:DefaultPadreForking",
"timeMillis": 38,
"timeSeconds": 0.038
},
{
"taskName": "datafetch:GenericHookScriptRunner post_datafetch",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "output:PadreReturnCode",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "output:ReMapRMCFOutputProcessor",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "output:FixMetaCollectionUpdateDate",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "output:FixPseudoLiveLinks",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "output:Curator",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "output:FixCacheAndClickLinks",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "output:FixDisplayUrls",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "output:HTMLEncodeSummaries",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "output:ExtraSearchesExecutor",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "output:FacetedNavigation",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "output:FacetedNavigationWhiteBlackList",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "output:PopulateFacetCategoryLabels",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "output:SortFacetValues",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "output:DateFacetSort",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "output:ContextualNavigation",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "output:QuickLinks",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "output:QueryCleanOutputProcessor",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "output:ContentOptimiser",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "output:GetTranslations",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "output:SearchHistory",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "output:RemoveFacetedNavigationExtraSearches",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "output:Metrics",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "output:GenericHookScriptRunner post_process",
"timeMillis": 0,
"timeSeconds": 0
}
]
}
},
"error": null,
"session": null,
"extraSearches": {},
"anyExtraSearchesIncomplete": false,
"customData": {}
},
"events": {
"question": {
"executionContext": "Public",
"query": "nsw",
"originalQuery": "nsw",
"collection": {
"id": "events-tabbed",
"profiles": {
"_default": {
"id": "_default",
"facetedNavConfConfig": {
"facetDefinitions": [
{
"name": "Campus",
"categoryDefinitions": [
{
"facetName": "Campus",
"data": "C",
"label": null,
"subCategories": [],
"queryStringCategoryExtraPart": "C",
"metadataClass": "C",
"allQueryStringParamNames": [
"f.Campus|C"
],
"queryStringParamName": "f.Campus|C"
}
],
"selectionType": "SINGLE",
"constraintJoin": "LEGACY",
"facetValues": "FROM_SCOPED_QUERY",
"order": [
"SELECTED_FIRST",
"COUNT_DESCENDING"
],
"allQueryStringParamNames": [
"f.Campus|C"
]
}
],
"metadataFieldsUsed": [
"C"
]
},
"padreOpts": null
}
},
"facetedNavigationConfConfig": null,
"facetedNavigationLiveConfig": null,
"metaComponents": [],
"type": "web"
},
"profile": "_default",
"clive": null,
"form": "simple",
"cnClickedCluster": null,
"facetsQueryConstraints": [],
"facetCollectionConstraints": null,
"facetsGScopeConstraints": null,
"impersonated": false,
"requestId": "10.128.48.247",
"logQuery": null,
"questionType": "EXTRA_SEARCH",
"locale": "en_GB",
"location": {
"areaCode": 0,
"city": null,
"countryCode": null,
"countryName": null,
"dmaCode": 0,
"latitude": 0,
"longitude": 0,
"metroCode": 0,
"postalCode": null,
"region": null
},
"hostname": null,
"funnelbackVersion": {
"major": 15,
"minor": 14,
"revision": 0
},
"cnPreviousClusters": [],
"metaParameters": [],
"systemMetaParameters": [],
"additionalParameters": {
"HTTP_HOST": [
"search.latrobe.edu.au"
],
"searchtype": [
"global"
],
"origin": [
"0.0,0.0"
],
"REMOTE_ADDR": [
"10.128.48.247"
],
"REQUEST_URI": [
"/s/search.json"
],
"REQUEST_URL": [
"https://search.latrobe.edu.au/s/search.json?collection=Latrobe-search&searchtype=global&query=nsw"
],
"sort": [
"metaM"
],
"gt_M": [
"1696982830932"
]
},
"environmentVariables": {
"REMOTE_ADDR": "10.128.48.247",
"REQUEST_URI": "/s/search.json",
"HTTP_HOST": "search.latrobe.edu.au",
"REQUEST_URL": "https://search.latrobe.edu.au/s/search.json?collection=Latrobe-search&searchtype=global&query=nsw"
},
"dynamicQueryProcessorOptions": [
"-rmcf=[C]",
"-qlog_file=/data/search/funnelback/data/events-tabbed/live/log/queries-funnelbackprd-bun.log"
],
"userKeys": [],
"selectedFacets": [],
"selectedCategoryValues": {},
"rawInputParameters": {
"HTTP_HOST": [
"search.latrobe.edu.au"
],
"searchtype": [
"global"
],
"query": [
"nsw"
],
"REMOTE_ADDR": [
"10.128.48.247"
],
"REQUEST_URI": [
"/s/search.json"
],
"collection": [
"Latrobe-search"
],
"REQUEST_URL": [
"https://search.latrobe.edu.au/s/search.json?collection=Latrobe-search&searchtype=global&query=nsw"
],
"sort": [
"metaM"
],
"gt_M": [
"1696982830932"
]
},
"inputParameterMap": {
"HTTP_HOST": "search.latrobe.edu.au",
"query": "nsw",
"REMOTE_ADDR": "10.128.48.247",
"REQUEST_URL": "https://search.latrobe.edu.au/s/search.json?collection=Latrobe-search&searchtype=global&query=nsw",
"collection": "Latrobe-search",
"sort": "metaM",
"searchtype": "global",
"REQUEST_URI": "/s/search.json",
"gt_M": "1696982830932"
},
"customData": {},
"queryStringMapCopy": {
"query": [
"nsw"
],
"searchtype": [
"global"
],
"collection": [
"Latrobe-search"
]
},
"extraSearch": true
},
"response": {
"resultPacket": {
"details": {
"padreVersion": "FUNNELBACK_PADRE_15.14.0.19 MDPLFS (Web/Ent) [64 bit]",
"collectionSize": "/data/search/funnelback/data/events-tabbed/live/idx/index: 33.7 MB, 341 docs",
"collectionUpdated": 1696910403000
},
"query": "nsw",
"queryAsProcessed": "nsw",
"queryRaw": "nsw",
"querySystemRaw": null,
"queryCleaned": "nsw",
"collection": "events-tabbed",
"resultsSummary": {
"fullyMatching": 1,
"collapsed": 0,
"partiallyMatching": 0,
"totalMatching": 1,
"estimatedCounts": false,
"carriedOverFtd": null,
"totalDistinctMatchingUrls": null,
"numRanks": 20,
"currStart": 1,
"currEnd": 1,
"prevStart": null,
"nextStart": null,
"totalSecurityObscuredUrls": null,
"anyUrlsPromoted": false,
"resultDiversificationApplied": false
},
"spell": null,
"bestBets": [],
"results": [
{
"rank": 1,
"score": 1000,
"title": "La Trobe Albury-Wodonga's Movie and Mingle: Mature Student Information Evening, Events, La Trobe University",
"collection": "events-tabbed",
"component": 0,
"collapsed": null,
"liveUrl": "https://www.latrobe.edu.au/events/all/movie-and-mingle-mature-student-information-evening",
"summary": "7.25 pm. Event ends, movie to begin. Regent Cinemas Albury, 456 Dean St, Albury NSW 2640 Gold Class 2.",
"cacheUrl": "/s/cache?collection=events-tabbed&url=https%3A%2F%2Fwww.latrobe.edu.au%2Fevents%2Fall%2Fmovie-and-mingle-mature-student-information-evening&profile=_default",
"date": 1698066000000,
"fileSize": 102764,
"fileType": "html",
"tier": 1,
"docNum": 213,
"exploreLink": null,
"kmFromOrigin": null,
"quickLinks": null,
"displayUrl": "https://www.latrobe.edu.au/events/all/movie-and-mingle-mature-student-information-evening",
"clickTrackingUrl": "/s/redirect?collection=events-tabbed&url=https%3A%2F%2Fwww.latrobe.edu.au%2Fevents%2Fall%2Fmovie-and-mingle-mature-student-information-evening&auth=luRQMLpUsOi2BbudN8EUhg&profile=_default&rank=1&query=nsw",
"explain": null,
"indexUrl": "https://www.latrobe.edu.au/events/all/movie-and-mingle-mature-student-information-evening",
"gscopesSet": [],
"documentVisibleToUser": true,
"promoted": false,
"diversified": false,
"metaData": {
"B": "Future Student Information",
"c": "Learn more about studying at La Trobe University’s Albury Wodonga campus, our scholarships and pathway options from current students.",
"C": "Albury-Wodonga",
"d": "2023-10-24T17:30+1100|2023-10-24T19:30+1100",
"D": "Jessica Chalmers-Borella <br> 02 6024 9738; j.chalmers@latrobe.edu.au |La Trobe Future Students |Future Student Information |Free ",
"E": "Schools_and_Departments,Faculties",
"F": "Research centres",
"G": "Areas of study",
"J": "https://www.eventbrite.com/e/698123826157",
"L": "1480659",
"M": "1698066000000",
"X": "24",
"Y": "Oct",
"Z": "Regent Cinemas Albury, 456 Dean St, Albury NSW 2640 Gold Class 2"
},
"tags": [],
"customData": {}
}
],
"tierBars": [
{
"matched": 1,
"outOf": 1,
"firstRank": 0,
"lastRank": 1,
"eventDate": null
}
],
"error": null,
"padreElapsedTime": 10,
"phlusterElapsedTime": null,
"queryProcessorCodes": "2?x",
"contextualNavigation": null,
"rmcs": {
"C:Albury-Wodonga": 1
},
"rmcItemResults": {},
"metadataRanges": {},
"boundingBoxes": {},
"urlCounts": {},
"dateCounts": {},
"queryHighlightRegex": "(?i)\\bnsw\\b",
"origin": [],
"entityList": {},
"includeScopes": [],
"excludeScopes": [],
"coolerWeights": {},
"explainTypes": {},
"stopWords": [],
"stemmedEquivs": {
"empty": true
},
"coolerNames": {},
"svgs": {},
"uniqueCountsByGroups": [],
"sumByGroups": [],
"indexedTermCounts": [],
"documentsPerCollection": {},
"metadataSums": {},
"gscopeCounts": {},
"resultsWithTierBars": [
{
"matched": 1,
"outOf": 1,
"firstRank": 0,
"lastRank": 1,
"eventDate": null
},
{
"rank": 1,
"score": 1000,
"title": "La Trobe Albury-Wodonga's Movie and Mingle: Mature Student Information Evening, Events, La Trobe University",
"collection": "events-tabbed",
"component": 0,
"collapsed": null,
"liveUrl": "https://www.latrobe.edu.au/events/all/movie-and-mingle-mature-student-information-evening",
"summary": "7.25 pm. Event ends, movie to begin. Regent Cinemas Albury, 456 Dean St, Albury NSW 2640 Gold Class 2.",
"cacheUrl": "/s/cache?collection=events-tabbed&url=https%3A%2F%2Fwww.latrobe.edu.au%2Fevents%2Fall%2Fmovie-and-mingle-mature-student-information-evening&profile=_default",
"date": 1698066000000,
"fileSize": 102764,
"fileType": "html",
"tier": 1,
"docNum": 213,
"exploreLink": null,
"kmFromOrigin": null,
"quickLinks": null,
"displayUrl": "https://www.latrobe.edu.au/events/all/movie-and-mingle-mature-student-information-evening",
"clickTrackingUrl": "/s/redirect?collection=events-tabbed&url=https%3A%2F%2Fwww.latrobe.edu.au%2Fevents%2Fall%2Fmovie-and-mingle-mature-student-information-evening&auth=luRQMLpUsOi2BbudN8EUhg&profile=_default&rank=1&query=nsw",
"explain": null,
"indexUrl": "https://www.latrobe.edu.au/events/all/movie-and-mingle-mature-student-information-evening",
"gscopesSet": [],
"documentVisibleToUser": true,
"promoted": false,
"diversified": false,
"metaData": {
"B": "Future Student Information",
"c": "Learn more about studying at La Trobe University’s Albury Wodonga campus, our scholarships and pathway options from current students.",
"C": "Albury-Wodonga",
"d": "2023-10-24T17:30+1100|2023-10-24T19:30+1100",
"D": "Jessica Chalmers-Borella <br> 02 6024 9738; j.chalmers@latrobe.edu.au |La Trobe Future Students |Future Student Information |Free ",
"E": "Schools_and_Departments,Faculties",
"F": "Research centres",
"G": "Areas of study",
"J": "https://www.eventbrite.com/e/698123826157",
"L": "1480659",
"M": "1698066000000",
"X": "24",
"Y": "Oct",
"Z": "Regent Cinemas Albury, 456 Dean St, Albury NSW 2640 Gold Class 2"
},
"tags": [],
"customData": {}
}
],
"qsups": []
},
"returnCode": 0,
"untruncatedPadreOutputSize": 5080,
"facets": [
{
"name": "Campus",
"unselectAllUrl": "?query=nsw&searchtype=global&collection=Latrobe-search",
"selectionType": "SINGLE",
"constraintJoin": "LEGACY",
"order": [
"SELECTED_FIRST",
"COUNT_DESCENDING"
],
"facetValues": "FROM_SCOPED_QUERY",
"guessedDisplayType": "SINGLE_DRILL_DOWN",
"categories": [
{
"label": null,
"queryStringParamName": "f.Campus|C",
"values": [
{
"data": "Albury-Wodonga",
"label": "Albury-Wodonga",
"count": 1,
"queryStringParam": "f.Campus%7CC=Albury-Wodonga",
"constraint": "C",
"selected": false,
"queryStringParamName": "f.Campus|C",
"queryStringParamValue": "Albury-Wodonga",
"toggleUrl": "?f.Campus%7CC=Albury-Wodonga&query=nsw&searchtype=global&collection=Latrobe-search"
}
],
"categories": []
}
],
"customData": {},
"selectedValues": [],
"allValues": [
{
"data": "Albury-Wodonga",
"label": "Albury-Wodonga",
"count": 1,
"queryStringParam": "f.Campus%7CC=Albury-Wodonga",
"constraint": "C",
"selected": false,
"queryStringParamName": "f.Campus|C",
"queryStringParamValue": "Albury-Wodonga",
"toggleUrl": "?f.Campus%7CC=Albury-Wodonga&query=nsw&searchtype=global&collection=Latrobe-search"
}
],
"selected": false
}
],
"facetExtras": {
"unselectAllFacetsUrl": "?query=nsw&searchtype=global&collection=Latrobe-search",
"hasSelectedNonTabFacets": false,
"hasNonTabFacets": true
},
"customData": {},
"optimiserModel": null,
"curator": {
"exhibits": []
},
"performanceMetrics": {
"id": "",
"running": true,
"lastTaskInfo": {
"taskName": "output:GenericHookScriptRunner post_process",
"timeMillis": 0,
"timeSeconds": 0
},
"taskCount": 62,
"totalTimeMillis": 38,
"lastTaskTimeMillis": 0,
"lastTaskName": "output:GenericHookScriptRunner post_process",
"totalTimeSeconds": 0.038,
"taskInfo": [
{
"taskName": "input:PassThroughParameters",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "input:PassThroughEnvironmentVariables",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "input:RestrictToCurrentProfileConfig",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "input:DisableLoggingFromCgiParams",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "input:IncreasePadreTimeOutForServiceUsers",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "input:LocaleOverride",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "input:ContentAuditor",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "input:Geolocation",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "input:GenericHookScriptRunner pre_process",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "input:AccessibilityAuditorOptionsForSpeed",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "input:SetQuery",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "input:SetQueryForCounts",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "input:SetQueryProcessorOptions",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "input:ConfigureFacets",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "input:Session",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "input:UserKeys",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "input:FacetScope",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "input:LegacyFacetedNavigation",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "input:FacetedNavigation",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "input:FacetedNavigationSetQueryProcessorOptions",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "input:MultiFacetedNavigation",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "input:MetaParameters",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "input:MetadataAliases",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "input:MetaDates",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "input:Curator",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "input:CliveMapping",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "input:QuickLinks",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "input:ExploreQuery",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "input:Hostname",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "input:UserIdInQueryLogs",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "input:ReMapRMCFOptionInputProcessor",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "input:SetQueryProcessorOptionsForCounts",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "input:ExtraSearches",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "input:GenericHookScriptRunner extra_searches",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "input:ExtraSearchesExecutor",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "datafetch:GenericHookScriptRunner pre_datafetch",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "datafetch:SetPriorityQueryProcessorOptions",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "datafetch:DefaultPadreForking",
"timeMillis": 38,
"timeSeconds": 0.038
},
{
"taskName": "datafetch:GenericHookScriptRunner post_datafetch",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "output:PadreReturnCode",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "output:ReMapRMCFOutputProcessor",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "output:FixMetaCollectionUpdateDate",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "output:FixPseudoLiveLinks",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "output:Curator",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "output:FixCacheAndClickLinks",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "output:FixDisplayUrls",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "output:HTMLEncodeSummaries",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "output:ExtraSearchesExecutor",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "output:FacetedNavigation",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "output:FacetedNavigationWhiteBlackList",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "output:PopulateFacetCategoryLabels",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "output:SortFacetValues",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "output:DateFacetSort",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "output:ContextualNavigation",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "output:QuickLinks",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "output:QueryCleanOutputProcessor",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "output:ContentOptimiser",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "output:GetTranslations",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "output:SearchHistory",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "output:RemoveFacetedNavigationExtraSearches",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "output:Metrics",
"timeMillis": 0,
"timeSeconds": 0
},
{
"taskName": "output:GenericHookScriptRunner post_process",
"timeMillis": 0,
"timeSeconds": 0
}
]
}
},
"error": null,
"session": null,
"extraSearches": {},
"anyExtraSearchesIncomplete": false,
"customData": {}
}
},
"error": null
}
};
