export const testData = {
    settings: {
        display: "visible",
        type: "3 columns",
        title: "We are here to service!",
        footnote: `Obtaining Your Dream Home: Our Professional Method for Locating the Ideal Property`
    },
    data: [
        {
            icon: "../externals/icons/proofpoints/icon-proofpoints-4.svg",
            headline: "Consistent",
            content: "We take pride in our consistency of quality in everything we do.",
//             url: 'https://wwwdev.latrobe.edu.au/'
        },
        {
            icon: "../externals/icons/proofpoints/icon-proofpoints-9.svg",
            headline: "Service",
            content: "We offer a wide range of services to assist. Also, we have starting connections with professional Bankers, Brokers, Lawyers and Solicitors. ",
//             url: 'https://wwwdev.latrobe.edu.au/'
        },
        {
            icon: "../externals/icons/proofpoints/icon-proofpoints-3.svg",
            headline: "Dedicated",
            content: "We ensure attention to detail and high levels of communication and ticked all your conditions."
        }
    ]
};
