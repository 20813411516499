export const testFooterData = {
    settings: {
        type:"1481153",
        copyright:"<p>Copyright 2023 Precious Gems Property. All Rights reserved.</p>",
        websiteFeedbackURL: "/feedback",
        websiteFeedbackText: "",
        lastUpdated: "1 Nov 2023"
    },
    social: {
        "linkedin": "https://www.linkedin.com/company/la-trobe-university/",
        "youtube": "https://www.youtube.com/user/latrobeuniaustralia/",
        "xtwitter": "https://www.twitter.com/latrobe/",
        "facebook": "https://www.facebook.com/latrobe/",
        "weibo": "https://www.weibo.com/latrobeuniaus?is_hot=1",
        "wechat": "https://www.latrobe.edu.au/international/international/wechat",
        "instagram": "https://www.instagram.com/latrobeuni/",
        "tiktok": "https://www.tiktok.com/@latrobeuni"
    },
    donation: {
        url: "/donation",
        text: "Donate today"
    },
    data:
{
"sites": {
"assetRoot": {
"assetid": "1481152",
"type_code": "folder",
"version": "0.0.1",
"name": "Footer",
"short_name": "Footer",
"status": "2",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-09-12 15:57:28",
"created_userid": "1480557",
"updated": "2023-09-12 15:57:28",
"updated_userid": "1480557",
"published": "Never",
"published_userid": "",
"status_changed": "2023-09-12 15:57:28",
"status_changed_userid": "1480557",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "Footer",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"pages": [
{
"name": "Standard",
"url": "https://wwwdev.latrobe.edu.au/sandbox/footer/standard",
"id": 1481153,
"status": "Under Construction",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/footer/standard\">Standard</a>",
"file": "folder",
"publish": "Never",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "null",
"parentData": {
"assetid": "1481152",
"type_code": "folder",
"version": "0.0.1",
"name": "Footer",
"short_name": "Footer",
"status": "2",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-09-12 15:57:28",
"created_userid": "1480557",
"updated": "2023-09-12 15:57:28",
"updated_userid": "1480557",
"published": "Never",
"published_userid": "",
"status_changed": "2023-09-12 15:57:28",
"status_changed_userid": "1480557",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "Footer",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1481152",
"parents": [
{
"linkid": "3160360",
"link_type": "2",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481152"
]
}
],
"childrenIds": [
"1481155",
"1481159",
"1481162"
],
"yearCreated": 2023
},
{
"name": "Student",
"url": "https://wwwdev.latrobe.edu.au/sandbox/footer/student",
"id": 1481154,
"status": "Under Construction",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/footer/student\">Student</a>",
"file": "folder",
"publish": "Never",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "null",
"parentData": {
"assetid": "1481152",
"type_code": "folder",
"version": "0.0.1",
"name": "Footer",
"short_name": "Footer",
"status": "2",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-09-12 15:57:28",
"created_userid": "1480557",
"updated": "2023-09-12 15:57:28",
"updated_userid": "1480557",
"published": "Never",
"published_userid": "",
"status_changed": "2023-09-12 15:57:28",
"status_changed_userid": "1480557",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "Footer",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1481152",
"parents": [
{
"linkid": "3160361",
"link_type": "2",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481152"
]
}
],
"childrenIds": [],
"yearCreated": 2023
},
{
"name": "Study with us",
"url": "https://wwwdev.latrobe.edu.au/sandbox/footer/standard/study-with-us",
"id": 1481155,
"status": "Under Construction",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/footer/standard/study-with-us\">Study with us</a>",
"file": "folder",
"publish": "Never",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "null",
"parentData": {
"assetid": "1481153",
"type_code": "folder",
"version": "0.0.1",
"name": "Standard",
"short_name": "Standard",
"status": "2",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-09-12 15:58:15",
"created_userid": "1480557",
"updated": "2023-09-12 15:58:15",
"updated_userid": "1480557",
"published": "Never",
"published_userid": "",
"status_changed": "2023-09-12 15:58:15",
"status_changed_userid": "1480557",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "Standard",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1481153",
"parents": [
{
"linkid": "3160362",
"link_type": "2",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481152",
"1481153"
]
}
],
"childrenIds": [
"1481156",
"1481157",
"1481158"
],
"yearCreated": 2023
},
{
"name": "Apply",
"url": "https://wwwdev.latrobe.edu.au/sandbox/footer/standard/study-with-us/apply",
"id": 1481156,
"status": "Under Construction",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/footer/standard/study-with-us/apply\">Apply</a>",
"file": "page_redirect",
"publish": "Never",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "",
"parentData": {
"assetid": "1481155",
"type_code": "folder",
"version": "0.0.1",
"name": "Study with us",
"short_name": "Study with us",
"status": "2",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-09-12 15:59:02",
"created_userid": "1480557",
"updated": "2023-09-12 15:59:02",
"updated_userid": "1480557",
"published": "Never",
"published_userid": "",
"status_changed": "2023-09-12 15:59:02",
"status_changed_userid": "1480557",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "Study with us",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1481155",
"parents": [
{
"linkid": "3160363",
"link_type": "1",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481152",
"1481153",
"1481155"
]
}
],
"childrenIds": [],
"yearCreated": 2023
},
{
"name": "Courses",
"url": "https://wwwdev.latrobe.edu.au/sandbox/footer/standard/study-with-us/courses",
"id": 1481157,
"status": "Under Construction",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/footer/standard/study-with-us/courses\">Courses</a>",
"file": "page_redirect",
"publish": "Never",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "",
"parentData": {
"assetid": "1481155",
"type_code": "folder",
"version": "0.0.1",
"name": "Study with us",
"short_name": "Study with us",
"status": "2",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-09-12 15:59:02",
"created_userid": "1480557",
"updated": "2023-09-12 15:59:02",
"updated_userid": "1480557",
"published": "Never",
"published_userid": "",
"status_changed": "2023-09-12 15:59:02",
"status_changed_userid": "1480557",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "Study with us",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1481155",
"parents": [
{
"linkid": "3160365",
"link_type": "1",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481152",
"1481153",
"1481155"
]
}
],
"childrenIds": [],
"yearCreated": 2023
},
{
"name": "Life at La Trobe",
"url": "https://wwwdev.latrobe.edu.au/sandbox/footer/standard/study-with-us/life-at-la-trobe",
"id": 1481158,
"status": "Under Construction",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/footer/standard/study-with-us/life-at-la-trobe\">Life at La Trobe</a>",
"file": "page_redirect",
"publish": "Never",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "",
"parentData": {
"assetid": "1481155",
"type_code": "folder",
"version": "0.0.1",
"name": "Study with us",
"short_name": "Study with us",
"status": "2",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-09-12 15:59:02",
"created_userid": "1480557",
"updated": "2023-09-12 15:59:02",
"updated_userid": "1480557",
"published": "Never",
"published_userid": "",
"status_changed": "2023-09-12 15:59:02",
"status_changed_userid": "1480557",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "Study with us",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1481155",
"parents": [
{
"linkid": "3160367",
"link_type": "1",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481152",
"1481153",
"1481155"
]
}
],
"childrenIds": [],
"yearCreated": 2023
},
{
"name": "International",
"url": "https://wwwdev.latrobe.edu.au/sandbox/footer/standard/international",
"id": 1481159,
"status": "Under Construction",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/footer/standard/international\">International</a>",
"file": "folder",
"publish": "Never",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "null",
"parentData": {
"assetid": "1481153",
"type_code": "folder",
"version": "0.0.1",
"name": "Standard",
"short_name": "Standard",
"status": "2",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-09-12 15:58:15",
"created_userid": "1480557",
"updated": "2023-09-12 15:58:15",
"updated_userid": "1480557",
"published": "Never",
"published_userid": "",
"status_changed": "2023-09-12 15:58:15",
"status_changed_userid": "1480557",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "Standard",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1481153",
"parents": [
{
"linkid": "3160369",
"link_type": "2",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481152",
"1481153"
]
}
],
"childrenIds": [
"1481160",
"1481161"
],
"yearCreated": 2023
},
{
"name": "International",
"url": "https://wwwdev.latrobe.edu.au/sandbox/footer/standard/international/international",
"id": 1481160,
"status": "Under Construction",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/footer/standard/international/international\">International</a>",
"file": "page_redirect",
"publish": "Never",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "",
"parentData": {
"assetid": "1481159",
"type_code": "folder",
"version": "0.0.1",
"name": "International",
"short_name": "International",
"status": "2",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-09-12 16:03:08",
"created_userid": "1480557",
"updated": "2023-09-12 16:03:08",
"updated_userid": "1480557",
"published": "Never",
"published_userid": "",
"status_changed": "2023-09-12 16:03:08",
"status_changed_userid": "1480557",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "International",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1481159",
"parents": [
{
"linkid": "3160370",
"link_type": "1",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481152",
"1481153",
"1481159"
]
}
],
"childrenIds": [],
"yearCreated": 2023
},
{
"name": "Courses and study options",
"url": "https://wwwdev.latrobe.edu.au/sandbox/footer/standard/international/courses-and-study-options",
"id": 1481161,
"status": "Under Construction",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/footer/standard/international/courses-and-study-options\">Courses and study options</a>",
"file": "page_redirect",
"publish": "Never",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "",
"parentData": {
"assetid": "1481159",
"type_code": "folder",
"version": "0.0.1",
"name": "International",
"short_name": "International",
"status": "2",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-09-12 16:03:08",
"created_userid": "1480557",
"updated": "2023-09-12 16:03:08",
"updated_userid": "1480557",
"published": "Never",
"published_userid": "",
"status_changed": "2023-09-12 16:03:08",
"status_changed_userid": "1480557",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "International",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1481159",
"parents": [
{
"linkid": "3160373",
"link_type": "1",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481152",
"1481153",
"1481159"
]
}
],
"childrenIds": [],
"yearCreated": 2023
},
{
"name": "Research",
"url": "https://wwwdev.latrobe.edu.au/sandbox/footer/standard/research",
"id": 1481162,
"status": "Under Construction",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/footer/standard/research\">Research</a>",
"file": "folder",
"publish": "Never",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "null",
"parentData": {
"assetid": "1481153",
"type_code": "folder",
"version": "0.0.1",
"name": "Standard",
"short_name": "Standard",
"status": "2",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-09-12 15:58:15",
"created_userid": "1480557",
"updated": "2023-09-12 15:58:15",
"updated_userid": "1480557",
"published": "Never",
"published_userid": "",
"status_changed": "2023-09-12 15:58:15",
"status_changed_userid": "1480557",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "Standard",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1481153",
"parents": [
{
"linkid": "3160375",
"link_type": "2",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481152",
"1481153"
]
}
],
"childrenIds": [
"1481163"
],
"yearCreated": 2023
},
{
"name": "University calendar",
"url": "https://wwwdev.latrobe.edu.au/sandbox/footer/standard/research/university-calendar",
"id": 1481163,
"status": "Under Construction",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/footer/standard/research/university-calendar\">University calendar</a>",
"file": "page_redirect",
"publish": "Never",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "",
"parentData": {
"assetid": "1481162",
"type_code": "folder",
"version": "0.0.1",
"name": "Research",
"short_name": "Research",
"status": "2",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-09-12 16:07:10",
"created_userid": "1480557",
"updated": "2023-09-12 16:07:10",
"updated_userid": "1480557",
"published": "Never",
"published_userid": "",
"status_changed": "2023-09-12 16:07:10",
"status_changed_userid": "1480557",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "Research",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1481162",
"parents": [
{
"linkid": "3160376",
"link_type": "1",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481152",
"1481153",
"1481162"
]
}
],
"childrenIds": [],
"yearCreated": 2023
},
{
"name": "Footer Legal items",
"url": "https://wwwdev.latrobe.edu.au/sandbox/footer/footer-legal-items",
"id": 1481178,
"status": "Under Construction",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/footer/footer-legal-items\">Footer Legal items</a>",
"file": "folder",
"publish": "Never",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "null",
"parentData": {
"assetid": "1481152",
"type_code": "folder",
"version": "0.0.1",
"name": "Footer",
"short_name": "Footer",
"status": "2",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-09-12 15:57:28",
"created_userid": "1480557",
"updated": "2023-09-12 15:57:28",
"updated_userid": "1480557",
"published": "Never",
"published_userid": "",
"status_changed": "2023-09-12 15:57:28",
"status_changed_userid": "1480557",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "Footer",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1481152",
"parents": [
{
"linkid": "3160399",
"link_type": "2",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481152"
]
}
],
"childrenIds": [
"1481179",
"1481180",
"1481181"
],
"yearCreated": 2023
},
{
"name": "Emergency information",
"url": "https://wwwdev.latrobe.edu.au/sandbox/footer/footer-legal-items/emergency-information",
"id": 1481179,
"status": "Under Construction",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/footer/footer-legal-items/emergency-information\">Emergency information</a>",
"file": "page_redirect",
"publish": "Never",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "",
"parentData": {
"assetid": "1481178",
"type_code": "folder",
"version": "0.0.1",
"name": "Footer Legal items",
"short_name": "Footer Legal items",
"status": "2",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-09-13 15:46:22",
"created_userid": "1480557",
"updated": "2023-09-13 15:46:22",
"updated_userid": "1480557",
"published": "Never",
"published_userid": "",
"status_changed": "2023-09-13 15:46:22",
"status_changed_userid": "1480557",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "Footer Legal items",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1481178",
"parents": [
{
"linkid": "3160400",
"link_type": "1",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481152",
"1481178"
]
}
],
"childrenIds": [],
"yearCreated": 2023
},
{
"name": "Child Safety",
"url": "https://wwwdev.latrobe.edu.au/sandbox/footer/footer-legal-items/child-safety",
"id": 1481180,
"status": "Under Construction",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/footer/footer-legal-items/child-safety\">Child Safety</a>",
"file": "page_redirect",
"publish": "Never",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "",
"parentData": {
"assetid": "1481178",
"type_code": "folder",
"version": "0.0.1",
"name": "Footer Legal items",
"short_name": "Footer Legal items",
"status": "2",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-09-13 15:46:22",
"created_userid": "1480557",
"updated": "2023-09-13 15:46:22",
"updated_userid": "1480557",
"published": "Never",
"published_userid": "",
"status_changed": "2023-09-13 15:46:22",
"status_changed_userid": "1480557",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "Footer Legal items",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1481178",
"parents": [
{
"linkid": "3160402",
"link_type": "1",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481152",
"1481178"
]
}
],
"childrenIds": [],
"yearCreated": 2023
},
{
"name": "Contacts",
"url": "https://wwwdev.latrobe.edu.au/sandbox/footer/footer-legal-items/contacts",
"id": 1481181,
"status": "Under Construction",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/footer/footer-legal-items/contacts\">Contacts</a>",
"file": "page_redirect",
"publish": "Never",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "",
"parentData": {
"assetid": "1481178",
"type_code": "folder",
"version": "0.0.1",
"name": "Footer Legal items",
"short_name": "Footer Legal items",
"status": "2",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-09-13 15:46:22",
"created_userid": "1480557",
"updated": "2023-09-13 15:46:22",
"updated_userid": "1480557",
"published": "Never",
"published_userid": "",
"status_changed": "2023-09-13 15:46:22",
"status_changed_userid": "1480557",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "Footer Legal items",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1481178",
"parents": [
{
"linkid": "3160404",
"link_type": "1",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481152",
"1481178"
]
}
],
"childrenIds": [],
"yearCreated": 2023
}
]
}
}

};
