import { testData } from './testData';
import SVGs from '../../_global/SVGs/js/svgs';
import { btnCollapse } from './../../_global/js/lb';

function AccordionsCCT() {
    let genHTML = '';
    const dataElems = document.querySelectorAll('[data-accordions]');
    for (const dataElem of dataElems) {
        let btnArray = [];
        const dataBtnUrls = dataElem.querySelectorAll('[data-btn-urls]');
        for (const item of dataBtnUrls) {
            const btns = item.querySelectorAll('[data-btn-url]');
            for (const btn of btns) {
                if (btn && btn.innerHTML) {
                    const btnUrl = JSON.parse(btn.innerHTML)[0];
                    const btnId = btn.getAttribute('data-id');
                    btnArray[btnId] = btnUrl;
                }
            }
            item.innerHTML = '';
            item.remove();
        }
        if (btnArray.length < 1) {
            btnArray = testData.buttons;
        }
        const dataJsonOrigin = dataElem && dataElem.querySelector('[data-json]')?dataElem.querySelector('[data-json]').getAttribute('data-json'):testData;
        const dataJson = dataJsonOrigin !== null && dataJsonOrigin !== ''? JSON.parse(dataJsonOrigin):testData;
        genHTML = `
        <div class="item-accordions">`;
        dataJson.data.map((v,i) => {
            const btnText = v && v.button_text ? v.button_text : 'button';
            genHTML += `
            <div class="item-main-container" data-id="${v.id}" elem-id="${i}">
                <h3 class="title btn-collapse" tabindex= "0">
                    ${v.title}
                </h3>
                <div class="data-collapse">
                    <div class="content">
                        ${v.content}
                `;
            const buttonLink = btnArray[v.id] !== '' && btnArray[v.id] !== null ? btnArray[v.id]: v.button_ext_url;
            if (buttonLink !== '' && buttonLink !== undefined && buttonLink !== null) {
                genHTML += `<a class="btn btn-primary" href="${buttonLink}" tabindex= "1">${btnText}<svgs data-svg="icon-chevron-right"></svgs></a>`;
            }
            genHTML += `
                    </div>
                </div>
            </div>`;
        })
        genHTML += `
        </div>
        `;
        dataElem.innerHTML = genHTML;
        dataElem.querySelector('.item-accordions').setAttribute('version', dataJson.settings.version);
        dataElem.removeAttribute('data-accordions');
        dataElem.removeAttribute('data-json');
    }
    new SVGs();
    btnCollapse();
}
(function(){
    'use strict';
    AccordionsCCT();
}());
