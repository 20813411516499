import { testFooterData } from './testFooterData';
import { btnCollapse } from '../../lb';
import SVGs from '../../../SVGs/js/svgs';

async function getJson(url) {
    const res = await fetch(url)
    .then(response =>response.json())
    .then(data => {
        genFooterContent(data.sites, data);
    })
    .catch(error => {
        console.log(error)
    });
    return res;
}
function genFooterContent(siteData, testFooterData) {
    const assetRoot = siteData.assetRoot;
    const dataPages = siteData.pages;
    const dataSettings = testFooterData.settings;
    const footerContainer = document.querySelector('[data-footer]');
    const dataInpJsons = footerContainer && footerContainer.getAttribute('data-json') !== '' && footerContainer.getAttribute('data-json') !== null?JSON.parse(footerContainer.getAttribute('data-json')): testFooterData;
    let footerLegals = []; const footerLegalLinks = [];
    const dataArrays = [];
    if (dataInpJsons) {
        const dataSocial = dataInpJsons && dataInpJsons.social? dataInpJsons.social:dataSettings.social;
        const dataDonate = dataInpJsons && dataInpJsons.donation? dataInpJsons.donation:dataSettings.donation;
        for (const dataPage of dataPages) {
            if (assetRoot.assetid == dataPage.parentData.assetid) {
                if (dataPage.name.indexOf('Footer Legal items') > -1) {
                    footerLegals = {"id": dataPage.id, "name": dataPage.name, "fileType": dataPage.file, "data": dataPage};
                } else {
                    dataArrays.push({"id": dataPage.id, "name": dataPage.name, "fileType": dataPage.file, "data": dataPage})
                }
            }
        }
        const subCatTitles = []; const subCats = [];
        for (const footerLegalId of footerLegals.data.childrenIds) {
            for (const dataPage of dataPages) {
                if (footerLegalId == dataPage.id) {
                    footerLegalLinks.push(`<div class="item-link">${dataPage.link}</div>`);
                    break;
                }
            }
        }
        for (const dataArray of dataArrays) {
            if (dataInpJsons.settings.type == dataArray.id) {
                for (const childrenId of dataArray.data.childrenIds) {
                    for (const dataPage of dataPages) {
                        if (dataPage.id == childrenId) {
                            const subCatLinks = [];
                            subCatTitles.push(`<div class="txt"><span class="title">${dataPage.name}</span></div>`);
                            for (const dataPageSub of dataPages) {
                                if (dataPageSub.parentData.assetid == childrenId && (dataPageSub.name !== '' && dataPageSub.name !== null)) {
                                    subCatLinks.push(`<div class="cat-sub item-txt">${dataPageSub.link}<svgs class="icon-title-wrapper" data-svg="icon-chevron-right"></svgs></div>`);
                                }
                            }
                            subCats.push({
                                'title': `<div class="title-wrapper btn-collapse"><span class="title">${dataPage.name}</span><svgs class="icon-title-wrapper" data-svg="icon-chevron-right"></svgs></div>`,
                                'data': subCatLinks
                            });
                            break;
                        }
                    }
                }
            }
        }
        let genHTML = `<div class="footer-wrapper">`;
        genHTML += `<div class="footer-bx mobile footer-logo-wrapper">`;
        genHTML += `<svgs data-svg="icon-logo-white"></svgs>`;
//         genHTML += `<a class="mobile" href="${dataDonate.url}" target="_selt"><div class="btn btn-donate ds-btn-secondary ds-btn--light">${dataDonate.text?dataDonate.text:"Danate Today"}</div></a>`;
        genHTML += `</div>`;
        genHTML += `<div class="footer-bx footer-category-wrapper">`;
        genHTML += `<div class="footer-category-item">`;
//         for (const subCat of subCats) {
//             genHTML += `<div class="cat-wrapper">`;
//             genHTML += subCat.title;
//             genHTML += `<div class="data-collapse">\n<div class="content">`
//             genHTML += subCat.data.join('\n');
//             genHTML += `</div>\n</div>`;
//             genHTML += `</div>`;
//         }
        genHTML += `</div>`;
        genHTML += `<div class="footer-category-item desktop">`;
        genHTML += `<svgs data-svg="icon-logo-white"></svgs>`;
        genHTML += `<div class="icon-wrapper desktop">`;
//         for (const social in dataSocial) {
//             genHTML += `<a href="${dataSocial[social]}" target="_blank"><svgs data-svg="icon-${social}"></svgs></a>`;
//         }
        genHTML += `</div>`;
//         genHTML += `<a class="desktop" href="${dataDonate.url}" target="_selt"><div class="btn btn-donate ds-btn-secondary ds-btn--light">${dataDonate.text?dataDonate.text:"Danate Today"}</div></a>`;
        genHTML += `</div>`;
        genHTML += `</div>`;
        genHTML += `<div class="footer-bx footer-legal-wrapper">`;
//         genHTML += footerLegalLinks.join('\n');
        genHTML += `</div>`;
        genHTML += `<div class="footer-bx icon-wrapper mobile">`;
//         for (const social in dataSocial) {
//             genHTML += `<a href="${dataSocial[social]}" target="_blank"><svgs data-svg="icon-${social}"></svgs></a>`;
//         }
        genHTML += `</div>`;
        genHTML += `<div class="footer-bx footer-copyright-wrapper"><div class="txt">`;
        genHTML += dataInpJsons.settings.copyright;
        genHTML += ` | Last edited: ${dataInpJsons.settings.lastUpdated}`;
        genHTML += ` | <a class="website-feedback" href="${dataInpJsons.settings && dataInpJsons.settings.websiteFeedbackURL? dataInpJsons.settings.websiteFeedbackURL : ''}" target="_blank">${dataInpJsons.settings.websiteFeedbackText && dataInpJsons.settings.websiteFeedbackText !== ''? dataInpJsons.settings.websiteFeedbackText: "Website feedback"}</a>`;
        genHTML += `</div></div>`;
        genHTML += `</div>`;
        if (footerContainer) {
            footerContainer.innerHTML = genHTML;
        }
    }
    new SVGs();
    btnCollapse();
}
function genFooter() {
    if (window.location.href.indexOf('.latrobe.edu.au') > -1) {
        getJson('https://wwwdev.latrobe.edu.au/sandbox/_design/includes/asset-listings/footer/_nocache');
    } else {
        genFooterContent(testFooterData.data.sites, testFooterData);
    }
}
export default class {
    constructor(context) {
        this.context = context;
        this.Footer();
    }
    Footer() {
        genFooter();
    }
}
