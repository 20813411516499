export const aboutData = {
    data: [
        {
            version: "standard",
//             version: "white-header-and-full-image-banner",
//             version:"dark-header-and-small-image",
//             version: "full-image-banner",
            image: "../externals/img/banners/about.jpg",
            imageMobile: "../externals/img/banners/about-sm.jpg",
            headline: "About Precious Gems Property",
            subheadline: "Real Estate Agency",
            content: "Founded by Precious Gems Property in 2021, Precious Gems Property was established to provide a holistic approach by covering all facets of Property Services.",
//             button_ext_url_1: "",
//             button_text_1: "Apply now",
//             button_url_2: "",
//             button_ext_url_2: "https://www.youtube.com/watch?v=tCxBaFE3C0Q",
//             button_text_2: "Register for Open Day"
        }
    ]
};
