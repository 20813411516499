const Icon = () => {
return `<svg xmlns="http://www.w3.org/2000/svg" width="206" height="48" viewBox="0 0 103 24" fill="none">
<mask id="path-1-outside-1_3_125" maskUnits="userSpaceOnUse" x="0" y="0" width="103" height="13" fill="black">
<rect fill="white" width="103" height="13"/>
<path d="M1 11.8536V1.14639H4.67965C5.34656 1.14639 5.92214 1.30323 6.40638 1.61692C6.89352 1.92712 7.26903 2.36106 7.5329 2.91873C7.79676 3.47291 7.9287 4.11771 7.9287 4.85314C7.9287 5.59205 7.79386 6.23859 7.5242 6.79278C7.25743 7.34347 6.87613 7.77044 6.38029 8.07367C5.88445 8.3769 5.29582 8.52852 4.6144 8.52852H2.34398V6.48954H4.21425C4.53901 6.48954 4.81013 6.42158 5.0276 6.28565C5.24798 6.14972 5.41471 5.95976 5.52779 5.71578C5.64088 5.46831 5.69742 5.18077 5.69742 4.85314C5.69742 4.52202 5.64088 4.23622 5.52779 3.99572C5.41471 3.75174 5.24798 3.56353 5.0276 3.43108C4.80723 3.29864 4.53611 3.23242 4.21425 3.23242H3.15298V11.8536H1Z"/>
<path d="M8.98997 11.8536V1.14639H12.6696C13.3365 1.14639 13.9121 1.29103 14.3963 1.58032C14.8835 1.86613 15.259 2.27741 15.5229 2.81416C15.7867 3.34743 15.9187 3.98004 15.9187 4.71198C15.9187 5.45437 15.7838 6.08523 15.5142 6.60456C15.2445 7.12041 14.8617 7.51426 14.3659 7.78612C13.8701 8.0545 13.2829 8.18869 12.6044 8.18869H10.2774V6.14971H12.2042C12.529 6.14971 12.8001 6.09918 13.0176 5.9981C13.2379 5.89354 13.4047 5.73669 13.5178 5.52757C13.6308 5.31496 13.6874 5.04309 13.6874 4.71198C13.6874 4.38086 13.6308 4.10726 13.5178 3.89116C13.4047 3.67158 13.2379 3.50776 13.0176 3.39972C12.7972 3.28818 12.5261 3.23242 12.2042 3.23242H11.143V11.8536H8.98997ZM14.0049 6.96008L16.2231 11.8536H13.8744L11.6997 6.96008H14.0049Z"/>
<path d="M17.0995 11.8536V1.14639H23.3106V3.2481H19.2525V5.44392H22.9931V7.55086H19.2525V9.7519H23.3106V11.8536H17.0995Z"/>
<path d="M32.7446 5.02567H30.5698C30.5408 4.75729 30.4814 4.51505 30.3915 4.29895C30.3016 4.08286 30.1827 3.89813 30.0348 3.74477C29.887 3.59141 29.7115 3.47465 29.5086 3.39449C29.3085 3.31084 29.0867 3.26901 28.8431 3.26901C28.411 3.26901 28.0384 3.39623 27.7253 3.65067C27.415 3.9051 27.1758 4.27281 27.0076 4.7538C26.8423 5.23479 26.7597 5.81686 26.7597 6.5C26.7597 7.21103 26.8438 7.80703 27.012 8.28802C27.183 8.76553 27.4223 9.12627 27.7296 9.37025C28.0399 9.61074 28.4067 9.73099 28.83 9.73099C29.0678 9.73099 29.2838 9.69439 29.4781 9.6212C29.6753 9.548 29.8478 9.4417 29.9957 9.30228C30.1465 9.15938 30.2697 8.98685 30.3654 8.7847C30.464 8.57906 30.5321 8.34728 30.5698 8.08935L32.7446 8.10504C32.7069 8.57906 32.5923 9.0461 32.401 9.50618C32.2125 9.96626 31.953 10.3862 31.6224 10.7662C31.2918 11.1426 30.8888 11.4423 30.4133 11.6654C29.9406 11.8885 29.3984 12 28.7866 12C27.9805 12 27.2584 11.7874 26.6205 11.3622C25.9855 10.9335 25.4839 10.3096 25.1156 9.49049C24.7473 8.67142 24.5632 7.67459 24.5632 6.5C24.5632 5.32193 24.7502 4.32335 25.1243 3.50428C25.4984 2.6852 26.0043 2.06305 26.6423 1.63783C27.2802 1.21261 27.9949 1 28.7866 1C29.3259 1 29.8246 1.09062 30.2828 1.27186C30.7409 1.44962 31.144 1.71103 31.4919 2.05608C31.8399 2.39766 32.1226 2.81765 32.3401 3.31607C32.5575 3.81448 32.6924 4.38435 32.7446 5.02567Z"/>
<path d="M36.1295 1.14639V11.8536H33.9765V1.14639H36.1295Z"/>
<path d="M45.8886 6.5C45.8886 7.67807 45.7002 8.67665 45.3232 9.49572C44.9463 10.3148 44.4359 10.9369 43.7922 11.3622C43.1514 11.7874 42.4323 12 41.6349 12C40.8346 12 40.114 11.7856 39.4732 11.3569C38.8324 10.9282 38.3235 10.3061 37.9465 9.49049C37.5725 8.67142 37.3854 7.67459 37.3854 6.5C37.3854 5.32193 37.5725 4.32335 37.9465 3.50428C38.3235 2.6852 38.8324 2.06305 39.4732 1.63783C40.114 1.21261 40.8346 1 41.6349 1C42.4323 1 43.1514 1.21261 43.7922 1.63783C44.4359 2.06305 44.9463 2.6852 45.3232 3.50428C45.7002 4.32335 45.8886 5.32193 45.8886 6.5ZM43.6878 6.5C43.6878 5.80292 43.6052 5.21388 43.4399 4.73289C43.2775 4.2519 43.0426 3.88767 42.7353 3.64021C42.4308 3.39274 42.064 3.26901 41.6349 3.26901C41.2086 3.26901 40.8418 3.39274 40.5345 3.64021C40.2271 3.88767 39.9908 4.2519 39.8255 4.73289C39.6631 5.21388 39.5819 5.80292 39.5819 6.5C39.5819 7.19709 39.6631 7.78612 39.8255 8.26711C39.9908 8.7481 40.2271 9.11233 40.5345 9.35979C40.8418 9.60726 41.2086 9.73099 41.6349 9.73099C42.064 9.73099 42.4308 9.60726 42.7353 9.35979C43.0426 9.11233 43.2775 8.7481 43.4399 8.26711C43.6052 7.78612 43.6878 7.19709 43.6878 6.5Z"/>
<path d="M52.5346 1.14639H54.6833V8.05276C54.6833 8.85092 54.5252 9.54626 54.2092 10.1388C53.8931 10.7278 53.4524 11.1844 52.8869 11.5086C52.3215 11.8292 51.6647 11.9895 50.9166 11.9895C50.1598 11.9895 49.4987 11.8292 48.9333 11.5086C48.3678 11.1844 47.9285 10.7278 47.6154 10.1388C47.3022 9.54626 47.1456 8.85092 47.1456 8.05276V1.14639H49.2986V7.82795C49.2986 8.1974 49.3653 8.52677 49.4987 8.81607C49.635 9.10536 49.8249 9.33191 50.0685 9.49572C50.312 9.65954 50.5948 9.74145 50.9166 9.74145C51.2385 9.74145 51.5197 9.65954 51.7604 9.49572C52.004 9.33191 52.1939 9.10536 52.3302 8.81607C52.4665 8.52677 52.5346 8.1974 52.5346 7.82795V1.14639Z"/>
<path d="M60.8475 4.35646C60.8185 3.97307 60.6895 3.67506 60.4604 3.46245C60.2343 3.24636 59.911 3.13831 59.4905 3.13831C59.2121 3.13831 58.9802 3.18188 58.7946 3.26901C58.609 3.35615 58.4698 3.47639 58.377 3.62975C58.2843 3.77963 58.2364 3.95215 58.2335 4.14734C58.2277 4.30767 58.2538 4.44883 58.3118 4.57082C58.3727 4.69281 58.4597 4.80086 58.5728 4.89496C58.6888 4.98558 58.8279 5.06575 58.9903 5.13546C59.1527 5.20517 59.3354 5.26616 59.5383 5.31844L60.3039 5.52757C60.7446 5.64259 61.1332 5.79594 61.4695 5.98764C61.8088 6.17934 62.0929 6.40764 62.322 6.67253C62.554 6.93742 62.7294 7.2424 62.8483 7.58745C62.9672 7.93251 63.0281 8.31939 63.031 8.7481C63.0281 9.42427 62.886 10.0046 62.6047 10.4891C62.3235 10.9735 61.919 11.3447 61.3912 11.6027C60.8664 11.8606 60.2328 11.9895 59.4905 11.9895C58.7453 11.9895 58.0958 11.8554 57.5419 11.587C56.9881 11.3186 56.5575 10.9108 56.2502 10.3636C55.9428 9.81638 55.7848 9.12452 55.7761 8.28802H57.8377C57.8551 8.63308 57.9319 8.92063 58.0682 9.15067C58.2045 9.3807 58.3915 9.55498 58.6293 9.67348C58.87 9.79198 59.1483 9.85124 59.4644 9.85124C59.7544 9.85124 60.0008 9.80418 60.2038 9.71008C60.4097 9.61597 60.5677 9.48527 60.6779 9.31797C60.7881 9.15067 60.8446 8.95897 60.8475 8.74287C60.8446 8.54072 60.7924 8.36819 60.691 8.22529C60.5895 8.0789 60.4329 7.95342 60.2212 7.84886C60.0124 7.74081 59.7457 7.64148 59.4209 7.55086L58.4901 7.28945C57.7188 7.07684 57.1113 6.73352 56.6677 6.25951C56.2241 5.782 56.0037 5.1372 56.0066 4.3251C56.0037 3.66286 56.1516 3.08254 56.4502 2.58413C56.7489 2.08571 57.1621 1.69709 57.6898 1.41825C58.2176 1.13942 58.8192 1 59.4949 1C60.185 1 60.7837 1.14116 61.2912 1.42348C61.8015 1.70231 62.1973 2.09442 62.4786 2.59981C62.7599 3.1052 62.9034 3.69075 62.9092 4.35646H60.8475Z"/>
<path d="M72.5954 4.64401C72.5461 4.42443 72.4737 4.23099 72.378 4.06369C72.2823 3.8929 72.1648 3.74826 72.0257 3.62975C71.8865 3.51125 71.727 3.42237 71.5472 3.36312C71.3674 3.30038 71.1703 3.26901 70.9557 3.26901C70.5236 3.26901 70.1496 3.39449 69.8335 3.64544C69.5204 3.89639 69.2783 4.26236 69.1072 4.74335C68.9361 5.22085 68.8505 5.80117 68.8505 6.48432C68.8505 7.17094 68.9332 7.7565 69.0985 8.24097C69.2638 8.72544 69.503 9.0949 69.8161 9.34933C70.1293 9.60377 70.5091 9.73099 70.9557 9.73099C71.35 9.73099 71.6835 9.65431 71.9561 9.50095C72.2315 9.34411 72.4403 9.12278 72.5824 8.83698C72.7245 8.55117 72.7955 8.21483 72.7955 7.82795L73.1957 7.88546H71.0296V5.9981H74.8702V7.41492C74.8702 8.37342 74.7006 9.19423 74.3613 9.87738C74.025 10.5605 73.561 11.0851 72.9695 11.451C72.3809 11.817 71.7038 12 70.9383 12C70.0887 12 69.342 11.7787 68.6983 11.336C68.0546 10.8934 67.553 10.2625 67.1934 9.44344C66.8339 8.62088 66.6541 7.64496 66.6541 6.51568C66.6541 5.63736 66.7628 4.85837 66.9803 4.17871C67.1978 3.49556 67.5008 2.91698 67.8893 2.44297C68.2808 1.96895 68.7331 1.60995 69.2464 1.36597C69.7625 1.12199 70.3178 1 70.9122 1C71.4283 1 71.9082 1.08888 72.3519 1.26664C72.7984 1.44439 73.1928 1.69534 73.5349 2.01949C73.88 2.34363 74.1598 2.72877 74.3744 3.17491C74.5889 3.62104 74.7223 4.11074 74.7745 4.64401H72.5954Z"/>
<path d="M76.1392 11.8536V1.14639H82.3502V3.2481H78.2922V5.44392H82.0327V7.55086H78.2922V9.7519H82.3502V11.8536H76.1392Z"/>
<path d="M83.6985 1.14639H86.3648L88.6265 7.77567H88.7309L90.9926 1.14639H93.6588V11.8536H91.5624V5.27662H91.4884L89.3485 11.7856H88.0089L85.8689 5.24002H85.795V11.8536H83.6985V1.14639Z"/>
<path d="M99.8166 4.35646C99.7876 3.97307 99.6585 3.67506 99.4295 3.46245C99.2033 3.24636 98.88 3.13831 98.4595 3.13831C98.1812 3.13831 97.9492 3.18188 97.7636 3.26901C97.578 3.35615 97.4389 3.47639 97.3461 3.62975C97.2533 3.77963 97.2054 3.95215 97.2025 4.14734C97.1967 4.30767 97.2228 4.44883 97.2808 4.57082C97.3417 4.69281 97.4287 4.80086 97.5418 4.89496C97.6578 4.98558 97.797 5.06575 97.9593 5.13546C98.1217 5.20517 98.3044 5.26616 98.5074 5.31844L99.2729 5.52757C99.7136 5.64259 100.102 5.79594 100.439 5.98764C100.778 6.17934 101.062 6.40764 101.291 6.67253C101.523 6.93742 101.698 7.2424 101.817 7.58745C101.936 7.93251 101.997 8.31939 102 8.7481C101.997 9.42427 101.855 10.0046 101.574 10.4891C101.292 10.9735 100.888 11.3447 100.36 11.6027C99.8354 11.8606 99.2018 11.9895 98.4595 11.9895C97.7143 11.9895 97.0648 11.8554 96.511 11.587C95.9571 11.3186 95.5265 10.9108 95.2192 10.3636C94.9118 9.81638 94.7538 9.12452 94.7451 8.28802H96.8067C96.8241 8.63308 96.901 8.92063 97.0373 9.15067C97.1735 9.3807 97.3606 9.55498 97.5983 9.67348C97.839 9.79198 98.1174 9.85124 98.4334 9.85124C98.7234 9.85124 98.9699 9.80418 99.1728 9.71008C99.3787 9.61597 99.5367 9.48527 99.6469 9.31797C99.7571 9.15067 99.8137 8.95897 99.8166 8.74287C99.8137 8.54072 99.7615 8.36819 99.66 8.22529C99.5585 8.0789 99.4019 7.95342 99.1902 7.84886C98.9815 7.74081 98.7147 7.64148 98.3899 7.55086L97.4592 7.28945C96.6878 7.07684 96.0804 6.73352 95.6367 6.25951C95.1931 5.782 94.9727 5.1372 94.9756 4.3251C94.9727 3.66286 95.1206 3.08254 95.4192 2.58413C95.7179 2.08571 96.1311 1.69709 96.6589 1.41825C97.1866 1.13942 97.7883 1 98.4639 1C99.154 1 99.7528 1.14116 100.26 1.42348C100.771 1.70231 101.166 2.09442 101.448 2.59981C101.729 3.1052 101.872 3.69075 101.878 4.35646H99.8166Z"/>
</mask>
<path d="M1 11.8536V1.14639H4.67965C5.34656 1.14639 5.92214 1.30323 6.40638 1.61692C6.89352 1.92712 7.26903 2.36106 7.5329 2.91873C7.79676 3.47291 7.9287 4.11771 7.9287 4.85314C7.9287 5.59205 7.79386 6.23859 7.5242 6.79278C7.25743 7.34347 6.87613 7.77044 6.38029 8.07367C5.88445 8.3769 5.29582 8.52852 4.6144 8.52852H2.34398V6.48954H4.21425C4.53901 6.48954 4.81013 6.42158 5.0276 6.28565C5.24798 6.14972 5.41471 5.95976 5.52779 5.71578C5.64088 5.46831 5.69742 5.18077 5.69742 4.85314C5.69742 4.52202 5.64088 4.23622 5.52779 3.99572C5.41471 3.75174 5.24798 3.56353 5.0276 3.43108C4.80723 3.29864 4.53611 3.23242 4.21425 3.23242H3.15298V11.8536H1Z" fill="white"/>
<path d="M8.98997 11.8536V1.14639H12.6696C13.3365 1.14639 13.9121 1.29103 14.3963 1.58032C14.8835 1.86613 15.259 2.27741 15.5229 2.81416C15.7867 3.34743 15.9187 3.98004 15.9187 4.71198C15.9187 5.45437 15.7838 6.08523 15.5142 6.60456C15.2445 7.12041 14.8617 7.51426 14.3659 7.78612C13.8701 8.0545 13.2829 8.18869 12.6044 8.18869H10.2774V6.14971H12.2042C12.529 6.14971 12.8001 6.09918 13.0176 5.9981C13.2379 5.89354 13.4047 5.73669 13.5178 5.52757C13.6308 5.31496 13.6874 5.04309 13.6874 4.71198C13.6874 4.38086 13.6308 4.10726 13.5178 3.89116C13.4047 3.67158 13.2379 3.50776 13.0176 3.39972C12.7972 3.28818 12.5261 3.23242 12.2042 3.23242H11.143V11.8536H8.98997ZM14.0049 6.96008L16.2231 11.8536H13.8744L11.6997 6.96008H14.0049Z" fill="white"/>
<path d="M17.0995 11.8536V1.14639H23.3106V3.2481H19.2525V5.44392H22.9931V7.55086H19.2525V9.7519H23.3106V11.8536H17.0995Z" fill="white"/>
<path d="M32.7446 5.02567H30.5698C30.5408 4.75729 30.4814 4.51505 30.3915 4.29895C30.3016 4.08286 30.1827 3.89813 30.0348 3.74477C29.887 3.59141 29.7115 3.47465 29.5086 3.39449C29.3085 3.31084 29.0867 3.26901 28.8431 3.26901C28.411 3.26901 28.0384 3.39623 27.7253 3.65067C27.415 3.9051 27.1758 4.27281 27.0076 4.7538C26.8423 5.23479 26.7597 5.81686 26.7597 6.5C26.7597 7.21103 26.8438 7.80703 27.012 8.28802C27.183 8.76553 27.4223 9.12627 27.7296 9.37025C28.0399 9.61074 28.4067 9.73099 28.83 9.73099C29.0678 9.73099 29.2838 9.69439 29.4781 9.6212C29.6753 9.548 29.8478 9.4417 29.9957 9.30228C30.1465 9.15938 30.2697 8.98685 30.3654 8.7847C30.464 8.57906 30.5321 8.34728 30.5698 8.08935L32.7446 8.10504C32.7069 8.57906 32.5923 9.0461 32.401 9.50618C32.2125 9.96626 31.953 10.3862 31.6224 10.7662C31.2918 11.1426 30.8888 11.4423 30.4133 11.6654C29.9406 11.8885 29.3984 12 28.7866 12C27.9805 12 27.2584 11.7874 26.6205 11.3622C25.9855 10.9335 25.4839 10.3096 25.1156 9.49049C24.7473 8.67142 24.5632 7.67459 24.5632 6.5C24.5632 5.32193 24.7502 4.32335 25.1243 3.50428C25.4984 2.6852 26.0043 2.06305 26.6423 1.63783C27.2802 1.21261 27.9949 1 28.7866 1C29.3259 1 29.8246 1.09062 30.2828 1.27186C30.7409 1.44962 31.144 1.71103 31.4919 2.05608C31.8399 2.39766 32.1226 2.81765 32.3401 3.31607C32.5575 3.81448 32.6924 4.38435 32.7446 5.02567Z" fill="white"/>
<path d="M36.1295 1.14639V11.8536H33.9765V1.14639H36.1295Z" fill="white"/>
<path d="M45.8886 6.5C45.8886 7.67807 45.7002 8.67665 45.3232 9.49572C44.9463 10.3148 44.4359 10.9369 43.7922 11.3622C43.1514 11.7874 42.4323 12 41.6349 12C40.8346 12 40.114 11.7856 39.4732 11.3569C38.8324 10.9282 38.3235 10.3061 37.9465 9.49049C37.5725 8.67142 37.3854 7.67459 37.3854 6.5C37.3854 5.32193 37.5725 4.32335 37.9465 3.50428C38.3235 2.6852 38.8324 2.06305 39.4732 1.63783C40.114 1.21261 40.8346 1 41.6349 1C42.4323 1 43.1514 1.21261 43.7922 1.63783C44.4359 2.06305 44.9463 2.6852 45.3232 3.50428C45.7002 4.32335 45.8886 5.32193 45.8886 6.5ZM43.6878 6.5C43.6878 5.80292 43.6052 5.21388 43.4399 4.73289C43.2775 4.2519 43.0426 3.88767 42.7353 3.64021C42.4308 3.39274 42.064 3.26901 41.6349 3.26901C41.2086 3.26901 40.8418 3.39274 40.5345 3.64021C40.2271 3.88767 39.9908 4.2519 39.8255 4.73289C39.6631 5.21388 39.5819 5.80292 39.5819 6.5C39.5819 7.19709 39.6631 7.78612 39.8255 8.26711C39.9908 8.7481 40.2271 9.11233 40.5345 9.35979C40.8418 9.60726 41.2086 9.73099 41.6349 9.73099C42.064 9.73099 42.4308 9.60726 42.7353 9.35979C43.0426 9.11233 43.2775 8.7481 43.4399 8.26711C43.6052 7.78612 43.6878 7.19709 43.6878 6.5Z" fill="white"/>
<path d="M52.5346 1.14639H54.6833V8.05276C54.6833 8.85092 54.5252 9.54626 54.2092 10.1388C53.8931 10.7278 53.4524 11.1844 52.8869 11.5086C52.3215 11.8292 51.6647 11.9895 50.9166 11.9895C50.1598 11.9895 49.4987 11.8292 48.9333 11.5086C48.3678 11.1844 47.9285 10.7278 47.6154 10.1388C47.3022 9.54626 47.1456 8.85092 47.1456 8.05276V1.14639H49.2986V7.82795C49.2986 8.1974 49.3653 8.52677 49.4987 8.81607C49.635 9.10536 49.8249 9.33191 50.0685 9.49572C50.312 9.65954 50.5948 9.74145 50.9166 9.74145C51.2385 9.74145 51.5197 9.65954 51.7604 9.49572C52.004 9.33191 52.1939 9.10536 52.3302 8.81607C52.4665 8.52677 52.5346 8.1974 52.5346 7.82795V1.14639Z" fill="white"/>
<path d="M60.8475 4.35646C60.8185 3.97307 60.6895 3.67506 60.4604 3.46245C60.2343 3.24636 59.911 3.13831 59.4905 3.13831C59.2121 3.13831 58.9802 3.18188 58.7946 3.26901C58.609 3.35615 58.4698 3.47639 58.377 3.62975C58.2843 3.77963 58.2364 3.95215 58.2335 4.14734C58.2277 4.30767 58.2538 4.44883 58.3118 4.57082C58.3727 4.69281 58.4597 4.80086 58.5728 4.89496C58.6888 4.98558 58.8279 5.06575 58.9903 5.13546C59.1527 5.20517 59.3354 5.26616 59.5383 5.31844L60.3039 5.52757C60.7446 5.64259 61.1332 5.79594 61.4695 5.98764C61.8088 6.17934 62.0929 6.40764 62.322 6.67253C62.554 6.93742 62.7294 7.2424 62.8483 7.58745C62.9672 7.93251 63.0281 8.31939 63.031 8.7481C63.0281 9.42427 62.886 10.0046 62.6047 10.4891C62.3235 10.9735 61.919 11.3447 61.3912 11.6027C60.8664 11.8606 60.2328 11.9895 59.4905 11.9895C58.7453 11.9895 58.0958 11.8554 57.5419 11.587C56.9881 11.3186 56.5575 10.9108 56.2502 10.3636C55.9428 9.81638 55.7848 9.12452 55.7761 8.28802H57.8377C57.8551 8.63308 57.9319 8.92063 58.0682 9.15067C58.2045 9.3807 58.3915 9.55498 58.6293 9.67348C58.87 9.79198 59.1483 9.85124 59.4644 9.85124C59.7544 9.85124 60.0008 9.80418 60.2038 9.71008C60.4097 9.61597 60.5677 9.48527 60.6779 9.31797C60.7881 9.15067 60.8446 8.95897 60.8475 8.74287C60.8446 8.54072 60.7924 8.36819 60.691 8.22529C60.5895 8.0789 60.4329 7.95342 60.2212 7.84886C60.0124 7.74081 59.7457 7.64148 59.4209 7.55086L58.4901 7.28945C57.7188 7.07684 57.1113 6.73352 56.6677 6.25951C56.2241 5.782 56.0037 5.1372 56.0066 4.3251C56.0037 3.66286 56.1516 3.08254 56.4502 2.58413C56.7489 2.08571 57.1621 1.69709 57.6898 1.41825C58.2176 1.13942 58.8192 1 59.4949 1C60.185 1 60.7837 1.14116 61.2912 1.42348C61.8015 1.70231 62.1973 2.09442 62.4786 2.59981C62.7599 3.1052 62.9034 3.69075 62.9092 4.35646H60.8475Z" fill="white"/>
<path d="M72.5954 4.64401C72.5461 4.42443 72.4737 4.23099 72.378 4.06369C72.2823 3.8929 72.1648 3.74826 72.0257 3.62975C71.8865 3.51125 71.727 3.42237 71.5472 3.36312C71.3674 3.30038 71.1703 3.26901 70.9557 3.26901C70.5236 3.26901 70.1496 3.39449 69.8335 3.64544C69.5204 3.89639 69.2783 4.26236 69.1072 4.74335C68.9361 5.22085 68.8505 5.80117 68.8505 6.48432C68.8505 7.17094 68.9332 7.7565 69.0985 8.24097C69.2638 8.72544 69.503 9.0949 69.8161 9.34933C70.1293 9.60377 70.5091 9.73099 70.9557 9.73099C71.35 9.73099 71.6835 9.65431 71.9561 9.50095C72.2315 9.34411 72.4403 9.12278 72.5824 8.83698C72.7245 8.55117 72.7955 8.21483 72.7955 7.82795L73.1957 7.88546H71.0296V5.9981H74.8702V7.41492C74.8702 8.37342 74.7006 9.19423 74.3613 9.87738C74.025 10.5605 73.561 11.0851 72.9695 11.451C72.3809 11.817 71.7038 12 70.9383 12C70.0887 12 69.342 11.7787 68.6983 11.336C68.0546 10.8934 67.553 10.2625 67.1934 9.44344C66.8339 8.62088 66.6541 7.64496 66.6541 6.51568C66.6541 5.63736 66.7628 4.85837 66.9803 4.17871C67.1978 3.49556 67.5008 2.91698 67.8893 2.44297C68.2808 1.96895 68.7331 1.60995 69.2464 1.36597C69.7625 1.12199 70.3178 1 70.9122 1C71.4283 1 71.9082 1.08888 72.3519 1.26664C72.7984 1.44439 73.1928 1.69534 73.5349 2.01949C73.88 2.34363 74.1598 2.72877 74.3744 3.17491C74.5889 3.62104 74.7223 4.11074 74.7745 4.64401H72.5954Z" fill="white"/>
<path d="M76.1392 11.8536V1.14639H82.3502V3.2481H78.2922V5.44392H82.0327V7.55086H78.2922V9.7519H82.3502V11.8536H76.1392Z" fill="white"/>
<path d="M83.6985 1.14639H86.3648L88.6265 7.77567H88.7309L90.9926 1.14639H93.6588V11.8536H91.5624V5.27662H91.4884L89.3485 11.7856H88.0089L85.8689 5.24002H85.795V11.8536H83.6985V1.14639Z" fill="white"/>
<path d="M99.8166 4.35646C99.7876 3.97307 99.6585 3.67506 99.4295 3.46245C99.2033 3.24636 98.88 3.13831 98.4595 3.13831C98.1812 3.13831 97.9492 3.18188 97.7636 3.26901C97.578 3.35615 97.4389 3.47639 97.3461 3.62975C97.2533 3.77963 97.2054 3.95215 97.2025 4.14734C97.1967 4.30767 97.2228 4.44883 97.2808 4.57082C97.3417 4.69281 97.4287 4.80086 97.5418 4.89496C97.6578 4.98558 97.797 5.06575 97.9593 5.13546C98.1217 5.20517 98.3044 5.26616 98.5074 5.31844L99.2729 5.52757C99.7136 5.64259 100.102 5.79594 100.439 5.98764C100.778 6.17934 101.062 6.40764 101.291 6.67253C101.523 6.93742 101.698 7.2424 101.817 7.58745C101.936 7.93251 101.997 8.31939 102 8.7481C101.997 9.42427 101.855 10.0046 101.574 10.4891C101.292 10.9735 100.888 11.3447 100.36 11.6027C99.8354 11.8606 99.2018 11.9895 98.4595 11.9895C97.7143 11.9895 97.0648 11.8554 96.511 11.587C95.9571 11.3186 95.5265 10.9108 95.2192 10.3636C94.9118 9.81638 94.7538 9.12452 94.7451 8.28802H96.8067C96.8241 8.63308 96.901 8.92063 97.0373 9.15067C97.1735 9.3807 97.3606 9.55498 97.5983 9.67348C97.839 9.79198 98.1174 9.85124 98.4334 9.85124C98.7234 9.85124 98.9699 9.80418 99.1728 9.71008C99.3787 9.61597 99.5367 9.48527 99.6469 9.31797C99.7571 9.15067 99.8137 8.95897 99.8166 8.74287C99.8137 8.54072 99.7615 8.36819 99.66 8.22529C99.5585 8.0789 99.4019 7.95342 99.1902 7.84886C98.9815 7.74081 98.7147 7.64148 98.3899 7.55086L97.4592 7.28945C96.6878 7.07684 96.0804 6.73352 95.6367 6.25951C95.1931 5.782 94.9727 5.1372 94.9756 4.3251C94.9727 3.66286 95.1206 3.08254 95.4192 2.58413C95.7179 2.08571 96.1311 1.69709 96.6589 1.41825C97.1866 1.13942 97.7883 1 98.4639 1C99.154 1 99.7528 1.14116 100.26 1.42348C100.771 1.70231 101.166 2.09442 101.448 2.59981C101.729 3.1052 101.872 3.69075 101.878 4.35646H99.8166Z" fill="white"/>
<path d="M1 11.8536V1.14639H4.67965C5.34656 1.14639 5.92214 1.30323 6.40638 1.61692C6.89352 1.92712 7.26903 2.36106 7.5329 2.91873C7.79676 3.47291 7.9287 4.11771 7.9287 4.85314C7.9287 5.59205 7.79386 6.23859 7.5242 6.79278C7.25743 7.34347 6.87613 7.77044 6.38029 8.07367C5.88445 8.3769 5.29582 8.52852 4.6144 8.52852H2.34398V6.48954H4.21425C4.53901 6.48954 4.81013 6.42158 5.0276 6.28565C5.24798 6.14972 5.41471 5.95976 5.52779 5.71578C5.64088 5.46831 5.69742 5.18077 5.69742 4.85314C5.69742 4.52202 5.64088 4.23622 5.52779 3.99572C5.41471 3.75174 5.24798 3.56353 5.0276 3.43108C4.80723 3.29864 4.53611 3.23242 4.21425 3.23242H3.15298V11.8536H1Z" stroke="black" stroke-width="0.2" mask="url(#path-1-outside-1_3_125)"/>
<path d="M8.98997 11.8536V1.14639H12.6696C13.3365 1.14639 13.9121 1.29103 14.3963 1.58032C14.8835 1.86613 15.259 2.27741 15.5229 2.81416C15.7867 3.34743 15.9187 3.98004 15.9187 4.71198C15.9187 5.45437 15.7838 6.08523 15.5142 6.60456C15.2445 7.12041 14.8617 7.51426 14.3659 7.78612C13.8701 8.0545 13.2829 8.18869 12.6044 8.18869H10.2774V6.14971H12.2042C12.529 6.14971 12.8001 6.09918 13.0176 5.9981C13.2379 5.89354 13.4047 5.73669 13.5178 5.52757C13.6308 5.31496 13.6874 5.04309 13.6874 4.71198C13.6874 4.38086 13.6308 4.10726 13.5178 3.89116C13.4047 3.67158 13.2379 3.50776 13.0176 3.39972C12.7972 3.28818 12.5261 3.23242 12.2042 3.23242H11.143V11.8536H8.98997ZM14.0049 6.96008L16.2231 11.8536H13.8744L11.6997 6.96008H14.0049Z" stroke="black" stroke-width="0.2" mask="url(#path-1-outside-1_3_125)"/>
<path d="M17.0995 11.8536V1.14639H23.3106V3.2481H19.2525V5.44392H22.9931V7.55086H19.2525V9.7519H23.3106V11.8536H17.0995Z" stroke="black" stroke-width="0.2" mask="url(#path-1-outside-1_3_125)"/>
<path d="M32.7446 5.02567H30.5698C30.5408 4.75729 30.4814 4.51505 30.3915 4.29895C30.3016 4.08286 30.1827 3.89813 30.0348 3.74477C29.887 3.59141 29.7115 3.47465 29.5086 3.39449C29.3085 3.31084 29.0867 3.26901 28.8431 3.26901C28.411 3.26901 28.0384 3.39623 27.7253 3.65067C27.415 3.9051 27.1758 4.27281 27.0076 4.7538C26.8423 5.23479 26.7597 5.81686 26.7597 6.5C26.7597 7.21103 26.8438 7.80703 27.012 8.28802C27.183 8.76553 27.4223 9.12627 27.7296 9.37025C28.0399 9.61074 28.4067 9.73099 28.83 9.73099C29.0678 9.73099 29.2838 9.69439 29.4781 9.6212C29.6753 9.548 29.8478 9.4417 29.9957 9.30228C30.1465 9.15938 30.2697 8.98685 30.3654 8.7847C30.464 8.57906 30.5321 8.34728 30.5698 8.08935L32.7446 8.10504C32.7069 8.57906 32.5923 9.0461 32.401 9.50618C32.2125 9.96626 31.953 10.3862 31.6224 10.7662C31.2918 11.1426 30.8888 11.4423 30.4133 11.6654C29.9406 11.8885 29.3984 12 28.7866 12C27.9805 12 27.2584 11.7874 26.6205 11.3622C25.9855 10.9335 25.4839 10.3096 25.1156 9.49049C24.7473 8.67142 24.5632 7.67459 24.5632 6.5C24.5632 5.32193 24.7502 4.32335 25.1243 3.50428C25.4984 2.6852 26.0043 2.06305 26.6423 1.63783C27.2802 1.21261 27.9949 1 28.7866 1C29.3259 1 29.8246 1.09062 30.2828 1.27186C30.7409 1.44962 31.144 1.71103 31.4919 2.05608C31.8399 2.39766 32.1226 2.81765 32.3401 3.31607C32.5575 3.81448 32.6924 4.38435 32.7446 5.02567Z" stroke="black" stroke-width="0.2" mask="url(#path-1-outside-1_3_125)"/>
<path d="M36.1295 1.14639V11.8536H33.9765V1.14639H36.1295Z" stroke="black" stroke-width="0.2" mask="url(#path-1-outside-1_3_125)"/>
<path d="M45.8886 6.5C45.8886 7.67807 45.7002 8.67665 45.3232 9.49572C44.9463 10.3148 44.4359 10.9369 43.7922 11.3622C43.1514 11.7874 42.4323 12 41.6349 12C40.8346 12 40.114 11.7856 39.4732 11.3569C38.8324 10.9282 38.3235 10.3061 37.9465 9.49049C37.5725 8.67142 37.3854 7.67459 37.3854 6.5C37.3854 5.32193 37.5725 4.32335 37.9465 3.50428C38.3235 2.6852 38.8324 2.06305 39.4732 1.63783C40.114 1.21261 40.8346 1 41.6349 1C42.4323 1 43.1514 1.21261 43.7922 1.63783C44.4359 2.06305 44.9463 2.6852 45.3232 3.50428C45.7002 4.32335 45.8886 5.32193 45.8886 6.5ZM43.6878 6.5C43.6878 5.80292 43.6052 5.21388 43.4399 4.73289C43.2775 4.2519 43.0426 3.88767 42.7353 3.64021C42.4308 3.39274 42.064 3.26901 41.6349 3.26901C41.2086 3.26901 40.8418 3.39274 40.5345 3.64021C40.2271 3.88767 39.9908 4.2519 39.8255 4.73289C39.6631 5.21388 39.5819 5.80292 39.5819 6.5C39.5819 7.19709 39.6631 7.78612 39.8255 8.26711C39.9908 8.7481 40.2271 9.11233 40.5345 9.35979C40.8418 9.60726 41.2086 9.73099 41.6349 9.73099C42.064 9.73099 42.4308 9.60726 42.7353 9.35979C43.0426 9.11233 43.2775 8.7481 43.4399 8.26711C43.6052 7.78612 43.6878 7.19709 43.6878 6.5Z" stroke="black" stroke-width="0.2" mask="url(#path-1-outside-1_3_125)"/>
<path d="M52.5346 1.14639H54.6833V8.05276C54.6833 8.85092 54.5252 9.54626 54.2092 10.1388C53.8931 10.7278 53.4524 11.1844 52.8869 11.5086C52.3215 11.8292 51.6647 11.9895 50.9166 11.9895C50.1598 11.9895 49.4987 11.8292 48.9333 11.5086C48.3678 11.1844 47.9285 10.7278 47.6154 10.1388C47.3022 9.54626 47.1456 8.85092 47.1456 8.05276V1.14639H49.2986V7.82795C49.2986 8.1974 49.3653 8.52677 49.4987 8.81607C49.635 9.10536 49.8249 9.33191 50.0685 9.49572C50.312 9.65954 50.5948 9.74145 50.9166 9.74145C51.2385 9.74145 51.5197 9.65954 51.7604 9.49572C52.004 9.33191 52.1939 9.10536 52.3302 8.81607C52.4665 8.52677 52.5346 8.1974 52.5346 7.82795V1.14639Z" stroke="black" stroke-width="0.2" mask="url(#path-1-outside-1_3_125)"/>
<path d="M60.8475 4.35646C60.8185 3.97307 60.6895 3.67506 60.4604 3.46245C60.2343 3.24636 59.911 3.13831 59.4905 3.13831C59.2121 3.13831 58.9802 3.18188 58.7946 3.26901C58.609 3.35615 58.4698 3.47639 58.377 3.62975C58.2843 3.77963 58.2364 3.95215 58.2335 4.14734C58.2277 4.30767 58.2538 4.44883 58.3118 4.57082C58.3727 4.69281 58.4597 4.80086 58.5728 4.89496C58.6888 4.98558 58.8279 5.06575 58.9903 5.13546C59.1527 5.20517 59.3354 5.26616 59.5383 5.31844L60.3039 5.52757C60.7446 5.64259 61.1332 5.79594 61.4695 5.98764C61.8088 6.17934 62.0929 6.40764 62.322 6.67253C62.554 6.93742 62.7294 7.2424 62.8483 7.58745C62.9672 7.93251 63.0281 8.31939 63.031 8.7481C63.0281 9.42427 62.886 10.0046 62.6047 10.4891C62.3235 10.9735 61.919 11.3447 61.3912 11.6027C60.8664 11.8606 60.2328 11.9895 59.4905 11.9895C58.7453 11.9895 58.0958 11.8554 57.5419 11.587C56.9881 11.3186 56.5575 10.9108 56.2502 10.3636C55.9428 9.81638 55.7848 9.12452 55.7761 8.28802H57.8377C57.8551 8.63308 57.9319 8.92063 58.0682 9.15067C58.2045 9.3807 58.3915 9.55498 58.6293 9.67348C58.87 9.79198 59.1483 9.85124 59.4644 9.85124C59.7544 9.85124 60.0008 9.80418 60.2038 9.71008C60.4097 9.61597 60.5677 9.48527 60.6779 9.31797C60.7881 9.15067 60.8446 8.95897 60.8475 8.74287C60.8446 8.54072 60.7924 8.36819 60.691 8.22529C60.5895 8.0789 60.4329 7.95342 60.2212 7.84886C60.0124 7.74081 59.7457 7.64148 59.4209 7.55086L58.4901 7.28945C57.7188 7.07684 57.1113 6.73352 56.6677 6.25951C56.2241 5.782 56.0037 5.1372 56.0066 4.3251C56.0037 3.66286 56.1516 3.08254 56.4502 2.58413C56.7489 2.08571 57.1621 1.69709 57.6898 1.41825C58.2176 1.13942 58.8192 1 59.4949 1C60.185 1 60.7837 1.14116 61.2912 1.42348C61.8015 1.70231 62.1973 2.09442 62.4786 2.59981C62.7599 3.1052 62.9034 3.69075 62.9092 4.35646H60.8475Z" stroke="black" stroke-width="0.2" mask="url(#path-1-outside-1_3_125)"/>
<path d="M72.5954 4.64401C72.5461 4.42443 72.4737 4.23099 72.378 4.06369C72.2823 3.8929 72.1648 3.74826 72.0257 3.62975C71.8865 3.51125 71.727 3.42237 71.5472 3.36312C71.3674 3.30038 71.1703 3.26901 70.9557 3.26901C70.5236 3.26901 70.1496 3.39449 69.8335 3.64544C69.5204 3.89639 69.2783 4.26236 69.1072 4.74335C68.9361 5.22085 68.8505 5.80117 68.8505 6.48432C68.8505 7.17094 68.9332 7.7565 69.0985 8.24097C69.2638 8.72544 69.503 9.0949 69.8161 9.34933C70.1293 9.60377 70.5091 9.73099 70.9557 9.73099C71.35 9.73099 71.6835 9.65431 71.9561 9.50095C72.2315 9.34411 72.4403 9.12278 72.5824 8.83698C72.7245 8.55117 72.7955 8.21483 72.7955 7.82795L73.1957 7.88546H71.0296V5.9981H74.8702V7.41492C74.8702 8.37342 74.7006 9.19423 74.3613 9.87738C74.025 10.5605 73.561 11.0851 72.9695 11.451C72.3809 11.817 71.7038 12 70.9383 12C70.0887 12 69.342 11.7787 68.6983 11.336C68.0546 10.8934 67.553 10.2625 67.1934 9.44344C66.8339 8.62088 66.6541 7.64496 66.6541 6.51568C66.6541 5.63736 66.7628 4.85837 66.9803 4.17871C67.1978 3.49556 67.5008 2.91698 67.8893 2.44297C68.2808 1.96895 68.7331 1.60995 69.2464 1.36597C69.7625 1.12199 70.3178 1 70.9122 1C71.4283 1 71.9082 1.08888 72.3519 1.26664C72.7984 1.44439 73.1928 1.69534 73.5349 2.01949C73.88 2.34363 74.1598 2.72877 74.3744 3.17491C74.5889 3.62104 74.7223 4.11074 74.7745 4.64401H72.5954Z" stroke="black" stroke-width="0.2" mask="url(#path-1-outside-1_3_125)"/>
<path d="M76.1392 11.8536V1.14639H82.3502V3.2481H78.2922V5.44392H82.0327V7.55086H78.2922V9.7519H82.3502V11.8536H76.1392Z" stroke="black" stroke-width="0.2" mask="url(#path-1-outside-1_3_125)"/>
<path d="M83.6985 1.14639H86.3648L88.6265 7.77567H88.7309L90.9926 1.14639H93.6588V11.8536H91.5624V5.27662H91.4884L89.3485 11.7856H88.0089L85.8689 5.24002H85.795V11.8536H83.6985V1.14639Z" stroke="black" stroke-width="0.2" mask="url(#path-1-outside-1_3_125)"/>
<path d="M99.8166 4.35646C99.7876 3.97307 99.6585 3.67506 99.4295 3.46245C99.2033 3.24636 98.88 3.13831 98.4595 3.13831C98.1812 3.13831 97.9492 3.18188 97.7636 3.26901C97.578 3.35615 97.4389 3.47639 97.3461 3.62975C97.2533 3.77963 97.2054 3.95215 97.2025 4.14734C97.1967 4.30767 97.2228 4.44883 97.2808 4.57082C97.3417 4.69281 97.4287 4.80086 97.5418 4.89496C97.6578 4.98558 97.797 5.06575 97.9593 5.13546C98.1217 5.20517 98.3044 5.26616 98.5074 5.31844L99.2729 5.52757C99.7136 5.64259 100.102 5.79594 100.439 5.98764C100.778 6.17934 101.062 6.40764 101.291 6.67253C101.523 6.93742 101.698 7.2424 101.817 7.58745C101.936 7.93251 101.997 8.31939 102 8.7481C101.997 9.42427 101.855 10.0046 101.574 10.4891C101.292 10.9735 100.888 11.3447 100.36 11.6027C99.8354 11.8606 99.2018 11.9895 98.4595 11.9895C97.7143 11.9895 97.0648 11.8554 96.511 11.587C95.9571 11.3186 95.5265 10.9108 95.2192 10.3636C94.9118 9.81638 94.7538 9.12452 94.7451 8.28802H96.8067C96.8241 8.63308 96.901 8.92063 97.0373 9.15067C97.1735 9.3807 97.3606 9.55498 97.5983 9.67348C97.839 9.79198 98.1174 9.85124 98.4334 9.85124C98.7234 9.85124 98.9699 9.80418 99.1728 9.71008C99.3787 9.61597 99.5367 9.48527 99.6469 9.31797C99.7571 9.15067 99.8137 8.95897 99.8166 8.74287C99.8137 8.54072 99.7615 8.36819 99.66 8.22529C99.5585 8.0789 99.4019 7.95342 99.1902 7.84886C98.9815 7.74081 98.7147 7.64148 98.3899 7.55086L97.4592 7.28945C96.6878 7.07684 96.0804 6.73352 95.6367 6.25951C95.1931 5.782 94.9727 5.1372 94.9756 4.3251C94.9727 3.66286 95.1206 3.08254 95.4192 2.58413C95.7179 2.08571 96.1311 1.69709 96.6589 1.41825C97.1866 1.13942 97.7883 1 98.4639 1C99.154 1 99.7528 1.14116 100.26 1.42348C100.771 1.70231 101.166 2.09442 101.448 2.59981C101.729 3.1052 101.872 3.69075 101.878 4.35646H99.8166Z" stroke="black" stroke-width="0.2" mask="url(#path-1-outside-1_3_125)"/>
<mask id="path-2-outside-2_3_125" maskUnits="userSpaceOnUse" x="1" y="16" width="102" height="8" fill="black">
<rect fill="white" x="1" y="16" width="102" height="8"/>
<path d="M2 22.9202V17.0798H4.92032C5.55772 17.0798 6.08578 17.1625 6.5045 17.3279C6.92321 17.4933 7.23658 17.7196 7.4446 18.0067C7.65262 18.2918 7.75663 18.6131 7.75663 18.9705C7.75663 19.3298 7.65129 19.653 7.4406 19.9401C7.23258 20.2253 6.91787 20.4515 6.49649 20.6188C6.07778 20.7842 5.55106 20.8669 4.91632 20.8669H2.9081V20.1198H4.80431C5.20702 20.1198 5.53372 20.0703 5.78442 19.9715C6.03511 19.8707 6.21913 19.7338 6.33648 19.5608C6.45382 19.3878 6.5125 19.1911 6.5125 18.9705C6.5125 18.75 6.45382 18.5542 6.33648 18.3831C6.21913 18.212 6.03378 18.0779 5.78042 17.981C5.52972 17.884 5.19902 17.8356 4.78831 17.8356H3.23614V22.9202H2Z"/>
<path d="M15.1684 22.9202V17.0798H18.0888C18.7235 17.0798 19.2502 17.1578 19.6689 17.3137C20.0903 17.4696 20.405 17.6854 20.613 17.961C20.8211 18.2348 20.9251 18.5513 20.9251 18.9106C20.9251 19.2681 20.8197 19.5827 20.609 19.8546C20.401 20.1245 20.0863 20.3346 19.6649 20.4848C19.2462 20.635 18.7195 20.7101 18.0848 20.7101H15.8725V19.9515H17.9728C18.3728 19.9515 18.6982 19.9106 18.9489 19.8289C19.2022 19.7471 19.3876 19.6283 19.5049 19.4724C19.6223 19.3165 19.6809 19.1293 19.6809 18.9106C19.6809 18.6901 19.6209 18.499 19.5009 18.3375C19.3836 18.1759 19.1982 18.0523 18.9449 17.9667C18.6942 17.8793 18.3648 17.8356 17.9568 17.8356H16.4046V22.9202H15.1684ZM19.2129 20.2852L21.2371 22.9202H19.829L17.8447 20.2852H19.2129Z"/>
<path d="M35.5087 20C35.5087 20.6236 35.3487 21.1597 35.0286 21.6084C34.7086 22.0551 34.2699 22.3992 33.7125 22.6407C33.1578 22.8802 32.527 23 31.8203 23C31.1109 23 30.4775 22.8802 29.9201 22.6407C29.3653 22.3992 28.928 22.0542 28.6079 21.6055C28.2879 21.1568 28.1279 20.6217 28.1279 20C28.1279 19.3764 28.2879 18.8413 28.6079 18.3945C28.928 17.9458 29.3653 17.6017 29.9201 17.3622C30.4775 17.1207 31.1109 17 31.8203 17C32.527 17 33.1578 17.1207 33.7125 17.3622C34.2699 17.6017 34.7086 17.9458 35.0286 18.3945C35.3487 18.8413 35.5087 19.3764 35.5087 20ZM34.2845 20C34.2845 19.5247 34.1765 19.1245 33.9605 18.7994C33.7472 18.4724 33.4538 18.2253 33.0804 18.058C32.7097 17.8888 32.2897 17.8042 31.8203 17.8042C31.3482 17.8042 30.9268 17.8888 30.5561 18.058C30.1854 18.2253 29.8921 18.4724 29.676 18.7994C29.4627 19.1245 29.356 19.5247 29.356 20C29.356 20.4753 29.4627 20.8764 29.676 21.2034C29.8921 21.5285 30.1854 21.7757 30.5561 21.9449C30.9268 22.1122 31.3482 22.1958 31.8203 22.1958C32.2897 22.1958 32.7097 22.1122 33.0804 21.9449C33.4538 21.7757 33.7472 21.5285 33.9605 21.2034C34.1765 20.8764 34.2845 20.4753 34.2845 20Z"/>
<path d="M43.0125 22.9202V17.0798H45.9328C46.5702 17.0798 47.0983 17.1625 47.517 17.3279C47.9357 17.4933 48.2491 17.7196 48.4571 18.0067C48.6651 18.2918 48.7691 18.6131 48.7691 18.9705C48.7691 19.3298 48.6638 19.653 48.4531 19.9401C48.2451 20.2253 47.9304 20.4515 47.509 20.6188C47.0903 20.7842 46.5636 20.8669 45.9288 20.8669H43.9206V20.1198H45.8168C46.2195 20.1198 46.5462 20.0703 46.7969 19.9715C47.0476 19.8707 47.2316 19.7338 47.349 19.5608C47.4663 19.3878 47.525 19.1911 47.525 18.9705C47.525 18.75 47.4663 18.5542 47.349 18.3831C47.2316 18.212 47.0463 18.0779 46.7929 17.981C46.5422 17.884 46.2115 17.8356 45.8008 17.8356H44.2486V22.9202H43.0125Z"/>
<path d="M56.181 22.9202V17.0798H61.3175V17.8384H57.4171V19.6179H61.0495V20.3736H57.4171V22.1616H61.3655V22.9202H56.181Z"/>
<path d="M68.9314 22.9202V17.0798H71.8517C72.4864 17.0798 73.0131 17.1578 73.4319 17.3137C73.8532 17.4696 74.1679 17.6854 74.376 17.961C74.584 18.2348 74.688 18.5513 74.688 18.9106C74.688 19.2681 74.5826 19.5827 74.372 19.8546C74.1639 20.1245 73.8492 20.3346 73.4279 20.4848C73.0091 20.635 72.4824 20.7101 71.8477 20.7101H69.6354V19.9515H71.7357C72.1357 19.9515 72.4611 19.9106 72.7118 19.8289C72.9651 19.7471 73.1505 19.6283 73.2678 19.4724C73.3852 19.3165 73.4439 19.1293 73.4439 18.9106C73.4439 18.6901 73.3838 18.499 73.2638 18.3375C73.1465 18.1759 72.9611 18.0523 72.7078 17.9667C72.4571 17.8793 72.1277 17.8356 71.7197 17.8356H70.1675V22.9202H68.9314ZM72.9758 20.2852L75 22.9202H73.5919L71.6077 20.2852H72.9758Z"/>
<path d="M81.7508 17.8384V17.0798H88.0915V17.8384H85.5352V22.9202H84.303V17.8384H81.7508Z"/>
<path d="M94.8232 17.0798H96.2274L98.3676 19.7348H98.4556L100.596 17.0798H102L99.0277 20.6274V22.9202H97.7955V20.6274L94.8232 17.0798Z"/>
</mask>
<path d="M2 22.9202V17.0798H4.92032C5.55772 17.0798 6.08578 17.1625 6.5045 17.3279C6.92321 17.4933 7.23658 17.7196 7.4446 18.0067C7.65262 18.2918 7.75663 18.6131 7.75663 18.9705C7.75663 19.3298 7.65129 19.653 7.4406 19.9401C7.23258 20.2253 6.91787 20.4515 6.49649 20.6188C6.07778 20.7842 5.55106 20.8669 4.91632 20.8669H2.9081V20.1198H4.80431C5.20702 20.1198 5.53372 20.0703 5.78442 19.9715C6.03511 19.8707 6.21913 19.7338 6.33648 19.5608C6.45382 19.3878 6.5125 19.1911 6.5125 18.9705C6.5125 18.75 6.45382 18.5542 6.33648 18.3831C6.21913 18.212 6.03378 18.0779 5.78042 17.981C5.52972 17.884 5.19902 17.8356 4.78831 17.8356H3.23614V22.9202H2Z" fill="white"/>
<path d="M15.1684 22.9202V17.0798H18.0888C18.7235 17.0798 19.2502 17.1578 19.6689 17.3137C20.0903 17.4696 20.405 17.6854 20.613 17.961C20.8211 18.2348 20.9251 18.5513 20.9251 18.9106C20.9251 19.2681 20.8197 19.5827 20.609 19.8546C20.401 20.1245 20.0863 20.3346 19.6649 20.4848C19.2462 20.635 18.7195 20.7101 18.0848 20.7101H15.8725V19.9515H17.9728C18.3728 19.9515 18.6982 19.9106 18.9489 19.8289C19.2022 19.7471 19.3876 19.6283 19.5049 19.4724C19.6223 19.3165 19.6809 19.1293 19.6809 18.9106C19.6809 18.6901 19.6209 18.499 19.5009 18.3375C19.3836 18.1759 19.1982 18.0523 18.9449 17.9667C18.6942 17.8793 18.3648 17.8356 17.9568 17.8356H16.4046V22.9202H15.1684ZM19.2129 20.2852L21.2371 22.9202H19.829L17.8447 20.2852H19.2129Z" fill="white"/>
<path d="M35.5087 20C35.5087 20.6236 35.3487 21.1597 35.0286 21.6084C34.7086 22.0551 34.2699 22.3992 33.7125 22.6407C33.1578 22.8802 32.527 23 31.8203 23C31.1109 23 30.4775 22.8802 29.9201 22.6407C29.3653 22.3992 28.928 22.0542 28.6079 21.6055C28.2879 21.1568 28.1279 20.6217 28.1279 20C28.1279 19.3764 28.2879 18.8413 28.6079 18.3945C28.928 17.9458 29.3653 17.6017 29.9201 17.3622C30.4775 17.1207 31.1109 17 31.8203 17C32.527 17 33.1578 17.1207 33.7125 17.3622C34.2699 17.6017 34.7086 17.9458 35.0286 18.3945C35.3487 18.8413 35.5087 19.3764 35.5087 20ZM34.2845 20C34.2845 19.5247 34.1765 19.1245 33.9605 18.7994C33.7472 18.4724 33.4538 18.2253 33.0804 18.058C32.7097 17.8888 32.2897 17.8042 31.8203 17.8042C31.3482 17.8042 30.9268 17.8888 30.5561 18.058C30.1854 18.2253 29.8921 18.4724 29.676 18.7994C29.4627 19.1245 29.356 19.5247 29.356 20C29.356 20.4753 29.4627 20.8764 29.676 21.2034C29.8921 21.5285 30.1854 21.7757 30.5561 21.9449C30.9268 22.1122 31.3482 22.1958 31.8203 22.1958C32.2897 22.1958 32.7097 22.1122 33.0804 21.9449C33.4538 21.7757 33.7472 21.5285 33.9605 21.2034C34.1765 20.8764 34.2845 20.4753 34.2845 20Z" fill="white"/>
<path d="M43.0125 22.9202V17.0798H45.9328C46.5702 17.0798 47.0983 17.1625 47.517 17.3279C47.9357 17.4933 48.2491 17.7196 48.4571 18.0067C48.6651 18.2918 48.7691 18.6131 48.7691 18.9705C48.7691 19.3298 48.6638 19.653 48.4531 19.9401C48.2451 20.2253 47.9304 20.4515 47.509 20.6188C47.0903 20.7842 46.5636 20.8669 45.9288 20.8669H43.9206V20.1198H45.8168C46.2195 20.1198 46.5462 20.0703 46.7969 19.9715C47.0476 19.8707 47.2316 19.7338 47.349 19.5608C47.4663 19.3878 47.525 19.1911 47.525 18.9705C47.525 18.75 47.4663 18.5542 47.349 18.3831C47.2316 18.212 47.0463 18.0779 46.7929 17.981C46.5422 17.884 46.2115 17.8356 45.8008 17.8356H44.2486V22.9202H43.0125Z" fill="white"/>
<path d="M56.181 22.9202V17.0798H61.3175V17.8384H57.4171V19.6179H61.0495V20.3736H57.4171V22.1616H61.3655V22.9202H56.181Z" fill="white"/>
<path d="M68.9314 22.9202V17.0798H71.8517C72.4864 17.0798 73.0131 17.1578 73.4319 17.3137C73.8532 17.4696 74.1679 17.6854 74.376 17.961C74.584 18.2348 74.688 18.5513 74.688 18.9106C74.688 19.2681 74.5826 19.5827 74.372 19.8546C74.1639 20.1245 73.8492 20.3346 73.4279 20.4848C73.0091 20.635 72.4824 20.7101 71.8477 20.7101H69.6354V19.9515H71.7357C72.1357 19.9515 72.4611 19.9106 72.7118 19.8289C72.9651 19.7471 73.1505 19.6283 73.2678 19.4724C73.3852 19.3165 73.4439 19.1293 73.4439 18.9106C73.4439 18.6901 73.3838 18.499 73.2638 18.3375C73.1465 18.1759 72.9611 18.0523 72.7078 17.9667C72.4571 17.8793 72.1277 17.8356 71.7197 17.8356H70.1675V22.9202H68.9314ZM72.9758 20.2852L75 22.9202H73.5919L71.6077 20.2852H72.9758Z" fill="white"/>
<path d="M81.7508 17.8384V17.0798H88.0915V17.8384H85.5352V22.9202H84.303V17.8384H81.7508Z" fill="white"/>
<path d="M94.8232 17.0798H96.2274L98.3676 19.7348H98.4556L100.596 17.0798H102L99.0277 20.6274V22.9202H97.7955V20.6274L94.8232 17.0798Z" fill="white"/>
<path d="M2 22.9202V17.0798H4.92032C5.55772 17.0798 6.08578 17.1625 6.5045 17.3279C6.92321 17.4933 7.23658 17.7196 7.4446 18.0067C7.65262 18.2918 7.75663 18.6131 7.75663 18.9705C7.75663 19.3298 7.65129 19.653 7.4406 19.9401C7.23258 20.2253 6.91787 20.4515 6.49649 20.6188C6.07778 20.7842 5.55106 20.8669 4.91632 20.8669H2.9081V20.1198H4.80431C5.20702 20.1198 5.53372 20.0703 5.78442 19.9715C6.03511 19.8707 6.21913 19.7338 6.33648 19.5608C6.45382 19.3878 6.5125 19.1911 6.5125 18.9705C6.5125 18.75 6.45382 18.5542 6.33648 18.3831C6.21913 18.212 6.03378 18.0779 5.78042 17.981C5.52972 17.884 5.19902 17.8356 4.78831 17.8356H3.23614V22.9202H2Z" stroke="black" stroke-width="0.2" mask="url(#path-2-outside-2_3_125)"/>
<path d="M15.1684 22.9202V17.0798H18.0888C18.7235 17.0798 19.2502 17.1578 19.6689 17.3137C20.0903 17.4696 20.405 17.6854 20.613 17.961C20.8211 18.2348 20.9251 18.5513 20.9251 18.9106C20.9251 19.2681 20.8197 19.5827 20.609 19.8546C20.401 20.1245 20.0863 20.3346 19.6649 20.4848C19.2462 20.635 18.7195 20.7101 18.0848 20.7101H15.8725V19.9515H17.9728C18.3728 19.9515 18.6982 19.9106 18.9489 19.8289C19.2022 19.7471 19.3876 19.6283 19.5049 19.4724C19.6223 19.3165 19.6809 19.1293 19.6809 18.9106C19.6809 18.6901 19.6209 18.499 19.5009 18.3375C19.3836 18.1759 19.1982 18.0523 18.9449 17.9667C18.6942 17.8793 18.3648 17.8356 17.9568 17.8356H16.4046V22.9202H15.1684ZM19.2129 20.2852L21.2371 22.9202H19.829L17.8447 20.2852H19.2129Z" stroke="black" stroke-width="0.2" mask="url(#path-2-outside-2_3_125)"/>
<path d="M35.5087 20C35.5087 20.6236 35.3487 21.1597 35.0286 21.6084C34.7086 22.0551 34.2699 22.3992 33.7125 22.6407C33.1578 22.8802 32.527 23 31.8203 23C31.1109 23 30.4775 22.8802 29.9201 22.6407C29.3653 22.3992 28.928 22.0542 28.6079 21.6055C28.2879 21.1568 28.1279 20.6217 28.1279 20C28.1279 19.3764 28.2879 18.8413 28.6079 18.3945C28.928 17.9458 29.3653 17.6017 29.9201 17.3622C30.4775 17.1207 31.1109 17 31.8203 17C32.527 17 33.1578 17.1207 33.7125 17.3622C34.2699 17.6017 34.7086 17.9458 35.0286 18.3945C35.3487 18.8413 35.5087 19.3764 35.5087 20ZM34.2845 20C34.2845 19.5247 34.1765 19.1245 33.9605 18.7994C33.7472 18.4724 33.4538 18.2253 33.0804 18.058C32.7097 17.8888 32.2897 17.8042 31.8203 17.8042C31.3482 17.8042 30.9268 17.8888 30.5561 18.058C30.1854 18.2253 29.8921 18.4724 29.676 18.7994C29.4627 19.1245 29.356 19.5247 29.356 20C29.356 20.4753 29.4627 20.8764 29.676 21.2034C29.8921 21.5285 30.1854 21.7757 30.5561 21.9449C30.9268 22.1122 31.3482 22.1958 31.8203 22.1958C32.2897 22.1958 32.7097 22.1122 33.0804 21.9449C33.4538 21.7757 33.7472 21.5285 33.9605 21.2034C34.1765 20.8764 34.2845 20.4753 34.2845 20Z" stroke="black" stroke-width="0.2" mask="url(#path-2-outside-2_3_125)"/>
<path d="M43.0125 22.9202V17.0798H45.9328C46.5702 17.0798 47.0983 17.1625 47.517 17.3279C47.9357 17.4933 48.2491 17.7196 48.4571 18.0067C48.6651 18.2918 48.7691 18.6131 48.7691 18.9705C48.7691 19.3298 48.6638 19.653 48.4531 19.9401C48.2451 20.2253 47.9304 20.4515 47.509 20.6188C47.0903 20.7842 46.5636 20.8669 45.9288 20.8669H43.9206V20.1198H45.8168C46.2195 20.1198 46.5462 20.0703 46.7969 19.9715C47.0476 19.8707 47.2316 19.7338 47.349 19.5608C47.4663 19.3878 47.525 19.1911 47.525 18.9705C47.525 18.75 47.4663 18.5542 47.349 18.3831C47.2316 18.212 47.0463 18.0779 46.7929 17.981C46.5422 17.884 46.2115 17.8356 45.8008 17.8356H44.2486V22.9202H43.0125Z" stroke="black" stroke-width="0.2" mask="url(#path-2-outside-2_3_125)"/>
<path d="M56.181 22.9202V17.0798H61.3175V17.8384H57.4171V19.6179H61.0495V20.3736H57.4171V22.1616H61.3655V22.9202H56.181Z" stroke="black" stroke-width="0.2" mask="url(#path-2-outside-2_3_125)"/>
<path d="M68.9314 22.9202V17.0798H71.8517C72.4864 17.0798 73.0131 17.1578 73.4319 17.3137C73.8532 17.4696 74.1679 17.6854 74.376 17.961C74.584 18.2348 74.688 18.5513 74.688 18.9106C74.688 19.2681 74.5826 19.5827 74.372 19.8546C74.1639 20.1245 73.8492 20.3346 73.4279 20.4848C73.0091 20.635 72.4824 20.7101 71.8477 20.7101H69.6354V19.9515H71.7357C72.1357 19.9515 72.4611 19.9106 72.7118 19.8289C72.9651 19.7471 73.1505 19.6283 73.2678 19.4724C73.3852 19.3165 73.4439 19.1293 73.4439 18.9106C73.4439 18.6901 73.3838 18.499 73.2638 18.3375C73.1465 18.1759 72.9611 18.0523 72.7078 17.9667C72.4571 17.8793 72.1277 17.8356 71.7197 17.8356H70.1675V22.9202H68.9314ZM72.9758 20.2852L75 22.9202H73.5919L71.6077 20.2852H72.9758Z" stroke="black" stroke-width="0.2" mask="url(#path-2-outside-2_3_125)"/>
<path d="M81.7508 17.8384V17.0798H88.0915V17.8384H85.5352V22.9202H84.303V17.8384H81.7508Z" stroke="black" stroke-width="0.2" mask="url(#path-2-outside-2_3_125)"/>
<path d="M94.8232 17.0798H96.2274L98.3676 19.7348H98.4556L100.596 17.0798H102L99.0277 20.6274V22.9202H97.7955V20.6274L94.8232 17.0798Z" stroke="black" stroke-width="0.2" mask="url(#path-2-outside-2_3_125)"/>
</svg>
`
}

export default Icon
