import {hasClass} from './lb';

function navBtnClick() {
    const buttons = document.querySelectorAll('.nav-universes-list li.nav-universe');
    Object.values(buttons).map((v) => {
        if (v) {
            v.addEventListener('click', function (e) {
                const _this = e.target;
                const elemParent = _this.parentNode;
                const elemMainParent = _this.parentNode.parentNode.parentNode;
                const elemClass = _this.getAttribute('class') !== null?_this.getAttribute('class'):'';
                const elems = document.querySelectorAll('.nav-universes-list li.nav-universe');
                for (const elem of elems) {
                    if (hasClass(elemParent, 'active') === false) {
                        elem.classList.remove('active');
                    }
                }
                if (elemClass.indexOf('mm-close') < 0) {
                    if (hasClass(elemParent, 'active') === false) {
                        elemMainParent.classList.add('active');
                    }
                }
                setTimeout(function() {
                    if (elemClass.indexOf('mm-close') < 0) {
                        if (hasClass(elemParent, 'active') === false) {
                            elemParent.classList.add('active');
                        }
                    }
                }, 100);
                e.stopImmediatePropagation();
            });
        }
        return true;
    });
    const closeButtons = document.querySelectorAll('.mm-close label');
    Object.values(closeButtons).map((v) => {
        if (v) {
            v.addEventListener('click', function (e) {
                const navUniversesLists = document.querySelectorAll('.nav-universes');
                for (const navUniversesList of navUniversesLists) {
                    setTimeout(function() {navUniversesList.classList.remove('active');}, 100);
                }
                e.stopImmediatePropagation();
            });
        }
        return true;
    });
}

export default class {
    constructor(context) {
        this.context = context;
        this.Nav();
    }

    Nav() {
        navBtnClick();
    }
}
