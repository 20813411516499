export const testData = {
    data: [
        {
            "image": "../externals/img/story-mobile.jpg",
            "text": "1 Arts, social sciences and communications"
        },
        {
            "image": "../externals/img/story-mobile.jpg",
            "text": "2 Business and commerce"
        },
        {
            "image": "../externals/img/story-mobile.jpg",
            "text": "3 Criminology"
        },
        {
            "image": "../externals/img/story-mobile.jpg",
            "text": "4 Education and teaching"
        },
        {
            "image": "../externals/img/story-mobile.jpg",
            "text": "5 Social and communications"
        }
    ]
};
