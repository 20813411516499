import { testData } from './testData';
import SVGs from '../../_global/SVGs/js/svgs';

function ContactCTAsectionCCT() {
    let genHTML = ''; let cnt = 0;
    const dataElems = document.querySelectorAll('[data-ContactCTAsection]');
    for (const dataElem of dataElems) {
        const dataJsonOrigin = dataElem.getAttribute('data-json') !== null && dataElem.getAttribute('data-json') !== ''? JSON.parse(dataElem.getAttribute('data-json')):testData;
        const dataSettings = dataJsonOrigin.settings?dataJsonOrigin.settings:testData.settings;
        let dataJsons = [];
        const dataItems = dataElem.querySelectorAll('[item]');
        for (const dataItem of dataItems) {
            const icon = dataItem.querySelector('icon') && dataItem.querySelector('icon').innerHTML !== ''? JSON.parse(dataItem.querySelector('icon').innerHTML)[0]:'';
            const button_url = dataItem.querySelector('buttonurl') && dataItem.querySelector('button_url').innerHTML.trim() !== ''? JSON.parse(dataItem.querySelector('button_url').innerHTML)[0]:'';
            dataJsons.push({
                icon: icon,
                headline: dataItem.querySelector('headline')? dataItem.querySelector('headline').innerHTML:'',
                content: dataItem.querySelector('content')? dataItem.querySelector('content').innerHTML:'',
                button_text: dataItem.querySelector('button_text')? dataItem.querySelector('button_text').innerHTML:'',
                button_url: button_url,
                button_ex_url: dataItem.querySelector('button_ex_url')? dataItem.querySelector('button_ex_url').innerHTML:''
            });
        }
        if (dataJsons.length < 1) {
            dataJsons = testData.data;
        }
        console.log(dataJsons)
        if (dataSettings.display === 'visible') {
            genHTML = `<div class="ContactCTAsection--wrapper" version="${dataSettings.version}" ContactCTAsection>`;
            genHTML += dataSettings && (dataSettings.title !== '' && dataSettings.title !== null && dataSettings.title !== undefined)?`<h2 class="title">${dataSettings.title}</h2>`: '';
            genHTML += `<div class="ContactCTAsection--wrapper--container">`;
                for (const dataJson of dataJsons) {
                    genHTML += `<div class="ContactCTAsection--wrapper--container--item">`;
                    if (dataJson.icon !== '' && dataJson.icon !== null && dataJson.icon !== undefined) {
                        genHTML += `<div class="bx-item bx-item--image bx-image">
                            <img src="${dataJson.icon}" />
                        </div>`;
                    }
                    genHTML += `<div class="bx-item bx-item--content bx-content">
                        <div class="bx-item--content--top">`;
                        if (dataJson.headline !== '' && dataJson.headline !== null && dataJson.headline !== undefined) {
                            const headerTag = dataSettings && dataSettings.version !== '4 columns'?'h3':'h4';
                            genHTML += `<${headerTag} class="item-title">${dataJson.headline}</${headerTag}>`;
                        }
                        if (dataJson.content !== '' && dataJson.content !== null && dataJson.content !== undefined) {
                            genHTML += `<div>${dataJson.content}</div>`;
                        }
                        genHTML += `</div>`;
                        const buttonUrl = dataJson.button_ex_url !== '' && dataJson.button_ex_url !== null && dataJson.button_ex_url !== undefined
                        ? dataJson.button_ex_url
                        : dataJson.button_url !== '' && dataJson.button_url !== null && dataJson.button_url !== undefined
                        ? dataJson.button_url
                        : '';
                    if (buttonUrl !== '' && buttonUrl !== null && buttonUrl !== undefined) {
                        const buttonText = dataJson && (dataJson.button_text !== '' && dataJson.button_text !== null && dataJson.button_text !== undefined)?dataJson.button_text: 'goto';
                        genHTML += `
                        <div class="bx-item--content--bottom"><a class="ds-btn-link ds-icon-chevron-right" href="${buttonUrl}">${buttonText}</a></div>`;
                    }
                    genHTML += `</div>
                    </div>`;
                }
            genHTML += `</div>`;
            if (dataSettings.button_url !== '' && dataSettings.button_url !== null && dataSettings.button_url !== undefined) {
                const buttonMainText = dataSettings && (dataSettings.button_text !== '' && dataSettings.button_text !== null && dataSettings.button_text !== undefined)?dataSettings.button_text: 'goto';
                genHTML += `
                <div class="ContactCTAsection--wrapper--button"><a class="ds-btn ds-btn-primary" href="${dataSettings.button_url}">${buttonMainText}</a></div>`;
            }
            genHTML += `</div>`;
            dataElem.innerHTML = genHTML;
            dataElem.setAttribute('background', dataSettings.background);
        }
        dataElem.removeAttribute('data-ContactCTAsection');
        if (dataElem && dataElem.getAttribute('data-json')) {
            dataElem.removeAttribute('data-json');
        }
        cnt++;
    }
}
(function(){
    'use strict';
    ContactCTAsectionCCT();
    new SVGs();
}());
