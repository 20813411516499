export const testData = {
    settings: {
        display: "visible",
        version: "version 4",
        title: "Start your studies at La Trobe",
        image: "../externals/img/aron-visuals.jpg",
        helpText: "Need help deciding?",
        helpUrl: "https://wwwdev.latrobe.edu.au/courses/help"
    },
    data: [
        {
            text: "Arts, social sciences and communications",
            url: "https://wwwdev.latrobe.edu.au/courses/study-arts-social-sciences-and-communications"
        },
        {
            text: "Criminology",
            url: "https://wwwdev.latrobe.edu.au/courses/study-criminology"
        },
        {
            text: "Engineering",
            url: "https://wwwdev.latrobe.edu.au/courses/study-engineering"
        },
        {
            text: "Information Technology",
            url: "https://wwwdev.latrobe.edu.au/courses/study-information-technology"
        },
        {
            text: "Science",
            url: "https://wwwdev.latrobe.edu.au/courses/study-science"
        },
        {
            text: "Business and commerce",
            url: "https://wwwdev.latrobe.edu.au/courses/study-business-and-commerce"
        },
        {
            text: "Education and teaching",
            url: "https://wwwdev.latrobe.edu.au/courses/study-education-teaching"
        },
        {
            text: "Health",
            url: "https://wwwdev.latrobe.edu.au/courses/study-health"
        },
        {
            text: "Law",
            url: "https://wwwdev.latrobe.edu.au/courses/study-law"
        }
    ]
};

