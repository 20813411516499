const Icon = () => {
return `<svg xmlns="http://www.w3.org/2000/svg" width="30" height="26" viewBox="0 0 30 26" fill="none">
<path d="M22.2469 12.6877C21.0768 12.4326 21.6618 11.8373 21.6618 11.8373C21.6618 11.8373 22.832 9.88115 21.4111 8.52038C19.7395 6.81943 15.5606 8.77553 15.5606 8.77553C13.9726 9.28582 14.3904 8.52038 14.6412 7.32971C14.6412 5.8839 14.1397 3.41751 9.96075 4.86332C5.78179 6.30914 2.18788 11.4971 2.18788 11.4971C-0.3195 14.899 0.0148174 17.5355 0.0148174 17.5355C0.599872 23.3187 6.70116 24.9346 11.4652 25.3599C16.3964 25.7851 23.1663 23.5739 25.1722 19.2364C27.0945 14.6438 23.417 12.9429 22.2469 12.6877ZM11.7159 23.5739C6.78474 23.829 2.77293 21.2776 2.77293 17.9607C2.77293 14.6438 6.78474 11.9223 11.7159 11.6672C16.6471 11.412 20.6589 13.5382 20.6589 16.8551C20.6589 20.172 16.6471 23.3187 11.7159 23.5739Z" fill="white"/>
<path d="M24.6701 10.6464C25.088 10.6464 25.4223 10.3062 25.5059 9.9661V9.88107C26.0909 4.35394 21.0761 5.2893 21.0761 5.2893C20.6583 5.2893 20.2404 5.62943 20.2404 6.13963C20.2404 6.56479 20.5747 6.98996 21.0761 6.98996C24.7536 6.13963 23.9178 9.88107 23.9178 9.88107C23.8343 10.2212 24.1686 10.6464 24.6701 10.6464Z" fill="white"/>
<path d="M24.0038 0.951311C22.2486 0.526072 20.4099 0.866263 19.9084 0.951311C19.9084 0.951311 19.8248 0.951311 19.8248 1.03636C19.3233 1.20646 18.989 1.63169 18.989 2.22703C18.989 2.90741 19.4905 3.4177 20.1591 3.4177C20.1591 3.4177 20.8278 3.33265 21.2457 3.16256C21.6636 2.99246 25.341 2.99246 27.0962 6.13924C28.0992 8.35048 27.5141 9.88135 27.4305 10.1365C27.4305 10.1365 27.1798 10.7318 27.1798 11.3272C27.1798 12.0075 27.6813 12.4328 28.3499 12.4328C28.9349 12.4328 29.3528 12.3477 29.52 11.4122C31.4423 4.77847 27.1798 1.63169 24.0038 0.951311Z" fill="white"/>
<path d="M10.7118 13.8785C5.78058 14.4738 6.36563 19.2365 6.36563 19.2365C6.36563 19.2365 6.28206 20.7674 7.7029 21.5328C10.6282 23.1487 13.5535 22.1281 15.1415 20.172C16.5623 18.1309 15.7265 13.2832 10.7118 13.8785ZM9.45807 20.5122C8.5387 20.5973 7.78648 20.087 7.78648 19.3216C7.78648 18.5561 8.45512 17.7057 9.37449 17.6206C10.461 17.5356 11.1297 18.1309 11.1297 18.8963C11.1297 19.6618 10.3774 20.4272 9.45807 20.5122ZM12.3833 17.9608C12.049 18.2159 11.7147 18.1309 11.5475 17.8757C11.3804 17.6206 11.464 17.1103 11.7147 16.9402C12.049 16.6851 12.4669 16.7701 12.6341 17.0253C12.8012 17.2804 12.7177 17.7057 12.3833 17.9608Z" fill="white"/>
</svg>
`
}

export default Icon
