import { testData } from './testData';
import SVGs from '../../_global/SVGs/js/svgs';
import { SearchGlobal } from "../../funnelbackSearch/js/global";

function FindYourCourseCCT() {
    let genHTML = ''; let cnt = 0;
    const dataElems = document.querySelectorAll('[data-FindYourCourse]');
    for (const dataElem of dataElems) {
        const dataAttrJsons = dataElem && dataElem.getAttribute('data-json')? JSON.parse(dataElem.getAttribute('data-json')):testData;
        const dataSettings = dataAttrJsons && dataAttrJsons.settings?dataAttrJsons.settings:testData.settings;
        const display = dataSettings && dataSettings.display?dataSettings.display:'visible';
        const dataItems = dataElem.querySelectorAll('[item]');
        let dataJsons = [];
        if (display === 'visible') {
        genHTML = `<div class="FindYourCourse--wrapper" data-id="${cnt}">`;
        const title = dataSettings && (dataSettings.title !== '' && dataSettings.title !== null && dataSettings.title !== undefined)?`<h2 class="title">${dataSettings.title}</h2>`: '';
        const funnelback = dataSettings && dataSettings.search ? {
        search: dataSettings.search && dataSettings.search.search?dataSettings.search.search:'',
        suggest: dataSettings.search && dataSettings.search.suggest?dataSettings.search.suggest:'',
        tracking: dataSettings.search && dataSettings.search.tracking?dataSettings.search.tracking:''
        }: [];
        const jsonFB = JSON.stringify(funnelback).replace(/"/g, '&quot;');
        genHTML += `${title}
                <form action="${dataSettings && dataSettings.search && dataSettings.search.url?dataSettings.search.url:''}" method="GET" class="FindYourCourse--form" role="search" novalidate="novalidate" data-funnelback="${jsonFB}">
                    <label for="search-query">${dataSettings.title}</label>
                    <input type="text" placeholder="${dataSettings&&dataSettings.search&&dataSettings.placeholder?dataSettings.placeholder:''}" name="query" value="" title="query" />
                    <input type="hidden" name="collection" value="${dataSettings&&dataSettings.search&&dataSettings.search.collection?dataSettings.search.collection:''}" />
                    <input type="hidden" name="searchtype" value="${dataSettings&&dataSettings.search&&dataSettings.search.searchtype?dataSettings.search.searchtype:''}" />
                    <svgs data-svg="icon-x-circle" fb-clear></svgs>
                    <button type="submit" class="search__submit search__submit--icon">
                        <div class="btn-group ds-btn-primary">`;
                if (dataSettings.search && dataSettings.search.btnText) {
                    genHTML += `<span class="txt">${dataSettings && dataSettings.search.btnText?dataSettings.search.btnText:''}</span>`;
                }
                    genHTML += `<svgs data-svg="icon-search"></svgs>
                        </div>
                    </button>
                    <div class="funnelback-suggest--wrapper" data-suggest></div>
                </form>
                <h4 class="sub-title">${dataSettings.subTitle}</h4>
                <ul class="ds-link-list ds-link-list--2-cols">`;
            for (const dataItem of dataItems) {
                const btnUrl = dataItem.querySelector('url') && dataItem.querySelector('url').innerHTML !== ''? JSON.parse(dataItem.querySelector('url').innerHTML)[0]:'';
                dataJsons.push({
                    text: dataItem.querySelector('text')? dataItem.querySelector('text').innerHTML:'',
                    url: btnUrl,
                });
            }
            if (dataJsons.length < 1) {
                dataJsons = testData.data;
            }
            for (const dataJson of dataJsons) {
                genHTML += `<li class="ds-link-list__item">
                <a class="ds-link-list__link" href="${dataJson.url}" target="_self">${dataJson.text}</a>
                </li>`;
            }
        genHTML += `<li class="ds-link-list__item">
                <a class="ds-link-list__info" href="${dataSettings.helpUrl}" data-component="ds-course-search">${dataSettings.helpText}<svgs data-svg="icon-info"></svgs></a>
                </li>
            </ul>
        </div>`;
        dataElem.innerHTML = genHTML;
        SearchGlobal();
        }
        dataElem.removeAttribute('data-FindYourCourse');
        if (dataElem && dataElem.getAttribute('data-json')) {
            dataElem.removeAttribute('data-json');
        }
        cnt++;
    }
}
(function(){
    'use strict';
    FindYourCourseCCT();
    new SVGs();
}());
