export const testData = {
    settings: {
        "version": "default"
//         "version": "small"
    },
    data: [
        {
            "id": 0,
            "title": "Test data Title",
            "content": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis tempus leo convallis metus cursus tempor. Sed accumsan, mi eu congue blandit, sapien urna luctus ligula, ut posuere risus turpis sagittis dui. Fusce porttitor commodo fringilla. Phasellus velit neque, tristique id convallis ut, dictum nec arcu. Duis ornare id massa at tincidunt",
            "button_url": "/page-1",
            "button_ext_url": "",
            "button_text": "This is a button"
        },
        {
            "id": 1,
            "title": "Test data Title 2",
            "content": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis tempus leo convallis metus cursus tempor. Sed accumsan, mi eu congue blandit, sapien urna luctus ligula, ut posuere risus turpis sagittis dui. Fusce porttitor commodo fringilla. Phasellus velit neque, tristique id convallis ut, dictum nec arcu. Duis ornare id massa at tincidunt",
            "button_url": "",
            "button_ext_url": "",
            "button_text": "Content here"
        },
        {
            "id": 2,
            "title": "Test data Title 3",
            "content": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis tempus leo convallis metus cursus tempor. Sed accumsan, mi eu congue blandit, sapien urna luctus ligula, ut posuere risus turpis sagittis dui. Fusce porttitor commodo fringilla. Phasellus velit neque, tristique id convallis ut, dictum nec arcu. Duis ornare id massa at tincidunt",
            "button_url": "",
            "button_ext_url": "https://www.latrobe.edu.au/",
            "button_text": "Content here"
        }
    ],
    buttons: [
        "https:\/\/wwwdev.latrobe.edu.au\/news",
        "https:\/\/wwwdev.latrobe.edu.au\/research"
    ]
};
