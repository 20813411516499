import { testData } from './testData';

function SmallPrintFooter() {
    let genHTML = ''; let cnt = 0;
    const dataElems = document.querySelectorAll('[data-footer-smallprint]');
    for (const dataElem of dataElems) {
        const dataJson = dataElem.getAttribute('data-json') !== null && dataElem.getAttribute('data-json') !== ''? JSON.parse(dataElem.getAttribute('data-json'))[cnt]:testData.data[cnt];
        if (dataJson.display === "yes") {
            genHTML = `
            <div class="content">
                <h3 class="title">${dataJson.headline}</h3>
                <div class="txt">${dataJson.content}</div>
            </div>
            `;
            dataElem.innerHTML = genHTML;
            dataElem.removeAttribute('data-footer-smallprint');
            dataElem.removeAttribute('data-json');
        } else {
            dataElem.remove();
        }
        cnt++;
    }
}
(function(){
    'use strict';
    SmallPrintFooter();
}());
