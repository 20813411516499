export const testData = {
    settings: {
        display: "visible",
        title: "Find your Course",
        subTitle: "Browse by topic",
        helpText: "Need help deciding?",
        helpUrl: "https://wwwdev.latrobe.edu.au/courses/help",
        search: {
            btnText: "Search",
            placeholder: "Search for a course by keyword ",
            search: "https://search.latrobe.edu.au/s/search.json",
            suggest: "https://search.latrobe.edu.au/s/suggest.json",
            tracking: "https://search.latrobe.edu.au/s/redirect",
            url: "/search.html",
            collection: "Latrobe-search",
            searchtype: "global",
        }
    },
    data: [
        {
            text: "Arts, social sciences and communications",
            url: "https://wwwdev.latrobe.edu.au/courses/study-arts-social-sciences-and-communications"
        },
        {
            text: "Criminology",
            url: "https://wwwdev.latrobe.edu.au/courses/study-criminology"
        },
        {
            text: "Engineering",
            url: "https://wwwdev.latrobe.edu.au/courses/study-engineering"
        },
        {
            text: "Information Technology",
            url: "https://wwwdev.latrobe.edu.au/courses/study-information-technology"
        },
        {
            text: "Science",
            url: "https://wwwdev.latrobe.edu.au/courses/study-science"
        },
        {
            text: "Business and commerce",
            url: "https://wwwdev.latrobe.edu.au/courses/study-business-and-commerce"
        },
        {
            text: "Education and teaching",
            url: "https://wwwdev.latrobe.edu.au/courses/study-education-teaching"
        },
        {
            text: "Health",
            url: "https://wwwdev.latrobe.edu.au/courses/study-health"
        },
        {
            text: "Law",
            url: "https://wwwdev.latrobe.edu.au/courses/study-law"
        }
    ]
};
