import iconMissing from './icons/icon-missing';
import iconLogo from './icons/icon-logo';
import iconLogoWhite from './icons/icon-logo-white';
import iconArrow from './icons/icon-arrow';
import iconSearch from './icons/icon-search';
import iconClose from './icons/icon-close';
import iconBlog from './icons/icon-blog';
import iconYoutube from './icons/icon-youtube';
import iconFacebook from './icons/icon-facebook';
import iconInstagram from './icons/icon-instagram';
import iconTwitter from './icons/icon-twitter';
import iconXTwitter from './icons/icon-xtwitter';
import iconLinkedIn from './icons/icon-linkedin';
import iconWeibo from './icons/icon-weibo';
import iconWeChat from './icons/icon-wechat';
import iconTikTok from './icons/icon-tiktok';

import iconFlower from './icons/icon-flower';
import iconHealth from './icons/icon-health';
import iconPens from './icons/icon-pens';
import iconEagle from './icons/icon-eagle';
import iconChevronRight from './icons/icon-chevron-right';
import iconPlus from './icons/icon-plus';
import iconDash from './icons/icon-dash';
import iconMagnify from './icons/icon-magnify';
import iconHamburger from './icons/icon-hamburger';
import iconStar from './icons/icon-star';
import iconInfo from './icons/icon-info';
import iconUpDown from './icons/Icon-chevron-up-and-down';
import iconX from './icons/icon-x';
import iconXcircle from './icons/icon-xCircle';
import iconLoader from './icons/icon-loader';
import iconPlay from './icons/icon-play';

function dataSvg() {
    const SVGs = document.querySelectorAll('svgs');
    let svgVal = '';
    for (const SVG of SVGs) {
        const svgType = SVG.getAttribute('data-svg');
          switch (svgType) {
            case 'icon-search':
                svgVal = iconSearch;
                break;
            case 'icon-close':
                svgVal = iconClose;
                break;
            case 'icon-arrow':
                svgVal = iconArrow;
                break;
            case 'icon-logo':
                svgVal = iconLogo;
                break;
            case 'icon-logo-white':
                svgVal = iconLogoWhite;
                break;
            case 'icon-blog':
                svgVal = iconBlog;
                break;
            case 'icon-read-our-blog':
                svgVal = iconBlog;
                break;
            case 'icon-facebook':
                svgVal = iconFacebook;
                break;
            case 'icon-twitter':
                svgVal = iconTwitter;
                break;
            case 'icon-xtwitter':
                svgVal = iconXTwitter;
                break;
            case 'icon-linkedin':
                svgVal = iconLinkedIn;
                break;
            case 'icon-youtube':
                svgVal = iconYoutube;
                break;
            case 'icon-weibo':
                svgVal = iconWeibo;
                break;
            case 'icon-wechat':
                svgVal = iconWeChat;
                break;
            case 'icon-instagram':
                svgVal = iconInstagram;
                break;
            case 'icon-tiktok':
                svgVal = iconTikTok;
                break;
            case 'icon-flower':
                svgVal = iconFlower;
            case 'icon-health':
                svgVal = iconHealth;
                break;
            case 'icon-pens':
                svgVal = iconPens;
            case 'icon-education':
                svgVal = iconPens;
                break;
            case 'icon-eagle':
                svgVal = iconEagle;
                break;
            case 'icon-chevron-right':
                svgVal = iconChevronRight;
                break;
            case 'icon-plus':
                svgVal = iconPlus;
                break;
            case 'icon-dash':
                svgVal = iconDash;
                break;
            case 'icon-magnify':
                svgVal = iconMagnify;
                break;
            case 'icon-hamburger':
                svgVal = iconHamburger;
                break;
            case 'icon-star':
                svgVal = iconStar;
                break;
            case 'icon-info':
                svgVal = iconInfo;
                break;
            case 'icon-updown':
                svgVal = iconUpDown;
                break;
            case 'icon-x':
                svgVal = iconX;
                break;
            case 'icon-x-circle':
                svgVal = iconXcircle;
                break;
            case 'icon-loader':
                svgVal = iconLoader;
                break;
            case 'icon-play':
                svgVal = iconPlay;
                break;
            default:
                svgVal = iconMissing;
                break;
          }
          SVG.innerHTML = svgVal();
    }
}

export default class {
    constructor(context) {
        this.context = context;
        this.SVGs();
    }

    SVGs() {
        dataSvg();
    }
}
