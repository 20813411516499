const Icon = () => {
return `<svg class="icon-arrow" xmlns="http://www.w3.org/2000/svg" width="23.25" height="42.25">
    <defs>
        <filter id="a">
            <feFlood flood-color="#ffffff" flood-opacity="1" result="floodOut"/>
            <feComposite in="floodOut" in2="SourceGraphic" operator="atop" result="compOut"/>
            <feBlend in="compOut" in2="SourceGraphic"/>
        </filter>
    </defs>
    <path fill="none" stroke="#ffffff" stroke-width="1.5" d="M21.752 1.807L2.908 20.249l18.844 18.446" filter="url(#a)"/>
    <path fill="none" stroke="#ffffff" stroke-width="1.5" d="M21.752 1.807L2.908 20.249l18.844 18.446"/>
</svg>
`
}

export default Icon
