const Icon = () => {
return `<svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
<g clip-path="url(#clip0_3849_435)">
<path d="M12.45 23C18.5251 23 23.45 18.0751 23.45 12C23.45 5.92487 18.5251 1 12.45 1C6.37482 1 1.44995 5.92487 1.44995 12C1.44995 18.0751 6.37482 23 12.45 23Z" stroke="#00AA98" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<rect x="11.45" y="6" width="2" height="2" fill="#00AA98"/>
<rect x="11.45" y="11" width="2" height="7" fill="#00AA98"/>
</g>
<defs>
<clipPath id="clip0_3849_435">
<rect width="24" height="24" fill="white" transform="translate(0.449951)"/>
</clipPath>
</defs>
</svg>
`
}

export default Icon
