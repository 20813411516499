import { testData } from './testData';
import SVGs from '../../_global/SVGs/js/svgs';

function ProofPointsCCT() {
    let genHTML = ''; let cnt = 0;
    const dataElems = document.querySelectorAll('[data-ProofPoints]');
    for (const dataElem of dataElems) {
        const dataJsonOrigin = dataElem.getAttribute('data-json') !== null && dataElem.getAttribute('data-json') !== ''? JSON.parse(dataElem.getAttribute('data-json')):testData;
        const dataSettings = dataJsonOrigin.settings?dataJsonOrigin.settings:testData.settings;
        let dataJsons = [];
        const dataItems = dataElem.querySelectorAll('[item]');
        for (const dataItem of dataItems) {
            const iconUrl = dataItem.querySelector('icon') && dataItem.querySelector('icon').innerHTML !== ''? JSON.parse(dataItem.querySelector('icon').innerHTML)[0]:'';
            const url = dataItem.querySelector('url') && dataItem.querySelector('icon').innerHTML !== ''? JSON.parse(dataItem.querySelector('url').innerHTML)[0]:'';
            dataJsons.push({
                headline: dataItem.querySelector('headline')? dataItem.querySelector('headline').innerHTML:'',
                content: dataItem.querySelector('content')? dataItem.querySelector('content').innerHTML:'',
                icon: iconUrl,
                url: url
            });
        }
        if (dataJsons.length < 1) {
            dataJsons = testData.data;
        }
        if (dataSettings.display === 'visible') {
            genHTML = `<div class="ProofPoints--wrapper" type="${dataSettings.type}" data-id="${cnt}" ProofPoints-container>`;
            if (dataSettings.title !== '' && dataSettings.title !== null && dataSettings.title !== undefined) {
                genHTML += `<h2 class="title">${dataSettings.title}</h2>`;
            }
            const subfix = '';
            genHTML += `<div class="ProofPoints--wrapper--group">`;
                for (const dataJson of dataJsons) {
                    genHTML += `<div class="bx-item">
                    <div class="item item--image bx-image">
                        <img src="${dataJson.icon}${subfix}" />
                    </div>
                    <div class="item item--content bx-content">
                        <h3 class="title">${dataJson.headline}</h3>
                        <div class="content">${dataJson.content}</div>
                    </div>`;
                    if (dataJson.url !== null && dataJson.url !== '' && dataJson.url !== undefined) {
                        genHTML += `<a href="${dataJson.url}" class="btn-linkfull"></a>`;
                    }
                    genHTML += `
                    </div>`;
                }
            genHTML += `</div>`;
            if (dataSettings.footnote !== '' && dataSettings.footnote !== null && dataSettings.footnote !== undefined) {
                genHTML += `<div class="ProofPoints--wrapper--footnote">`;
                genHTML += dataSettings.footnote;
                genHTML += `</div>`;
            }
            genHTML += `</div>`;
            dataElem.innerHTML = genHTML;
        }
        dataElem.removeAttribute('data-ProofPoints');
        if (dataElem && dataElem.getAttribute('data-json')) {
            dataElem.removeAttribute('data-json');
        }
        cnt++;
    }
}
(function(){
    'use strict';
    ProofPointsCCT();
    new SVGs();
}());
