import SVGs from '../../SVGs/js/svgs';

function genBreadcrumbs(headerVersion) {
    const body = document.querySelector('body');
    const headerNav = body.querySelector('.header-nav');
    const Breadcrumb = document.querySelector('[data-breadcrumbs]');
    if (Breadcrumb) {
        Breadcrumb.classList.remove('template');
        posBreadcrumbs(Breadcrumb, headerNav, headerVersion);
        if (headerVersion === 'Transparent') {
            Breadcrumb.remove();
        }
    }
    new SVGs();
}
function posBreadcrumbs(Breadcrumb, headerNav, headerVersion) {
    const newBreadcrumb = document.createElement('div');
    newBreadcrumb.classList = 'breadcrumbs-wrapper';
    newBreadcrumb.setAttribute('breadcrumbs-version', headerVersion);
    newBreadcrumb.setAttribute('data-breadcrumbs', true);
    if (headerVersion === 'Transparent') {
        newBreadcrumb.innerHTML = Breadcrumb.innerHTML;
        headerNav.append(newBreadcrumb);
    }
    const mainElement = document.querySelector('main');
    const heroBanner = mainElement.querySelector('[ds-hero-promo]');
    if (heroBanner) {
        mainElement.insertBefore(Breadcrumb, mainElement.children[1]);
    } else {
        mainElement.insertBefore(Breadcrumb, mainElement.children[0]);
    }
}

export {
  genBreadcrumbs
};
