export const teamData = {
    data: {
        teamAlex: {
            version: "standard",
            image: "../externals/img/banners/about.jpg",
            imageMobile: "../externals/img/banners/about-sm.jpg",
            headline: "Alex",
            subheadline: "Precious Gems Property",
            content: "Alex brings an impressive background of valuable insight and real estate market knowledge. Growing up seeing its development inspires him to help his clients find the home of their dreams.",
        },
        teamGraeme: {
            version: "standard",
            image: "../externals/img/banners/about.jpg",
            imageMobile: "../externals/img/banners/about-sm.jpg",
            headline: "Graeme",
            subheadline: "Precious Gems Property",
            content: "Graeme is a dedicated and motivated consultant in the real estate industry, eager to make a mark in the field. ",
        },
    }
};
