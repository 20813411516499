const Icon = () => {
return `<svg xmlns="http://www.w3.org/2000/svg" width="28" height="24" viewBox="0 0 28 24" fill="none">
<g id="Hamburger">
<path id="Line 26" d="M2 2H26" stroke="white" stroke-width="4" stroke-linecap="round"/>
<path id="Line 27" d="M2 12H26" stroke="white" stroke-width="4" stroke-linecap="round"/>
<path id="Line 28" d="M2 22H26" stroke="white" stroke-width="4" stroke-linecap="round"/>
</g>
</svg>
`
}

export default Icon
