export const testNavData = {
    settings: {
        type:"1482471",
        type:"1482472",
        headerNavVersion: "Transparent",
        searchTitle: "SEARCH FOR A COURSEeewwew",
        btn_ATAR_text: "Find course by ATAR",
        btn_ATAR_link: "/atar",
        url: "sandbox/_design/includes/asset-listings/header-nav/_nocache",
        search: {
            btnText: "Search",
            placeholder: "Search ...",
            search: "https://search.latrobe.edu.au/s/search.json",
            suggest: "https://search.latrobe.edu.au/s/suggest.json",
            tracking: "https://search.latrobe.edu.au/s/redirect",
            url: "/search.html",
            collection: "Latrobe-search",
            searchtype: "global",
            suggestNum: 10,
        },
        course: {
            btnText: "Search",
            placeholder: "Search ...",
            search: "https://search.latrobe.edu.au/s/search.json",
            suggest: "https://search.latrobe.edu.au/s/suggest.json",
            tracking: "https://search.latrobe.edu.au/s/redirect",
            url: "/search.html",
            collection: "course-search",
            suggestNum: 4,
            btn_view_all_courses_link: "./course",
            btn_view_all_courses_text: "View all courses",
        }
    },
    data:
{
"sites": {
"assetRoot": {
"assetid": "1481016",
"type_code": "folder",
"version": "0.0.1",
"name": "Header Nav",
"short_name": "Header Nav",
"status": "16",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-09-01 16:25:36",
"created_userid": "1480557",
"updated": "2023-09-26 09:18:32",
"updated_userid": "248053",
"published": "2023-09-26 09:18:32",
"published_userid": "248053",
"status_changed": "2023-09-26 09:18:32",
"status_changed_userid": "248053",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "Header Nav",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"pages": [
{
"name": "Browse By Study Level",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/study-with-us3/browse-by-study-level2",
"id": 1481036,
"status": "Live",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/study-with-us3/browse-by-study-level2\">Browse By Study Level</a>",
"file": "folder",
"publish": "2023-09-26 09:18:30",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "null",
"parentData": {
"assetid": "1481080",
"type_code": "folder",
"version": "0.0.3",
"name": "Study with us",
"short_name": "Study with us",
"status": "16",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-09-04 15:34:44",
"created_userid": "1480557",
"updated": "2023-10-26 16:12:48",
"updated_userid": "1480557",
"published": "2023-09-26 09:18:25",
"published_userid": "248053",
"status_changed": "2023-09-26 09:18:25",
"status_changed_userid": "248053",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "Study with us",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1481080",
"parents": [
{
"linkid": "3159925",
"link_type": "2",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482471",
"1481122",
"1481080"
]
}
],
"childrenIds": [
"1481079",
"1481081",
"1481082"
],
"yearCreated": 2023
},
{
"name": "Your first degree",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/study-with-us3/browse-by-study-level2/study-with-us2",
"id": 1481079,
"status": "Live",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/study-with-us3/browse-by-study-level2/study-with-us2\">Your first degree</a>",
"file": "page_redirect",
"publish": "2023-09-26 09:18:34",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "",
"parentData": {
"assetid": "1481036",
"type_code": "folder",
"version": "0.0.1",
"name": "Browse By Study Level",
"short_name": "Browse By Study Level",
"status": "16",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-09-01 17:31:38",
"created_userid": "1480557",
"updated": "2023-09-26 09:18:30",
"updated_userid": "248053",
"published": "2023-09-26 09:18:30",
"published_userid": "248053",
"status_changed": "2023-09-26 09:18:30",
"status_changed_userid": "248053",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "Browse By Study Level",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1481036",
"parents": [
{
"linkid": "3159928",
"link_type": "1",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482471",
"1481122",
"1481080",
"1481036"
]
}
],
"childrenIds": [],
"yearCreated": 2023
},
{
"name": "Study with us",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/study-with-us3",
"id": 1481080,
"status": "Live",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/study-with-us3\">Study with us</a>",
"file": "folder",
"publish": "2023-09-26 09:18:25",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "null",
"parentData": {
"assetid": "1481122",
"type_code": "folder",
"version": "0.0.2",
"name": "Nav Middle",
"short_name": "Nav Middle",
"status": "16",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-09-06 11:35:48",
"created_userid": "1480557",
"updated": "2023-10-30 14:40:29",
"updated_userid": "248053",
"published": "2023-10-30 14:40:29",
"published_userid": "248053",
"status_changed": "2023-10-30 14:40:29",
"status_changed_userid": "248053",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "Nav Middle",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1481122",
"parents": [
{
"linkid": "3160173",
"link_type": "2",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482471",
"1481122"
]
}
],
"childrenIds": [
"1481036",
"1481086"
],
"yearCreated": 2023
},
{
"name": "Postgraduate degrees",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/study-with-us3/browse-by-study-level2/postgraduate-degrees",
"id": 1481081,
"status": "Live",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/study-with-us3/browse-by-study-level2/postgraduate-degrees\">Postgraduate degrees</a>",
"file": "page_redirect",
"publish": "2023-09-26 09:18:30",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "",
"parentData": {
"assetid": "1481036",
"type_code": "folder",
"version": "0.0.1",
"name": "Browse By Study Level",
"short_name": "Browse By Study Level",
"status": "16",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-09-01 17:31:38",
"created_userid": "1480557",
"updated": "2023-09-26 09:18:30",
"updated_userid": "248053",
"published": "2023-09-26 09:18:30",
"published_userid": "248053",
"status_changed": "2023-09-26 09:18:30",
"status_changed_userid": "248053",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "Browse By Study Level",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1481036",
"parents": [
{
"linkid": "3159933",
"link_type": "1",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482471",
"1481122",
"1481080",
"1481036"
]
}
],
"childrenIds": [],
"yearCreated": 2023
},
{
"name": " Graduate research degrees",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/study-with-us3/browse-by-study-level2/graduate-research-degrees",
"id": 1481082,
"status": "Live",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/study-with-us3/browse-by-study-level2/graduate-research-degrees\"> Graduate research degrees</a>",
"file": "page_redirect",
"publish": "2023-09-26 09:18:36",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "",
"parentData": {
"assetid": "1481036",
"type_code": "folder",
"version": "0.0.1",
"name": "Browse By Study Level",
"short_name": "Browse By Study Level",
"status": "16",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-09-01 17:31:38",
"created_userid": "1480557",
"updated": "2023-09-26 09:18:30",
"updated_userid": "248053",
"published": "2023-09-26 09:18:30",
"published_userid": "248053",
"status_changed": "2023-09-26 09:18:30",
"status_changed_userid": "248053",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "Browse By Study Level",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1481036",
"parents": [
{
"linkid": "3159935",
"link_type": "1",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482471",
"1481122",
"1481080",
"1481036"
]
}
],
"childrenIds": [],
"yearCreated": 2023
},
{
"name": "Courses",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/courses",
"id": 1481083,
"status": "Live",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/courses\">Courses</a>",
"file": "folder",
"publish": "2023-09-26 09:18:30",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "null",
"parentData": {
"assetid": "1481122",
"type_code": "folder",
"version": "0.0.2",
"name": "Nav Middle",
"short_name": "Nav Middle",
"status": "16",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-09-06 11:35:48",
"created_userid": "1480557",
"updated": "2023-10-30 14:40:29",
"updated_userid": "248053",
"published": "2023-10-30 14:40:29",
"published_userid": "248053",
"status_changed": "2023-10-30 14:40:29",
"status_changed_userid": "248053",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "Nav Middle",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1481122",
"parents": [
{
"linkid": "3160174",
"link_type": "2",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482471",
"1481122"
]
}
],
"childrenIds": [
"1481085",
"1481136",
"1481139"
],
"yearCreated": 2023
},
{
"name": "Choose La Trobe",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/study-with-us3/plan-your-way-into-la-trobe/choose-la-trobe",
"id": 1481084,
"status": "Live",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/study-with-us3/plan-your-way-into-la-trobe/choose-la-trobe\">Choose La Trobe</a>",
"file": "page_redirect",
"publish": "2023-09-26 09:18:24",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "",
"parentData": {
"assetid": "1481086",
"type_code": "folder",
"version": "0.0.1",
"name": "PLAN YOUR WAY INTO LA TROBE",
"short_name": "PLAN YOUR WAY INTO LA TROBE",
"status": "16",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-09-04 15:45:21",
"created_userid": "1480557",
"updated": "2023-09-26 09:18:30",
"updated_userid": "248053",
"published": "2023-09-26 09:18:30",
"published_userid": "248053",
"status_changed": "2023-09-26 09:18:30",
"status_changed_userid": "248053",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "PLAN YOUR WAY INTO LA TROBE",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1481086",
"parents": [
{
"linkid": "3159944",
"link_type": "1",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482471",
"1481122",
"1481080",
"1481086"
]
}
],
"childrenIds": [],
"yearCreated": 2023
},
{
"name": "BROWSE BY DISCIPLINE",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/courses/browse-by-discipline",
"id": 1481085,
"status": "Live",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/courses/browse-by-discipline\">BROWSE BY DISCIPLINE</a>",
"file": "folder",
"publish": "2023-09-26 09:18:48",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "null",
"parentData": {
"assetid": "1481083",
"type_code": "folder",
"version": "0.0.1",
"name": "Courses",
"short_name": "Courses",
"status": "16",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-09-04 15:42:46",
"created_userid": "1480557",
"updated": "2023-09-26 09:18:30",
"updated_userid": "248053",
"published": "2023-09-26 09:18:30",
"published_userid": "248053",
"status_changed": "2023-09-26 09:18:30",
"status_changed_userid": "248053",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "Courses",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1481083",
"parents": [
{
"linkid": "3159940",
"link_type": "2",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482471",
"1481122",
"1481083"
]
}
],
"childrenIds": [
"1481088",
"1481129",
"1482428",
"1482429",
"1482430",
"1482489",
"1482490",
"1482491",
"1482492"
],
"yearCreated": 2023
},
{
"name": "PLAN YOUR WAY INTO LA TROBE",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/study-with-us3/plan-your-way-into-la-trobe",
"id": 1481086,
"status": "Live",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/study-with-us3/plan-your-way-into-la-trobe\">PLAN YOUR WAY INTO LA TROBE</a>",
"file": "folder",
"publish": "2023-09-26 09:18:30",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "null",
"parentData": {
"assetid": "1481080",
"type_code": "folder",
"version": "0.0.3",
"name": "Study with us",
"short_name": "Study with us",
"status": "16",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-09-04 15:34:44",
"created_userid": "1480557",
"updated": "2023-10-26 16:12:48",
"updated_userid": "1480557",
"published": "2023-09-26 09:18:25",
"published_userid": "248053",
"status_changed": "2023-09-26 09:18:25",
"status_changed_userid": "248053",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "Study with us",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1481080",
"parents": [
{
"linkid": "3159943",
"link_type": "2",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482471",
"1481122",
"1481080"
]
}
],
"childrenIds": [
"1481084",
"1481087"
],
"yearCreated": 2023
},
{
"name": " Find your course by ATAR",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/study-with-us3/plan-your-way-into-la-trobe/find-your-course-by-atar",
"id": 1481087,
"status": "Live",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/study-with-us3/plan-your-way-into-la-trobe/find-your-course-by-atar\"> Find your course by ATAR</a>",
"file": "page_redirect",
"publish": "2023-09-26 09:18:40",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "",
"parentData": {
"assetid": "1481086",
"type_code": "folder",
"version": "0.0.1",
"name": "PLAN YOUR WAY INTO LA TROBE",
"short_name": "PLAN YOUR WAY INTO LA TROBE",
"status": "16",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-09-04 15:45:21",
"created_userid": "1480557",
"updated": "2023-09-26 09:18:30",
"updated_userid": "248053",
"published": "2023-09-26 09:18:30",
"published_userid": "248053",
"status_changed": "2023-09-26 09:18:30",
"status_changed_userid": "248053",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "PLAN YOUR WAY INTO LA TROBE",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1481086",
"parents": [
{
"linkid": "3159945",
"link_type": "1",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482471",
"1481122",
"1481080",
"1481086"
]
}
],
"childrenIds": [],
"yearCreated": 2023
},
{
"name": "Arts, social sciences and communications",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/courses/browse-by-discipline/arts,-social-sciences-and-communications",
"id": 1481088,
"status": "Live",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/courses/browse-by-discipline/arts,-social-sciences-and-communications\">Arts, social sciences and communications</a>",
"file": "page_redirect",
"publish": "2023-09-26 09:18:34",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "",
"parentData": {
"assetid": "1481085",
"type_code": "folder",
"version": "0.0.1",
"name": "BROWSE BY DISCIPLINE",
"short_name": "BROWSE BY DISCIPLINE",
"status": "16",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-09-04 15:44:31",
"created_userid": "1480557",
"updated": "2023-09-26 09:18:48",
"updated_userid": "248053",
"published": "2023-09-26 09:18:48",
"published_userid": "248053",
"status_changed": "2023-09-26 09:18:48",
"status_changed_userid": "248053",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "BROWSE BY DISCIPLINE",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1481085",
"parents": [
{
"linkid": "3159947",
"link_type": "1",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482471",
"1481122",
"1481083",
"1481085"
]
}
],
"childrenIds": [],
"yearCreated": 2023
},
{
"name": "Nav Middle",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle",
"id": 1481122,
"status": "Live",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle\">Nav Middle</a>",
"file": "folder",
"publish": "2023-10-30 14:40:29",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "null",
"parentData": {
"assetid": "1482471",
"type_code": "folder",
"version": "0.0.1",
"name": "Standard",
"short_name": "Standard",
"status": "2",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-10-31 14:25:48",
"created_userid": "1480557",
"updated": "2023-10-31 14:25:49",
"updated_userid": "1480557",
"published": "Never",
"published_userid": "",
"status_changed": "2023-10-31 14:25:48",
"status_changed_userid": "1480557",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "Standard",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1482471",
"parents": [
{
"linkid": "3162557",
"link_type": "2",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482471"
]
}
],
"childrenIds": [
"1481080",
"1481083",
"1481130",
"1482328",
"1482329",
"1482330"
],
"yearCreated": 2023
},
{
"name": "Nav last",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-last",
"id": 1481123,
"status": "Live",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-last\">Nav last</a>",
"file": "folder",
"publish": "2023-09-26 09:18:37",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "null",
"parentData": {
"assetid": "1482471",
"type_code": "folder",
"version": "0.0.1",
"name": "Standard",
"short_name": "Standard",
"status": "2",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-10-31 14:25:48",
"created_userid": "1480557",
"updated": "2023-10-31 14:25:49",
"updated_userid": "1480557",
"published": "Never",
"published_userid": "",
"status_changed": "2023-10-31 14:25:48",
"status_changed_userid": "1480557",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "Standard",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1482471",
"parents": [
{
"linkid": "3162558",
"link_type": "2",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482471"
]
}
],
"childrenIds": [
"1481124",
"1481125",
"1481126"
],
"yearCreated": 2023
},
{
"name": "Students",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-last/students",
"id": 1481124,
"status": "Live",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-last/students\">Students</a>",
"file": "page_redirect",
"publish": "2023-09-26 09:18:46",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "",
"parentData": {
"assetid": "1481123",
"type_code": "folder",
"version": "0.0.1",
"name": "Nav last",
"short_name": "Nav last",
"status": "16",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-09-06 11:36:01",
"created_userid": "1480557",
"updated": "2023-09-26 09:18:37",
"updated_userid": "248053",
"published": "2023-09-26 09:18:37",
"published_userid": "248053",
"status_changed": "2023-09-26 09:18:37",
"status_changed_userid": "248053",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "Nav last",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1481123",
"parents": [
{
"linkid": "3160175",
"link_type": "1",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482471",
"1481123"
]
}
],
"childrenIds": [],
"yearCreated": 2023
},
{
"name": "Staff",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-last/staff",
"id": 1481125,
"status": "Live",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-last/staff\">Staff</a>",
"file": "page_redirect",
"publish": "2023-09-26 09:18:25",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "",
"parentData": {
"assetid": "1481123",
"type_code": "folder",
"version": "0.0.1",
"name": "Nav last",
"short_name": "Nav last",
"status": "16",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-09-06 11:36:01",
"created_userid": "1480557",
"updated": "2023-09-26 09:18:37",
"updated_userid": "248053",
"published": "2023-09-26 09:18:37",
"published_userid": "248053",
"status_changed": "2023-09-26 09:18:37",
"status_changed_userid": "248053",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "Nav last",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1481123",
"parents": [
{
"linkid": "3160177",
"link_type": "1",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482471",
"1481123"
]
}
],
"childrenIds": [],
"yearCreated": 2023
},
{
"name": "Library",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-last/library",
"id": 1481126,
"status": "Live",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-last/library\">Library</a>",
"file": "page_redirect",
"publish": "2023-09-26 09:18:32",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "",
"parentData": {
"assetid": "1481123",
"type_code": "folder",
"version": "0.0.1",
"name": "Nav last",
"short_name": "Nav last",
"status": "16",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-09-06 11:36:01",
"created_userid": "1480557",
"updated": "2023-09-26 09:18:37",
"updated_userid": "248053",
"published": "2023-09-26 09:18:37",
"published_userid": "248053",
"status_changed": "2023-09-26 09:18:37",
"status_changed_userid": "248053",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "Nav last",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1481123",
"parents": [
{
"linkid": "3160180",
"link_type": "1",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482471",
"1481123"
]
}
],
"childrenIds": [],
"yearCreated": 2023
},
{
"name": "Business and commerce",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/courses/browse-by-discipline/business-and-commerce",
"id": 1481129,
"status": "Live",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/courses/browse-by-discipline/business-and-commerce\">Business and commerce</a>",
"file": "page_redirect",
"publish": "2023-09-26 09:18:35",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "",
"parentData": {
"assetid": "1481085",
"type_code": "folder",
"version": "0.0.1",
"name": "BROWSE BY DISCIPLINE",
"short_name": "BROWSE BY DISCIPLINE",
"status": "16",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-09-04 15:44:31",
"created_userid": "1480557",
"updated": "2023-09-26 09:18:48",
"updated_userid": "248053",
"published": "2023-09-26 09:18:48",
"published_userid": "248053",
"status_changed": "2023-09-26 09:18:48",
"status_changed_userid": "248053",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "BROWSE BY DISCIPLINE",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1481085",
"parents": [
{
"linkid": "3160323",
"link_type": "1",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482471",
"1481122",
"1481083",
"1481085"
]
}
],
"childrenIds": [],
"yearCreated": 2023
},
{
"name": "International students",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/international-students",
"id": 1481130,
"status": "Live",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/international-students\">International students</a>",
"file": "folder",
"publish": "2023-10-30 15:58:24",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "null",
"parentData": {
"assetid": "1481122",
"type_code": "folder",
"version": "0.0.2",
"name": "Nav Middle",
"short_name": "Nav Middle",
"status": "16",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-09-06 11:35:48",
"created_userid": "1480557",
"updated": "2023-10-30 14:40:29",
"updated_userid": "248053",
"published": "2023-10-30 14:40:29",
"published_userid": "248053",
"status_changed": "2023-10-30 14:40:29",
"status_changed_userid": "248053",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "Nav Middle",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1481122",
"parents": [
{
"linkid": "3160325",
"link_type": "2",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482471",
"1481122"
]
}
],
"childrenIds": [
"1481131",
"1482399",
"1482400",
"1482401",
"1482402"
],
"yearCreated": 2023
},
{
"name": "LEARN MORE ABOUT LA TROBE",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/international-students/learn-more-about-la-trobe",
"id": 1481131,
"status": "Live",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/international-students/learn-more-about-la-trobe\">LEARN MORE ABOUT LA TROBE</a>",
"file": "folder",
"publish": "2023-09-26 09:18:48",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "null",
"parentData": {
"assetid": "1481130",
"type_code": "folder",
"version": "0.1.0",
"name": "International students",
"short_name": "International students",
"status": "16",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-09-07 18:01:10",
"created_userid": "1480557",
"updated": "2023-10-30 15:58:24",
"updated_userid": "248053",
"published": "2023-10-30 15:58:24",
"published_userid": "248053",
"status_changed": "2023-10-30 15:58:24",
"status_changed_userid": "248053",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "International students",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1481130",
"parents": [
{
"linkid": "3160326",
"link_type": "1",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482471",
"1481122",
"1481130"
]
}
],
"childrenIds": [
"1481132",
"1482403",
"1482404",
"1482405",
"1482406",
"1482407",
"1482408"
],
"yearCreated": 2023
},
{
"name": "Life at La Trobe",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/international-students/learn-more-about-la-trobe/life-at-la-trobe",
"id": 1481132,
"status": "Live",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/international-students/learn-more-about-la-trobe/life-at-la-trobe\">Life at La Trobe</a>",
"file": "page_redirect",
"publish": "2023-09-26 09:18:36",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "",
"parentData": {
"assetid": "1481131",
"type_code": "folder",
"version": "0.0.1",
"name": "LEARN MORE ABOUT LA TROBE",
"short_name": "LEARN MORE ABOUT LA TROBE",
"status": "16",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-09-07 18:01:40",
"created_userid": "1480557",
"updated": "2023-09-26 09:18:48",
"updated_userid": "248053",
"published": "2023-09-26 09:18:48",
"published_userid": "248053",
"status_changed": "2023-09-26 09:18:48",
"status_changed_userid": "248053",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "LEARN MORE ABOUT LA TROBE",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1481131",
"parents": [
{
"linkid": "3160327",
"link_type": "1",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482471",
"1481122",
"1481130",
"1481131"
]
}
],
"childrenIds": [],
"yearCreated": 2023
},
{
"name": "FEATURED COURSES",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/courses/featured-courses",
"id": 1481136,
"status": "Live",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/courses/featured-courses\">FEATURED COURSES</a>",
"file": "folder",
"publish": "2023-09-26 09:18:36",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "null",
"parentData": {
"assetid": "1481083",
"type_code": "folder",
"version": "0.0.1",
"name": "Courses",
"short_name": "Courses",
"status": "16",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-09-04 15:42:46",
"created_userid": "1480557",
"updated": "2023-09-26 09:18:30",
"updated_userid": "248053",
"published": "2023-09-26 09:18:30",
"published_userid": "248053",
"status_changed": "2023-09-26 09:18:30",
"status_changed_userid": "248053",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "Courses",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1481083",
"parents": [
{
"linkid": "3160335",
"link_type": "2",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482471",
"1481122",
"1481083"
]
}
],
"childrenIds": [
"1481137",
"1481138",
"1482435",
"1482436"
],
"yearCreated": 2023
},
{
"name": "Diplomas",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/courses/featured-courses/diplomas",
"id": 1481137,
"status": "Live",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/courses/featured-courses/diplomas\">Diplomas</a>",
"file": "page_redirect",
"publish": "2023-09-26 09:18:25",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "",
"parentData": {
"assetid": "1481136",
"type_code": "folder",
"version": "0.0.1",
"name": "FEATURED COURSES",
"short_name": "FEATURED COURSES",
"status": "16",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-09-08 15:08:29",
"created_userid": "1480557",
"updated": "2023-09-26 09:18:36",
"updated_userid": "248053",
"published": "2023-09-26 09:18:36",
"published_userid": "248053",
"status_changed": "2023-09-26 09:18:36",
"status_changed_userid": "248053",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "FEATURED COURSES",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1481136",
"parents": [
{
"linkid": "3160336",
"link_type": "1",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482471",
"1481122",
"1481083",
"1481136"
]
}
],
"childrenIds": [],
"yearCreated": 2023
},
{
"name": "Online degrees",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/courses/featured-courses/online-degrees",
"id": 1481138,
"status": "Live",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/courses/featured-courses/online-degrees\">Online degrees</a>",
"file": "page_redirect",
"publish": "2023-09-26 09:18:29",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "",
"parentData": {
"assetid": "1481136",
"type_code": "folder",
"version": "0.0.1",
"name": "FEATURED COURSES",
"short_name": "FEATURED COURSES",
"status": "16",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-09-08 15:08:29",
"created_userid": "1480557",
"updated": "2023-09-26 09:18:36",
"updated_userid": "248053",
"published": "2023-09-26 09:18:36",
"published_userid": "248053",
"status_changed": "2023-09-26 09:18:36",
"status_changed_userid": "248053",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "FEATURED COURSES",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1481136",
"parents": [
{
"linkid": "3160339",
"link_type": "1",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482471",
"1481122",
"1481083",
"1481136"
]
}
],
"childrenIds": [],
"yearCreated": 2023
},
{
"name": "BROWSE BY STUDY LEVEL",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/courses/browse-by-study-level",
"id": 1481139,
"status": "Live",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/courses/browse-by-study-level\">BROWSE BY STUDY LEVEL</a>",
"file": "folder",
"publish": "2023-09-26 09:18:25",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "null",
"parentData": {
"assetid": "1481083",
"type_code": "folder",
"version": "0.0.1",
"name": "Courses",
"short_name": "Courses",
"status": "16",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-09-04 15:42:46",
"created_userid": "1480557",
"updated": "2023-09-26 09:18:30",
"updated_userid": "248053",
"published": "2023-09-26 09:18:30",
"published_userid": "248053",
"status_changed": "2023-09-26 09:18:30",
"status_changed_userid": "248053",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "Courses",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1481083",
"parents": [
{
"linkid": "3160341",
"link_type": "2",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482471",
"1481122",
"1481083"
]
}
],
"childrenIds": [
"1481140"
],
"yearCreated": 2023
},
{
"name": "Undergraduate courses",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/courses/browse-by-study-level/undergraduate-courses",
"id": 1481140,
"status": "Live",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/courses/browse-by-study-level/undergraduate-courses\">Undergraduate courses</a>",
"file": "page_redirect",
"publish": "2023-09-26 09:18:48",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "",
"parentData": {
"assetid": "1481139",
"type_code": "folder",
"version": "0.0.1",
"name": "BROWSE BY STUDY LEVEL",
"short_name": "BROWSE BY STUDY LEVEL",
"status": "16",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-09-08 15:30:11",
"created_userid": "1480557",
"updated": "2023-09-26 09:18:25",
"updated_userid": "248053",
"published": "2023-09-26 09:18:25",
"published_userid": "248053",
"status_changed": "2023-09-26 09:18:25",
"status_changed_userid": "248053",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "BROWSE BY STUDY LEVEL",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1481139",
"parents": [
{
"linkid": "3160342",
"link_type": "1",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482471",
"1481122",
"1481083",
"1481139"
]
}
],
"childrenIds": [],
"yearCreated": 2023
},
{
"name": "Industry and Community",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/industry-and-community",
"id": 1482328,
"status": "Live",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/industry-and-community\">Industry and Community</a>",
"file": "folder",
"publish": "2023-10-30 14:40:33",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "null",
"parentData": {
"assetid": "1481122",
"type_code": "folder",
"version": "0.0.2",
"name": "Nav Middle",
"short_name": "Nav Middle",
"status": "16",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-09-06 11:35:48",
"created_userid": "1480557",
"updated": "2023-10-30 14:40:29",
"updated_userid": "248053",
"published": "2023-10-30 14:40:29",
"published_userid": "248053",
"status_changed": "2023-10-30 14:40:29",
"status_changed_userid": "248053",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "Nav Middle",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1481122",
"parents": [
{
"linkid": "3162393",
"link_type": "2",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482471",
"1481122"
]
}
],
"childrenIds": [
"1482331",
"1482332",
"1482333",
"1482334"
],
"yearCreated": 2023
},
{
"name": "Research",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/research",
"id": 1482329,
"status": "Live",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/research\">Research</a>",
"file": "folder",
"publish": "2023-10-30 14:40:31",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "null",
"parentData": {
"assetid": "1481122",
"type_code": "folder",
"version": "0.0.2",
"name": "Nav Middle",
"short_name": "Nav Middle",
"status": "16",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-09-06 11:35:48",
"created_userid": "1480557",
"updated": "2023-10-30 14:40:29",
"updated_userid": "248053",
"published": "2023-10-30 14:40:29",
"published_userid": "248053",
"status_changed": "2023-10-30 14:40:29",
"status_changed_userid": "248053",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "Nav Middle",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1481122",
"parents": [
{
"linkid": "3162394",
"link_type": "2",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482471",
"1481122"
]
}
],
"childrenIds": [
"1482355",
"1482356",
"1482357",
"1482358"
],
"yearCreated": 2023
},
{
"name": "About",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/about",
"id": 1482330,
"status": "Live",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/about\">About</a>",
"file": "folder",
"publish": "2023-10-30 14:40:33",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "null",
"parentData": {
"assetid": "1481122",
"type_code": "folder",
"version": "0.0.2",
"name": "Nav Middle",
"short_name": "Nav Middle",
"status": "16",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-09-06 11:35:48",
"created_userid": "1480557",
"updated": "2023-10-30 14:40:29",
"updated_userid": "248053",
"published": "2023-10-30 14:40:29",
"published_userid": "248053",
"status_changed": "2023-10-30 14:40:29",
"status_changed_userid": "248053",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "Nav Middle",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1481122",
"parents": [
{
"linkid": "3162395",
"link_type": "2",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482471",
"1481122"
]
}
],
"childrenIds": [
"1482359",
"1482360",
"1482361",
"1482362"
],
"yearCreated": 2023
},
{
"name": "RESEARCH PARTNERSHIPS",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/industry-and-community/research-partnerships",
"id": 1482331,
"status": "Live",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/industry-and-community/research-partnerships\">RESEARCH PARTNERSHIPS</a>",
"file": "folder",
"publish": "2023-10-30 14:40:32",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "null",
"parentData": {
"assetid": "1482328",
"type_code": "folder",
"version": "0.0.0",
"name": "Industry and Community",
"short_name": "Industry and Community",
"status": "16",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-10-30 12:56:02",
"created_userid": "248053",
"updated": "2023-10-30 14:40:33",
"updated_userid": "248053",
"published": "2023-10-30 14:40:33",
"published_userid": "248053",
"status_changed": "2023-10-30 14:40:33",
"status_changed_userid": "248053",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "Industry and Community",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1482328",
"parents": [
{
"linkid": "3162396",
"link_type": "1",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482471",
"1481122",
"1482328"
]
}
],
"childrenIds": [
"1482335",
"1482336",
"1482337",
"1482338",
"1482339",
"1482340"
],
"yearCreated": 2023
},
{
"name": "LOCAL AND GLOBAL ENGAGEMENT",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/industry-and-community/local-and-global-engagement",
"id": 1482332,
"status": "Live",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/industry-and-community/local-and-global-engagement\">LOCAL AND GLOBAL ENGAGEMENT</a>",
"file": "folder",
"publish": "2023-10-30 14:40:29",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "null",
"parentData": {
"assetid": "1482328",
"type_code": "folder",
"version": "0.0.0",
"name": "Industry and Community",
"short_name": "Industry and Community",
"status": "16",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-10-30 12:56:02",
"created_userid": "248053",
"updated": "2023-10-30 14:40:33",
"updated_userid": "248053",
"published": "2023-10-30 14:40:33",
"published_userid": "248053",
"status_changed": "2023-10-30 14:40:33",
"status_changed_userid": "248053",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "Industry and Community",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1482328",
"parents": [
{
"linkid": "3162398",
"link_type": "1",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482471",
"1481122",
"1482328"
]
}
],
"childrenIds": [
"1482341",
"1482342",
"1482343",
"1482344",
"1482345",
"1482346"
],
"yearCreated": 2023
},
{
"name": "CAREER READY",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/industry-and-community/career-ready",
"id": 1482333,
"status": "Live",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/industry-and-community/career-ready\">CAREER READY</a>",
"file": "folder",
"publish": "2023-10-30 14:40:33",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "null",
"parentData": {
"assetid": "1482328",
"type_code": "folder",
"version": "0.0.0",
"name": "Industry and Community",
"short_name": "Industry and Community",
"status": "16",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-10-30 12:56:02",
"created_userid": "248053",
"updated": "2023-10-30 14:40:33",
"updated_userid": "248053",
"published": "2023-10-30 14:40:33",
"published_userid": "248053",
"status_changed": "2023-10-30 14:40:33",
"status_changed_userid": "248053",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "Industry and Community",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1482328",
"parents": [
{
"linkid": "3162399",
"link_type": "1",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482471",
"1481122",
"1482328"
]
}
],
"childrenIds": [
"1482347",
"1482348",
"1482349",
"1482350",
"1482351"
],
"yearCreated": 2023
},
{
"name": "SCHOLARSHIPS AND GIVING",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/industry-and-community/scholarships-and-giving",
"id": 1482334,
"status": "Live",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/industry-and-community/scholarships-and-giving\">SCHOLARSHIPS AND GIVING</a>",
"file": "folder",
"publish": "2023-10-30 14:40:35",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "null",
"parentData": {
"assetid": "1482328",
"type_code": "folder",
"version": "0.0.0",
"name": "Industry and Community",
"short_name": "Industry and Community",
"status": "16",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-10-30 12:56:02",
"created_userid": "248053",
"updated": "2023-10-30 14:40:33",
"updated_userid": "248053",
"published": "2023-10-30 14:40:33",
"published_userid": "248053",
"status_changed": "2023-10-30 14:40:33",
"status_changed_userid": "248053",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "Industry and Community",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1482328",
"parents": [
{
"linkid": "3162400",
"link_type": "1",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482471",
"1481122",
"1482328"
]
}
],
"childrenIds": [
"1482352",
"1482353",
"1482354"
],
"yearCreated": 2023
},
{
"name": "Find an expert",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/industry-and-community/research-partnerships/find-an-expert",
"id": 1482335,
"status": "Live",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/industry-and-community/research-partnerships/find-an-expert\">Find an expert</a>",
"file": "page_redirect",
"publish": "2023-10-30 13:00:28",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "",
"parentData": {
"assetid": "1482331",
"type_code": "folder",
"version": "0.0.1",
"name": "RESEARCH PARTNERSHIPS",
"short_name": "RESEARCH PARTNERSHIPS",
"status": "16",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-10-30 12:59:01",
"created_userid": "248053",
"updated": "2023-10-30 14:40:32",
"updated_userid": "248053",
"published": "2023-10-30 14:40:32",
"published_userid": "248053",
"status_changed": "2023-10-30 14:40:32",
"status_changed_userid": "248053",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "RESEARCH PARTNERSHIPS",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1482331",
"parents": [
{
"linkid": "3162401",
"link_type": "1",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482471",
"1481122",
"1482328",
"1482331"
]
}
],
"childrenIds": [],
"yearCreated": 2023
},
{
"name": "La Trobe Industry",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/industry-and-community/research-partnerships/la-trobe-industry",
"id": 1482336,
"status": "Live",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/industry-and-community/research-partnerships/la-trobe-industry\">La Trobe Industry</a>",
"file": "page_redirect",
"publish": "2023-10-30 14:40:32",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "",
"parentData": {
"assetid": "1482331",
"type_code": "folder",
"version": "0.0.1",
"name": "RESEARCH PARTNERSHIPS",
"short_name": "RESEARCH PARTNERSHIPS",
"status": "16",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-10-30 12:59:01",
"created_userid": "248053",
"updated": "2023-10-30 14:40:32",
"updated_userid": "248053",
"published": "2023-10-30 14:40:32",
"published_userid": "248053",
"status_changed": "2023-10-30 14:40:32",
"status_changed_userid": "248053",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "RESEARCH PARTNERSHIPS",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1482331",
"parents": [
{
"linkid": "3162402",
"link_type": "1",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482471",
"1481122",
"1482328",
"1482331"
]
}
],
"childrenIds": [],
"yearCreated": 2023
},
{
"name": "Research &amp; Innovation Precinct",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/industry-and-community/research-partnerships/research-and-innovation-precinct",
"id": 1482337,
"status": "Live",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/industry-and-community/research-partnerships/research-and-innovation-precinct\">Research &amp; Innovation Precinct</a>",
"file": "page_redirect",
"publish": "2023-10-30 14:40:30",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "",
"parentData": {
"assetid": "1482331",
"type_code": "folder",
"version": "0.0.1",
"name": "RESEARCH PARTNERSHIPS",
"short_name": "RESEARCH PARTNERSHIPS",
"status": "16",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-10-30 12:59:01",
"created_userid": "248053",
"updated": "2023-10-30 14:40:32",
"updated_userid": "248053",
"published": "2023-10-30 14:40:32",
"published_userid": "248053",
"status_changed": "2023-10-30 14:40:32",
"status_changed_userid": "248053",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "RESEARCH PARTNERSHIPS",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1482331",
"parents": [
{
"linkid": "3162403",
"link_type": "1",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482471",
"1481122",
"1482328",
"1482331"
]
}
],
"childrenIds": [],
"yearCreated": 2023
},
{
"name": "Innovation and Entrepreneurship",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/industry-and-community/research-partnerships/innovation-and-entrepreneurship",
"id": 1482338,
"status": "Live",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/industry-and-community/research-partnerships/innovation-and-entrepreneurship\">Innovation and Entrepreneurship</a>",
"file": "page_redirect",
"publish": "2023-10-30 14:40:34",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "",
"parentData": {
"assetid": "1482331",
"type_code": "folder",
"version": "0.0.1",
"name": "RESEARCH PARTNERSHIPS",
"short_name": "RESEARCH PARTNERSHIPS",
"status": "16",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-10-30 12:59:01",
"created_userid": "248053",
"updated": "2023-10-30 14:40:32",
"updated_userid": "248053",
"published": "2023-10-30 14:40:32",
"published_userid": "248053",
"status_changed": "2023-10-30 14:40:32",
"status_changed_userid": "248053",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "RESEARCH PARTNERSHIPS",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1482331",
"parents": [
{
"linkid": "3162404",
"link_type": "1",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482471",
"1481122",
"1482328",
"1482331"
]
}
],
"childrenIds": [],
"yearCreated": 2023
},
{
"name": "Industry Research and Commercialisation",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/industry-and-community/research-partnerships/industry-research-and-commercialisation",
"id": 1482339,
"status": "Live",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/industry-and-community/research-partnerships/industry-research-and-commercialisation\">Industry Research and Commercialisation</a>",
"file": "page_redirect",
"publish": "2023-10-30 14:40:29",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "",
"parentData": {
"assetid": "1482331",
"type_code": "folder",
"version": "0.0.1",
"name": "RESEARCH PARTNERSHIPS",
"short_name": "RESEARCH PARTNERSHIPS",
"status": "16",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-10-30 12:59:01",
"created_userid": "248053",
"updated": "2023-10-30 14:40:32",
"updated_userid": "248053",
"published": "2023-10-30 14:40:32",
"published_userid": "248053",
"status_changed": "2023-10-30 14:40:32",
"status_changed_userid": "248053",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "RESEARCH PARTNERSHIPS",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1482331",
"parents": [
{
"linkid": "3162405",
"link_type": "1",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482471",
"1481122",
"1482328",
"1482331"
]
}
],
"childrenIds": [],
"yearCreated": 2023
},
{
"name": "Industry focused PhDs",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/industry-and-community/research-partnerships/industry-focused-phds",
"id": 1482340,
"status": "Live",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/industry-and-community/research-partnerships/industry-focused-phds\">Industry focused PhDs</a>",
"file": "page_redirect",
"publish": "2023-10-30 14:40:35",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "",
"parentData": {
"assetid": "1482331",
"type_code": "folder",
"version": "0.0.1",
"name": "RESEARCH PARTNERSHIPS",
"short_name": "RESEARCH PARTNERSHIPS",
"status": "16",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-10-30 12:59:01",
"created_userid": "248053",
"updated": "2023-10-30 14:40:32",
"updated_userid": "248053",
"published": "2023-10-30 14:40:32",
"published_userid": "248053",
"status_changed": "2023-10-30 14:40:32",
"status_changed_userid": "248053",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "RESEARCH PARTNERSHIPS",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1482331",
"parents": [
{
"linkid": "3162406",
"link_type": "1",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482471",
"1481122",
"1482328",
"1482331"
]
}
],
"childrenIds": [],
"yearCreated": 2023
},
{
"name": "La Trobe Sport",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/industry-and-community/local-and-global-engagement/la-trobe-sport",
"id": 1482341,
"status": "Live",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/industry-and-community/local-and-global-engagement/la-trobe-sport\">La Trobe Sport</a>",
"file": "page_redirect",
"publish": "2023-10-30 14:40:32",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "",
"parentData": {
"assetid": "1482332",
"type_code": "folder",
"version": "0.0.0",
"name": "LOCAL AND GLOBAL ENGAGEMENT",
"short_name": "LOCAL AND GLOBAL ENGAGEMENT",
"status": "16",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-10-30 12:59:14",
"created_userid": "248053",
"updated": "2023-10-30 14:40:29",
"updated_userid": "248053",
"published": "2023-10-30 14:40:29",
"published_userid": "248053",
"status_changed": "2023-10-30 14:40:29",
"status_changed_userid": "248053",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "LOCAL AND GLOBAL ENGAGEMENT",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1482332",
"parents": [
{
"linkid": "3162407",
"link_type": "1",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482471",
"1481122",
"1482328",
"1482332"
]
}
],
"childrenIds": [],
"yearCreated": 2023
},
{
"name": "International students",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/industry-and-community/local-and-global-engagement/international-students",
"id": 1482342,
"status": "Live",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/industry-and-community/local-and-global-engagement/international-students\">International students</a>",
"file": "page_redirect",
"publish": "2023-10-30 14:40:30",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "",
"parentData": {
"assetid": "1482332",
"type_code": "folder",
"version": "0.0.0",
"name": "LOCAL AND GLOBAL ENGAGEMENT",
"short_name": "LOCAL AND GLOBAL ENGAGEMENT",
"status": "16",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-10-30 12:59:14",
"created_userid": "248053",
"updated": "2023-10-30 14:40:29",
"updated_userid": "248053",
"published": "2023-10-30 14:40:29",
"published_userid": "248053",
"status_changed": "2023-10-30 14:40:29",
"status_changed_userid": "248053",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "LOCAL AND GLOBAL ENGAGEMENT",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1482332",
"parents": [
{
"linkid": "3162408",
"link_type": "1",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482471",
"1481122",
"1482328",
"1482332"
]
}
],
"childrenIds": [],
"yearCreated": 2023
},
{
"name": "Alumni",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/industry-and-community/local-and-global-engagement/alumni",
"id": 1482343,
"status": "Live",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/industry-and-community/local-and-global-engagement/alumni\">Alumni</a>",
"file": "page_redirect",
"publish": "2023-10-30 14:40:33",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "",
"parentData": {
"assetid": "1482332",
"type_code": "folder",
"version": "0.0.0",
"name": "LOCAL AND GLOBAL ENGAGEMENT",
"short_name": "LOCAL AND GLOBAL ENGAGEMENT",
"status": "16",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-10-30 12:59:14",
"created_userid": "248053",
"updated": "2023-10-30 14:40:29",
"updated_userid": "248053",
"published": "2023-10-30 14:40:29",
"published_userid": "248053",
"status_changed": "2023-10-30 14:40:29",
"status_changed_userid": "248053",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "LOCAL AND GLOBAL ENGAGEMENT",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1482332",
"parents": [
{
"linkid": "3162409",
"link_type": "1",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482471",
"1481122",
"1482328",
"1482332"
]
}
],
"childrenIds": [],
"yearCreated": 2023
},
{
"name": "Community engagement",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/industry-and-community/local-and-global-engagement/community-engagement",
"id": 1482344,
"status": "Live",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/industry-and-community/local-and-global-engagement/community-engagement\">Community engagement</a>",
"file": "page_redirect",
"publish": "2023-10-30 14:40:31",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "",
"parentData": {
"assetid": "1482332",
"type_code": "folder",
"version": "0.0.0",
"name": "LOCAL AND GLOBAL ENGAGEMENT",
"short_name": "LOCAL AND GLOBAL ENGAGEMENT",
"status": "16",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-10-30 12:59:14",
"created_userid": "248053",
"updated": "2023-10-30 14:40:29",
"updated_userid": "248053",
"published": "2023-10-30 14:40:29",
"published_userid": "248053",
"status_changed": "2023-10-30 14:40:29",
"status_changed_userid": "248053",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "LOCAL AND GLOBAL ENGAGEMENT",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1482332",
"parents": [
{
"linkid": "3162410",
"link_type": "1",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482471",
"1481122",
"1482328",
"1482332"
]
}
],
"childrenIds": [],
"yearCreated": 2023
},
{
"name": "University City of the Future",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/industry-and-community/local-and-global-engagement/university-city-of-the-future",
"id": 1482345,
"status": "Live",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/industry-and-community/local-and-global-engagement/university-city-of-the-future\">University City of the Future</a>",
"file": "page_redirect",
"publish": "2023-10-30 14:40:31",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "",
"parentData": {
"assetid": "1482332",
"type_code": "folder",
"version": "0.0.0",
"name": "LOCAL AND GLOBAL ENGAGEMENT",
"short_name": "LOCAL AND GLOBAL ENGAGEMENT",
"status": "16",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-10-30 12:59:14",
"created_userid": "248053",
"updated": "2023-10-30 14:40:29",
"updated_userid": "248053",
"published": "2023-10-30 14:40:29",
"published_userid": "248053",
"status_changed": "2023-10-30 14:40:29",
"status_changed_userid": "248053",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "LOCAL AND GLOBAL ENGAGEMENT",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1482332",
"parents": [
{
"linkid": "3162411",
"link_type": "1",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482471",
"1481122",
"1482328",
"1482332"
]
}
],
"childrenIds": [],
"yearCreated": 2023
},
{
"name": "Partnerships",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/industry-and-community/local-and-global-engagement/partnerships",
"id": 1482346,
"status": "Live",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/industry-and-community/local-and-global-engagement/partnerships\">Partnerships</a>",
"file": "page_redirect",
"publish": "2023-10-30 14:40:32",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "",
"parentData": {
"assetid": "1482332",
"type_code": "folder",
"version": "0.0.0",
"name": "LOCAL AND GLOBAL ENGAGEMENT",
"short_name": "LOCAL AND GLOBAL ENGAGEMENT",
"status": "16",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-10-30 12:59:14",
"created_userid": "248053",
"updated": "2023-10-30 14:40:29",
"updated_userid": "248053",
"published": "2023-10-30 14:40:29",
"published_userid": "248053",
"status_changed": "2023-10-30 14:40:29",
"status_changed_userid": "248053",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "LOCAL AND GLOBAL ENGAGEMENT",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1482332",
"parents": [
{
"linkid": "3162412",
"link_type": "1",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482471",
"1481122",
"1482328",
"1482332"
]
}
],
"childrenIds": [],
"yearCreated": 2023
},
{
"name": "Short courses",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/industry-and-community/career-ready/short-courses",
"id": 1482347,
"status": "Live",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/industry-and-community/career-ready/short-courses\">Short courses</a>",
"file": "page_redirect",
"publish": "2023-10-30 14:40:33",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "",
"parentData": {
"assetid": "1482333",
"type_code": "folder",
"version": "0.0.0",
"name": "CAREER READY",
"short_name": "CAREER READY",
"status": "16",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-10-30 12:59:33",
"created_userid": "248053",
"updated": "2023-10-30 14:40:33",
"updated_userid": "248053",
"published": "2023-10-30 14:40:33",
"published_userid": "248053",
"status_changed": "2023-10-30 14:40:33",
"status_changed_userid": "248053",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "CAREER READY",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1482333",
"parents": [
{
"linkid": "3162413",
"link_type": "1",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482471",
"1481122",
"1482328",
"1482333"
]
}
],
"childrenIds": [],
"yearCreated": 2023
},
{
"name": "Browse our courses",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/industry-and-community/career-ready/browse-our-courses",
"id": 1482348,
"status": "Live",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/industry-and-community/career-ready/browse-our-courses\">Browse our courses</a>",
"file": "page_redirect",
"publish": "2023-10-30 14:40:30",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "",
"parentData": {
"assetid": "1482333",
"type_code": "folder",
"version": "0.0.0",
"name": "CAREER READY",
"short_name": "CAREER READY",
"status": "16",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-10-30 12:59:33",
"created_userid": "248053",
"updated": "2023-10-30 14:40:33",
"updated_userid": "248053",
"published": "2023-10-30 14:40:33",
"published_userid": "248053",
"status_changed": "2023-10-30 14:40:33",
"status_changed_userid": "248053",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "CAREER READY",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1482333",
"parents": [
{
"linkid": "3162414",
"link_type": "1",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482471",
"1481122",
"1482328",
"1482333"
]
}
],
"childrenIds": [],
"yearCreated": 2023
},
{
"name": "Employing graduates and students",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/industry-and-community/career-ready/employing-graduates-and-students",
"id": 1482349,
"status": "Live",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/industry-and-community/career-ready/employing-graduates-and-students\">Employing graduates and students</a>",
"file": "page_redirect",
"publish": "2023-10-30 14:40:32",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "",
"parentData": {
"assetid": "1482333",
"type_code": "folder",
"version": "0.0.0",
"name": "CAREER READY",
"short_name": "CAREER READY",
"status": "16",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-10-30 12:59:33",
"created_userid": "248053",
"updated": "2023-10-30 14:40:33",
"updated_userid": "248053",
"published": "2023-10-30 14:40:33",
"published_userid": "248053",
"status_changed": "2023-10-30 14:40:33",
"status_changed_userid": "248053",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "CAREER READY",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1482333",
"parents": [
{
"linkid": "3162415",
"link_type": "1",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482471",
"1481122",
"1482328",
"1482333"
]
}
],
"childrenIds": [],
"yearCreated": 2023
},
{
"name": "Find a work based placement",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/industry-and-community/career-ready/find-a-work-based-placement",
"id": 1482350,
"status": "Live",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/industry-and-community/career-ready/find-a-work-based-placement\">Find a work based placement</a>",
"file": "page_redirect",
"publish": "2023-10-30 14:40:31",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "",
"parentData": {
"assetid": "1482333",
"type_code": "folder",
"version": "0.0.0",
"name": "CAREER READY",
"short_name": "CAREER READY",
"status": "16",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-10-30 12:59:33",
"created_userid": "248053",
"updated": "2023-10-30 14:40:33",
"updated_userid": "248053",
"published": "2023-10-30 14:40:33",
"published_userid": "248053",
"status_changed": "2023-10-30 14:40:33",
"status_changed_userid": "248053",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "CAREER READY",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1482333",
"parents": [
{
"linkid": "3162416",
"link_type": "1",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482471",
"1481122",
"1482328",
"1482333"
]
}
],
"childrenIds": [],
"yearCreated": 2023
},
{
"name": "Future skills and workforce",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/industry-and-community/career-ready/future-skills-and-workforce",
"id": 1482351,
"status": "Live",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/industry-and-community/career-ready/future-skills-and-workforce\">Future skills and workforce</a>",
"file": "page_redirect",
"publish": "2023-10-30 14:40:32",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "",
"parentData": {
"assetid": "1482333",
"type_code": "folder",
"version": "0.0.0",
"name": "CAREER READY",
"short_name": "CAREER READY",
"status": "16",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-10-30 12:59:33",
"created_userid": "248053",
"updated": "2023-10-30 14:40:33",
"updated_userid": "248053",
"published": "2023-10-30 14:40:33",
"published_userid": "248053",
"status_changed": "2023-10-30 14:40:33",
"status_changed_userid": "248053",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "CAREER READY",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1482333",
"parents": [
{
"linkid": "3162417",
"link_type": "1",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482471",
"1481122",
"1482328",
"1482333"
]
}
],
"childrenIds": [],
"yearCreated": 2023
},
{
"name": "Scholarships",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/industry-and-community/scholarships-and-giving/scholarships",
"id": 1482352,
"status": "Live",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/industry-and-community/scholarships-and-giving/scholarships\">Scholarships</a>",
"file": "page_redirect",
"publish": "2023-10-30 14:40:30",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "",
"parentData": {
"assetid": "1482334",
"type_code": "folder",
"version": "0.0.0",
"name": "SCHOLARSHIPS AND GIVING",
"short_name": "SCHOLARSHIPS AND GIVING",
"status": "16",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-10-30 12:59:47",
"created_userid": "248053",
"updated": "2023-10-30 14:40:35",
"updated_userid": "248053",
"published": "2023-10-30 14:40:35",
"published_userid": "248053",
"status_changed": "2023-10-30 14:40:35",
"status_changed_userid": "248053",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "SCHOLARSHIPS AND GIVING",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1482334",
"parents": [
{
"linkid": "3162418",
"link_type": "1",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482471",
"1481122",
"1482328",
"1482334"
]
}
],
"childrenIds": [],
"yearCreated": 2023
},
{
"name": "International scholarships",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/industry-and-community/scholarships-and-giving/international-scholarships",
"id": 1482353,
"status": "Live",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/industry-and-community/scholarships-and-giving/international-scholarships\">International scholarships</a>",
"file": "page_redirect",
"publish": "2023-10-30 14:40:31",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "",
"parentData": {
"assetid": "1482334",
"type_code": "folder",
"version": "0.0.0",
"name": "SCHOLARSHIPS AND GIVING",
"short_name": "SCHOLARSHIPS AND GIVING",
"status": "16",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-10-30 12:59:47",
"created_userid": "248053",
"updated": "2023-10-30 14:40:35",
"updated_userid": "248053",
"published": "2023-10-30 14:40:35",
"published_userid": "248053",
"status_changed": "2023-10-30 14:40:35",
"status_changed_userid": "248053",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "SCHOLARSHIPS AND GIVING",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1482334",
"parents": [
{
"linkid": "3162419",
"link_type": "1",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482471",
"1481122",
"1482328",
"1482334"
]
}
],
"childrenIds": [],
"yearCreated": 2023
},
{
"name": "Giving to La Trobe",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/industry-and-community/scholarships-and-giving/giving-to-la-trobe",
"id": 1482354,
"status": "Live",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/industry-and-community/scholarships-and-giving/giving-to-la-trobe\">Giving to La Trobe</a>",
"file": "page_redirect",
"publish": "2023-10-30 14:40:30",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "",
"parentData": {
"assetid": "1482334",
"type_code": "folder",
"version": "0.0.0",
"name": "SCHOLARSHIPS AND GIVING",
"short_name": "SCHOLARSHIPS AND GIVING",
"status": "16",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-10-30 12:59:47",
"created_userid": "248053",
"updated": "2023-10-30 14:40:35",
"updated_userid": "248053",
"published": "2023-10-30 14:40:35",
"published_userid": "248053",
"status_changed": "2023-10-30 14:40:35",
"status_changed_userid": "248053",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "SCHOLARSHIPS AND GIVING",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1482334",
"parents": [
{
"linkid": "3162420",
"link_type": "1",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482471",
"1481122",
"1482328",
"1482334"
]
}
],
"childrenIds": [],
"yearCreated": 2023
},
{
"name": "RESEARCH PARTNERSHIPS",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/research/research-partnerships",
"id": 1482355,
"status": "Live",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/research/research-partnerships\">RESEARCH PARTNERSHIPS</a>",
"file": "folder",
"publish": "2023-10-30 14:20:20",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "null",
"parentData": {
"assetid": "1482329",
"type_code": "folder",
"version": "0.0.0",
"name": "Research",
"short_name": "Research",
"status": "16",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-10-30 12:57:19",
"created_userid": "248053",
"updated": "2023-10-30 14:40:31",
"updated_userid": "248053",
"published": "2023-10-30 14:40:31",
"published_userid": "248053",
"status_changed": "2023-10-30 14:40:31",
"status_changed_userid": "248053",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "Research",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1482329",
"parents": [
{
"linkid": "3162421",
"link_type": "1",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482471",
"1481122",
"1482329"
]
}
],
"childrenIds": [
"1482363",
"1482364",
"1482365",
"1482366",
"1482367",
"1482368"
],
"yearCreated": 2023
},
{
"name": "GET A RESEARCH DEGREE",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/research/get-a-research-degree",
"id": 1482356,
"status": "Live",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/research/get-a-research-degree\">GET A RESEARCH DEGREE</a>",
"file": "folder",
"publish": "2023-10-30 14:40:29",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "null",
"parentData": {
"assetid": "1482329",
"type_code": "folder",
"version": "0.0.0",
"name": "Research",
"short_name": "Research",
"status": "16",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-10-30 12:57:19",
"created_userid": "248053",
"updated": "2023-10-30 14:40:31",
"updated_userid": "248053",
"published": "2023-10-30 14:40:31",
"published_userid": "248053",
"status_changed": "2023-10-30 14:40:31",
"status_changed_userid": "248053",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "Research",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1482329",
"parents": [
{
"linkid": "3162422",
"link_type": "1",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482471",
"1481122",
"1482329"
]
}
],
"childrenIds": [
"1482369",
"1482370",
"1482371",
"1482372"
],
"yearCreated": 2023
},
{
"name": "OUR RESEARCH",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/research/our-research",
"id": 1482357,
"status": "Live",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/research/our-research\">OUR RESEARCH</a>",
"file": "folder",
"publish": "2023-10-30 14:40:32",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "null",
"parentData": {
"assetid": "1482329",
"type_code": "folder",
"version": "0.0.0",
"name": "Research",
"short_name": "Research",
"status": "16",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-10-30 12:57:19",
"created_userid": "248053",
"updated": "2023-10-30 14:40:31",
"updated_userid": "248053",
"published": "2023-10-30 14:40:31",
"published_userid": "248053",
"status_changed": "2023-10-30 14:40:31",
"status_changed_userid": "248053",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "Research",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1482329",
"parents": [
{
"linkid": "3162423",
"link_type": "1",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482471",
"1481122",
"1482329"
]
}
],
"childrenIds": [
"1482373",
"1482374",
"1482375",
"1482376",
"1482377"
],
"yearCreated": 2023
},
{
"name": "RESEARCHERS",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/research/researchers",
"id": 1482358,
"status": "Live",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/research/researchers\">RESEARCHERS</a>",
"file": "folder",
"publish": "2023-10-30 14:40:31",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "null",
"parentData": {
"assetid": "1482329",
"type_code": "folder",
"version": "0.0.0",
"name": "Research",
"short_name": "Research",
"status": "16",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-10-30 12:57:19",
"created_userid": "248053",
"updated": "2023-10-30 14:40:31",
"updated_userid": "248053",
"published": "2023-10-30 14:40:31",
"published_userid": "248053",
"status_changed": "2023-10-30 14:40:31",
"status_changed_userid": "248053",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "Research",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1482329",
"parents": [
{
"linkid": "3162424",
"link_type": "1",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482471",
"1481122",
"1482329"
]
}
],
"childrenIds": [
"1482378",
"1482379",
"1482380",
"1482381"
],
"yearCreated": 2023
},
{
"name": "LEADERSHIP &amp; STRUCTURE",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/about/leadership-and-structure",
"id": 1482359,
"status": "Live",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/about/leadership-and-structure\">LEADERSHIP &amp; STRUCTURE</a>",
"file": "folder",
"publish": "2023-10-30 14:40:29",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "null",
"parentData": {
"assetid": "1482330",
"type_code": "folder",
"version": "0.0.0",
"name": "About",
"short_name": "About",
"status": "16",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-10-30 12:57:31",
"created_userid": "248053",
"updated": "2023-10-30 14:40:33",
"updated_userid": "248053",
"published": "2023-10-30 14:40:33",
"published_userid": "248053",
"status_changed": "2023-10-30 14:40:33",
"status_changed_userid": "248053",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "About",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1482330",
"parents": [
{
"linkid": "3162425",
"link_type": "1",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482471",
"1481122",
"1482330"
]
}
],
"childrenIds": [
"1482382",
"1482383",
"1482384",
"1482385",
"1482386",
"1482387"
],
"yearCreated": 2023
},
{
"name": "VISION AND STRATEGY",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/about/vision-and-strategy",
"id": 1482360,
"status": "Live",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/about/vision-and-strategy\">VISION AND STRATEGY</a>",
"file": "folder",
"publish": "2023-10-30 14:40:34",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "null",
"parentData": {
"assetid": "1482330",
"type_code": "folder",
"version": "0.0.0",
"name": "About",
"short_name": "About",
"status": "16",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-10-30 12:57:31",
"created_userid": "248053",
"updated": "2023-10-30 14:40:33",
"updated_userid": "248053",
"published": "2023-10-30 14:40:33",
"published_userid": "248053",
"status_changed": "2023-10-30 14:40:33",
"status_changed_userid": "248053",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "About",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1482330",
"parents": [
{
"linkid": "3162426",
"link_type": "1",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482471",
"1481122",
"1482330"
]
}
],
"childrenIds": [
"1482388",
"1482389",
"1482390",
"1482391"
],
"yearCreated": 2023
},
{
"name": "LOCATIONS AND FACILITIES",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/about/locations-and-facilities",
"id": 1482361,
"status": "Live",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/about/locations-and-facilities\">LOCATIONS AND FACILITIES</a>",
"file": "folder",
"publish": "2023-10-30 14:40:34",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "null",
"parentData": {
"assetid": "1482330",
"type_code": "folder",
"version": "0.0.0",
"name": "About",
"short_name": "About",
"status": "16",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-10-30 12:57:31",
"created_userid": "248053",
"updated": "2023-10-30 14:40:33",
"updated_userid": "248053",
"published": "2023-10-30 14:40:33",
"published_userid": "248053",
"status_changed": "2023-10-30 14:40:33",
"status_changed_userid": "248053",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "About",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1482330",
"parents": [
{
"linkid": "3162427",
"link_type": "1",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482471",
"1481122",
"1482330"
]
}
],
"childrenIds": [
"1482392",
"1482393",
"1482394"
],
"yearCreated": 2023
},
{
"name": "ENGAGE WITH LA TROBE",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/about/engage-with-la-trobe",
"id": 1482362,
"status": "Live",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/about/engage-with-la-trobe\">ENGAGE WITH LA TROBE</a>",
"file": "folder",
"publish": "2023-10-30 14:40:34",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "null",
"parentData": {
"assetid": "1482330",
"type_code": "folder",
"version": "0.0.0",
"name": "About",
"short_name": "About",
"status": "16",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-10-30 12:57:31",
"created_userid": "248053",
"updated": "2023-10-30 14:40:33",
"updated_userid": "248053",
"published": "2023-10-30 14:40:33",
"published_userid": "248053",
"status_changed": "2023-10-30 14:40:33",
"status_changed_userid": "248053",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "About",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1482330",
"parents": [
{
"linkid": "3162428",
"link_type": "1",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482471",
"1481122",
"1482330"
]
}
],
"childrenIds": [
"1482395",
"1482396",
"1482397",
"1482398"
],
"yearCreated": 2023
},
{
"name": "Find an expert",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/research/research-partnerships/find-an-expert",
"id": 1482363,
"status": "Live",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/research/research-partnerships/find-an-expert\">Find an expert</a>",
"file": "page_redirect",
"publish": "2023-10-30 14:40:31",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "",
"parentData": {
"assetid": "1482355",
"type_code": "folder",
"version": "0.1.0",
"name": "RESEARCH PARTNERSHIPS",
"short_name": "RESEARCH PARTNERSHIPS",
"status": "16",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-10-30 14:20:16",
"created_userid": "248053",
"updated": "2023-10-30 14:20:20",
"updated_userid": "248053",
"published": "2023-10-30 14:20:20",
"published_userid": "248053",
"status_changed": "2023-10-30 14:20:20",
"status_changed_userid": "248053",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "RESEARCH PARTNERSHIPS",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1482355",
"parents": [
{
"linkid": "3162429",
"link_type": "1",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482471",
"1481122",
"1482329",
"1482355"
]
}
],
"childrenIds": [],
"yearCreated": 2023
},
{
"name": "La Trobe Industry",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/research/research-partnerships/la-trobe-industry",
"id": 1482364,
"status": "Live",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/research/research-partnerships/la-trobe-industry\">La Trobe Industry</a>",
"file": "page_redirect",
"publish": "2023-10-30 14:40:35",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "",
"parentData": {
"assetid": "1482355",
"type_code": "folder",
"version": "0.1.0",
"name": "RESEARCH PARTNERSHIPS",
"short_name": "RESEARCH PARTNERSHIPS",
"status": "16",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-10-30 14:20:16",
"created_userid": "248053",
"updated": "2023-10-30 14:20:20",
"updated_userid": "248053",
"published": "2023-10-30 14:20:20",
"published_userid": "248053",
"status_changed": "2023-10-30 14:20:20",
"status_changed_userid": "248053",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "RESEARCH PARTNERSHIPS",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1482355",
"parents": [
{
"linkid": "3162430",
"link_type": "1",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482471",
"1481122",
"1482329",
"1482355"
]
}
],
"childrenIds": [],
"yearCreated": 2023
},
{
"name": "Research &amp; Innovation Precinct",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/research/research-partnerships/research-and-innovation-precinct",
"id": 1482365,
"status": "Live",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/research/research-partnerships/research-and-innovation-precinct\">Research &amp; Innovation Precinct</a>",
"file": "page_redirect",
"publish": "2023-10-30 14:40:29",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "",
"parentData": {
"assetid": "1482355",
"type_code": "folder",
"version": "0.1.0",
"name": "RESEARCH PARTNERSHIPS",
"short_name": "RESEARCH PARTNERSHIPS",
"status": "16",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-10-30 14:20:16",
"created_userid": "248053",
"updated": "2023-10-30 14:20:20",
"updated_userid": "248053",
"published": "2023-10-30 14:20:20",
"published_userid": "248053",
"status_changed": "2023-10-30 14:20:20",
"status_changed_userid": "248053",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "RESEARCH PARTNERSHIPS",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1482355",
"parents": [
{
"linkid": "3162431",
"link_type": "1",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482471",
"1481122",
"1482329",
"1482355"
]
}
],
"childrenIds": [],
"yearCreated": 2023
},
{
"name": "Innovation and Entrepreneurship",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/research/research-partnerships/innovation-and-entrepreneurship",
"id": 1482366,
"status": "Live",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/research/research-partnerships/innovation-and-entrepreneurship\">Innovation and Entrepreneurship</a>",
"file": "page_redirect",
"publish": "2023-10-30 14:40:30",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "",
"parentData": {
"assetid": "1482355",
"type_code": "folder",
"version": "0.1.0",
"name": "RESEARCH PARTNERSHIPS",
"short_name": "RESEARCH PARTNERSHIPS",
"status": "16",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-10-30 14:20:16",
"created_userid": "248053",
"updated": "2023-10-30 14:20:20",
"updated_userid": "248053",
"published": "2023-10-30 14:20:20",
"published_userid": "248053",
"status_changed": "2023-10-30 14:20:20",
"status_changed_userid": "248053",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "RESEARCH PARTNERSHIPS",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1482355",
"parents": [
{
"linkid": "3162432",
"link_type": "1",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482471",
"1481122",
"1482329",
"1482355"
]
}
],
"childrenIds": [],
"yearCreated": 2023
},
{
"name": "Industry Research and Commercialisation",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/research/research-partnerships/industry-research-and-commercialisation",
"id": 1482367,
"status": "Live",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/research/research-partnerships/industry-research-and-commercialisation\">Industry Research and Commercialisation</a>",
"file": "page_redirect",
"publish": "2023-10-30 14:40:35",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "",
"parentData": {
"assetid": "1482355",
"type_code": "folder",
"version": "0.1.0",
"name": "RESEARCH PARTNERSHIPS",
"short_name": "RESEARCH PARTNERSHIPS",
"status": "16",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-10-30 14:20:16",
"created_userid": "248053",
"updated": "2023-10-30 14:20:20",
"updated_userid": "248053",
"published": "2023-10-30 14:20:20",
"published_userid": "248053",
"status_changed": "2023-10-30 14:20:20",
"status_changed_userid": "248053",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "RESEARCH PARTNERSHIPS",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1482355",
"parents": [
{
"linkid": "3162433",
"link_type": "1",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482471",
"1481122",
"1482329",
"1482355"
]
}
],
"childrenIds": [],
"yearCreated": 2023
},
{
"name": "Industry focused PhDs",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/research/research-partnerships/industry-focused-phds",
"id": 1482368,
"status": "Live",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/research/research-partnerships/industry-focused-phds\">Industry focused PhDs</a>",
"file": "page_redirect",
"publish": "2023-10-30 14:40:30",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "",
"parentData": {
"assetid": "1482355",
"type_code": "folder",
"version": "0.1.0",
"name": "RESEARCH PARTNERSHIPS",
"short_name": "RESEARCH PARTNERSHIPS",
"status": "16",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-10-30 14:20:16",
"created_userid": "248053",
"updated": "2023-10-30 14:20:20",
"updated_userid": "248053",
"published": "2023-10-30 14:20:20",
"published_userid": "248053",
"status_changed": "2023-10-30 14:20:20",
"status_changed_userid": "248053",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "RESEARCH PARTNERSHIPS",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1482355",
"parents": [
{
"linkid": "3162434",
"link_type": "1",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482471",
"1481122",
"1482329",
"1482355"
]
}
],
"childrenIds": [],
"yearCreated": 2023
},
{
"name": "Fees and scholarships",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/research/get-a-research-degree/fees-and-scholarships",
"id": 1482369,
"status": "Live",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/research/get-a-research-degree/fees-and-scholarships\">Fees and scholarships</a>",
"file": "page_redirect",
"publish": "2023-10-30 14:40:35",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "",
"parentData": {
"assetid": "1482356",
"type_code": "folder",
"version": "0.0.1",
"name": "GET A RESEARCH DEGREE",
"short_name": "GET A RESEARCH DEGREE",
"status": "16",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-10-30 14:20:29",
"created_userid": "248053",
"updated": "2023-10-30 14:40:29",
"updated_userid": "248053",
"published": "2023-10-30 14:40:29",
"published_userid": "248053",
"status_changed": "2023-10-30 14:40:29",
"status_changed_userid": "248053",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "GET A RESEARCH DEGREE",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1482356",
"parents": [
{
"linkid": "3162435",
"link_type": "1",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482471",
"1481122",
"1482329",
"1482356"
]
}
],
"childrenIds": [],
"yearCreated": 2023
},
{
"name": "Research degree options",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/research/get-a-research-degree/research-degree-options",
"id": 1482370,
"status": "Live",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/research/get-a-research-degree/research-degree-options\">Research degree options</a>",
"file": "page_redirect",
"publish": "2023-10-30 14:40:30",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "",
"parentData": {
"assetid": "1482356",
"type_code": "folder",
"version": "0.0.1",
"name": "GET A RESEARCH DEGREE",
"short_name": "GET A RESEARCH DEGREE",
"status": "16",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-10-30 14:20:29",
"created_userid": "248053",
"updated": "2023-10-30 14:40:29",
"updated_userid": "248053",
"published": "2023-10-30 14:40:29",
"published_userid": "248053",
"status_changed": "2023-10-30 14:40:29",
"status_changed_userid": "248053",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "GET A RESEARCH DEGREE",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1482356",
"parents": [
{
"linkid": "3162436",
"link_type": "1",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482471",
"1481122",
"1482329",
"1482356"
]
}
],
"childrenIds": [],
"yearCreated": 2023
},
{
"name": "About our research",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/research/get-a-research-degree/about-our-research",
"id": 1482371,
"status": "Live",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/research/get-a-research-degree/about-our-research\">About our research</a>",
"file": "page_redirect",
"publish": "2023-10-30 14:40:33",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "",
"parentData": {
"assetid": "1482356",
"type_code": "folder",
"version": "0.0.1",
"name": "GET A RESEARCH DEGREE",
"short_name": "GET A RESEARCH DEGREE",
"status": "16",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-10-30 14:20:29",
"created_userid": "248053",
"updated": "2023-10-30 14:40:29",
"updated_userid": "248053",
"published": "2023-10-30 14:40:29",
"published_userid": "248053",
"status_changed": "2023-10-30 14:40:29",
"status_changed_userid": "248053",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "GET A RESEARCH DEGREE",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1482356",
"parents": [
{
"linkid": "3162437",
"link_type": "1",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482471",
"1481122",
"1482329",
"1482356"
]
}
],
"childrenIds": [],
"yearCreated": 2023
},
{
"name": "Industry PhD, internships and mentoring",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/research/get-a-research-degree/industry-phd,-internships-and-mentoring",
"id": 1482372,
"status": "Live",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/research/get-a-research-degree/industry-phd,-internships-and-mentoring\">Industry PhD, internships and mentoring</a>",
"file": "page_redirect",
"publish": "2023-10-30 14:40:32",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "",
"parentData": {
"assetid": "1482356",
"type_code": "folder",
"version": "0.0.1",
"name": "GET A RESEARCH DEGREE",
"short_name": "GET A RESEARCH DEGREE",
"status": "16",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-10-30 14:20:29",
"created_userid": "248053",
"updated": "2023-10-30 14:40:29",
"updated_userid": "248053",
"published": "2023-10-30 14:40:29",
"published_userid": "248053",
"status_changed": "2023-10-30 14:40:29",
"status_changed_userid": "248053",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "GET A RESEARCH DEGREE",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1482356",
"parents": [
{
"linkid": "3162438",
"link_type": "1",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482471",
"1481122",
"1482329",
"1482356"
]
}
],
"childrenIds": [],
"yearCreated": 2023
},
{
"name": "Research centres",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/research/our-research/research-centres",
"id": 1482373,
"status": "Live",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/research/our-research/research-centres\">Research centres</a>",
"file": "page_redirect",
"publish": "2023-10-30 14:40:34",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "",
"parentData": {
"assetid": "1482357",
"type_code": "folder",
"version": "0.0.1",
"name": "OUR RESEARCH",
"short_name": "OUR RESEARCH",
"status": "16",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-10-30 14:20:36",
"created_userid": "248053",
"updated": "2023-10-30 14:40:32",
"updated_userid": "248053",
"published": "2023-10-30 14:40:32",
"published_userid": "248053",
"status_changed": "2023-10-30 14:40:32",
"status_changed_userid": "248053",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "OUR RESEARCH",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1482357",
"parents": [
{
"linkid": "3162439",
"link_type": "1",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482471",
"1481122",
"1482329",
"1482357"
]
}
],
"childrenIds": [],
"yearCreated": 2023
},
{
"name": "Care Economy Research Institute (CERI)",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/research/our-research/care-economy-research-institute-ceri",
"id": 1482374,
"status": "Live",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/research/our-research/care-economy-research-institute-ceri\">Care Economy Research Institute (CERI)</a>",
"file": "page_redirect",
"publish": "2023-10-30 14:40:35",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "",
"parentData": {
"assetid": "1482357",
"type_code": "folder",
"version": "0.0.1",
"name": "OUR RESEARCH",
"short_name": "OUR RESEARCH",
"status": "16",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-10-30 14:20:36",
"created_userid": "248053",
"updated": "2023-10-30 14:40:32",
"updated_userid": "248053",
"published": "2023-10-30 14:40:32",
"published_userid": "248053",
"status_changed": "2023-10-30 14:40:32",
"status_changed_userid": "248053",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "OUR RESEARCH",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1482357",
"parents": [
{
"linkid": "3162440",
"link_type": "1",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482471",
"1481122",
"1482329",
"1482357"
]
}
],
"childrenIds": [],
"yearCreated": 2023
},
{
"name": "La Trobe Institute for Agriculture and Food (LIAF)",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/research/our-research/la-trobe-institute-for-agriculture-and-food-liaf",
"id": 1482375,
"status": "Live",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/research/our-research/la-trobe-institute-for-agriculture-and-food-liaf\">La Trobe Institute for Agriculture and Food (LIAF)</a>",
"file": "page_redirect",
"publish": "2023-10-30 14:40:30",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "",
"parentData": {
"assetid": "1482357",
"type_code": "folder",
"version": "0.0.1",
"name": "OUR RESEARCH",
"short_name": "OUR RESEARCH",
"status": "16",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-10-30 14:20:36",
"created_userid": "248053",
"updated": "2023-10-30 14:40:32",
"updated_userid": "248053",
"published": "2023-10-30 14:40:32",
"published_userid": "248053",
"status_changed": "2023-10-30 14:40:32",
"status_changed_userid": "248053",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "OUR RESEARCH",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1482357",
"parents": [
{
"linkid": "3162441",
"link_type": "1",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482471",
"1481122",
"1482329",
"1482357"
]
}
],
"childrenIds": [],
"yearCreated": 2023
},
{
"name": "Research at La Trobe",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/research/our-research/research-at-la-trobe",
"id": 1482376,
"status": "Live",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/research/our-research/research-at-la-trobe\">Research at La Trobe</a>",
"file": "page_redirect",
"publish": "2023-10-30 14:40:32",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "",
"parentData": {
"assetid": "1482357",
"type_code": "folder",
"version": "0.0.1",
"name": "OUR RESEARCH",
"short_name": "OUR RESEARCH",
"status": "16",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-10-30 14:20:36",
"created_userid": "248053",
"updated": "2023-10-30 14:40:32",
"updated_userid": "248053",
"published": "2023-10-30 14:40:32",
"published_userid": "248053",
"status_changed": "2023-10-30 14:40:32",
"status_changed_userid": "248053",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "OUR RESEARCH",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1482357",
"parents": [
{
"linkid": "3162442",
"link_type": "1",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482471",
"1481122",
"1482329",
"1482357"
]
}
],
"childrenIds": [],
"yearCreated": 2023
},
{
"name": "Our research themes",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/research/our-research/our-research-themes",
"id": 1482377,
"status": "Live",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/research/our-research/our-research-themes\">Our research themes</a>",
"file": "page_redirect",
"publish": "2023-10-30 14:40:34",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "",
"parentData": {
"assetid": "1482357",
"type_code": "folder",
"version": "0.0.1",
"name": "OUR RESEARCH",
"short_name": "OUR RESEARCH",
"status": "16",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-10-30 14:20:36",
"created_userid": "248053",
"updated": "2023-10-30 14:40:32",
"updated_userid": "248053",
"published": "2023-10-30 14:40:32",
"published_userid": "248053",
"status_changed": "2023-10-30 14:40:32",
"status_changed_userid": "248053",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "OUR RESEARCH",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1482357",
"parents": [
{
"linkid": "3162443",
"link_type": "1",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482471",
"1481122",
"1482329",
"1482357"
]
}
],
"childrenIds": [],
"yearCreated": 2023
},
{
"name": "Graduate Research School",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/research/researchers/graduate-research-school",
"id": 1482378,
"status": "Live",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/research/researchers/graduate-research-school\">Graduate Research School</a>",
"file": "page_redirect",
"publish": "2023-10-30 14:40:32",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "",
"parentData": {
"assetid": "1482358",
"type_code": "folder",
"version": "0.0.1",
"name": "RESEARCHERS",
"short_name": "RESEARCHERS",
"status": "16",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-10-30 14:20:41",
"created_userid": "248053",
"updated": "2023-10-30 14:40:31",
"updated_userid": "248053",
"published": "2023-10-30 14:40:31",
"published_userid": "248053",
"status_changed": "2023-10-30 14:40:31",
"status_changed_userid": "248053",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "RESEARCHERS",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1482358",
"parents": [
{
"linkid": "3162444",
"link_type": "1",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482471",
"1481122",
"1482329",
"1482358"
]
}
],
"childrenIds": [],
"yearCreated": 2023
},
{
"name": "Research Education and Development (RED)",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/research/researchers/research-education-and-development-red",
"id": 1482379,
"status": "Live",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/research/researchers/research-education-and-development-red\">Research Education and Development (RED)</a>",
"file": "page_redirect",
"publish": "2023-10-30 14:40:36",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "",
"parentData": {
"assetid": "1482358",
"type_code": "folder",
"version": "0.0.1",
"name": "RESEARCHERS",
"short_name": "RESEARCHERS",
"status": "16",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-10-30 14:20:41",
"created_userid": "248053",
"updated": "2023-10-30 14:40:31",
"updated_userid": "248053",
"published": "2023-10-30 14:40:31",
"published_userid": "248053",
"status_changed": "2023-10-30 14:40:31",
"status_changed_userid": "248053",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "RESEARCHERS",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1482358",
"parents": [
{
"linkid": "3162445",
"link_type": "1",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482471",
"1481122",
"1482329",
"1482358"
]
}
],
"childrenIds": [],
"yearCreated": 2023
},
{
"name": "The Research Office",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/research/researchers/the-research-office",
"id": 1482380,
"status": "Live",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/research/researchers/the-research-office\">The Research Office</a>",
"file": "page_redirect",
"publish": "2023-10-30 14:40:35",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "",
"parentData": {
"assetid": "1482358",
"type_code": "folder",
"version": "0.0.1",
"name": "RESEARCHERS",
"short_name": "RESEARCHERS",
"status": "16",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-10-30 14:20:41",
"created_userid": "248053",
"updated": "2023-10-30 14:40:31",
"updated_userid": "248053",
"published": "2023-10-30 14:40:31",
"published_userid": "248053",
"status_changed": "2023-10-30 14:40:31",
"status_changed_userid": "248053",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "RESEARCHERS",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1482358",
"parents": [
{
"linkid": "3162446",
"link_type": "1",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482471",
"1481122",
"1482329",
"1482358"
]
}
],
"childrenIds": [],
"yearCreated": 2023
},
{
"name": "Office of Research Infrastructure",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/research/researchers/office-of-research-infrastructure",
"id": 1482381,
"status": "Live",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/research/researchers/office-of-research-infrastructure\">Office of Research Infrastructure</a>",
"file": "page_redirect",
"publish": "2023-10-30 14:40:32",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "",
"parentData": {
"assetid": "1482358",
"type_code": "folder",
"version": "0.0.1",
"name": "RESEARCHERS",
"short_name": "RESEARCHERS",
"status": "16",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-10-30 14:20:41",
"created_userid": "248053",
"updated": "2023-10-30 14:40:31",
"updated_userid": "248053",
"published": "2023-10-30 14:40:31",
"published_userid": "248053",
"status_changed": "2023-10-30 14:40:31",
"status_changed_userid": "248053",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "RESEARCHERS",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1482358",
"parents": [
{
"linkid": "3162447",
"link_type": "1",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482471",
"1481122",
"1482329",
"1482358"
]
}
],
"childrenIds": [],
"yearCreated": 2023
},
{
"name": "Vice-Chancellor",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/about/leadership-and-structure/vice-chancellor",
"id": 1482382,
"status": "Live",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/about/leadership-and-structure/vice-chancellor\">Vice-Chancellor</a>",
"file": "page_redirect",
"publish": "2023-10-30 14:40:35",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "",
"parentData": {
"assetid": "1482359",
"type_code": "folder",
"version": "0.0.1",
"name": "LEADERSHIP & STRUCTURE",
"short_name": "LEADERSHIP & STRUCTURE",
"status": "16",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-10-30 14:20:47",
"created_userid": "248053",
"updated": "2023-10-30 14:40:29",
"updated_userid": "248053",
"published": "2023-10-30 14:40:29",
"published_userid": "248053",
"status_changed": "2023-10-30 14:40:29",
"status_changed_userid": "248053",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "LEADERSHIP & STRUCTURE",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1482359",
"parents": [
{
"linkid": "3162448",
"link_type": "1",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482471",
"1481122",
"1482330",
"1482359"
]
}
],
"childrenIds": [],
"yearCreated": 2023
},
{
"name": "Management and organisational structure",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/about/leadership-and-structure/management-and-organisational-structure",
"id": 1482383,
"status": "Live",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/about/leadership-and-structure/management-and-organisational-structure\">Management and organisational structure</a>",
"file": "page_redirect",
"publish": "2023-10-30 14:40:33",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "",
"parentData": {
"assetid": "1482359",
"type_code": "folder",
"version": "0.0.1",
"name": "LEADERSHIP & STRUCTURE",
"short_name": "LEADERSHIP & STRUCTURE",
"status": "16",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-10-30 14:20:47",
"created_userid": "248053",
"updated": "2023-10-30 14:40:29",
"updated_userid": "248053",
"published": "2023-10-30 14:40:29",
"published_userid": "248053",
"status_changed": "2023-10-30 14:40:29",
"status_changed_userid": "248053",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "LEADERSHIP & STRUCTURE",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1482359",
"parents": [
{
"linkid": "3162449",
"link_type": "1",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482471",
"1481122",
"1482330",
"1482359"
]
}
],
"childrenIds": [],
"yearCreated": 2023
},
{
"name": "Academic board",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/about/leadership-and-structure/academic-board",
"id": 1482384,
"status": "Live",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/about/leadership-and-structure/academic-board\">Academic board</a>",
"file": "page_redirect",
"publish": "2023-10-30 14:40:35",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "",
"parentData": {
"assetid": "1482359",
"type_code": "folder",
"version": "0.0.1",
"name": "LEADERSHIP & STRUCTURE",
"short_name": "LEADERSHIP & STRUCTURE",
"status": "16",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-10-30 14:20:47",
"created_userid": "248053",
"updated": "2023-10-30 14:40:29",
"updated_userid": "248053",
"published": "2023-10-30 14:40:29",
"published_userid": "248053",
"status_changed": "2023-10-30 14:40:29",
"status_changed_userid": "248053",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "LEADERSHIP & STRUCTURE",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1482359",
"parents": [
{
"linkid": "3162450",
"link_type": "1",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482471",
"1481122",
"1482330",
"1482359"
]
}
],
"childrenIds": [],
"yearCreated": 2023
},
{
"name": "Governance",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/about/leadership-and-structure/governance",
"id": 1482385,
"status": "Live",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/about/leadership-and-structure/governance\">Governance</a>",
"file": "page_redirect",
"publish": "2023-10-30 14:40:33",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "",
"parentData": {
"assetid": "1482359",
"type_code": "folder",
"version": "0.0.1",
"name": "LEADERSHIP & STRUCTURE",
"short_name": "LEADERSHIP & STRUCTURE",
"status": "16",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-10-30 14:20:47",
"created_userid": "248053",
"updated": "2023-10-30 14:40:29",
"updated_userid": "248053",
"published": "2023-10-30 14:40:29",
"published_userid": "248053",
"status_changed": "2023-10-30 14:40:29",
"status_changed_userid": "248053",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "LEADERSHIP & STRUCTURE",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1482359",
"parents": [
{
"linkid": "3162451",
"link_type": "1",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482471",
"1481122",
"1482330",
"1482359"
]
}
],
"childrenIds": [],
"yearCreated": 2023
},
{
"name": "Schools and Departments",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/about/leadership-and-structure/schools-and-departments",
"id": 1482386,
"status": "Live",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/about/leadership-and-structure/schools-and-departments\">Schools and Departments</a>",
"file": "page_redirect",
"publish": "2023-10-30 14:40:33",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "",
"parentData": {
"assetid": "1482359",
"type_code": "folder",
"version": "0.0.1",
"name": "LEADERSHIP & STRUCTURE",
"short_name": "LEADERSHIP & STRUCTURE",
"status": "16",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-10-30 14:20:47",
"created_userid": "248053",
"updated": "2023-10-30 14:40:29",
"updated_userid": "248053",
"published": "2023-10-30 14:40:29",
"published_userid": "248053",
"status_changed": "2023-10-30 14:40:29",
"status_changed_userid": "248053",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "LEADERSHIP & STRUCTURE",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1482359",
"parents": [
{
"linkid": "3162452",
"link_type": "1",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482471",
"1481122",
"1482330",
"1482359"
]
}
],
"childrenIds": [],
"yearCreated": 2023
},
{
"name": "Learning and teaching",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/about/leadership-and-structure/learning-and-teaching",
"id": 1482387,
"status": "Live",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/about/leadership-and-structure/learning-and-teaching\">Learning and teaching</a>",
"file": "page_redirect",
"publish": "2023-10-30 14:40:35",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "",
"parentData": {
"assetid": "1482359",
"type_code": "folder",
"version": "0.0.1",
"name": "LEADERSHIP & STRUCTURE",
"short_name": "LEADERSHIP & STRUCTURE",
"status": "16",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-10-30 14:20:47",
"created_userid": "248053",
"updated": "2023-10-30 14:40:29",
"updated_userid": "248053",
"published": "2023-10-30 14:40:29",
"published_userid": "248053",
"status_changed": "2023-10-30 14:40:29",
"status_changed_userid": "248053",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "LEADERSHIP & STRUCTURE",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1482359",
"parents": [
{
"linkid": "3162453",
"link_type": "1",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482471",
"1481122",
"1482330",
"1482359"
]
}
],
"childrenIds": [],
"yearCreated": 2023
},
{
"name": "La Trobe's vision and strategy",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/about/vision-and-strategy/la-trobes-vision-and-strategy",
"id": 1482388,
"status": "Live",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/about/vision-and-strategy/la-trobes-vision-and-strategy\">La Trobe's vision and strategy</a>",
"file": "page_redirect",
"publish": "2023-10-30 14:40:29",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "",
"parentData": {
"assetid": "1482360",
"type_code": "folder",
"version": "0.0.1",
"name": "VISION AND STRATEGY",
"short_name": "VISION AND STRATEGY",
"status": "16",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-10-30 14:20:55",
"created_userid": "248053",
"updated": "2023-10-30 14:40:34",
"updated_userid": "248053",
"published": "2023-10-30 14:40:34",
"published_userid": "248053",
"status_changed": "2023-10-30 14:40:34",
"status_changed_userid": "248053",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "VISION AND STRATEGY",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1482360",
"parents": [
{
"linkid": "3162455",
"link_type": "1",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482471",
"1481122",
"1482330",
"1482360"
]
}
],
"childrenIds": [],
"yearCreated": 2023
},
{
"name": "University City of the Future",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/about/vision-and-strategy/university-city-of-the-future",
"id": 1482389,
"status": "Live",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/about/vision-and-strategy/university-city-of-the-future\">University City of the Future</a>",
"file": "page_redirect",
"publish": "2023-10-30 14:40:31",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "",
"parentData": {
"assetid": "1482360",
"type_code": "folder",
"version": "0.0.1",
"name": "VISION AND STRATEGY",
"short_name": "VISION AND STRATEGY",
"status": "16",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-10-30 14:20:55",
"created_userid": "248053",
"updated": "2023-10-30 14:40:34",
"updated_userid": "248053",
"published": "2023-10-30 14:40:34",
"published_userid": "248053",
"status_changed": "2023-10-30 14:40:34",
"status_changed_userid": "248053",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "VISION AND STRATEGY",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1482360",
"parents": [
{
"linkid": "3162456",
"link_type": "1",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482471",
"1481122",
"1482330",
"1482360"
]
}
],
"childrenIds": [],
"yearCreated": 2023
},
{
"name": "Plans and publications",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/about/vision-and-strategy/plans-and-publications",
"id": 1482390,
"status": "Live",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/about/vision-and-strategy/plans-and-publications\">Plans and publications</a>",
"file": "page_redirect",
"publish": "2023-10-30 14:40:31",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "",
"parentData": {
"assetid": "1482360",
"type_code": "folder",
"version": "0.0.1",
"name": "VISION AND STRATEGY",
"short_name": "VISION AND STRATEGY",
"status": "16",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-10-30 14:20:55",
"created_userid": "248053",
"updated": "2023-10-30 14:40:34",
"updated_userid": "248053",
"published": "2023-10-30 14:40:34",
"published_userid": "248053",
"status_changed": "2023-10-30 14:40:34",
"status_changed_userid": "248053",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "VISION AND STRATEGY",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1482360",
"parents": [
{
"linkid": "3162457",
"link_type": "1",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482471",
"1481122",
"1482330",
"1482360"
]
}
],
"childrenIds": [],
"yearCreated": 2023
},
{
"name": "Rankings and ratings",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/about/vision-and-strategy/rankings-and-ratings",
"id": 1482391,
"status": "Live",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/about/vision-and-strategy/rankings-and-ratings\">Rankings and ratings</a>",
"file": "page_redirect",
"publish": "2023-10-30 14:40:31",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "",
"parentData": {
"assetid": "1482360",
"type_code": "folder",
"version": "0.0.1",
"name": "VISION AND STRATEGY",
"short_name": "VISION AND STRATEGY",
"status": "16",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-10-30 14:20:55",
"created_userid": "248053",
"updated": "2023-10-30 14:40:34",
"updated_userid": "248053",
"published": "2023-10-30 14:40:34",
"published_userid": "248053",
"status_changed": "2023-10-30 14:40:34",
"status_changed_userid": "248053",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "VISION AND STRATEGY",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1482360",
"parents": [
{
"linkid": "3162458",
"link_type": "1",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482471",
"1481122",
"1482330",
"1482360"
]
}
],
"childrenIds": [],
"yearCreated": 2023
},
{
"name": "Campus locations and maps",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/about/locations-and-facilities/campus-locations-and-maps",
"id": 1482392,
"status": "Live",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/about/locations-and-facilities/campus-locations-and-maps\">Campus locations and maps</a>",
"file": "page_redirect",
"publish": "2023-10-30 14:40:30",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "",
"parentData": {
"assetid": "1482361",
"type_code": "folder",
"version": "0.0.1",
"name": "LOCATIONS AND FACILITIES",
"short_name": "LOCATIONS AND FACILITIES",
"status": "16",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-10-30 14:20:59",
"created_userid": "248053",
"updated": "2023-10-30 14:40:34",
"updated_userid": "248053",
"published": "2023-10-30 14:40:34",
"published_userid": "248053",
"status_changed": "2023-10-30 14:40:34",
"status_changed_userid": "248053",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "LOCATIONS AND FACILITIES",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1482361",
"parents": [
{
"linkid": "3162459",
"link_type": "1",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482471",
"1481122",
"1482330",
"1482361"
]
}
],
"childrenIds": [],
"yearCreated": 2023
},
{
"name": "Getting to La Trobe",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/about/locations-and-facilities/getting-to-la-trobe",
"id": 1482393,
"status": "Live",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/about/locations-and-facilities/getting-to-la-trobe\">Getting to La Trobe</a>",
"file": "page_redirect",
"publish": "2023-10-30 14:40:33",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "",
"parentData": {
"assetid": "1482361",
"type_code": "folder",
"version": "0.0.1",
"name": "LOCATIONS AND FACILITIES",
"short_name": "LOCATIONS AND FACILITIES",
"status": "16",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-10-30 14:20:59",
"created_userid": "248053",
"updated": "2023-10-30 14:40:34",
"updated_userid": "248053",
"published": "2023-10-30 14:40:34",
"published_userid": "248053",
"status_changed": "2023-10-30 14:40:34",
"status_changed_userid": "248053",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "LOCATIONS AND FACILITIES",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1482361",
"parents": [
{
"linkid": "3162460",
"link_type": "1",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482471",
"1481122",
"1482330",
"1482361"
]
}
],
"childrenIds": [],
"yearCreated": 2023
},
{
"name": "Facilities and services",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/about/locations-and-facilities/facilities-and-services",
"id": 1482394,
"status": "Live",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/about/locations-and-facilities/facilities-and-services\">Facilities and services</a>",
"file": "page_redirect",
"publish": "2023-10-30 14:40:34",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "",
"parentData": {
"assetid": "1482361",
"type_code": "folder",
"version": "0.0.1",
"name": "LOCATIONS AND FACILITIES",
"short_name": "LOCATIONS AND FACILITIES",
"status": "16",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-10-30 14:20:59",
"created_userid": "248053",
"updated": "2023-10-30 14:40:34",
"updated_userid": "248053",
"published": "2023-10-30 14:40:34",
"published_userid": "248053",
"status_changed": "2023-10-30 14:40:34",
"status_changed_userid": "248053",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "LOCATIONS AND FACILITIES",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1482361",
"parents": [
{
"linkid": "3162461",
"link_type": "1",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482471",
"1481122",
"1482330",
"1482361"
]
}
],
"childrenIds": [],
"yearCreated": 2023
},
{
"name": "Alumni2",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/about/engage-with-la-trobe/alumni",
"id": 1482395,
"status": "Live",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/about/engage-with-la-trobe/alumni\">Alumni2</a>",
"file": "page_redirect",
"publish": "2023-10-30 14:40:30",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "",
"parentData": {
"assetid": "1482362",
"type_code": "folder",
"version": "0.0.1",
"name": "ENGAGE WITH LA TROBE",
"short_name": "ENGAGE WITH LA TROBE",
"status": "16",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-10-30 14:21:04",
"created_userid": "248053",
"updated": "2023-10-30 14:40:34",
"updated_userid": "248053",
"published": "2023-10-30 14:40:34",
"published_userid": "248053",
"status_changed": "2023-10-30 14:40:34",
"status_changed_userid": "248053",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "ENGAGE WITH LA TROBE",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1482362",
"parents": [
{
"linkid": "3162462",
"link_type": "1",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482471",
"1481122",
"1482330",
"1482362"
]
}
],
"childrenIds": [],
"yearCreated": 2023
},
{
"name": "Careers at La Trobe",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/about/engage-with-la-trobe/careers-at-la-trobe",
"id": 1482396,
"status": "Live",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/about/engage-with-la-trobe/careers-at-la-trobe\">Careers at La Trobe</a>",
"file": "page_redirect",
"publish": "2023-10-30 14:40:31",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "",
"parentData": {
"assetid": "1482362",
"type_code": "folder",
"version": "0.0.1",
"name": "ENGAGE WITH LA TROBE",
"short_name": "ENGAGE WITH LA TROBE",
"status": "16",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-10-30 14:21:04",
"created_userid": "248053",
"updated": "2023-10-30 14:40:34",
"updated_userid": "248053",
"published": "2023-10-30 14:40:34",
"published_userid": "248053",
"status_changed": "2023-10-30 14:40:34",
"status_changed_userid": "248053",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "ENGAGE WITH LA TROBE",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1482362",
"parents": [
{
"linkid": "3162463",
"link_type": "1",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482471",
"1481122",
"1482330",
"1482362"
]
}
],
"childrenIds": [],
"yearCreated": 2023
},
{
"name": "Research at La Trobe",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/about/engage-with-la-trobe/research-at-la-trobe",
"id": 1482397,
"status": "Live",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/about/engage-with-la-trobe/research-at-la-trobe\">Research at La Trobe</a>",
"file": "page_redirect",
"publish": "2023-10-30 14:40:30",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "",
"parentData": {
"assetid": "1482362",
"type_code": "folder",
"version": "0.0.1",
"name": "ENGAGE WITH LA TROBE",
"short_name": "ENGAGE WITH LA TROBE",
"status": "16",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-10-30 14:21:04",
"created_userid": "248053",
"updated": "2023-10-30 14:40:34",
"updated_userid": "248053",
"published": "2023-10-30 14:40:34",
"published_userid": "248053",
"status_changed": "2023-10-30 14:40:34",
"status_changed_userid": "248053",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "ENGAGE WITH LA TROBE",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1482362",
"parents": [
{
"linkid": "3162464",
"link_type": "1",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482471",
"1481122",
"1482330",
"1482362"
]
}
],
"childrenIds": [],
"yearCreated": 2023
},
{
"name": "Contact us",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/about/engage-with-la-trobe/contact-us",
"id": 1482398,
"status": "Live",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/about/engage-with-la-trobe/contact-us\">Contact us</a>",
"file": "page_redirect",
"publish": "2023-10-30 14:40:33",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "",
"parentData": {
"assetid": "1482362",
"type_code": "folder",
"version": "0.0.1",
"name": "ENGAGE WITH LA TROBE",
"short_name": "ENGAGE WITH LA TROBE",
"status": "16",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-10-30 14:21:04",
"created_userid": "248053",
"updated": "2023-10-30 14:40:34",
"updated_userid": "248053",
"published": "2023-10-30 14:40:34",
"published_userid": "248053",
"status_changed": "2023-10-30 14:40:34",
"status_changed_userid": "248053",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "ENGAGE WITH LA TROBE",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1482362",
"parents": [
{
"linkid": "3162465",
"link_type": "1",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482471",
"1481122",
"1482330",
"1482362"
]
}
],
"childrenIds": [],
"yearCreated": 2023
},
{
"name": "WELCOME TO LA TROBE",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/international-students/welcome-to-la-trobe",
"id": 1482399,
"status": "Live",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/international-students/welcome-to-la-trobe\">WELCOME TO LA TROBE</a>",
"file": "folder",
"publish": "2023-10-30 14:40:35",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "null",
"parentData": {
"assetid": "1481130",
"type_code": "folder",
"version": "0.1.0",
"name": "International students",
"short_name": "International students",
"status": "16",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-09-07 18:01:10",
"created_userid": "1480557",
"updated": "2023-10-30 15:58:24",
"updated_userid": "248053",
"published": "2023-10-30 15:58:24",
"published_userid": "248053",
"status_changed": "2023-10-30 15:58:24",
"status_changed_userid": "248053",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "International students",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1481130",
"parents": [
{
"linkid": "3162466",
"link_type": "1",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482471",
"1481122",
"1481130"
]
}
],
"childrenIds": [
"1482409",
"1482410",
"1482411",
"1482412",
"1482413"
],
"yearCreated": 2023
},
{
"name": "APPLYING FOR STUDY",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/international-students/applying-for-study",
"id": 1482400,
"status": "Live",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/international-students/applying-for-study\">APPLYING FOR STUDY</a>",
"file": "folder",
"publish": "2023-10-30 14:40:31",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "null",
"parentData": {
"assetid": "1481130",
"type_code": "folder",
"version": "0.1.0",
"name": "International students",
"short_name": "International students",
"status": "16",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-09-07 18:01:10",
"created_userid": "1480557",
"updated": "2023-10-30 15:58:24",
"updated_userid": "248053",
"published": "2023-10-30 15:58:24",
"published_userid": "248053",
"status_changed": "2023-10-30 15:58:24",
"status_changed_userid": "248053",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "International students",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1481130",
"parents": [
{
"linkid": "3162467",
"link_type": "1",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482471",
"1481122",
"1481130"
]
}
],
"childrenIds": [
"1482414",
"1482415",
"1482416",
"1482417",
"1482418",
"1482419",
"1482420"
],
"yearCreated": 2023
},
{
"name": "HAVE AN OFFER? WHAT'S NEXT",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/international-students/have-an-offer-whats-next",
"id": 1482401,
"status": "Live",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/international-students/have-an-offer-whats-next\">HAVE AN OFFER? WHAT'S NEXT</a>",
"file": "folder",
"publish": "2023-10-30 14:40:34",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "null",
"parentData": {
"assetid": "1481130",
"type_code": "folder",
"version": "0.1.0",
"name": "International students",
"short_name": "International students",
"status": "16",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-09-07 18:01:10",
"created_userid": "1480557",
"updated": "2023-10-30 15:58:24",
"updated_userid": "248053",
"published": "2023-10-30 15:58:24",
"published_userid": "248053",
"status_changed": "2023-10-30 15:58:24",
"status_changed_userid": "248053",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "International students",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1481130",
"parents": [
{
"linkid": "3162468",
"link_type": "1",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482471",
"1481122",
"1481130"
]
}
],
"childrenIds": [
"1482421",
"1482422",
"1482423",
"1482424"
],
"yearCreated": 2023
},
{
"name": "CONTACT US",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/international-students/contact-us",
"id": 1482402,
"status": "Live",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/international-students/contact-us\">CONTACT US</a>",
"file": "folder",
"publish": "2023-10-30 14:40:34",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "null",
"parentData": {
"assetid": "1481130",
"type_code": "folder",
"version": "0.1.0",
"name": "International students",
"short_name": "International students",
"status": "16",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-09-07 18:01:10",
"created_userid": "1480557",
"updated": "2023-10-30 15:58:24",
"updated_userid": "248053",
"published": "2023-10-30 15:58:24",
"published_userid": "248053",
"status_changed": "2023-10-30 15:58:24",
"status_changed_userid": "248053",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "International students",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1481130",
"parents": [
{
"linkid": "3162469",
"link_type": "1",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482471",
"1481122",
"1481130"
]
}
],
"childrenIds": [
"1482425",
"1482426",
"1482427"
],
"yearCreated": 2023
},
{
"name": "Our campuses",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/international-students/learn-more-about-la-trobe/life-at-la-trobe2",
"id": 1482403,
"status": "Live",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/international-students/learn-more-about-la-trobe/life-at-la-trobe2\">Our campuses</a>",
"file": "page_redirect",
"publish": "2023-10-30 14:40:35",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "",
"parentData": {
"assetid": "1481131",
"type_code": "folder",
"version": "0.0.1",
"name": "LEARN MORE ABOUT LA TROBE",
"short_name": "LEARN MORE ABOUT LA TROBE",
"status": "16",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-09-07 18:01:40",
"created_userid": "1480557",
"updated": "2023-09-26 09:18:48",
"updated_userid": "248053",
"published": "2023-09-26 09:18:48",
"published_userid": "248053",
"status_changed": "2023-09-26 09:18:48",
"status_changed_userid": "248053",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "LEARN MORE ABOUT LA TROBE",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1481131",
"parents": [
{
"linkid": "3162470",
"link_type": "1",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482471",
"1481122",
"1481130",
"1481131"
]
}
],
"childrenIds": [],
"yearCreated": 2023
},
{
"name": "Rankings and ratings",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/international-students/learn-more-about-la-trobe/rankings-and-ratings",
"id": 1482404,
"status": "Live",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/international-students/learn-more-about-la-trobe/rankings-and-ratings\">Rankings and ratings</a>",
"file": "page_redirect",
"publish": "2023-10-30 14:40:29",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "",
"parentData": {
"assetid": "1481131",
"type_code": "folder",
"version": "0.0.1",
"name": "LEARN MORE ABOUT LA TROBE",
"short_name": "LEARN MORE ABOUT LA TROBE",
"status": "16",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-09-07 18:01:40",
"created_userid": "1480557",
"updated": "2023-09-26 09:18:48",
"updated_userid": "248053",
"published": "2023-09-26 09:18:48",
"published_userid": "248053",
"status_changed": "2023-09-26 09:18:48",
"status_changed_userid": "248053",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "LEARN MORE ABOUT LA TROBE",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1481131",
"parents": [
{
"linkid": "3162471",
"link_type": "1",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482471",
"1481122",
"1481130",
"1481131"
]
}
],
"childrenIds": [],
"yearCreated": 2023
},
{
"name": "开始在乐卓博大学的学习",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/international-students/learn-more-about-la-trobe/1482405",
"id": 1482405,
"status": "Live",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/international-students/learn-more-about-la-trobe/1482405\">开始在乐卓博大学的学习</a>",
"file": "page_redirect",
"publish": "2023-10-30 14:40:30",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "",
"parentData": {
"assetid": "1481131",
"type_code": "folder",
"version": "0.0.1",
"name": "LEARN MORE ABOUT LA TROBE",
"short_name": "LEARN MORE ABOUT LA TROBE",
"status": "16",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-09-07 18:01:40",
"created_userid": "1480557",
"updated": "2023-09-26 09:18:48",
"updated_userid": "248053",
"published": "2023-09-26 09:18:48",
"published_userid": "248053",
"status_changed": "2023-09-26 09:18:48",
"status_changed_userid": "248053",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "LEARN MORE ABOUT LA TROBE",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1481131",
"parents": [
{
"linkid": "3162472",
"link_type": "1",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482471",
"1481122",
"1481130",
"1481131"
]
}
],
"childrenIds": [],
"yearCreated": 2023
},
{
"name": "Vietnamese students",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/international-students/learn-more-about-la-trobe/vietnamese-students",
"id": 1482406,
"status": "Live",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/international-students/learn-more-about-la-trobe/vietnamese-students\">Vietnamese students</a>",
"file": "page_redirect",
"publish": "2023-10-30 14:40:34",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "",
"parentData": {
"assetid": "1481131",
"type_code": "folder",
"version": "0.0.1",
"name": "LEARN MORE ABOUT LA TROBE",
"short_name": "LEARN MORE ABOUT LA TROBE",
"status": "16",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-09-07 18:01:40",
"created_userid": "1480557",
"updated": "2023-09-26 09:18:48",
"updated_userid": "248053",
"published": "2023-09-26 09:18:48",
"published_userid": "248053",
"status_changed": "2023-09-26 09:18:48",
"status_changed_userid": "248053",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "LEARN MORE ABOUT LA TROBE",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1481131",
"parents": [
{
"linkid": "3162473",
"link_type": "1",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482471",
"1481122",
"1481130",
"1481131"
]
}
],
"childrenIds": [],
"yearCreated": 2023
},
{
"name": "Events and webinars",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/international-students/learn-more-about-la-trobe/events-and-webinars",
"id": 1482407,
"status": "Live",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/international-students/learn-more-about-la-trobe/events-and-webinars\">Events and webinars</a>",
"file": "page_redirect",
"publish": "2023-10-30 14:40:33",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "",
"parentData": {
"assetid": "1481131",
"type_code": "folder",
"version": "0.0.1",
"name": "LEARN MORE ABOUT LA TROBE",
"short_name": "LEARN MORE ABOUT LA TROBE",
"status": "16",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-09-07 18:01:40",
"created_userid": "1480557",
"updated": "2023-09-26 09:18:48",
"updated_userid": "248053",
"published": "2023-09-26 09:18:48",
"published_userid": "248053",
"status_changed": "2023-09-26 09:18:48",
"status_changed_userid": "248053",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "LEARN MORE ABOUT LA TROBE",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1481131",
"parents": [
{
"linkid": "3162474",
"link_type": "1",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482471",
"1481122",
"1481130",
"1481131"
]
}
],
"childrenIds": [],
"yearCreated": 2023
},
{
"name": "FAQs",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/international-students/learn-more-about-la-trobe/faqs",
"id": 1482408,
"status": "Live",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/international-students/learn-more-about-la-trobe/faqs\">FAQs</a>",
"file": "page_redirect",
"publish": "2023-10-30 14:40:33",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "",
"parentData": {
"assetid": "1481131",
"type_code": "folder",
"version": "0.0.1",
"name": "LEARN MORE ABOUT LA TROBE",
"short_name": "LEARN MORE ABOUT LA TROBE",
"status": "16",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-09-07 18:01:40",
"created_userid": "1480557",
"updated": "2023-09-26 09:18:48",
"updated_userid": "248053",
"published": "2023-09-26 09:18:48",
"published_userid": "248053",
"status_changed": "2023-09-26 09:18:48",
"status_changed_userid": "248053",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "LEARN MORE ABOUT LA TROBE",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1481131",
"parents": [
{
"linkid": "3162475",
"link_type": "1",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482471",
"1481122",
"1481130",
"1481131"
]
}
],
"childrenIds": [],
"yearCreated": 2023
},
{
"name": "Welcome information",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/international-students/welcome-to-la-trobe/welcome-information",
"id": 1482409,
"status": "Live",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/international-students/welcome-to-la-trobe/welcome-information\">Welcome information</a>",
"file": "page_redirect",
"publish": "2023-10-30 14:40:32",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "",
"parentData": {
"assetid": "1482399",
"type_code": "folder",
"version": "0.0.1",
"name": "WELCOME TO LA TROBE",
"short_name": "WELCOME TO LA TROBE",
"status": "16",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-10-30 14:26:07",
"created_userid": "248053",
"updated": "2023-10-30 14:40:35",
"updated_userid": "248053",
"published": "2023-10-30 14:40:35",
"published_userid": "248053",
"status_changed": "2023-10-30 14:40:35",
"status_changed_userid": "248053",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "WELCOME TO LA TROBE",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1482399",
"parents": [
{
"linkid": "3162476",
"link_type": "1",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482471",
"1481122",
"1481130",
"1482399"
]
}
],
"childrenIds": [],
"yearCreated": 2023
},
{
"name": "Pre-departure",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/international-students/welcome-to-la-trobe/pre-departure",
"id": 1482410,
"status": "Live",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/international-students/welcome-to-la-trobe/pre-departure\">Pre-departure</a>",
"file": "page_redirect",
"publish": "2023-10-30 14:40:35",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "",
"parentData": {
"assetid": "1482399",
"type_code": "folder",
"version": "0.0.1",
"name": "WELCOME TO LA TROBE",
"short_name": "WELCOME TO LA TROBE",
"status": "16",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-10-30 14:26:07",
"created_userid": "248053",
"updated": "2023-10-30 14:40:35",
"updated_userid": "248053",
"published": "2023-10-30 14:40:35",
"published_userid": "248053",
"status_changed": "2023-10-30 14:40:35",
"status_changed_userid": "248053",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "WELCOME TO LA TROBE",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1482399",
"parents": [
{
"linkid": "3162477",
"link_type": "1",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482471",
"1481122",
"1481130",
"1482399"
]
}
],
"childrenIds": [],
"yearCreated": 2023
},
{
"name": "Travel and arrival",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/international-students/welcome-to-la-trobe/travel-and-arrival",
"id": 1482411,
"status": "Live",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/international-students/welcome-to-la-trobe/travel-and-arrival\">Travel and arrival</a>",
"file": "page_redirect",
"publish": "2023-10-30 14:40:32",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "",
"parentData": {
"assetid": "1482399",
"type_code": "folder",
"version": "0.0.1",
"name": "WELCOME TO LA TROBE",
"short_name": "WELCOME TO LA TROBE",
"status": "16",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-10-30 14:26:07",
"created_userid": "248053",
"updated": "2023-10-30 14:40:35",
"updated_userid": "248053",
"published": "2023-10-30 14:40:35",
"published_userid": "248053",
"status_changed": "2023-10-30 14:40:35",
"status_changed_userid": "248053",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "WELCOME TO LA TROBE",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1482399",
"parents": [
{
"linkid": "3162478",
"link_type": "1",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482471",
"1481122",
"1481130",
"1482399"
]
}
],
"childrenIds": [],
"yearCreated": 2023
},
{
"name": "Accommodation",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/international-students/welcome-to-la-trobe/accommodation",
"id": 1482412,
"status": "Live",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/international-students/welcome-to-la-trobe/accommodation\">Accommodation</a>",
"file": "page_redirect",
"publish": "2023-10-30 14:40:33",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "",
"parentData": {
"assetid": "1482399",
"type_code": "folder",
"version": "0.0.1",
"name": "WELCOME TO LA TROBE",
"short_name": "WELCOME TO LA TROBE",
"status": "16",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-10-30 14:26:07",
"created_userid": "248053",
"updated": "2023-10-30 14:40:35",
"updated_userid": "248053",
"published": "2023-10-30 14:40:35",
"published_userid": "248053",
"status_changed": "2023-10-30 14:40:35",
"status_changed_userid": "248053",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "WELCOME TO LA TROBE",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1482399",
"parents": [
{
"linkid": "3162479",
"link_type": "1",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482471",
"1481122",
"1481130",
"1482399"
]
}
],
"childrenIds": [],
"yearCreated": 2023
},
{
"name": "Campus life",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/international-students/welcome-to-la-trobe/campus-life",
"id": 1482413,
"status": "Live",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/international-students/welcome-to-la-trobe/campus-life\">Campus life</a>",
"file": "page_redirect",
"publish": "2023-10-30 14:40:34",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "",
"parentData": {
"assetid": "1482399",
"type_code": "folder",
"version": "0.0.1",
"name": "WELCOME TO LA TROBE",
"short_name": "WELCOME TO LA TROBE",
"status": "16",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-10-30 14:26:07",
"created_userid": "248053",
"updated": "2023-10-30 14:40:35",
"updated_userid": "248053",
"published": "2023-10-30 14:40:35",
"published_userid": "248053",
"status_changed": "2023-10-30 14:40:35",
"status_changed_userid": "248053",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "WELCOME TO LA TROBE",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1482399",
"parents": [
{
"linkid": "3162480",
"link_type": "1",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482471",
"1481122",
"1481130",
"1482399"
]
}
],
"childrenIds": [],
"yearCreated": 2023
},
{
"name": "Courses and study options",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/international-students/applying-for-study/courses-and-study-options",
"id": 1482414,
"status": "Live",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/international-students/applying-for-study/courses-and-study-options\">Courses and study options</a>",
"file": "page_redirect",
"publish": "2023-10-30 14:40:35",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "",
"parentData": {
"assetid": "1482400",
"type_code": "folder",
"version": "0.0.1",
"name": "APPLYING FOR STUDY",
"short_name": "APPLYING FOR STUDY",
"status": "16",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-10-30 14:26:15",
"created_userid": "248053",
"updated": "2023-10-30 14:40:31",
"updated_userid": "248053",
"published": "2023-10-30 14:40:31",
"published_userid": "248053",
"status_changed": "2023-10-30 14:40:31",
"status_changed_userid": "248053",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "APPLYING FOR STUDY",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1482400",
"parents": [
{
"linkid": "3162481",
"link_type": "1",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482471",
"1481122",
"1481130",
"1482400"
]
}
],
"childrenIds": [],
"yearCreated": 2023
},
{
"name": "How to apply",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/international-students/applying-for-study/how-to-apply",
"id": 1482415,
"status": "Live",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/international-students/applying-for-study/how-to-apply\">How to apply</a>",
"file": "page_redirect",
"publish": "2023-10-30 14:40:35",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "",
"parentData": {
"assetid": "1482400",
"type_code": "folder",
"version": "0.0.1",
"name": "APPLYING FOR STUDY",
"short_name": "APPLYING FOR STUDY",
"status": "16",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-10-30 14:26:15",
"created_userid": "248053",
"updated": "2023-10-30 14:40:31",
"updated_userid": "248053",
"published": "2023-10-30 14:40:31",
"published_userid": "248053",
"status_changed": "2023-10-30 14:40:31",
"status_changed_userid": "248053",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "APPLYING FOR STUDY",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1482400",
"parents": [
{
"linkid": "3162482",
"link_type": "1",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482471",
"1481122",
"1481130",
"1482400"
]
}
],
"childrenIds": [],
"yearCreated": 2023
},
{
"name": "English language requirements",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/international-students/applying-for-study/english-language-requirements",
"id": 1482416,
"status": "Live",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/international-students/applying-for-study/english-language-requirements\">English language requirements</a>",
"file": "page_redirect",
"publish": "2023-10-30 14:40:30",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "",
"parentData": {
"assetid": "1482400",
"type_code": "folder",
"version": "0.0.1",
"name": "APPLYING FOR STUDY",
"short_name": "APPLYING FOR STUDY",
"status": "16",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-10-30 14:26:15",
"created_userid": "248053",
"updated": "2023-10-30 14:40:31",
"updated_userid": "248053",
"published": "2023-10-30 14:40:31",
"published_userid": "248053",
"status_changed": "2023-10-30 14:40:31",
"status_changed_userid": "248053",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "APPLYING FOR STUDY",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1482400",
"parents": [
{
"linkid": "3162483",
"link_type": "1",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482471",
"1481122",
"1481130",
"1482400"
]
}
],
"childrenIds": [],
"yearCreated": 2023
},
{
"name": "Fees and scholarships",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/international-students/applying-for-study/fees-and-scholarships",
"id": 1482417,
"status": "Live",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/international-students/applying-for-study/fees-and-scholarships\">Fees and scholarships</a>",
"file": "page_redirect",
"publish": "2023-10-30 14:40:34",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "",
"parentData": {
"assetid": "1482400",
"type_code": "folder",
"version": "0.0.1",
"name": "APPLYING FOR STUDY",
"short_name": "APPLYING FOR STUDY",
"status": "16",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-10-30 14:26:15",
"created_userid": "248053",
"updated": "2023-10-30 14:40:31",
"updated_userid": "248053",
"published": "2023-10-30 14:40:31",
"published_userid": "248053",
"status_changed": "2023-10-30 14:40:31",
"status_changed_userid": "248053",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "APPLYING FOR STUDY",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1482400",
"parents": [
{
"linkid": "3162484",
"link_type": "1",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482471",
"1481122",
"1481130",
"1482400"
]
}
],
"childrenIds": [],
"yearCreated": 2023
},
{
"name": "Pathways",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/international-students/applying-for-study/pathways",
"id": 1482418,
"status": "Live",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/international-students/applying-for-study/pathways\">Pathways</a>",
"file": "page_redirect",
"publish": "2023-10-30 14:40:34",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "",
"parentData": {
"assetid": "1482400",
"type_code": "folder",
"version": "0.0.1",
"name": "APPLYING FOR STUDY",
"short_name": "APPLYING FOR STUDY",
"status": "16",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-10-30 14:26:15",
"created_userid": "248053",
"updated": "2023-10-30 14:40:31",
"updated_userid": "248053",
"published": "2023-10-30 14:40:31",
"published_userid": "248053",
"status_changed": "2023-10-30 14:40:31",
"status_changed_userid": "248053",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "APPLYING FOR STUDY",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1482400",
"parents": [
{
"linkid": "3162485",
"link_type": "1",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482471",
"1481122",
"1481130",
"1482400"
]
}
],
"childrenIds": [],
"yearCreated": 2023
},
{
"name": "Find an agent",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/international-students/applying-for-study/find-an-agent",
"id": 1482419,
"status": "Live",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/international-students/applying-for-study/find-an-agent\">Find an agent</a>",
"file": "page_redirect",
"publish": "2023-10-30 14:40:34",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "",
"parentData": {
"assetid": "1482400",
"type_code": "folder",
"version": "0.0.1",
"name": "APPLYING FOR STUDY",
"short_name": "APPLYING FOR STUDY",
"status": "16",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-10-30 14:26:15",
"created_userid": "248053",
"updated": "2023-10-30 14:40:31",
"updated_userid": "248053",
"published": "2023-10-30 14:40:31",
"published_userid": "248053",
"status_changed": "2023-10-30 14:40:31",
"status_changed_userid": "248053",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "APPLYING FOR STUDY",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1482400",
"parents": [
{
"linkid": "3162486",
"link_type": "1",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482471",
"1481122",
"1481130",
"1482400"
]
}
],
"childrenIds": [],
"yearCreated": 2023
},
{
"name": "Information for agent representatives",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/international-students/applying-for-study/information-for-agent-representatives",
"id": 1482420,
"status": "Live",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/international-students/applying-for-study/information-for-agent-representatives\">Information for agent representatives</a>",
"file": "page_redirect",
"publish": "2023-10-30 14:40:32",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "",
"parentData": {
"assetid": "1482400",
"type_code": "folder",
"version": "0.0.1",
"name": "APPLYING FOR STUDY",
"short_name": "APPLYING FOR STUDY",
"status": "16",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-10-30 14:26:15",
"created_userid": "248053",
"updated": "2023-10-30 14:40:31",
"updated_userid": "248053",
"published": "2023-10-30 14:40:31",
"published_userid": "248053",
"status_changed": "2023-10-30 14:40:31",
"status_changed_userid": "248053",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "APPLYING FOR STUDY",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1482400",
"parents": [
{
"linkid": "3162487",
"link_type": "1",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482471",
"1481122",
"1481130",
"1482400"
]
}
],
"childrenIds": [],
"yearCreated": 2023
},
{
"name": "Accepting your offer",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/international-students/have-an-offer-whats-next/accepting-your-offer",
"id": 1482421,
"status": "Live",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/international-students/have-an-offer-whats-next/accepting-your-offer\">Accepting your offer</a>",
"file": "page_redirect",
"publish": "2023-10-30 14:40:31",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "",
"parentData": {
"assetid": "1482401",
"type_code": "folder",
"version": "0.0.1",
"name": "HAVE AN OFFER? WHAT'S NEXT",
"short_name": "HAVE AN OFFER? WHAT'S NEXT",
"status": "16",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-10-30 14:26:33",
"created_userid": "248053",
"updated": "2023-10-30 14:40:34",
"updated_userid": "248053",
"published": "2023-10-30 14:40:34",
"published_userid": "248053",
"status_changed": "2023-10-30 14:40:34",
"status_changed_userid": "248053",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "HAVE AN OFFER? WHAT'S NEXT",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1482401",
"parents": [
{
"linkid": "3162488",
"link_type": "1",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482471",
"1481122",
"1481130",
"1482401"
]
}
],
"childrenIds": [],
"yearCreated": 2023
},
{
"name": "Enroling in your course and gettting started",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/international-students/have-an-offer-whats-next/enroling-in-your-course-and-gettting-started",
"id": 1482422,
"status": "Live",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/international-students/have-an-offer-whats-next/enroling-in-your-course-and-gettting-started\">Enroling in your course and gettting started</a>",
"file": "page_redirect",
"publish": "2023-10-30 14:40:30",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "",
"parentData": {
"assetid": "1482401",
"type_code": "folder",
"version": "0.0.1",
"name": "HAVE AN OFFER? WHAT'S NEXT",
"short_name": "HAVE AN OFFER? WHAT'S NEXT",
"status": "16",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-10-30 14:26:33",
"created_userid": "248053",
"updated": "2023-10-30 14:40:34",
"updated_userid": "248053",
"published": "2023-10-30 14:40:34",
"published_userid": "248053",
"status_changed": "2023-10-30 14:40:34",
"status_changed_userid": "248053",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "HAVE AN OFFER? WHAT'S NEXT",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1482401",
"parents": [
{
"linkid": "3162489",
"link_type": "1",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482471",
"1481122",
"1481130",
"1482401"
]
}
],
"childrenIds": [],
"yearCreated": 2023
},
{
"name": "Pre-departure guide",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/international-students/have-an-offer-whats-next/pre-departure-guide",
"id": 1482423,
"status": "Live",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/international-students/have-an-offer-whats-next/pre-departure-guide\">Pre-departure guide</a>",
"file": "page_redirect",
"publish": "2023-10-30 14:40:31",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "",
"parentData": {
"assetid": "1482401",
"type_code": "folder",
"version": "0.0.1",
"name": "HAVE AN OFFER? WHAT'S NEXT",
"short_name": "HAVE AN OFFER? WHAT'S NEXT",
"status": "16",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-10-30 14:26:33",
"created_userid": "248053",
"updated": "2023-10-30 14:40:34",
"updated_userid": "248053",
"published": "2023-10-30 14:40:34",
"published_userid": "248053",
"status_changed": "2023-10-30 14:40:34",
"status_changed_userid": "248053",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "HAVE AN OFFER? WHAT'S NEXT",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1482401",
"parents": [
{
"linkid": "3162490",
"link_type": "1",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482471",
"1481122",
"1481130",
"1482401"
]
}
],
"childrenIds": [],
"yearCreated": 2023
},
{
"name": "Support services",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/international-students/have-an-offer-whats-next/support-services",
"id": 1482424,
"status": "Live",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/international-students/have-an-offer-whats-next/support-services\">Support services</a>",
"file": "page_redirect",
"publish": "2023-10-30 14:40:30",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "",
"parentData": {
"assetid": "1482401",
"type_code": "folder",
"version": "0.0.1",
"name": "HAVE AN OFFER? WHAT'S NEXT",
"short_name": "HAVE AN OFFER? WHAT'S NEXT",
"status": "16",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-10-30 14:26:33",
"created_userid": "248053",
"updated": "2023-10-30 14:40:34",
"updated_userid": "248053",
"published": "2023-10-30 14:40:34",
"published_userid": "248053",
"status_changed": "2023-10-30 14:40:34",
"status_changed_userid": "248053",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "HAVE AN OFFER? WHAT'S NEXT",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1482401",
"parents": [
{
"linkid": "3162491",
"link_type": "1",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482471",
"1481122",
"1481130",
"1482401"
]
}
],
"childrenIds": [],
"yearCreated": 2023
},
{
"name": "Call us on (+61 3) 6145 0033",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/international-students/contact-us/call-us-on-61-3-6145-0033",
"id": 1482425,
"status": "Live",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/international-students/contact-us/call-us-on-61-3-6145-0033\">Call us on (+61 3) 6145 0033</a>",
"file": "page_redirect",
"publish": "2023-10-30 14:40:34",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "",
"parentData": {
"assetid": "1482402",
"type_code": "folder",
"version": "0.0.1",
"name": "CONTACT US",
"short_name": "CONTACT US",
"status": "16",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-10-30 14:26:38",
"created_userid": "248053",
"updated": "2023-10-30 14:40:34",
"updated_userid": "248053",
"published": "2023-10-30 14:40:34",
"published_userid": "248053",
"status_changed": "2023-10-30 14:40:34",
"status_changed_userid": "248053",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "CONTACT US",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1482402",
"parents": [
{
"linkid": "3162492",
"link_type": "1",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482471",
"1481122",
"1481130",
"1482402"
]
}
],
"childrenIds": [],
"yearCreated": 2023
},
{
"name": "Enquire now",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/international-students/contact-us/enquire-now",
"id": 1482426,
"status": "Live",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/international-students/contact-us/enquire-now\">Enquire now</a>",
"file": "page_redirect",
"publish": "2023-10-30 14:40:32",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "",
"parentData": {
"assetid": "1482402",
"type_code": "folder",
"version": "0.0.1",
"name": "CONTACT US",
"short_name": "CONTACT US",
"status": "16",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-10-30 14:26:38",
"created_userid": "248053",
"updated": "2023-10-30 14:40:34",
"updated_userid": "248053",
"published": "2023-10-30 14:40:34",
"published_userid": "248053",
"status_changed": "2023-10-30 14:40:34",
"status_changed_userid": "248053",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "CONTACT US",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1482402",
"parents": [
{
"linkid": "3162493",
"link_type": "1",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482471",
"1481122",
"1481130",
"1482402"
]
}
],
"childrenIds": [],
"yearCreated": 2023
},
{
"name": "Find us on Twitter, Facebook and Weibo",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/international-students/contact-us/find-us-on-twitter,-facebook-and-weibo",
"id": 1482427,
"status": "Live",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/international-students/contact-us/find-us-on-twitter,-facebook-and-weibo\">Find us on Twitter, Facebook and Weibo</a>",
"file": "page_redirect",
"publish": "2023-10-30 14:40:31",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "",
"parentData": {
"assetid": "1482402",
"type_code": "folder",
"version": "0.0.1",
"name": "CONTACT US",
"short_name": "CONTACT US",
"status": "16",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-10-30 14:26:38",
"created_userid": "248053",
"updated": "2023-10-30 14:40:34",
"updated_userid": "248053",
"published": "2023-10-30 14:40:34",
"published_userid": "248053",
"status_changed": "2023-10-30 14:40:34",
"status_changed_userid": "248053",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "CONTACT US",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1482402",
"parents": [
{
"linkid": "3162494",
"link_type": "1",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482471",
"1481122",
"1481130",
"1482402"
]
}
],
"childrenIds": [],
"yearCreated": 2023
},
{
"name": "Criminology",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/courses/browse-by-discipline/criminology",
"id": 1482428,
"status": "Under Construction",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/courses/browse-by-discipline/criminology\">Criminology</a>",
"file": "page_redirect",
"publish": "Never",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "",
"parentData": {
"assetid": "1481085",
"type_code": "folder",
"version": "0.0.1",
"name": "BROWSE BY DISCIPLINE",
"short_name": "BROWSE BY DISCIPLINE",
"status": "16",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-09-04 15:44:31",
"created_userid": "1480557",
"updated": "2023-09-26 09:18:48",
"updated_userid": "248053",
"published": "2023-09-26 09:18:48",
"published_userid": "248053",
"status_changed": "2023-09-26 09:18:48",
"status_changed_userid": "248053",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "BROWSE BY DISCIPLINE",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1481085",
"parents": [
{
"linkid": "3162495",
"link_type": "1",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482471",
"1481122",
"1481083",
"1481085"
]
}
],
"childrenIds": [],
"yearCreated": 2023
},
{
"name": "Education and teaching",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/courses/browse-by-discipline/education-and-teaching",
"id": 1482429,
"status": "Under Construction",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/courses/browse-by-discipline/education-and-teaching\">Education and teaching</a>",
"file": "page_redirect",
"publish": "Never",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "",
"parentData": {
"assetid": "1481085",
"type_code": "folder",
"version": "0.0.1",
"name": "BROWSE BY DISCIPLINE",
"short_name": "BROWSE BY DISCIPLINE",
"status": "16",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-09-04 15:44:31",
"created_userid": "1480557",
"updated": "2023-09-26 09:18:48",
"updated_userid": "248053",
"published": "2023-09-26 09:18:48",
"published_userid": "248053",
"status_changed": "2023-09-26 09:18:48",
"status_changed_userid": "248053",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "BROWSE BY DISCIPLINE",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1481085",
"parents": [
{
"linkid": "3162496",
"link_type": "1",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482471",
"1481122",
"1481083",
"1481085"
]
}
],
"childrenIds": [],
"yearCreated": 2023
},
{
"name": "Engineering",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/courses/browse-by-discipline/engineering",
"id": 1482430,
"status": "Under Construction",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/courses/browse-by-discipline/engineering\">Engineering</a>",
"file": "page_redirect",
"publish": "Never",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "",
"parentData": {
"assetid": "1481085",
"type_code": "folder",
"version": "0.0.1",
"name": "BROWSE BY DISCIPLINE",
"short_name": "BROWSE BY DISCIPLINE",
"status": "16",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-09-04 15:44:31",
"created_userid": "1480557",
"updated": "2023-09-26 09:18:48",
"updated_userid": "248053",
"published": "2023-09-26 09:18:48",
"published_userid": "248053",
"status_changed": "2023-09-26 09:18:48",
"status_changed_userid": "248053",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "BROWSE BY DISCIPLINE",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1481085",
"parents": [
{
"linkid": "3162497",
"link_type": "1",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482471",
"1481122",
"1481083",
"1481085"
]
}
],
"childrenIds": [],
"yearCreated": 2023
},
{
"name": "Health",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/student/nav-middle/courses/browse-by-discipline/health",
"id": 1482431,
"status": "Under Construction",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/student/nav-middle/courses/browse-by-discipline/health\">Health</a>",
"file": "page_redirect",
"publish": "Never",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "",
"parentData": {
"assetid": "1482487",
"type_code": "folder",
"version": "0.0.0",
"name": "BROWSE BY DISCIPLINE",
"short_name": "BROWSE BY DISCIPLINE",
"status": "2",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-10-31 14:28:53",
"created_userid": "1480557",
"updated": "2023-10-31 14:28:53",
"updated_userid": "1480557",
"published": "Never",
"published_userid": "",
"status_changed": "1970-01-01 10:00:00",
"status_changed_userid": "",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "BROWSE BY DISCIPLINE",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1482487",
"parents": [
{
"linkid": "3162583",
"link_type": "1",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482472",
"1482473",
"1482479",
"1482487"
]
}
],
"childrenIds": [],
"yearCreated": 2023
},
{
"name": "Information technology",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/student/nav-middle/courses/browse-by-discipline/information-technology",
"id": 1482432,
"status": "Under Construction",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/student/nav-middle/courses/browse-by-discipline/information-technology\">Information technology</a>",
"file": "page_redirect",
"publish": "Never",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "",
"parentData": {
"assetid": "1482487",
"type_code": "folder",
"version": "0.0.0",
"name": "BROWSE BY DISCIPLINE",
"short_name": "BROWSE BY DISCIPLINE",
"status": "2",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-10-31 14:28:53",
"created_userid": "1480557",
"updated": "2023-10-31 14:28:53",
"updated_userid": "1480557",
"published": "Never",
"published_userid": "",
"status_changed": "1970-01-01 10:00:00",
"status_changed_userid": "",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "BROWSE BY DISCIPLINE",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1482487",
"parents": [
{
"linkid": "3162584",
"link_type": "1",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482472",
"1482473",
"1482479",
"1482487"
]
}
],
"childrenIds": [],
"yearCreated": 2023
},
{
"name": "Law",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/student/nav-middle/courses/browse-by-discipline/law",
"id": 1482433,
"status": "Under Construction",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/student/nav-middle/courses/browse-by-discipline/law\">Law</a>",
"file": "page_redirect",
"publish": "Never",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "",
"parentData": {
"assetid": "1482487",
"type_code": "folder",
"version": "0.0.0",
"name": "BROWSE BY DISCIPLINE",
"short_name": "BROWSE BY DISCIPLINE",
"status": "2",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-10-31 14:28:53",
"created_userid": "1480557",
"updated": "2023-10-31 14:28:53",
"updated_userid": "1480557",
"published": "Never",
"published_userid": "",
"status_changed": "1970-01-01 10:00:00",
"status_changed_userid": "",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "BROWSE BY DISCIPLINE",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1482487",
"parents": [
{
"linkid": "3162585",
"link_type": "1",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482472",
"1482473",
"1482479",
"1482487"
]
}
],
"childrenIds": [],
"yearCreated": 2023
},
{
"name": "Science",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/student/nav-middle/courses/browse-by-discipline/science",
"id": 1482434,
"status": "Under Construction",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/student/nav-middle/courses/browse-by-discipline/science\">Science</a>",
"file": "page_redirect",
"publish": "Never",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "",
"parentData": {
"assetid": "1482487",
"type_code": "folder",
"version": "0.0.0",
"name": "BROWSE BY DISCIPLINE",
"short_name": "BROWSE BY DISCIPLINE",
"status": "2",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-10-31 14:28:53",
"created_userid": "1480557",
"updated": "2023-10-31 14:28:53",
"updated_userid": "1480557",
"published": "Never",
"published_userid": "",
"status_changed": "1970-01-01 10:00:00",
"status_changed_userid": "",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "BROWSE BY DISCIPLINE",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1482487",
"parents": [
{
"linkid": "3162586",
"link_type": "1",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482472",
"1482473",
"1482479",
"1482487"
]
}
],
"childrenIds": [],
"yearCreated": 2023
},
{
"name": "Packaged course offers",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/courses/featured-courses/packaged-course-offers",
"id": 1482435,
"status": "Under Construction",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/courses/featured-courses/packaged-course-offers\">Packaged course offers</a>",
"file": "page_redirect",
"publish": "Never",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "",
"parentData": {
"assetid": "1481136",
"type_code": "folder",
"version": "0.0.1",
"name": "FEATURED COURSES",
"short_name": "FEATURED COURSES",
"status": "16",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-09-08 15:08:29",
"created_userid": "1480557",
"updated": "2023-09-26 09:18:36",
"updated_userid": "248053",
"published": "2023-09-26 09:18:36",
"published_userid": "248053",
"status_changed": "2023-09-26 09:18:36",
"status_changed_userid": "248053",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "FEATURED COURSES",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1481136",
"parents": [
{
"linkid": "3162502",
"link_type": "1",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482471",
"1481122",
"1481083",
"1481136"
]
}
],
"childrenIds": [],
"yearCreated": 2023
},
{
"name": "Short courses",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/courses/featured-courses/short-courses",
"id": 1482436,
"status": "Under Construction",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/standard/nav-middle/courses/featured-courses/short-courses\">Short courses</a>",
"file": "page_redirect",
"publish": "Never",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "",
"parentData": {
"assetid": "1481136",
"type_code": "folder",
"version": "0.0.1",
"name": "FEATURED COURSES",
"short_name": "FEATURED COURSES",
"status": "16",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-09-08 15:08:29",
"created_userid": "1480557",
"updated": "2023-09-26 09:18:36",
"updated_userid": "248053",
"published": "2023-09-26 09:18:36",
"published_userid": "248053",
"status_changed": "2023-09-26 09:18:36",
"status_changed_userid": "248053",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "FEATURED COURSES",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1481136",
"parents": [
{
"linkid": "3162503",
"link_type": "1",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482471",
"1481122",
"1481083",
"1481136"
]
}
],
"childrenIds": [],
"yearCreated": 2023
},
{
"name": "Standard",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/standard",
"id": 1482471,
"status": "Under Construction",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/standard\">Standard</a>",
"file": "folder",
"publish": "Never",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "null",
"parentData": {
"assetid": "1481016",
"type_code": "folder",
"version": "0.0.1",
"name": "Header Nav",
"short_name": "Header Nav",
"status": "16",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-09-01 16:25:36",
"created_userid": "1480557",
"updated": "2023-09-26 09:18:32",
"updated_userid": "248053",
"published": "2023-09-26 09:18:32",
"published_userid": "248053",
"status_changed": "2023-09-26 09:18:32",
"status_changed_userid": "248053",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "Header Nav",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1481016",
"parents": [
{
"linkid": "3162556",
"link_type": "2",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016"
]
}
],
"childrenIds": [
"1481122",
"1481123"
],
"yearCreated": 2023
},
{
"name": "Student",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/student",
"id": 1482472,
"status": "Under Construction",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/student\">Student</a>",
"file": "folder",
"publish": "Never",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "null",
"parentData": {
"assetid": "1481016",
"type_code": "folder",
"version": "0.0.1",
"name": "Header Nav",
"short_name": "Header Nav",
"status": "16",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-09-01 16:25:36",
"created_userid": "1480557",
"updated": "2023-09-26 09:18:32",
"updated_userid": "248053",
"published": "2023-09-26 09:18:32",
"published_userid": "248053",
"status_changed": "2023-09-26 09:18:32",
"status_changed_userid": "248053",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "Header Nav",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1481016",
"parents": [
{
"linkid": "3162559",
"link_type": "2",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016"
]
}
],
"childrenIds": [
"1482473",
"1482474"
],
"yearCreated": 2023
},
{
"name": "Nav Middle",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/student/nav-middle",
"id": 1482473,
"status": "Under Construction",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/student/nav-middle\">Nav Middle</a>",
"file": "folder",
"publish": "Never",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "null",
"parentData": {
"assetid": "1482472",
"type_code": "folder",
"version": "0.0.0",
"name": "Student",
"short_name": "Student",
"status": "2",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-10-31 14:26:46",
"created_userid": "1480557",
"updated": "2023-10-31 14:26:46",
"updated_userid": "1480557",
"published": "Never",
"published_userid": "",
"status_changed": "1970-01-01 10:00:00",
"status_changed_userid": "",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "Student",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1482472",
"parents": [
{
"linkid": "3162560",
"link_type": "2",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482472"
]
}
],
"childrenIds": [
"1482478",
"1482479",
"1482480"
],
"yearCreated": 2023
},
{
"name": "Nav last",
"url": "https://wwwdev.latrobe.edu.au/sandbox/nav/student/nav-last",
"id": 1482474,
"status": "Under Construction",
"link": "<a href=\"https://wwwdev.latrobe.edu.au/sandbox/nav/student/nav-last\">Nav last</a>",
"file": "folder",
"publish": "Never",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "null",
"parentData": {
"assetid": "1482472",
"type_code": "folder",
"version": "0.0.0",
"name": "Student",
"short_name": "Student",
"status": "2",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-10-31 14:26:46",
"created_userid": "1480557",
"updated": "2023-10-31 14:26:46",
"updated_userid": "1480557",
"published": "Never",
"published_userid": "",
"status_changed": "1970-01-01 10:00:00",
"status_changed_userid": "",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "Student",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1482472",
"parents": [
{
"linkid": "3162561",
"link_type": "2",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482472"
]
}
],
"childrenIds": [
"1482475",
"1482476",
"1482477"
],
"yearCreated": 2023
},
{
"name": "About",
"url": "/about.html",
"id": 1482475,
"status": "Under Construction",
"link": "<a href=\"/about.html\">About</a>",
"file": "page_redirect",
"publish": "Never",
"thumbnail_url": "",
"thumbnail_alt": "",
"description": "",
"parentData": {
"assetid": "1482474",
"type_code": "folder",
"version": "0.0.0",
"name": "Nav last",
"short_name": "Nav last",
"status": "2",
"force_secure": "0",
"languages": "en",
"charset": "utf-8",
"created": "2023-10-31 14:27:04",
"created_userid": "1480557",
"updated": "2023-10-31 14:27:04",
"updated_userid": "1480557",
"published": "Never",
"published_userid": "",
"status_changed": "1970-01-01 10:00:00",
"status_changed_userid": "",
"thumbnail": "",
"attributes": {
"name": {
"attrid": "1",
"type": "text",
"value": "Nav last",
"is_contextable": true,
"use_default": true
}
},
"metadata": []
},
"parent": "1482474",
"parents": [
{
"linkid": "3162562",
"link_type": "1",
"link_value": "",
"lineage": [
"1480581",
"1480676",
"1480677",
"1481016",
"1482472",
"1482474"
]
}
],
"childrenIds": [],
"yearCreated": 2023
},
]
}
}

};
